import React from 'react'
import { Dropdown, Menu, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import ScoreConfirm from './ScoreConfirm'

export default ({ typeLists, onScoresChange }) => {
    const TypeMenu = () => {
        const onMenuClick = (list) => {
            ScoreConfirm({
                onConfirm: (score) => {
                    const scores = list.map((id) => ({ index: 0, questionId: id, score }))
                    onScoresChange(scores)
                },
            })
        }
        return (
            <Menu style={{ textAlign: 'center' }}>
                {typeLists
                    ?.filter((t) => t.key !== 'Analyze' && t.key !== 'Operate')
                    ?.map((type) => (
                        <Menu.Item key={type.key} onClick={() => onMenuClick(type.list)}>
                            <span>{type.name}</span>
                        </Menu.Item>
                    ))}
            </Menu>
        )
    }
    return (
        <Dropdown overlay={TypeMenu} placement="bottomCenter">
            <Button className="score-btn">
                批量赋分
                <DownOutlined />
            </Button>
        </Dropdown>
    )
}
