import React, { useState, useEffect } from 'react'
import { recordAction } from '../../../../services/CandidateRequest'
import './FullscreenButton.styl'

export default function FullscreenButton() {
    const isFullscreen = () => {
        return document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
    }

    const [fullscreen, setFullscreen] = useState(isFullscreen())

    const toggleFullscreen = () => {
        const isFull = isFullscreen()
        const el = document.documentElement || {}
        try {
            if (!isFull) {
                // 进入全屏
                if (el.requestFullscreen) el.requestFullscreen()
                else if (el.mozRequestFullScreen) el.mozRequestFullScreen()
                else if (el.webkitRequestFullscreen) el.webkitRequestFullscreen()
                else if (el.msRequestFullscreen) el.msRequestFullscreen()
            } else {
                // 退出全屏
                if (document.exitFullscreen && document.fullscreenElement) document.exitFullscreen()
                if (document.mozCancelFullScreen && document.fullscreenElement) document.mozCancelFullScreen()
                if (document.webkitExitFullscreen && document.fullscreenElement) document.webkitExitFullscreen()
            }
            setFullscreen(!isFull)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const existFullscreen = () => {
            if (!isFullscreen()) {
                setFullscreen((prev) => {
                    prev && recordAction('switchFullscreen')
                    return false
                })
            } else {
                setFullscreen(true)
            }
        }
        window.addEventListener('resize', existFullscreen)
        return () => {
            window.removeEventListener('resize', existFullscreen)
        }
    })

    return (
        <div className={'fullscreen-button' + (fullscreen ? '' : ' show')} onClick={toggleFullscreen}>
            全屏
        </div>
    )
}
