import React, { useState } from 'react'
import produce from 'immer'
import { Checkbox } from 'antd'

import { answerNos, typeNames } from '../configs'
import ImageOption from '../components/ImageOption'
import Answer from '../components/Answer'

import './common.styl'

const MultipleChoice = ({ stepData, editAnswer, onChange, preview, answerVisible }) => {
    const [customAnswer, setCustomAnswer] = useState(null)

    const answer = editAnswer || customAnswer || stepData?.userAnswer || {}
    const onCustomChange = (e) => {
        const value = e.target.name
        const newAnswer = produce(answer, (draft) => {
            const options = draft.questionAnswer?.options || []
            options[0] = value
            draft.questionAnswer = {
                options,
                type: stepData.type,
            }
        })
        if (onChange) {
            onChange(newAnswer)
        } else {
            setCustomAnswer(newAnswer)
        }
    }

    return (
        <div className={'exam-question question-multiple-choice' + (!stepData.id ? ' child-question' : '')}>
            <div className="question-header">
                <div className="question-no">{(stepData.index || 0) + 1}</div>
                <div className="question-title">
                    {(preview || !stepData.id) && <span className="question-type">【{typeNames[stepData.type]}】</span>}
                    {stepData.content}
                    <span className="question-score">{`${stepData.score}分`}</span>
                </div>
            </div>
            {stepData.image && (
                <div className="question-image">
                    <img src={stepData.image} />
                </div>
            )}
            <div className="question-answer">
                {stepData.options?.map((option, index) => {
                    const no = answerNos[index]
                    const checked = answer?.questionAnswer?.options?.indexOf(no) > -1
                    return (
                        <Checkbox
                            className={'question-option radio-style' + (option.type === 'Image' ? ' img-option' : '')}
                            key={index}
                            name={no}
                            checked={checked}
                            onChange={onCustomChange}
                        >
                            <span>{no}. </span>
                            {option.type === 'Text' ? (
                                <span className="option-content">{option.content}</span>
                            ) : (
                                <ImageOption src={option.content} />
                            )}
                        </Checkbox>
                    )
                })}
            </div>
            <Answer visible={answerVisible} answer={stepData.answer} explanation={stepData.explanation} />
        </div>
    )
}

export default MultipleChoice
