import React, { useEffect, useState } from 'react'
import moment from 'moment'

const Countdown = ({ start, onEnd }) => {
    const [time, setTime] = useState(10000)

    useEffect(() => {
        const getTime = async () => {
            try {
                const aheadTime = await window.getCurrentTime()
                const current = new Date().valueOf() + aheadTime
                if (current < start) {
                    setTime(start - current)
                }
            } catch (error) {}
        }
        getTime()
    }, [start])
    useEffect(() => {
        let interval = null
        if (time > 0) {
            interval = setInterval(
                () =>
                    setTime((prev) => {
                        if (prev <= 1000) {
                            setTimeout(() => {
                                onEnd()
                            }, 50)
                            return 0
                        }
                        return prev - 1000
                    }),
                1000
            )
        }
        return () => {
            interval && clearInterval(interval)
        }
    }, [time, onEnd])

    const format = time < 1000 * 60 ? 's秒' : 'm分s秒'
    if (time < 1000) return null

    return <div className="button-countdown">（{moment(time).utc().format(format)}）</div>
}

export default Countdown
