//cSpell:word Bian Geng Feng
import React from 'react'
import produce from 'immer'
import { Form, Row, Col, Input, Select, Button, DatePicker, Table } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'

import './QiFeng.styl'

const ZhiGongBianGengYeWuChaXu = ({ data, selectChecked, prevUrl }) => {
    const prevData = data.editAnswer?.questionAnswer?.operationRecord || []
    let prevList = []
    prevData.forEach((item) => {
        if (item.operateObject.length > 1) {
            item.operateObject.forEach((s) => {
                prevList.push(s)
            })
        } else {
            prevList.push(item.operateObject[0])
        }
    })
    const prev = produce(prevList, (draft) => {
        draft.reverse()
        draft.forEach((d, index) => {
            d.key = index
        })
    })
    const initialValues = {
        housing: '住房公积金',
        result: '全部',
    }

    const columns = [
        {
            title: '网上业务编号',
            width: 120,
            dataIndex: '网上业务编号',
            key: '网上业务编号',
            fixed: 'left',
            render: () => <span>XXX</span>,
        },
        {
            title: '操作日期',
            width: 90,
            dataIndex: 'XX',
            key: '操作日期',
            fixed: 'left',
            render: () => <span>XXX</span>,
        },
        {
            title: '操作结果',
            key: '操作结果',
            width: 90,
            fixed: 'left',
            render: (record) => (
                <span className={!record.data?.操作结果 ? 'success' : 'fail'}>
                    {!record.data?.操作结果 ? '成功' : record.data?.操作结果}
                </span>
            ),
        },
        {
            title: '业务类型',
            key: '业务类型',
            width: 110,
            fixed: 'left',
            render: (record) => <span>{record.data?.操作类型 || ''}</span>,
        },
        {
            title: '职工名称',
            key: '职工名称',
            width: 100,
            fixed: 'left',
            render: (record) => <span>{record.data?.姓名 || ''}</span>,
        },
        {
            title: '身份证号',
            key: '身份证号',
            width: 200,
            render: (record) => <span>{record.data.身份证号码 || record?.identity}</span>,
        },
        {
            title: '账户类型',
            key: '账户类型',
            width: 110,
            render: (record) => (
                <span>
                    {record.data?.账户类型 === '住房公积金' || record.data?.账户类型 === '补充住房公积金'
                        ? record.data?.账户类型
                        : '住房公积金'}
                </span>
            ),
        },
        {
            title: '公积金账号',
            key: '公积金账号',
            width: 140,
            render: (record) => (
                <span>{record.data?.个人账号 || record.data?.职工账号 || record.data?.公积金账号}</span>
            ),
        },
        {
            title: '缴存基数',
            key: '缴存基数',
            width: 100,
            render: (record) => (record.data?.操作类型 === '转入' ? '' : <span>{record.data?.工资 || ''}</span>),
        },
        {
            title: '缴存比例',
            key: '缴存比例',
            width: 90,
            render: (record) => (record.data?.操作类型 === '转入' ? '' : <span>7</span>),
        },
        {
            title: '缴存额',
            key: '缴存额',
            width: 90,
            render: (record) =>
                record.data?.操作类型 === '转入' ? '' : <span>{Math.round(record.data?.月缴存额) || ''}</span>,
        },
        {
            title: '转入单位名称',
            key: '转入单位名称',
            width: 150,
            render: (record) => <span>{record.data?.单位名称 || ''}</span>,
        },
        {
            title: '转入单位账号',
            key: '转入单位账号',
            width: 150,
            render: (record) => <span>{record.data?.单位账号 || ''}</span>,
        },
        {
            title: '停缴期限',
            key: '停缴期限',
            width: 100,
            render: (record) => <span>{record.data.停缴期限 ? record.data.停缴期限 + '个月' : ''}</span>,
        },
        {
            title: '停缴原因',
            key: '停缴原因',
            width: 100,
            render: (record) => <span>{record.data?.停缴原因 || ''}</span>,
        },
        {
            title: '备注',
            key: '备注',
            width: 100,
            render: (record) => <span>{record.data?.备注 || ''}</span>,
        },
    ]
    return (
        <div className="gjj-QiFeng-page">
            <div className="QiFeng-header">
                <Link to={prevUrl} onClick={selectChecked} className="current">
                    首页
                </Link>
                &gt;
                <span className="header-personal">职工变更业务查询</span>
            </div>
            <div className="QiFeng-main">
                <div className="main-header">
                    <Form className="search-form" initialValues={initialValues} labelCol={{ flex: '88px' }}>
                        <Row gutter={24} className="form-row">
                            <Col span={10}>
                                <Form.Item label="账户类型" name="housing">
                                    <Select style={{ width: '62.26%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label="业务类型">
                                    <Select placeholder="请选择" style={{ width: '62.26%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24} className="form-row">
                            <Col span={10}>
                                <Form.Item label="公积金账号">
                                    <Input style={{ width: '62.26%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label="身份证号">
                                    <Input style={{ width: '62.26%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24} className="form-row">
                            <Col span={10}>
                                <Form.Item label="姓名[%]">
                                    <Input style={{ width: '62.26%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label="开始时间">
                                    <DatePicker
                                        style={{ width: '62.26%' }}
                                        defaultValue={moment('2021-03-31', 'YYYY-MM-DD')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24} className="form-row">
                            <Col span={10}>
                                <Form.Item label="操作结果" name="result">
                                    <Select style={{ width: '62.26%' }} />
                                </Form.Item>
                            </Col>

                            <Col span={10}>
                                <Form.Item label="结束时间">
                                    <DatePicker
                                        style={{ width: '62.26%' }}
                                        defaultValue={moment('2021-03-31', 'YYYY-MM-DD')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24} className="form-btn">
                            <Col span={24} style={{ textAlign: 'left' }}>
                                <Button>查询</Button>
                                <Button className="submit-btn">收起查询</Button>
                                <span className="form-tagging">注：[%]为模糊搜索</span>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="main-button">
                    <Button>回执单</Button>
                    <Button>打印表单</Button>
                    <Button>导出</Button>
                    <Button className="btn-four">打印汇缴书</Button>
                    <Button className="btn-five">生成补缴清册</Button>
                </div>
                <Table
                    className="main-table"
                    columns={columns}
                    dataSource={prev}
                    scroll={{ x: 'max-content' }}
                    rowKey="key"
                    bordered
                />
            </div>
        </div>
    )
}

export default ZhiGongBianGengYeWuChaXu
