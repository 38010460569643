export const types = [
    { name: '单选题', value: 'MultipleChoice' },
    { name: '多选题', value: 'MultipleSelection' },
    { name: '判断题', value: 'TrueOrFalse' },
    { name: '排序题', value: 'Sorting' },
    { name: '简答题', value: 'Text' },
    { name: '情景分析题', value: 'Analyze' },
]

export const levels = [
    { name: '初级', value: 'Beginner' },
    { name: '中级', value: 'Intermediate' },
    { name: '高级', value: 'Advanced' },
]

export const subjects = [
    { name: '理论', value: 'Theory' },
    { name: '实操', value: 'Practice' },
]

export const fields = [
    { name: '人事服务', value: 'A' },
    { name: '薪税服务', value: 'B' },
    { name: '招聘支持', value: 'C' },
    { name: '培训服务', value: 'D' },
    { name: '薪酬福利', value: 'E' },
    { name: '招聘服务', value: 'F' },
    { name: 'HRSSC运营管理', value: 'G' },
    { name: 'HR产品设计与运营', value: 'H' },
    { name: '人力资源数字化应用', value: 'I' },
    { name: '人力资源共享服务职业基础', value: 'J' },
]

export const typeNames = {
    MultipleChoice: '单选题',
    MultipleSelection: '多选题',
    TrueOrFalse: '判断题',
    Sorting: '排序题',
    Text: '简答题',
    Analyze: '情景分析题',
    Operate: '实操题',
}

export const levelNames = {
    Beginner: '初级',
    Intermediate: '中级',
    Advanced: '高级',
}

export const subjectNames = {
    Theory: '理论',
    Practice: '实操',
}

export const fieldNames = fields.reduce((acc, item) => {
    acc[item.value] = item.name
    return acc
}, {})

export const indexNo = 'ABCDEFGHIJKLMNOPQRST'

const commonData = {
    certificate: '人力资源共享服务',
    level: 'Beginner',
    subject: 'Theory',
    content: '',
    field: 'A',
    image: '',
    options: [],
    tags: [],
    explanation: '',
}

export const defaultData = {
    MultipleChoice: {
        type: 'MultipleChoice',
        optionType: 'Text',
        answer: { options: [], type: 'MultipleChoice' },
        ...commonData,
    },
    MultipleSelection: {
        type: 'MultipleSelection',
        optionType: 'Text',
        answer: { options: [], type: 'MultipleSelection' },
        ...commonData,
    },
    TrueOrFalse: {
        type: 'TrueOrFalse',
        optionType: 'Text',
        answer: { options: [], type: 'TrueOrFalse' },
        ...commonData,
        options: [
            { no: 'A', content: '正确', type: 'Text' },
            { no: 'B', content: '错误', type: 'Text' },
        ],
    },
    Sorting: {
        type: 'Sorting',
        optionType: 'Text',
        answer: { options: [], type: 'Sorting' },
        ...commonData,
    },
    Text: {
        type: 'Text',
        answer: { content: '', type: 'Text' },
        ...commonData,
    },
    Analyze: {
        type: 'Analyze',
        ...commonData,
    },
}
