import React from 'react'
import Loading from '../../../components/loading'
import './layout.styl'
import ellipse from '../../../assets/椭圆形.png'
import ellipses from '../../../assets/椭圆形2.png'

const Layout = ({ loading, children }) => (
    <div className="login-layout">
        <div className="login-back">
            <img src={ellipse} alt="" className="ellipse" />
            <img src={ellipses} alt="" className="ellipse2" />
        </div>

        <div className="login-box">{loading ? <Loading /> : <div className="login-content">{children}</div>}</div>
    </div>
)

export default Layout
