import React from 'react'
import { Form, Row, Col, Input, Select, Button } from 'antd'
import { removeEmptyKeys } from '../../../../common/utils'

const Option = Select.Option

const ListFilter = ({ onFilter }) => {
    const [form] = Form.useForm()

    const onFinish = (values) => {
        onFilter(removeEmptyKeys(values))
    }

    const onReset = () => {
        form.resetFields()
        onFilter({})
    }

    return (
        <Form form={form} className="search-form" onFinish={onFinish} labelCol={{ flex: '80px' }}>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item name="type" label="类型">
                        <Select placeholder="全部">
                            <Option value="">全部</Option>
                            <Option value="Official">正式考试</Option>
                            <Option value="Simulation">模拟考试</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="subject" label="科目">
                        <Select placeholder="全部">
                            <Option value="">全部</Option>
                            <Option value="Theory">理论知识</Option>
                            <Option value="Practice">实操技能</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="level" label="等级">
                        <Select placeholder="全部">
                            <Option value="">全部</Option>
                            <Option value="Beginner">初级</Option>
                            <Option value="Intermediate">中级</Option>
                            <Option value="Advanced">高级</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="progress" label="进度">
                        <Select placeholder="全部">
                            <Option value="">全部</Option>
                            <Option value="待考试">待考试</Option>
                            <Option value="考试中">考试中</Option>
                            <Option value="已结束">已结束</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item name="scoreStatus" label="评分状态">
                        <Select placeholder="全部">
                            <Option value="">全部</Option>
                            <Option value="Init">待评分</Option>
                            <Option value="Done">已评分</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="status" label="发布状态">
                        <Select placeholder="全部">
                            <Option value="">全部</Option>
                            <Option value="Release">已发布</Option>
                            <Option value="Unpublished">未发布/停用</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="key" label="名称/ID">
                        <Input placeholder="输入考试名称或 ID" />
                    </Form.Item>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <Button type="primary" style={{ marginRight: '10px' }} htmlType="submit">
                        搜索
                    </Button>
                    <Button onClick={onReset}>重置</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default ListFilter
