import React, { useState } from 'react'
import { Form, Input, Button, Checkbox, Radio } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import produce from 'immer'
import { UploadView } from './Upload'
import { indexNo } from '../configs'

const TypeSelect = ({ defaultValue, onChange }) => {
    return (
        <Radio.Group defaultValue={defaultValue} onChange={(e) => onChange(e.target.value)}>
            <Radio value="Text">文本类型</Radio>
            <Radio value="Image">
                图片类型
                <span className="item-extra">格式要求：png/jpg/jpeg</span>
            </Radio>
        </Radio.Group>
    )
}

// type: text | textOrPic | trueOrFalse
// 因需要在切换选项类型时保留之前编辑的内容，故用 options/imgOptions 来分别保存两种类型下的表单数据
export default ({ type, radio, checkbox, sorting, form, prevPath, childrenIndex = 0 }) => {
    const isAnalyze = prevPath.length > 0
    const questions = form.getFieldValue('baseQuestions') || []
    const defaultRadioValue = isAnalyze ? questions[childrenIndex]?.radioValue : form.getFieldValue('radioValue')
    // 记录单选题当前选中的项目,当删除选项时用来更新页面
    const [currentValue, setCurrentValue] = useState(defaultRadioValue)
    const defaultType = isAnalyze ? questions[childrenIndex]?.optionType : form.getFieldValue('optionType')
    const [optionType, setOptionType] = useState(defaultType)
    const listPath = [...prevPath, optionType === 'Text' ? 'options' : 'imgOptions']
    const SelectComponent = radio ? Radio : checkbox ? Checkbox : React.Fragment
    const onOptionTypeChange = (value) => {
        // 普通题型，直接保存在最外层
        if (!isAnalyze) {
            form.setFieldsValue({ optionType: value })
            setOptionType(value)
            return
        }
        // 情景分析题，需保存在对应的小题中
        const newQuestions = produce(form.getFieldValue('baseQuestions'), (draft) => {
            draft[childrenIndex].optionType = value
        })
        form.setFieldsValue({ baseQuestions: newQuestions })
        setOptionType(value)
    }
    const onRadioValueChange = (e) => {
        const value = e.target.value
        // 普通题型，直接保存在最外层
        if (!isAnalyze) {
            form.setFieldsValue({ radioValue: value })
            setCurrentValue(value)
            form.validateFields(listPath)
            return
        }
        // 情景分析题，需保存在对应的小题中
        const newQuestions = produce(form.getFieldValue('baseQuestions'), (draft) => {
            draft[childrenIndex].radioValue = value
        })
        form.setFieldsValue({ baseQuestions: newQuestions })
        setCurrentValue(value)
        // 暂时做全局校验，没找到情景分析单独校验小题的方法
        form.validateFields()
    }
    const reHandleNo = (index) => {
        // 更新项目的no字段
        // 普通题型，直接保存在最外层
        if (!isAnalyze) {
            // 如果是单选题则还需要处理之前选中的答案
            const handleRadio = () => {
                const prevRadioValue = form.getFieldValue('radioValue')
                // 将已选择的答案指向删除前的选项；若是选中项被删除则清除答案
                const currentIndex = indexNo.indexOf(prevRadioValue)
                if (currentIndex === index) {
                    form.setFieldsValue({ radioValue: '' })
                    setCurrentValue('')
                } else if (currentIndex > index) {
                    form.setFieldsValue({ radioValue: indexNo[currentIndex - 1] })
                    setCurrentValue(indexNo[currentIndex - 1])
                }
            }
            if (radio) handleRadio()

            const key = optionType === 'Text' ? 'options' : 'imgOptions'
            const prevOptions = form.getFieldValue(key)
            const newOptions = produce(prevOptions, (draft) => {
                draft.forEach((option, index) => {
                    option.no = indexNo[index]
                })
            })
            form.setFieldsValue({ [key]: newOptions })
            return
        }

        // 情景分析题，需保存在对应的小题中
        let nextValue = ''
        const newQuestions = produce(form.getFieldValue('baseQuestions'), (draft) => {
            const handleRadio = () => {
                const prevRadioValue = draft[childrenIndex].radioValue
                const currentIndex = indexNo.indexOf(prevRadioValue)

                if (currentIndex === index) {
                    draft[childrenIndex].radioValue = ''
                    nextValue = ''
                } else if (currentIndex > index) {
                    draft[childrenIndex].radioValue = indexNo[currentIndex - 1]
                    nextValue = indexNo[currentIndex - 1]
                }
            }
            if (radio) handleRadio()

            const key = optionType === 'Text' ? 'options' : 'imgOptions'
            const prevOptions = draft[childrenIndex][key]
            prevOptions.forEach((option, index) => {
                option.no = indexNo[index]
            })
        })
        form.setFieldsValue({ baseQuestions: newQuestions })
        setCurrentValue(nextValue)
    }
    return (
        <>
            <Form.List
                name={listPath}
                rules={[
                    {
                        validator: (_, options) => {
                            // 此处校验时机有问题，加上规则默认就会提示
                            if (options.length < 2) {
                                return Promise.reject(new Error('至少需要两个选项'))
                            } else if (radio) {
                                const basePath = isAnalyze ? ['baseQuestions', ...prevPath] : prevPath
                                const answer = form.getFieldValue([...basePath, 'radioValue'])
                                // 单选题未选择答案
                                if (!answer) return Promise.reject(new Error('请选择正确答案'))
                            } else if (checkbox) {
                                let count = 0
                                options.forEach((o) => o.checked && count++)
                                if (count < 2) return Promise.reject(new Error('请至少选择两个答案'))
                            }
                            return Promise.resolve()
                        },
                    },
                ]}
            >
                {(fields, { add, remove }, { errors }) => {
                    // 列表渲染内容，需要根据列表类型使用不同包裹组件(Radio特殊处理)
                    const MainContent = fields.map((field, index) => (
                        <Form.Item required={false} key={field.key} noStyle>
                            <Form.Item
                                name={[field.name, checkbox ? 'checked' : 'no']}
                                valuePropName={checkbox ? 'checked' : 'value'}
                            >
                                <SelectComponent name="true-answer" style={{ width: '100%' }}>
                                    <span style={{ padding: '0 10px 0 6px' }}>{indexNo[index]}</span>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'content']}
                                        className="option-container"
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `          ${
                                                    optionType === 'Text' ? '请输入选项内容' : '请上传图片'
                                                }`,
                                            },
                                        ]}
                                        style={{ display: 'inline-block' }}
                                        noStyle
                                    >
                                        {optionType === 'Text' ? (
                                            <Input
                                                placeholder="请输入选项内容"
                                                style={{ width: '70%' }}
                                                readOnly={type === 'trueOrFalse'}
                                                bordered={type !== 'trueOrFalse'}
                                                maxLength={300}
                                            />
                                        ) : (
                                            <UploadView
                                                name={[field.name, 'content']}
                                                prevPath={prevPath}
                                                listType="picture"
                                            />
                                        )}
                                    </Form.Item>
                                    {type !== 'trueOrFalse' && (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            style={{ fontSize: '16px', padding: '4px 10px' }}
                                            onClick={() => {
                                                remove(field.name)
                                                reHandleNo(field.name, field.name >= fields.length - 1)
                                            }}
                                        />
                                    )}
                                </SelectComponent>
                            </Form.Item>
                        </Form.Item>
                    ))

                    return (
                        <Form.Item label="选项">
                            {type === 'textOrPic' && (
                                <TypeSelect defaultValue={optionType} onChange={onOptionTypeChange} />
                            )}
                            {radio ? (
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Radio.Group value={currentValue} onChange={onRadioValueChange}>
                                        {MainContent}
                                    </Radio.Group>
                                </Form.Item>
                            ) : (
                                MainContent
                            )}
                            {type !== 'trueOrFalse' && fields.length < 10 && (
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            const base = { no: indexNo[fields.length], type: optionType }
                                            if (sorting) base.order = 'A'
                                            add(base)
                                        }}
                                        style={{ width: '60%' }}
                                        icon={<PlusOutlined />}
                                    >
                                        增加选项
                                    </Button>
                                </Form.Item>
                            )}
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    )
                }}
            </Form.List>
            <Form.Item name={[...prevPath, 'radioValue']} hidden>
                <Input />
            </Form.Item>
            <Form.Item name={[...prevPath, 'optionType']} hidden>
                <Input />
            </Form.Item>
        </>
    )
}
