import React from 'react'
import { Row, Col, Badge } from 'antd'
import { formatDate } from '../../../../common/utils'
import './DetailView.styl'

const ItemRow = ({ label, value, children }) => (
    <Row wrap={false}>
        <Col flex="none">
            <div className="item-label">{`${label}：`}</div>
        </Col>
        <Col fill="auto" className="item-value">
            {!value && value !== 0 ? children : value}
        </Col>
    </Row>
)

export default ({ data }) => {
    return (
        <div className="paper-detail">
            <ItemRow label="试卷说明" value={data.description} />
            <ItemRow
                label="公开状态"
                value={data.open ? <Badge status="success" text="公开" /> : <Badge status="default" text="未公开" />}
            />
            <ItemRow label="创建人" value={data.createdUserName} />
            <ItemRow label="创建时间" value={formatDate(data.createdDate, true, '-')} />
            <ItemRow label="最后修改人" value={data.modifiedUserName} />
            <ItemRow label="最后修改时间" value={formatDate(data.modifiedDate, true, '-')} />
            <ItemRow label="试卷使用次数" value={data.examIds?.length || 0} />
            <ItemRow label="相关考试" value={data.paperNames || '无'} />
            <ItemRow label="题目信息" value=" " />
        </div>
    )
}
