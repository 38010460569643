import React from 'react'
import { Form, Row, Col, Select } from 'antd'
import { fields } from '../configs'
const Option = Select.Option

export default ({ type }) => (
    <Row gutter={24}>
        <Col span={6}>
            <Form.Item name="certificate" label="证书">
                <div>人力资源共享服务</div>
            </Form.Item>
        </Col>
        <Col span={6}>
            <Form.Item name="level" label="等级">
                <Select>
                    <Option value="Beginner">初级</Option>
                    <Option value="Intermediate">中级</Option>
                    <Option value="Advanced">高级</Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={6}>
            <Form.Item name="subject" label="科目">
                <Select disabled={type === 'Operate'}>
                    <Option value="Theory">理论</Option>
                    <Option value="Practice">实操</Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={6}>
            <Form.Item name="field" label="领域">
                <Select options={fields.map((it) => ({ label: it.name, value: it.value }))} />
            </Form.Item>
        </Col>
    </Row>
)
