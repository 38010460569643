//cSpell:word Jiao Tiao Zheng Zhuan
import React, { useState } from 'react'
import produce from 'immer'
import { Form, Space, Row, Col } from 'antd'
import Confirm from '../components/Confirm'
import Input from '../components/Input'
import SearchPanel from '../components/SearchPanel'
import Button from '../components/Button'
import { BaseInfo } from './SheBaoZhuanRu'
import { round } from 'lodash'

const defaultData = {
    调整后缴费基数: '-',
    养保单位缴纳金额: '-',
    养保个人缴纳金额: '-',
    医保单位缴纳金额: '-',
    医保个人缴纳金额: '-',
    失保单位缴纳金额: '-',
    失保个人缴纳金额: '-',
}

const JiaoFeiJiShuTiaoZheng = ({ data, onNewRecordSave }) => {
    const [user, setUser] = useState(null)
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()

    const onSearchConfirm = (result) => {
        setUser(null)
        form.resetFields()
        if (!result) return
        const newUser = {
            identity: result.identity,
            data: {
                ...result.data,
                ...defaultData,
            },
        }
        setUser(newUser)
        form.setFieldsValue(newUser)
    }

    const onResetForm = () => {
        setUser(null)
        form.resetFields()
        searchForm.resetFields()
    }

    const onCalculate = () => {
        const prevData = form.getFieldValue('data')
        const newData = produce(prevData, (draft) => {
            const number = Number(draft.月平均工资性收入)
            if (!number) {
                for (const key in defaultData) {
                    if (draft[key]) draft[key] = '-'
                }
                return draft
            }
            draft.月平均工资性收入 = `${number}`
            draft.调整后缴费基数 = `${number}`
            draft.养保单位缴纳金额 = `${round(number * 0.16, 2)}`
            draft.养保个人缴纳金额 = `${round(number * 0.08, 2)}`
            draft.医保单位缴纳金额 = `${round(number * 0.105, 2)}`
            draft.医保个人缴纳金额 = `${round(number * 0.02, 2)}`
            draft.失保单位缴纳金额 = `${round(number * 0.005, 2)}`
            draft.失保个人缴纳金额 = `${round(number * 0.005, 2)}`
        })
        form.setFieldsValue({ data: newData })
    }

    const onFinish = (values) => {
        Confirm({
            title: '考试系统提示',
            content: '提交后，不可修改或删除信息。是否提交？',
            onOk: () => {
                const obj = produce(values, (draft) => {})
                onNewRecordSave({
                    type: '社保缴费基数调整',
                    operateObject: [obj],
                }).then(onResetForm)
            },
        })
    }

    const renderChildren = () => {
        if (!user) return null
        return (
            <Form className="data-form sub-page-j-f-j-s" form={form} onFinish={onFinish} initialValues={user}>
                <BaseInfo />
                <div className="panel extra-info-panel">
                    <div className="panel-title">申报信息</div>
                    <div className="panel-content">
                        <Form.Item name={['data', '原缴费基数']} label="原缴费基数（元）">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name={['data', '调整起始年月']} label="调整起始年月">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name={['data', '调整结束年月']} label="调整结束年月">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item label="月平均工资性收入" name={['data', '月平均工资性收入']}>
                            <Space>
                                <Form.Item noStyle>
                                    <Input maxLength={10} />
                                </Form.Item>
                                <div className="form-tip">元</div>
                                <Button className="theme" onClick={onCalculate}>
                                    确定
                                </Button>
                            </Space>
                        </Form.Item>
                        <Form.Item name={['data', '调整后缴费基数']} label="调整后缴费基数">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name={['data', '养保单位缴纳金额']} label="养保单位缴纳金额">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={['data', '养保个人缴纳金额']} label="养保个人缴纳金额">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name={['data', '医保单位缴纳金额']} label="医保单位缴纳金额">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={['data', '医保个人缴纳金额']} label="医保个人缴纳金额">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name={['data', '失保单位缴纳金额']} label="失保单位缴纳金额">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={['data', '失保个人缴纳金额']} label="失保个人缴纳金额">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="bottom-action">
                    <Button className="commit-btn" type="primary" htmlType="submit">
                        提交
                    </Button>
                    <Button className="cancel-btn" onClick={onResetForm}>
                        取消
                    </Button>
                </div>
            </Form>
        )
    }
    return (
        <div className="sb-detail-sub-page">
            <SearchPanel title="缴费基数调整人员" form={searchForm} data={data} onConfirm={onSearchConfirm} />
            {renderChildren()}
        </div>
    )
}

export default JiaoFeiJiShuTiaoZheng
