import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Breadcrumb } from 'antd'
import { find } from 'lodash'

import Loading from '../../../../components/loading'
import { getQuestion } from '../../../../services/QuestionRequest'
import { getPaperNames } from '../../../../services/PaperRequest'
import { getUserNames } from '../../../../services/UserRequest'
import DetailView from './DetailView'
import MultipleChoice from '../../../ks/exam/questions/MultipleChoice'
import MultipleSelection from '../../../ks/exam/questions/MultipleSelection'
import TrueOrFalse from '../../../ks/exam/questions/TrueOrFalse'
import Sorting from '../../../ks/exam/questions/Sorting'
import Text from '../../../ks/exam/questions/Text'
import Analyze from '../../../ks/exam/questions/Analyze'
import Operate from '../../../ks/exam/questions/Operate'

import { ExamProvider } from '../../../ks/exam/Context'

import './index.styl'

const QuestionDetail = () => {
    const { type, id } = useParams()
    const [data, setData] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            const data = await getQuestion(id)
            const userNames = await getUserNames([data.createdUser, data.modifiedUser])
            const paperNames = await getPaperNames(data.paperIds)
            data.createdUserName = find(userNames, (u) => u.id === data.createdUser)?.username || '-'
            data.modifiedUserName = find(userNames, (u) => u.id === data.modifiedUser)?.username || '-'
            data.paperNames = paperNames.map((p) => p.name).join('、')
            if (data.type === 'Analyze' || data.type === 'Operate') {
                data.baseQuestions.forEach((q, index) => {
                    q.index = index
                    q.userAnswer = q.answer || {
                        questionAnswer: {},
                        questionId: q.id,
                        questionIndex: index,
                    }
                })
            }
            setData(data)
        }
        fetchData()
    }, [id])
    if (!data.id) return <Loading />

    const state = {
        user: {},
        examData: { questions: {}, exam: {}, paper: {} },
        answers: {},
    }

    const RenderQuestion = () => {
        const type = data.type
        switch (type) {
            case 'MultipleChoice':
                return <MultipleChoice stepData={data} preview />
            case 'MultipleSelection':
                return <MultipleSelection stepData={data} preview />
            case 'Sorting':
                return <Sorting stepData={data} preview />
            case 'TrueOrFalse':
                return <TrueOrFalse stepData={data} preview />
            case 'Text':
                return <Text stepData={data} preview />
            case 'Analyze':
                return <Analyze staticPosition stepData={data} preview />
            case 'Operate':
                return <Operate staticPosition stepData={data} preview />
            default:
                break
        }
    }

    return (
        <div className="question-detail-page">
            <div className="page-header">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item key="/kw/question">
                        <Link to="/kw/question">题目管理</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>题目详情</Breadcrumb.Item>
                </Breadcrumb>
                <Button>
                    <Link to={`/kw/question/${type}/edit/${id}`}>编辑</Link>
                </Button>
            </div>

            <ExamProvider originData={state}>
                <div className="question-preview-container">{RenderQuestion()}</div>
            </ExamProvider>
            <DetailView data={data} />
        </div>
    )
}

export default QuestionDetail
