import React, { useState, useRef, useLayoutEffect } from 'react'
import { isBoolean } from 'lodash'
import { getAnswer } from '../configs'
import answerSrc from '../../../../assets/answer.png'
import explanationSrc from '../../../../assets/explanation.png'
import './Answer.styl'

const Reference = ({ reference }) => {
    const containerRef = useRef(null)
    const [fold, setFold] = useState()

    const style = {
        maxHeight: fold === false ? 'none' : '328px',
    }

    useLayoutEffect(() => {
        if (containerRef.current?.offsetHeight > 320) {
            setFold(true)
        }
    }, [])

    return (
        <div className="answer-view" style={style} ref={containerRef}>
            <div className="view-section">
                <img src={answerSrc} className="view-icon" />
                <div className="view-label">参考答案与解析</div>
            </div>
            <div className="view-reference scrollbar" dangerouslySetInnerHTML={{ __html: reference }} />
            {isBoolean(fold) && (
                <div className="fold-view">
                    <div className="fold-btn" onClick={() => setFold(!fold)}>
                        <span>{fold ? '展开' : '收起'}</span>
                        <i className={`iconfont icon-down ${fold ? '' : 'rotate-180'}`} />
                    </div>
                </div>
            )}
        </div>
    )
}

const AnswerAndExplanation = ({ answer, explanation }) => {
    const answerText = getAnswer(answer)
    return (
        <div className="answer-view">
            <div className="view-section">
                <img src={answerSrc} className="view-icon" />
                <div className="view-label">答案：</div>
                <div className="view-answer">{answerText || '无'}</div>
            </div>
            <div className="view-section">
                <img src={explanationSrc} className="view-icon" />
                <div className="view-label">解析：</div>
                <div className="view-explanation">{explanation || '无'}</div>
            </div>
        </div>
    )
}

const Answer = ({ visible, answer, explanation, reference }) => {
    if (!visible) return null
    if (reference) return <Reference reference={reference} />
    return <AnswerAndExplanation answer={answer} explanation={explanation} />
}

export default Answer
