import React from 'react'
import Modals from './Modal'
import message from '../../../../../components/message'
import Confirm from '../../../../../components/modal/Confirm'
import { deleteCandidateId } from '../../../../../services/ExamCandidateRequest'
import './ListMenu.styl'

const ListMenu = (record, settingData, setSettingData, getData, detailId) => {
    const handleSetup = () => {
        setSettingData({
            visible: true,
            record,
        })
    }
    const handleDelete = () => {
        Confirm({
            title: '删除确认',
            content: '是否确认删除？',
            okText: '确认',
            cancelText: '取消',
            onOk: () =>
                deleteCandidateId(detailId, record.id)
                    .then(() => {
                        message.success('操作成功！')
                        getData()
                    })
                    .catch(() => message.error('操作失败，请稍后重试！')),
        })
    }
    return (
        <div>
            <div className="list-menu">
                <div className="list-item-menu" onClick={handleSetup}>
                    <span>编辑</span>
                </div>
                <div className="list-item-menu" onClick={handleDelete}>
                    <span style={{ color: 'red' }}>删除</span>
                </div>
            </div>
            <Modals isModalVisible={settingData} setIsModalVisible={setSettingData} getData={getData} />
        </div>
    )
}

export default ListMenu
