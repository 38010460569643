import AxiosRequest from './AxiosRequest'
const url = 'candidate'

export const getCandidates = (id, params = '') => {
    return AxiosRequest.get(`exams/${id}/${url}?${params}`)
}

export const postOrPutCandidate = (id, data, isPost) => {
    if (isPost) {
        return AxiosRequest.post(`exams/${id}/${url}`, data)
    }
    return AxiosRequest.put(`exams/${id}/${url}/${data.id}`, data)
}

export const getCandidateOption = (id) => {
    return AxiosRequest.get(`exams/${id}/${url}/option`)
}

export const deleteCandidateId = (id, candidateId) => {
    return AxiosRequest.delete(`exams/${id}/${url}/${candidateId}`)
}

export const deleteCandidate = (id, ids = []) => {
    return AxiosRequest.delete(`exams/${id}/${url}?ids=${ids.join('&ids=')}`)
}

export const postCandidateByExcel = (id, fileName) => {
    return AxiosRequest.post(`exams/${id}/excel`, { fileName })
}

export const postCandidateByExcelScore = (id, fileName) => {
    return AxiosRequest.post(`exams/${id}/questions/score`, { fileName })
}

export const exportGetCandidate = (id, params = '') => {
    return AxiosRequest.get(`exams/${id}/${url}.xlsx?${params}`, { responseType: 'arraybuffer' })
        .then((data) => {
            fileDownload(data, '考生信息-批量导出.xlsx')
        })
        .catch(arraybufferCatch)
}

export const exportGetQuestion = (id, params = '') => {
    return AxiosRequest.get(`exams/${id}/questions.xlsx?${params}`, { responseType: 'arraybuffer' })
        .then((data) => {
            fileDownload(data, '主观题答案-批量导出.xlsx')
        })
        .catch(arraybufferCatch)
}

export const exportGetScore = (id, params = '', examName) => {
    return AxiosRequest.get(`exams/${id}/${url}/scores.xlsx?${params}`, { responseType: 'arraybuffer' })
        .then((data) => {
            fileDownload(data, `${examName}_科目成绩批量导出.xlsx`)
        })
        .catch(arraybufferCatch)
}

export const getCandidateScore = (id, candidateId) => {
    return AxiosRequest.get(`exams/${id}/${url}/${candidateId}/score`)
}

export const putCandidateScore = (data) => {
    return AxiosRequest.put(`exams/${url}/score`, data)
}

//使用了 responseType: 'arraybuffer' 所以 err.response.data 不再是 JSON
export const arraybufferCatch = (err) => {
    const utf8decoder = new TextDecoder()
    const u8arr = new Uint8Array(err.response.data)
    // 将二进制数据转为字符串
    const temp = utf8decoder.decode(u8arr)
    try {
        err.response.data = JSON.parse(temp)
    } catch (parseError) {
        console.log('序列化data失败', err, parseError)
    }
    return Promise.reject(err)
}

export const fileDownload = (data, filename, mime, bom) => {
    const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
    const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename)
    } else {
        const blobURL =
            window.URL && window.URL.createObjectURL
                ? window.URL.createObjectURL(blob)
                : window.webkitURL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', filename)
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }

        document.body.appendChild(tempLink)
        tempLink.click()

        // Fixes "webkit blob resource error 1"
        setTimeout(() => {
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(blobURL)
        }, 200)
    }
}
