// import React from 'react'
import { Modal } from 'antd'
import './Confirm.styl'

const CustomConfirm = ({ className, ...rest }) => {
    return Modal.confirm({
        className: `confirm-modal${className ? ` ${className}` : ''}`,
        icon: null,
        cancelText: '取消',
        okText: '确认',
        width: 460,
        closable: true,
        centered: true,
        ...rest,
    })
}

export default CustomConfirm

export const ErrorConfirm = ({ message, href, ...rest }) => {
    return CustomConfirm({
        width: 320,
        closable: false,
        className: 'error-confirm',
        content: (
            <div className="error-content">
                <div className="error-message">{message}</div>
            </div>
        ),
        onOk: () => {
            if (href) return (location.href = href)
        },
    })
}
