import React from 'react'
import produce from 'immer'
import moment from 'moment'
import 'moment/locale/zh-cn'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import { Form, Input, Select, DatePicker, Button } from 'antd'
import message from '../../../../components/message'
import { putExam } from '../../../../services/ExamsRequest'
const Option = Select.Option

const SetUpForm = ({ settingData, setSettingData, onRefresh }) => {
    const { record = {} } = settingData
    const [form] = Form.useForm()
    const handleOk = () => {
        const values = produce(form.getFieldsValue(true), (draft) => {
            draft.name = draft.name.trim()
        })
        putExam(record.id, values)
            .then(() => {
                setSettingData({ visible: false })
                message.success('操作成功！')
                onRefresh()
            })
            .catch((error) => {
                message.error(error?.response?.data?.message || '操作失败，请稍后重试！')
            })
    }
    const handleCancel = () => {
        setSettingData({ visible: false })
    }
    const initialValues = {
        name: record.name,
        type: record.type,
        subject: record.subject,
        level: record.level,
        startDate: moment(record.startDate),
        durationMinute: record.durationMinute,
        endDate: moment(record.endDate).format('YYYY-MM-DD HH:mm:ss'),
        notes: record.notes,
        pin: record.pin ? true : false,
    }
    return (
        <Form form={form} labelAlign="right" initialValues={initialValues} onFinish={handleOk} labelCol={{ span: 8 }}>
            <h3 className="top-h3">一、基础信息</h3>
            <Form.Item
                name="name"
                label="考试名称"
                rules={[{ required: true, whitespace: true, message: '请填写考试名称' }]}
            >
                <Input style={{ width: 240 }} />
            </Form.Item>
            <Form.Item label="证书">
                <div>人力资源共享服务职业技能等级证书</div>
            </Form.Item>
            <Form.Item
                name="type"
                label="类型"
                rules={[{ required: true, whitespace: true, message: '请选择考试类型' }]}
            >
                <Select placeholder="请选择" style={{ width: 240 }}>
                    <Option value="">请选择</Option>
                    <Option value="Official">正式考试</Option>
                    <Option value="Simulation">模拟考试</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="subject"
                label="科目"
                rules={[{ required: true, whitespace: true, message: '请选择考试科目' }]}
            >
                <Select placeholder="请选择" style={{ width: 240 }}>
                    <Option value="">请选择</Option>
                    <Option value="Theory">理论知识</Option>
                    <Option value="Practice">实操技能</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="level"
                label="等级"
                rules={[{ required: true, whitespace: true, message: '请选择考试等级' }]}
            >
                <Select placeholder="请选择" style={{ width: 240 }}>
                    <Option value="">请选择</Option>
                    <Option value="Beginner">初级</Option>
                    <Option value="Intermediate">中级</Option>
                    <Option value="Advanced">高级</Option>
                </Select>
            </Form.Item>
            <h3>二、时间规则</h3>
            <Form.Item name="startDate" label="开始时间" rules={[{ required: true, message: '请填写开始时间' }]}>
                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" locale={locale} />
            </Form.Item>
            <Form.Item name="durationMinute" label="考试时长" rules={[{ required: true, message: '请填写考试时长' }]}>
                <Input style={{ width: 100 }} addonAfter="分钟" />
            </Form.Item>
            <Form.Item name="endDate" label="结束时间">
                <Input readOnly style={{ border: 'none', boxShadow: 'none' }} />
            </Form.Item>
            <Form.Item label="允许提前登录时间">15 分钟</Form.Item>
            <Form.Item label="倒计时提醒">5 分钟</Form.Item>
            <h3>三、考试须知</h3>
            <Form.Item name="notes" style={{ marginLeft: '70px' }}>
                <Input.TextArea maxLength={500} autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
            <div className="right-action">
                <Button onClick={handleCancel}>取消</Button>
                <Button type="primary" htmlType="submit">
                    完成
                </Button>
            </div>
        </Form>
    )
}

export default SetUpForm
