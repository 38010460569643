import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './index.styl'

const Loading = ({ className, text }) => {
    // 父容器需设置 position: relative
    return (
        <span className={`loading ${className || ''}`}>
            <Spin indicator={<LoadingOutlined spin />} />
            {text && <span className="loading-text">{text || '加载中…'}</span>}
        </span>
    )
}

export default Loading
