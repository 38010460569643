import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import CreateForm from './CreateForm'
import './Header.styl'

const Header = ({ onRefresh }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <div className="page-header">
            <div className="page-title">考试管理</div>
            <Button type="primary" onClick={showModal}>
                创建考试
            </Button>
            <Modal
                title="创建考试"
                className="create-exam-modal"
                visible={isModalVisible}
                centered
                footer={null}
                onCancel={handleCancel}
                destroyOnClose={true}
            >
                <CreateForm onRefresh={onRefresh} setIsModalVisible={setIsModalVisible} />
            </Modal>
        </div>
    )
}

export default Header
