//cSpell:word Zhuan Jiao Feng
import React, { useState, useEffect } from 'react'
import produce from 'immer'
import { Button, Table, Checkbox, message, Form } from 'antd'
import { difference, union } from 'lodash'
import { useHistory, Link } from 'react-router-dom'

import Confirm from '../components/DaiZhuanChuTingJiaoModal'
import PublicForm from './PublicForm'
import './QiFeng.styl'

const DaiZhuanChuTingJiao = ({ data, selectChecked, onNewRecordSave, prevUrl }) => {
    const history = useHistory()
    const [selects, setSelects] = useState([])
    const [list, setList] = useState(data.question.presetData)
    const [form] = Form.useForm()

    useEffect(() => {
        const content = data.editAnswer.questionAnswer?.content || []
        setList(
            produce((draft) => {
                for (const item of content) {
                    const index = draft.findIndex((val) => val.identity === item.identity)
                    if (index > -1) {
                        draft[index] = Object.assign({}, draft[index], item)
                    } else {
                        draft.push(item)
                    }
                }
            })
        )
    }, [data])

    const prevData = list.filter((s) => !s.data.操作类型)
    const prevList = produce(prevData, (draft) => {
        draft.forEach((d, index) => {
            d.key = index
        })
    })

    const handleDelete = () => {
        const ss = prevList?.map((item) => item.identity) || []
        setSelects(difference(selects, ss))
    }

    const transferOut = () => {
        if (selects.length === 0) {
            message.warning({
                className: 'gjj-warning',
                content: <p style={{ float: 'right' }}>请选择需要待转出停缴的员工！</p>,
            })
        } else {
            let resData = []
            selects.forEach((select) => {
                resData.push(prevList.find((s) => s.identity === select))
            })

            Confirm(resData, form, onNewRecordSave, data, selectChecked, history, prevUrl, setSelects)
        }
    }
    const handleSelect = (e, identity, isSelect) => {
        e.stopPropagation()
        const newSelects = produce(selects, (draft) => {
            if (isSelect) {
                const idx = selects.indexOf(identity)
                draft.splice(idx, 1)
            } else {
                draft.push(identity)
            }
        })
        setSelects(newSelects)
    }

    const handleSelectAll = () => {
        const ss = prevList?.map((item) => item.identity) || []
        const unselects = difference(ss, selects)
        if (unselects.length === 0) {
            // 当前页已全选
            setSelects(difference(selects, ss))
        } else {
            setSelects(union(selects, ss))
        }
    }

    const columns = [
        {
            title: () => (
                <Checkbox
                    onClick={handleSelectAll}
                    checked={prevList.length === 0 ? false : selects.length === prevList?.length}
                />
            ),
            key: 'checkbox',
            fixed: 'left',
            render: (record) => {
                const selected = selects.indexOf(record.identity) !== -1
                return <Checkbox checked={selected} onClick={(e) => handleSelect(e, record.identity, selected)} />
            },
        },
        {
            title: '账户类型',
            key: '账户类型',
            render: (record) => (
                <span>
                    {record.data.账户类型 === '住房公积金' || record.data.账户类型 === '基本账户类型'
                        ? '基本账户类型'
                        : '补充账户类型'}
                </span>
            ),
        },
        {
            title: '职工账号',
            key: '职工账号',
            render: (record) => <span>{record.data.职工账号}</span>,
        },
        {
            title: '所属部门',
            key: '所属部门',
            render: (record) => <span>{record.data.所属部门}</span>,
        },
        {
            title: '工号',
            key: '工号',
            render: (record) => '-',
        },
        {
            title: '姓名',
            key: '姓名',
            render: (record) => <span>{record.data?.姓名 || '—'}</span>,
        },
        {
            title: '修正名',
            key: '修正名',
            render: (record) => '-',
        },
        {
            title: '身份证号',
            key: '身份证号',
            render: (record) => <span>{record?.identity || '—'}</span>,
        },
        {
            title: '账户状态',
            key: '账户状态',
            render: (record) => <span>{record.data?.账户状态 || '正常'}</span>,
        },
        {
            title: '工资',
            key: '工资',
            render: (record) => <span>{record.data?.工资 || '—'}</span>,
        },
        {
            title: '月缴存额',
            key: '月缴存额',
            render: (record) => <span>{record.data?.月缴存额 || '—'}</span>,
        },
        {
            title: '出生年月',
            key: '出生年月',
            render: (record) => <span>{record.data?.出生年月 || ''}</span>,
        },
        {
            title: '性别',
            key: '性别',
            render: (record) => <span>{record.data?.性别 || ''}</span>,
        },
    ]

    return (
        <div className="gjj-QiFeng-page">
            <div className="QiFeng-header">
                <Link to={prevUrl} onClick={selectChecked} className="current">
                    首页
                </Link>
                &gt;
                <span className="header-personal">待转出停缴</span>
            </div>
            <div className="QiFeng-main">
                <div className="main-header">
                    <PublicForm />
                </div>
                <div className="main-button">
                    <Button onClick={transferOut}>待转出停缴</Button>
                    <Button onClick={handleDelete}>清空</Button>
                    <Button>批量导入</Button>
                </div>
                <Table
                    columns={columns}
                    className="main-table"
                    dataSource={prevList}
                    pagination={false}
                    rowKey="key"
                    bordered
                    scroll={{ x: 'max-content' }}
                    style={{ margin: '20px 0 30px 0' }}
                />
            </div>
        </div>
    )
}

export default DaiZhuanChuTingJiao
