//cSpell:word gongjijin
import React from 'react'

import g_public from '../../../../../../resources/gongjijin/公告.png'
import './DefaultPage.styl'

const DefaultPage = () => {
    const sources1 = ['最新公告', '政策法规', '业务指南']
    const sources2 = [
        '市公积金中心招聘启事',
        '关于20XX年X月 提取还款计划安排的通知',
        '关于20XX年X月 提取还款计划安排的通知',
        '20XX年春节停办公积金业务公告',
        '回迁公告',
    ]
    return (
        <div className="gjj-default-page">
            <div className="default-header">首页</div>
            <div className="default-main">
                <div className="main-tab">
                    {sources1.map((item) => (
                        <span key={item} className="tab-item">
                            {item}
                        </span>
                    ))}
                </div>
                <div className="main-content">
                    {sources2.map((item, index) => (
                        <div key={index} className="content-item">
                            <div className="content-title">
                                <img src={g_public} className="img-public" />
                                <span className="content-item-name">{item}</span>
                            </div>
                            <span style={{ marginRight: '37.65%', color: '#999999', fontSize: '14px' }}>
                                20XX-XX-XX
                            </span>
                        </div>
                    ))}
                </div>
                <p className="main-button">
                    更多<span style={{ marginLeft: '5px' }}>&gt;&gt;</span>
                </p>
            </div>
        </div>
    )
}

export default DefaultPage
