import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Header from './components/Header'
import Nav from './components/Nav'

import HomePage from './HomePage'
import PersonList from './PersonList'
import { TaxHome, TaxList } from './Tax'

import './index.styl'
import { useState } from 'react'

const GeShui = ({ data, onNewRecordSave }) => {
    const match = useRouteMatch()

    const {
        question: { name, presetData, presetOtherData },
        editAnswer: { questionAnswer },
    } = data
    const [taxMonth, setTaxMonth] = useState(presetOtherData.date)

    return (
        <div className="simulation-geshui">
            <Header />
            <Nav match={match} name={name} />
            <div className="wrap scrollbar">
                <article className="full-height">
                    <Switch>
                        <Route
                            path={`${match.path}/综合所得申报/正常工资薪金所得`}
                            render={({ match }) => (
                                <TaxList
                                    match={match}
                                    taxMonth={taxMonth}
                                    questionAnswer={questionAnswer}
                                    presetData={presetData}
                                    onNewRecordSave={onNewRecordSave}
                                />
                            )}
                        />
                        <Route
                            path={`${match.path}/综合所得申报`}
                            render={({ match }) => (
                                <TaxHome
                                    taxMonth={taxMonth}
                                    questionAnswer={questionAnswer}
                                    match={match}
                                    presetData={presetData}
                                />
                            )}
                        />
                        <Route
                            path={`${match.path}/人员信息采集`}
                            render={({ match }) => (
                                <PersonList
                                    match={match}
                                    onNewRecordSave={onNewRecordSave}
                                    name={name}
                                    questionAnswer={questionAnswer}
                                    presetData={presetData}
                                />
                            )}
                        />
                        <Route
                            path={match.path}
                            render={() => (
                                <HomePage
                                    name={name}
                                    taxMonth={taxMonth}
                                    presetDate={presetOtherData}
                                    onTaxMonthChange={setTaxMonth}
                                />
                            )}
                        />
                    </Switch>
                </article>
            </div>
        </div>
    )
}

export default GeShui
