// import { message } from 'antd'
import axios from 'axios'

const BASE_URL = '/api/'

const AxiosRequest = axios.create({
    baseURL: BASE_URL,
})

AxiosRequest.interceptors.response.use(
    (response) => {
        return response?.data || response || {}
    },
    (error) => {
        if (error?.response?.status === 401) {
            const isKw = location.href.indexOf('/kw') !== -1
            location.href = `${isKw ? '/kw/login' : '/ks'}?back=${location.pathname}`
        }
        if (error?.response?.status === 403) {
            //两种情况

            if (error?.response?.data?.message === '当前登录已过期（您已在其他设备登录）') {
                //一，重复登陆
                // message.warn('当前账号已在其他设备登陆')
            } else {
                //二，没有权限
                // message.warn('当前账号无权限')
            }
            //当前登录已过期（您已在其他设备登录）
        }
        return Promise.reject(error)
    }
)

export default AxiosRequest
