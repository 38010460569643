//cSpell:word Zhao CasCader
import React, { useState } from 'react'
import produce from 'immer'
import { Form, Row, Col, Space, DatePicker } from 'antd'
import Confirm from '../components/Confirm'
import Input from '../components/Input'
import SearchPanel from '../components/SearchPanel'
import Button from '../components/Button'
import Select, { Option } from '../components/Select'
import SelectPanel from '../components/SelectPanel'
import CasCader, { AddressCascader } from '../../components/CasCader'
import StringDateWrap from '../../components/StringDatePicker'

const options1Level1 = ['机械设备维修人员', '运输车辆装配人员', '仪器仪表修理人员', '其他机械设备修理人员']
const options1Level2 = [
    [
        '饲料加工设备维修工',
        '机修钳工',
        '汽车修理工',
        '船舶修理工',
        '农机修理工',
        '摩托车维修工',
        '（汽车）生产线调整工',
    ],
    ['汽车（拖拉机）装配工', '电机车装配工', '摩托车装配工', '助动车', '自行车装配工及其他运输车辆装配人员'],
    ['工业自动化仪器仪表与装置修理工', '电子仪器仪表修理工', '精密仪器仪表修理工及其他仪器仪表修理人员'],
    ['其他机械设备修理人员'],
]
const options1 = options1Level1.map((l1, index) => ({
    label: l1,
    value: l1,
    children: options1Level2[index].map((l2) => ({ label: l2, value: l2 })),
}))
const options2 = [
    '机关',
    '事业单位',
    '社会组织',
    '国有及国有控股',
    '私营企业',
    '外商投资（含港澳台）',
    '外商驻沪代表机构',
    '其他',
]
const options3 = ['一年（含）及以下', '1年-2年（含）', '2年-5年（含）', '5年以上']

const ZhaoGongDengJi = ({ data, onNewRecordSave }) => {
    const [user, setUser] = useState(null)
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()

    const onSearchConfirm = (result) => {
        setUser(null)
        form.resetFields()
        if (!result) return
        const newUser = {
            identity: result.identity,
            data: {
                ...result.data,
                劳务派遣: false,
                月缴费基数: '-',
            },
        }
        setUser(newUser)
        form.setFieldsValue(newUser)
    }

    const onCalculate = () => {
        const prevData = form.getFieldValue('data')
        const newData = produce(prevData, (draft) => {
            const number = Number(draft.月平均工资性收入)
            if (number) draft.月平均工资性收入 = `${number}`
            draft.月缴费基数 = `${number || '-'}`
        })
        form.setFieldsValue({ data: newData })
    }

    const onResetForm = () => {
        setUser(null)
        form.resetFields()
        searchForm.resetFields()
    }

    const onFormValuesChange = (changedValues, allValues) => {
        if ('劳务派遣' in (changedValues?.data || {})) {
            if (!changedValues.data.劳务派遣) {
                const prevData = allValues.data
                const newData = produce(prevData, (draft) => {
                    draft.派遣去向 = null
                    draft.派遣期限 = null
                })
                form.setFieldsValue({ data: newData })
            }
        }
    }

    const onFinish = (values) => {
        Confirm({
            title: '考试系统提示',
            content: '提交后，不可修改或删除信息。是否提交？',
            onOk: () => {
                const obj = produce(values, (draft) => {
                    if (!draft.data['劳务派遣']) draft.data['劳务派遣'] = false

                    if (draft.data['职业工种']?.length > 1) draft.data['职业工种'] = draft.data['职业工种'][1]
                    else draft.data['职业工种'] = null
                })
                onNewRecordSave({
                    type: '招工登记',
                    operateObject: [obj],
                }).then(onResetForm)
            },
        })
    }

    const renderChildren = () => {
        if (!user) return null
        return (
            <Form
                className="data-form sub-page-z-g-d-j"
                form={form}
                onFinish={onFinish}
                initialValues={user}
                onValuesChange={onFormValuesChange}
            >
                <div className="panel info-panel">
                    <div className="panel-title">个人信息</div>
                    <div className="panel-content">
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name={['data', '姓名']} label="姓名">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                                <Form.Item name={['data', '性别']} label="性别">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                                <Form.Item name={['data', '联系电话（固定电话）']} label="联系电话">
                                    <Space>
                                        <Form.Item noStyle>
                                            <Input maxLength={30} />
                                        </Form.Item>
                                        <div className="form-tip">固定电话</div>
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="identity" label="公民身份证号码">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                                <Form.Item name={['data', '出生日期']} label="出生日期">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                                <Form.Item name={['data', '联系电话（移动电话）']} label="联系电话">
                                    <Space>
                                        <Form.Item noStyle>
                                            <Input maxLength={30} />
                                        </Form.Item>
                                        <div className="form-tip">移动电话</div>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="联系地址" name={['data', '联系地址（省市区）']}>
                            <AddressCascader popupClassName="sb-cascader-popup" />
                        </Form.Item>
                        <div className="form-tip">
                            提示：若以上户籍户别信息与您申报人员的实际情况不一致，请至区县就业服务机构、社保中心办理相关调整手续。
                        </div>
                    </div>
                </div>
                <SelectPanel title="个人参保登记" disabled={user?.identity === '340121199001011606'}>
                    <div className="bordered-panel">
                        <div className="sub-module">
                            <div className="module-name">申报信息</div>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="个人序号" name={['data', '个人序号']}>
                                        <Input readOnly bordered={false} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="缴费起始年月" name={['data', '缴费起始年月']}>
                                        <StringDateWrap component={<DatePicker.MonthPicker />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="月平均工资性收入">
                                        <Space>
                                            <Form.Item name={['data', '月平均工资性收入']} noStyle>
                                                <Input maxLength={10} />
                                            </Form.Item>
                                            <div className="form-tip">元</div>
                                            <Button className="theme" onClick={onCalculate}>
                                                确定
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="月缴费基数（元）" name={['data', '月缴费基数']}>
                                        <Input readOnly bordered={false} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="form-tip">提示：用人单位招用劳动者，应自招用之日起30日内办妥招工登记手续。</div>
                </SelectPanel>
                <SelectPanel title="填写被招用人员的信息">
                    <Form.Item label="劳动合同签订情况" name={['data', '劳动合同签订情况']}>
                        <Select allowClear>
                            <Option value="初签">初签</Option>
                            <Option value="续签">续签</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="就业起始日期" name={['data', '就业起始日期']}>
                        <StringDateWrap component={<DatePicker showToday={false} />} />
                    </Form.Item>
                    <Form.Item name={['data', '用工形式']} label="用工形式">
                        <Select allowClear>
                            <Option value="全日制">全日制</Option>
                            <Option value="非全日制">非全日制</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={['data', '职业工种']} label="职业工种">
                        <CasCader
                            className="job-cascader"
                            options={options1}
                            changeOnSelect={false}
                            expandTrigger="hover"
                            displayRender={(label) => label[label.length - 1]}
                            popupClassName="sb-cascader-popup job-cascader-popup"
                        />
                    </Form.Item>
                    <Form.Item label="本次劳动合同起止日期">
                        <Space>
                            <Form.Item name={['data', '本次劳动合同起始日期']} noStyle>
                                <StringDateWrap component={<DatePicker placeholder="起始日期" showToday={false} />} />
                            </Form.Item>
                            <div className="form-tip">至</div>
                            <Form.Item name={['data', '本次劳动合同终止日期']} noStyle>
                                <StringDateWrap component={<DatePicker placeholder="结束日期" showToday={false} />} />
                            </Form.Item>
                            <div className="form-tip">结束日期可为空</div>
                        </Space>
                    </Form.Item>
                    <Form.Item label="劳务派遣">
                        <Space>
                            <Form.Item name={['data', '劳务派遣']} noStyle>
                                <Select>
                                    <Option value={true}>是</Option>
                                    <Option value={false}>否</Option>
                                </Select>
                            </Form.Item>
                            <div className="form-tip">劳务派遣员工选择“是”，非劳务派遣员工默认选择“否”</div>
                        </Space>
                    </Form.Item>
                    <Form.Item
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.data['劳务派遣'] !== curValues.data['劳务派遣']
                        }
                    >
                        {() => {
                            const disabled = !form.getFieldValue(['data', '劳务派遣'])
                            return (
                                <>
                                    <Form.Item label="派遣去向">
                                        <Space>
                                            <Form.Item name={disabled ? 'hidden' : ['data', '派遣去向']} noStyle>
                                                <Select
                                                    allowClear
                                                    disabled={disabled}
                                                    options={options2.map((i) => ({ label: i, value: i }))}
                                                />
                                            </Form.Item>
                                            <div className="form-tip">实际使用劳动派遣员工的用人单位的单位性质</div>
                                        </Space>
                                    </Form.Item>
                                    <Form.Item label="派遣期限">
                                        <Space>
                                            <Form.Item name={disabled ? 'hidden' : ['data', '派遣期限']} noStyle>
                                                <Select
                                                    allowClear
                                                    disabled={disabled}
                                                    options={options3.map((i) => ({ label: i, value: i }))}
                                                />
                                            </Form.Item>
                                            <div className="form-tip">劳务派遣员工的派遣期限</div>
                                        </Space>
                                    </Form.Item>
                                </>
                            )
                        }}
                    </Form.Item>
                </SelectPanel>
                <div className="bottom-action">
                    <Button className="commit-btn" type="primary" htmlType="submit">
                        提交
                    </Button>
                    <Button className="cancel-btn" onClick={onResetForm}>
                        取消
                    </Button>
                </div>
            </Form>
        )
    }
    return (
        <div className="sb-detail-sub-page">
            <SearchPanel form={searchForm} data={data} onConfirm={onSearchConfirm} />
            {renderChildren()}
        </div>
    )
}

export default ZhaoGongDengJi
