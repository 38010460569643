import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Modal } from 'antd'
import { getLocalStorage, setLocalStorage } from '../../../../common/storage'
import './SimulationModal.styl'
import 'react-splitter-layout/lib/index.css'
import closeBg from '../../../../assets/close-bg.png'
import closeBtn from '../../../../assets/close-btn.png'

export default ({ children }) => {
    const { id } = useParams()
    const [visible, setVisible] = useState(true)
    const [tipHidden, setTipHidden] = useState(getLocalStorage(`task-${id}-tip`))
    const history = useHistory()
    const onClose = () => setVisible(false)
    const onAfterClose = () => {
        history.push(history.location.pathname.replace(/\/operate.*$/, ''))
    }
    const setTipStatus = () => {
        setLocalStorage(`task-${id}-tip`, 'true')
        setTipHidden(true)
    }
    return (
        <Modal
            className="simulation-modal"
            centered
            closeIcon={
                <div className="close-btn" onClick={onClose}>
                    <span>关闭</span>
                    {!tipHidden && (
                        <div className="close-tip-view" onClick={(e) => e.stopPropagation()}>
                            <img src={closeBg} className="close-bg" />
                            <div className="close-tip-btn" onClick={setTipStatus}>
                                <img src={closeBtn} className="close-tip-btn-icon" />
                            </div>
                            <div className="close-tip-text">
                                <div>完成任务后，点击上方</div>
                                <div>关闭可返回答题主界面</div>
                            </div>
                        </div>
                    )}
                </div>
            }
            afterClose={onAfterClose}
            footer={null}
            width="100%"
            visible={visible}
            onClose={onClose}
            destroyOnClose
        >
            <div className="simulation-title">模拟操作系统</div>
            {children}
        </Modal>
    )
}
