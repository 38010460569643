import React from 'react'
import { Form, Select } from 'antd'

export default () => {
    const handleValues = (values) => {
        if (!Array.isArray(values) || values.length === 0) return values
        // 给每次新增的标签去掉前后的空格
        values.splice(values.length - 1, 1, values[values.length - 1]?.trim())
        return values
    }
    return (
        <Form.Item
            label="标签"
            name="tags"
            getValueFromEvent={handleValues}
            rules={[
                {
                    validator: (rule, value) => {
                        if (value.some((val) => !val.trim())) {
                            return Promise.reject('标签内容不能为空！')
                        }
                        if (value.length > 5) {
                            return Promise.reject('最多允许添加 5 个关键词！')
                        }
                        if (value.some((val) => val.length > 10)) {
                            return Promise.reject('关键词最多 10 个字！')
                        }
                        return Promise.resolve()
                    },
                },
            ]}
        >
            <Select mode="tags" open={false} />
        </Form.Item>
    )
}
