import AxiosRequest from './AxiosRequest'

export const getAllMockExams = () => AxiosRequest.get(`/mock_exams/sort`)

export const getMockExamPage = (page, size, search = {}) =>
    AxiosRequest.get(`/mock_exams/page`, { params: { page, size, ...search } })

export const delMockExam = (id) => AxiosRequest.delete(`/mock_exams/${id}`)

export const createMockExam = (data) => AxiosRequest.post(`/mock_exams`, data)

export const updateMockExam = (id, data) => AxiosRequest.put(`/mock_exams/${id}`, data)

export const updateMockExamSort = (data) => AxiosRequest.put(`/mock_exams/sort`, data)

export const hiddenMockExam = (id, hidden) =>
    AxiosRequest.put(`/mock_exams/${id}/visibility`, {
        hidden: !!hidden,
    })

export const getMockExams = () => AxiosRequest.get(`/mock_exams`)

export const linkPaper = (id, paperId) => AxiosRequest.put(`/mock_exams/${id}/paper/${paperId}`)

export const removePaper = (id) => AxiosRequest.delete(`/mock_exams/${id}/paper`)
