import React, { useEffect, useState, useCallback } from 'react'
import { message } from 'antd'
import moment from 'moment'
import messageTip from '../../../../components/message'
import { getTime } from '../../../../common/utils'
import './Countdown.styl'

const Countdown = ({ start, end, onEnd }) => {
    const [time, setTime] = useState('-- : -- : --')
    const [ended, setEnded] = useState(false)
    const [warned, setWarned] = useState(false)

    const formatTime = useCallback(() => {
        const now = getTime()
        if (!start || !end || !now || start > now || end < now) {
            if (end < now && !ended) {
                onEnd('timeout')
            } else {
                messageTip.error('考试状态异常，请刷新重试')
            }
            setEnded(true)
            return '00 : 00 : 00'
        }
        const remainTime = end - now
        if (!warned && moment.duration(remainTime).asMinutes() < 5) {
            message.warn({
                className: 'countdown-warning',
                content: (
                    <span>
                        距离考试结束<b>不足5分钟！</b>
                        <span className="confirm-btn" onClick={() => message.destroy('countdown')}>
                            我知道了
                        </span>
                    </span>
                ),
                duration: 300,
                key: 'countdown',
            })
            setWarned(true)
        }
        let hours = parseInt(moment.duration(remainTime).asHours(), 10)
        if (hours < 10) hours = `0${hours}`
        const minutesAndSeconds = moment(remainTime).utc().format('mm : ss')
        return `${hours} : ${minutesAndSeconds}`
    }, [start, end, ended, warned, onEnd])
    useEffect(() => {
        let interval = null
        if (!ended) interval = setInterval(() => setTime(formatTime()), 1000)
        window.examInterval = interval
        return () => {
            interval && clearInterval(interval)
            window.examInterval = null
        }
    }, [formatTime, ended])

    return <div className="countdown">{time}</div>
}

export default Countdown
