import React from 'react'
import { Form, Row, Col, Select, Input, Checkbox } from 'antd'
const Option = Select.Option

export default () => (
    <>
        <Form.Item
            name="name"
            label="试卷名称"
            rules={[{ required: true, whitespace: true, message: '试卷名称不能为空' }]}
        >
            <Input maxLength={50} />
        </Form.Item>
        <Row gutter={24}>
            <Col flex="234px">
                <Form.Item name="certificate" label="证书" labelCol={{ span: 8 }}>
                    <div>人力资源共享服务</div>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="level" label="等级">
                    <Select>
                        <Option value="Beginner">初级</Option>
                        <Option value="Intermediate">中级</Option>
                        <Option value="Advanced">高级</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="subject" label="科目">
                    <Select>
                        <Option value="Theory">理论</Option>
                        <Option value="Practice">实操</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    name="open"
                    valuePropName="checked"
                    label="公开"
                    tooltip="公开后拥有【考试权限】的用户可见此试卷"
                >
                    <Checkbox />
                </Form.Item>
            </Col>
        </Row>
        <Form.Item
            name="description"
            label="试卷说明"
            rules={[{ required: true, whitespace: true, message: '试卷说明不能为空' }]}
        >
            <Input.TextArea showCount maxLength={300} autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
    </>
)
