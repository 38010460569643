import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Row, Col, Input, Select, Button } from 'antd'

import message from '../../../../../components/message'
import { removeEmptyKeys } from '../../../../../common/utils'
import { getCandidateOption } from '../../../../../services/ExamCandidateRequest'
import { exportGetCandidate, exportGetQuestion, exportGetScore } from '../../../../../services/ExamCandidateRequest'
import './Filter.styl'

const Option = Select.Option

const Filter = ({ ids, onFilter, examName }) => {
    const [form] = Form.useForm()
    const { id } = useParams()
    const [display, setDisplay] = useState(false)
    const [optionData, setOptionData] = useState({ examinationSiteList: [], examinationRoomList: [] })

    useEffect(() => {
        getCandidateOption(id).then((res) => {
            setOptionData({
                examinationSiteList: res.examinationSiteList,
                examinationRoomList: res.examinationRoomList,
            })
        })
    }, [id])
    const onFinish = (values) => {
        onFilter(removeEmptyKeys(values))
    }
    const onReset = () => {
        form.resetFields()
        onFilter({})
    }

    const exportExcel = (asyncFunc) => {
        const values = form.getFieldsValue(true)
        onFinish(values)
        const params = Object.keys(values)
            .map((k) => `${k}=${values[k]}`)
            .join('&')
        asyncFunc(`${ids}`, `${params}`, examName).catch((err) => {
            message.error(err.response.data.message)
        })
    }
    const getCandidate = () => {
        exportExcel(exportGetCandidate)
    }
    const getQuestion = () => {
        exportExcel(exportGetQuestion)
    }
    const getScore = () => {
        exportExcel(exportGetScore)
    }

    return (
        <div className="student-filter">
            <Form
                form={form}
                className="search-form"
                onFinish={onFinish}
                labelCol={{ flex: '80px' }}
                style={{ marginTop: '10px' }}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name="examinationSite" label="考核站点">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder="全部"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">全部</Option>
                                {optionData.examinationSiteList.map((item, index) => {
                                    return (
                                        <Option value={item} key={index}>
                                            {item}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="examinationRoom" label="考场">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder="全部"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="">全部</Option>
                                {optionData.examinationRoomList.map((item, index) => {
                                    return (
                                        <Option value={item} key={index}>
                                            {item}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="status" label="状态">
                            <Select placeholder="全部">
                                <Option value="">全部</Option>
                                <Option value="Init">待提交</Option>
                                <Option value="NotScored">待评分</Option>
                                <Option value="Done">已评分</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name="name" label="学生姓名">
                            <Input placeholder="输入学生姓名" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="ticket" label="准考证号">
                            <Input placeholder="输入准考证号" />
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ textAlign: 'right' }}>
                        <Button type="primary" style={{ marginRight: '10px' }} htmlType="submit">
                            搜索
                        </Button>
                        <Button onClick={onReset}>重置</Button>
                    </Col>
                </Row>
            </Form>
            <div className="box">
                <div
                    className="question-mark"
                    onMouseOver={() => setDisplay(true)}
                    onMouseOut={() => setDisplay(false)}
                >
                    ?
                </div>
                <div className={display ? 'question-tips' : 'question-active'}>仅导出符合筛选条件的数据</div>
                <Button onClick={getCandidate}>导出考生信息</Button>
                <Button type="primary" onClick={getQuestion}>
                    导出主观题答案
                </Button>
                <Button type="primary" onClick={getScore}>
                    导出成绩
                </Button>
            </div>
        </div>
    )
}

export default Filter
