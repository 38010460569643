import React, { Suspense } from 'react'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'

import AxiosRequest from './services/AxiosRequest'
import Loading from './components/loading'

// 考试系统
// const KsLogin = lazy(() => import(/* webpackPrefetch: true */ './routes/ks/login'))
// const KsExamInfo = lazy(() => import(/* webpackPrefetch: true */ './routes/ks/info'))
// const KsExam = lazy(() => import(/* webpackPrefetch: true */ './routes/ks/exam'))
import KsLogin from './routes/ks/login'
import KsExamInfo from './routes/ks/info'
import KsExam from './routes/ks/exam'

// // 模拟演练
// const MockExam = lazy(() => import('./routes/ks/mock'))
import MockExam from './routes/ks/mock'

// // 考务系统
// const KwLayout = lazy(() => import('./routes/kw/layout'))
// const KwLogin = lazy(() => import('./routes/kw/login'))
// const KwQuestionList = lazy(() => import('./routes/kw/question/list'))
// const KwQuestionDetail = lazy(() => import('./routes/kw/question/detail'))
// const KwQuestionEdit = lazy(() => import('./routes/kw/question/edit'))
// const KwPaperList = lazy(() => import('./routes/kw/paper/list'))
// const KwPaperDetail = lazy(() => import('./routes/kw/paper/detail'))
// const KwPaperEdit = lazy(() => import('./routes/kw/paper/edit'))
// const KwExamList = lazy(() => import('./routes/kw/exam/list'))
// const KwExamDetail = lazy(() => import('./routes/kw/exam/detail'))
// const KwExamPaper = lazy(() => import('./routes/kw/exam/detail/paperDetail'))
// const KwManeuvers = lazy(() => import('./routes/kw/maneuvers'))

import KwLayout from './routes/kw/layout'
import KwLogin from './routes/kw/login'
import KwQuestionList from './routes/kw/question/list'
import KwQuestionDetail from './routes/kw/question/detail'
import KwQuestionEdit from './routes/kw/question/edit'
import KwPaperList from './routes/kw/paper/list'
import KwPaperDetail from './routes/kw/paper/detail'
import KwPaperPrint from './routes/kw/paper/detail/PrintPage'
import KwPaperEdit from './routes/kw/paper/edit'
import KwExamList from './routes/kw/exam/list'
import KwExamDetail from './routes/kw/exam/detail'
import KwExamPaper from './routes/kw/exam/detail/paperDetail'
import KwManeuvers from './routes/kw/maneuvers'

// 通用
import NotFound from './components/404'

const routes = [
    { path: '/', component: () => <Redirect to="/ks" />, exact: true },
    { path: ['/ks', '/ks/login'], component: KsLogin, exact: true },
    { path: '/ks/:id/info', component: KsExamInfo },
    { path: '/ks/:id/step/:step', component: KsExam },
    { path: '/mock', component: MockExam },
    { path: '/kw/login', component: KwLogin },
    { path: '/kw/print/paper/:id', component: KwPaperPrint },
    {
        path: '/kw',
        component: KwLayout,
        routes: [
            { path: '/kw', component: () => <Redirect to="/kw/exam" />, exact: true },
            { path: '/kw/question', component: KwQuestionList, exact: true },
            { path: '/kw/question/:type/detail/:id', component: KwQuestionDetail },
            { path: ['/kw/question/:type/create', '/kw/question/:type/edit/:id'], component: KwQuestionEdit },
            { path: '/kw/paper', component: KwPaperList, exact: true },
            { path: '/kw/paper/detail/:id', component: KwPaperDetail },
            { path: ['/kw/paper/create/', '/kw/paper/edit/:id'], component: KwPaperEdit },
            { path: '/kw/exam', component: KwExamList, exact: true },
            { path: '/kw/exam/detail/:id/paper/:paperId', component: KwExamPaper },
            { path: '/kw/exam/detail/:id', component: KwExamDetail },
            { path: '/kw/maneuvers', component: KwManeuvers },
        ],
    },
    { component: () => <NotFound title="404" /> },
]

AxiosRequest.defaults.adapter = null
// console.log('%c 不使用 adapter', 'background: #ffff00; color: #ff0000')
const Routers = () => (
    <Router>
        <Suspense fallback={<Loading />}>{renderRoutes(routes)}</Suspense>
    </Router>
)

export default Routers
