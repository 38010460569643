import React from 'react'
import { Form, Row, Col, Input, Select, Button } from 'antd'
import { removeEmptyKeys } from '../../../../common/utils'

const Option = Select.Option

const ListFilter = ({ onFilter }) => {
    const [form] = Form.useForm()

    const onFinish = (values) => {
        onFilter(removeEmptyKeys(values))
    }

    const onReset = () => {
        form.resetFields()
        onFilter({})
    }

    return (
        <Form form={form} className="search-form" onFinish={onFinish} labelCol={{ flex: '60px' }}>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item name="level" label="等级">
                        <Select placeholder="不限">
                            <Option value="">不限</Option>
                            <Option value="Beginner">初级</Option>
                            <Option value="Intermediate">中级</Option>
                            <Option value="Advanced">高级</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="subject" label="科目">
                        <Select placeholder="不限">
                            <Option value="">不限</Option>
                            <Option value="Theory">理论</Option>
                            <Option value="Practice">实操</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="key" label="关键词">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit">
                        搜索
                    </Button>
                    <Button style={{ marginLeft: '10px' }} onClick={onReset}>
                        重置
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default ListFilter
