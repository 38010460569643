import React from 'react'
import { camelCase } from 'lodash'
import { typeNames } from '../../question/edit/configs'
import { Badge } from 'antd'
import './ListItem.styl'

export default ({ data }) => {
    const questions = []
    let total = 0
    Object.keys(typeNames).forEach((key) => {
        const questionCount = data[`${camelCase(key)}List`].length
        if (questionCount > 0) {
            questions.push({
                name: typeNames[key].substr(0, typeNames[key].length - 1),
                count: questionCount,
            })
            total += questionCount
        }
    })
    return (
        <div className="item-detail paper-item-detail">
            <div className="row-1">
                <span>{data.certificateName}</span>
                <span>{data.levelName}</span>
                <span>{data.subjectName}</span>
            </div>
            <div className="row-2">{data.name}</div>
            <div className="row-3">
                <span>
                    公开状态：
                    {data.open ? <Badge status="success" text="公开" /> : <Badge status="default" text="未公开" />}
                </span>
                <span>{`分值总计：${
                    String(data?.totalScore).indexOf('.') + 1 > 0 ? data?.totalScore?.toFixed(1) : data?.totalScore
                }`}</span>
                <span>{`题目数量：${total}（${questions.map((q) => q.name + q.count).join('，')}）`}</span>
            </div>
        </div>
    )
}
