//cSpell:word gongjijin

import g_1 from '../../../../../resources/gongjijin/g_1.png'
import g_2 from '../../../../../resources/gongjijin/g_2.png'
import g_3 from '../../../../../resources/gongjijin/g_3.png'
import g_4 from '../../../../../resources/gongjijin/g_4.png'
import g_5 from '../../../../../resources/gongjijin/g_5.png'
import g_6 from '../../../../../resources/gongjijin/g_6.png'

export const sidebarData = [
    {
        name: '单位账户信息',
        icon: g_1,
        children: [
            { name: '单位账户信息' },
            { name: '单位账户明细信息' },
            { name: '职工账户信息' },
            { name: '职工账户明细信息' },
            { name: '职工封存状态查询' },
        ],
    },
    {
        name: '单位业务',
        icon: g_2,
        children: [
            { name: '个人开户', allow: '增员办理', batch: '批量开户' },
            { name: '启封', allow: '增员办理' },
            { name: '转入', allow: '增员办理' },
            { name: '转出', reduce: '减员办理' },
            { name: '封存', reduce: '减员办理' },
            { name: '待销户停缴', reduce: '减员办理' },
            { name: '待转出停缴', reduce: '减员办理' },
            { name: '年度基数调整' },
            { name: '单位补充公积金开户' },
            { name: '单位缴存证明' },
            { name: '单位缴存网点变更' },
            { name: '单位住房公积金账户注销' },
        ],
    },
    {
        name: '资金业务',
        icon: g_3,
        children: [{ name: '汇缴' }, { name: '补缴' }],
    },
    {
        name: '网上业务查询',
        icon: g_4,
        children: [
            { name: '职工变更业务查询', allow: '增员办理', batch: '批量开户', reduce: '减员办理' },
            { name: '汇缴查询' },
            { name: '补缴查询' },
            { name: '单位开户结果查询' },
            { name: '单位缴存证明结果查询' },
            { name: '缴存网点变更结果查询' },
            { name: '单位住房公积金账户注销查询' },
            { name: '缓缴企业汇缴补缴查询' },
        ],
    },
    {
        name: '单位设置',
        icon: g_5,
        children: [{ name: '部门设定' }, { name: '工号账号对照表' }, { name: '员工部门对照表' }, { name: '部门统计' }],
    },
    {
        name: '用户管理',
        icon: g_6,
        children: [{ name: '卡权限设置' }, { name: '卡业务设置' }, { name: '查看服务协议' }],
    },
]
