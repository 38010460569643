import React from 'react'
import { Form } from 'antd'
import { typeNames } from '../configs'

export default ({ type }) => {
    return (
        <Form.Item name="type" label="题型">
            <div style={{ color: '#6076FF' }}>【{typeNames[type]}】</div>
        </Form.Item>
    )
}
