import React from 'react'
import { isNumber } from 'lodash'

import Type from '../formItem/Type'
import InfoGroup from '../formItem/InfoGroup'
import Upload from '../formItem/Upload'
import Tags from '../formItem/Tags'
import Content from '../formItem/Content'
import Options from '../formItem/Options'
import Explanation from '../formItem/Explanation'

export const MultipleSelection = ({ form, childrenIndex }) => {
    const prevPath = isNumber(childrenIndex) ? [childrenIndex] : []
    return (
        <>
            <Content prevPath={prevPath} />
            <Upload prevPath={prevPath} />
            <Options checkbox form={form} type="textOrPic" childrenIndex={childrenIndex} prevPath={prevPath} />
            <Explanation prevPath={prevPath} />
        </>
    )
}

export default ({ form }) => (
    <>
        <Type type="MultipleSelection" />
        <InfoGroup />
        <Tags />
        <MultipleSelection form={form} />
    </>
)
