import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Menu } from 'antd'
import ExcelUpload from '../../../../components/upload/ExcelUpload'
import { postQuestionByExcel } from '../../../../services/QuestionRequest'
import excelHref from '../../../../resources/题目批量导入模板.xlsx'

const CreateMenu = () => {
    const types = [
        { route: 'MultipleChoice', name: '单选题' },
        { route: 'MultipleSelection', name: '多选题' },
        { route: 'TrueOrFalse', name: '判断题' },
        { route: 'Sorting', name: '排序题' },
        { route: 'Text', name: '简答题' },
        { route: 'Analyze', name: '情景分析题' },
    ]
    return (
        <Menu>
            {types.map((type) => (
                <Menu.Item key={type.name}>
                    <Link to={`/kw/question/${type.route}/create`}>{type.name}</Link>
                </Menu.Item>
            ))}
        </Menu>
    )
}

const Header = ({ onRefresh }) => {
    const [visible, setVisible] = useState(false)
    return (
        <div className="page-header">
            <div className="page-title">题目管理</div>
            <Button type="primary" onClick={() => setVisible(true)}>
                批量导入
            </Button>
            <Dropdown overlay={CreateMenu} placement="bottomCenter">
                <Button type="primary">添加题目</Button>
            </Dropdown>
            <ExcelUpload
                title="批量导入题目"
                visible={visible}
                setVisible={setVisible}
                titleDownload={{ name: '题目批量导入模板.xlsx', href: excelHref }}
                confirmFunc={postQuestionByExcel}
                onComplete={onRefresh}
                extra={{ type: 'question' }}
            />
        </div>
    )
}

export default Header
