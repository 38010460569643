//cSpell:word shebao Shui geshui gongjijin
import React, { useContext } from 'react'
import SplitterLayout from 'react-splitter-layout'
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import produce from 'immer'
import { find, isString } from 'lodash'

import { postOperationRecord } from '../../../../services/CandidateRequest'
import message from '../../../../components/message'
import { ErrorConfirm } from '../../../../components/modal/Confirm'
import { ExamContext } from '../Context'

import SheBao from './shebao'
import GeShui from './geshui'
import GongJiJin from './gongjijin'

import TaskAndRecords from './task&records'

import './index.styl'

const SimulationSystem = ({ stepData, answers, onChange }) => {
    const match = useRouteMatch()
    const { index } = useParams()
    const { state } = useContext(ExamContext)
    const { exam } = state
    const childIndex = parseInt(index, 10)

    // 先按题目顺序排序
    const editAnswers = stepData.baseQuestions?.map(
        (question, index) => find(answers, (as) => as.questionIndex === index) || answers[index]
    )

    // 构造当前实操题对应的小题数据
    const data = {
        index: childIndex,
        question: stepData.baseQuestions?.[index],
        editAnswer: editAnswers[index], // 包含已保存的所有操作记录
    }

    /**
     * 处理需要保存的数据（新增一条操作记录）;目前是调用单独的实操题数据保存方法，由后端做数据合并
     * @param { type: String, operateObject: Array } newRecord
     */
    const onNewRecordSave = (newRecord) => {
        const saveData = produce(newRecord, (draft) => {
            draft.questionId = stepData.id
            draft.questionIndex = childIndex
            draft.operateObject.forEach((obj) => {
                obj.identity = obj.identity.trim()
                Object.keys(obj.data).forEach((key) => {
                    const val = obj.data[key]
                    if (isString(val)) obj.data[key] = val.trim()
                })
            })
        })
        return postOperationRecord(exam.id, exam.paperSnapshotId, saveData)
            .then((result) => {
                onChange(childIndex, result)
            })
            .catch((error) => {
                const msg = error.response?.data?.message || '操作失败！请刷新页面后重试'
                if (msg === '操作失败！请刷新页面后重试') return message.error(msg)
                ErrorConfirm({ message: msg })
                throw error
            })
    }

    return (
        <SplitterLayout vertical primaryMinSize={200} secondaryMinSize={40} secondaryInitialSize={230}>
            <Switch>
                <Route
                    path={`${match.path}/shebao`}
                    render={() => <SheBao data={data} onNewRecordSave={onNewRecordSave} />}
                />
                <Route
                    path={`${match.path}/gongjijin`}
                    render={() => <GongJiJin data={data} onNewRecordSave={onNewRecordSave} />}
                />
                <Route
                    path={`${match.path}/geshui`}
                    render={() => <GeShui data={data} onNewRecordSave={onNewRecordSave} />}
                />
            </Switch>
            <TaskAndRecords data={data} />
        </SplitterLayout>
    )
}

export default SimulationSystem
