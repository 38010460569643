import React, { useState, useEffect, useCallback } from 'react'
import { getExams } from '../../../../services/ExamsRequest'
import Header from './Header'
import Filter from './Filter'
import List from './List'

const ExamList = () => {
    const [filters, setFilters] = useState({})
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const getData = useCallback(
        async (page = 1) => {
            let params = `page=${page}`
            Object.keys(filters).forEach((k) => {
                params = `${params}&${k}=${filters[k]}`
            })
            setLoading(true)
            const res = await getExams(`${params}`)
            await new Promise((resolve) => setTimeout(resolve, 200))
            setData(res)
            setLoading(false)
        },
        [filters]
    )
    useEffect(() => {
        getData()
    }, [getData])
    const onFilter = (filters) => {
        setFilters(filters)
    }
    return (
        <div className="exam-list">
            <Header onRefresh={getData} />
            <Filter onFilter={onFilter} />
            {data.content && <List loading={loading} data={data} onRefresh={getData} />}
        </div>
    )
}

export default ExamList
