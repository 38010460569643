import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Button, Breadcrumb, Form, Input } from 'antd'

import message from '../../../../components/message'
import Loading from '../../../../components/loading'
import { getPaper, postPaper, putPaper } from '../../../../services/PaperRequest'
import { dataInput, dataOutput } from './dataHandle'
import BaseForm from './BaseForm'
import QuestionForm from './QuestionForm'

const PaperEdit = () => {
    const [form] = Form.useForm()
    const history = useHistory()
    const { id } = useParams()
    const [formData, setFormData] = useState(null)
    useEffect(() => {
        if (!id) {
            setFormData(dataInput())
        } else {
            getPaper(id).then((data) => {
                setFormData(dataInput(data))
            })
        }
    }, [id])
    if (!formData) return <Loading />
    const onFinish = (values) => {
        const outputData = dataOutput({ ...formData, ...values })
        if (outputData.error) return message.error(outputData.error)
        const p = id ? putPaper(id, outputData) : postPaper(outputData)
        p.then(() => {
            message.success(id ? '操作成功！' : '创建成功')
            history.push(id ? `/kw/paper/detail/${id}` : '/kw/paper')
        }).catch((err) => {
            message.error(err?.response?.message || '操作失败，请稍后重试！')
        })
    }
    return (
        <div className="paper-edit-page">
            <Form
                form={form}
                name="create-form"
                className="ant-create-form"
                onFinish={onFinish}
                requiredMark={false}
                initialValues={formData}
            >
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <div className="page-header">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item key="/kw/paper">
                            <Link to="/kw/paper">试卷管理</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{!id ? '试卷创建' : '试卷编辑'}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Link to={id ? `/kw/paper/detail/${id}` : '/kw/paper'}>
                        <Button>取消</Button>
                    </Link>
                    <Button htmlType="submit">保存</Button>
                </div>
                <div className="page-content-container">
                    <BaseForm />
                    <QuestionForm data={formData} setData={setFormData} />
                </div>
            </Form>
        </div>
    )
}

export default PaperEdit
