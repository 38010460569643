import React, { useState } from 'react'
import produce from 'immer'
import { Form, Row, Col, DatePicker } from 'antd'
import Confirm from '../components/Confirm'
import Input from '../components/Input'
import SearchPanel from '../components/SearchPanel'
import Button from '../components/Button'
import Select, { Option } from '../components/Select'
import StringDateWrap from '../../components/StringDatePicker'

const TuiGongDengJi = ({ data, onNewRecordSave }) => {
    const [user, setUser] = useState(null)
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()

    const onSearchConfirm = (result) => {
        setUser(null)
        form.resetFields()
        if (!result) return
        const newUser = {
            identity: result.identity,
            data: {
                ...result.data,
                转档需求: false,
            },
        }
        setUser(newUser)
        form.setFieldsValue(newUser)
    }

    const onResetForm = () => {
        setUser(null)
        form.resetFields()
        searchForm.resetFields()
    }

    const onFormValuesChange = (changedValues, allValues) => {
        if ('转档需求' in (changedValues?.data || {})) {
            if (!changedValues.data.转档需求) {
                const prevData = allValues.data
                const newData = produce(prevData, (draft) => {
                    draft.退工备案区县 = null
                })
                form.setFieldsValue({ data: newData })
            }
        }
    }

    const onFinish = (values) => {
        Confirm({
            title: '考试系统提示',
            content: '提交后，不可修改或删除信息。是否提交？',
            onOk: () => {
                const obj = produce(values, (draft) => {})
                onNewRecordSave({
                    type: '退工登记',
                    operateObject: [obj],
                }).then(onResetForm)
            },
        })
    }

    const renderChildren = () => {
        if (!user) return null
        return (
            <Form
                className="data-form sub-page-t-g-d-j"
                form={form}
                onFinish={onFinish}
                initialValues={user}
                onValuesChange={onFormValuesChange}
            >
                <div className="panel info-panel">
                    <div className="panel-title">个人信息</div>
                    <div className="panel-content">
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name={['data', '姓名']} label="姓名">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="identity" label="公民身份证号码">
                                    <Input readOnly bordered={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="panel content-panel">
                    <div className="panel-title">填写被退用人员信息</div>
                    <div className="panel-content">
                        <Form.Item name={['data', '人员类型']} label="人员类型">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name={['data', '就业起始日期']} label="就业起始日期">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name={['data', '职业工种']} label="职业工种">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name={['data', '解除或终止日期']} label="解除或终止日期">
                            <StringDateWrap component={<DatePicker placeholder="解除或终止日期" showToday={false} />} />
                        </Form.Item>
                        <Form.Item name={['data', '退工原因']} label="退工原因">
                            <Select allowClear>
                                <Option value="终止劳动合同">终止劳动合同</Option>
                                <Option value="解除劳动合同">解除劳动合同</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="转档需求" name={['data', '转档需求']}>
                            <Select>
                                <Option value={true}>是</Option>
                                <Option value={false}>否</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            shouldUpdate={(prevValues, curValues) =>
                                prevValues.data['转档需求'] !== curValues.data['转档需求']
                            }
                        >
                            {() => {
                                const disabled = !form.getFieldValue(['data', '转档需求'])
                                const options = [
                                    '黄浦',
                                    '徐汇',
                                    '长宁',
                                    '静安',
                                    '普陀',
                                    '虹口',
                                    '杨浦',
                                    '闵行',
                                    '宝山',
                                    '嘉定',
                                    '浦东',
                                    '金山',
                                    '松江',
                                    '青浦',
                                    '奉贤',
                                    '崇明',
                                ]
                                return (
                                    <Form.Item
                                        name={disabled ? 'hidden' : ['data', '退工备案区县']}
                                        label="退工备案区县"
                                    >
                                        <Select
                                            allowClear
                                            disabled={disabled}
                                            options={options.map((i) => ({ label: i, value: i }))}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </div>
                </div>
                <div className="bottom-action">
                    <Button className="commit-btn" type="primary" htmlType="submit">
                        提交
                    </Button>
                    <Button className="cancel-btn" onClick={onResetForm}>
                        取消
                    </Button>
                </div>
            </Form>
        )
    }
    return (
        <div className="sb-detail-sub-page">
            <div className="alert-warning-view">
                <i className="iconfont icon-warn" />
                <span>用人单位与劳动者终止或解除劳动关系后，请15日内办妥退工登记备案手续。</span>
            </div>
            <SearchPanel form={searchForm} data={data} onConfirm={onSearchConfirm} />
            {renderChildren()}
        </div>
    )
}

export default TuiGongDengJi
