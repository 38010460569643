//cSpell:word Feng
import produce from 'immer'
import { Button, Modal, Form, Row, Col, Input } from 'antd'
import img from '../../../../../../assets/提示.png'

import Confirm from '../../components/Confirm'
import './QiFengModal.styl'

const QiFengForm = ({ record, onNewRecordSave, data }) => {
    const [form] = Form.useForm()
    const onFinish = (values) => {
        const dataObj = produce(values, (draft) => {
            draft.data.身份证号码 = draft.identity.trim()
            draft.identity = record.identity
        })
        Confirm({
            className: 'confirm-submit',
            title: '确认',
            width: 480,
            content:
                '提交正式受理，请仔细核对所填信息。\n贵单位汇缴至20XX年X月，该员工启封后将参与X月份的汇缴，是否启封？',
            onOk: () => {
                const obj = produce(dataObj, (draft) => {
                    draft.data['操作类型'] = '启封'
                    draft.data['账户类型'] =
                        record.data.账户类型 === '住房公积金' || record.data.账户类型 === '基本账户类型'
                            ? '基本账户类型'
                            : '补充账户类型'

                    draft.data['身份证号码'] = dataObj.data.身份证号码
                    draft.identity = dataObj.identity
                })
                onNewRecordSave({
                    type: '启封',
                    operateObject: [obj],
                })
                Confirm({
                    className: 'confirm-careful',
                    title: '确认',
                    width: 320,
                    content: (
                        <div>
                            <p className="success">启封处理成功。</p>
                            <div className="confirm-notice-btn">
                                <Button onClick={onCancel}>确认</Button>
                            </div>
                        </div>
                    ),
                    onCancel: () => {
                        Modal.destroyAll()
                    },
                })
            },
        })
    }
    const initialValues = {
        data: { 个人账号: record.data?.个人账号 || '', 姓名: record.data?.姓名 || '', 缴存比例: '7%' },
    }
    const inputChange = () => {
        const prevData = form.getFieldValue('data')
        const newData = produce(prevData, (draft) => {
            if (!isNaN(draft.工资) && draft.工资 !== '') {
                const deposit = Number(draft.工资) * 0.14
                draft['月缴存额'] = Math.round(deposit)
            } else {
                draft['月缴存额'] = ''
            }
        })
        form.setFieldsValue({ data: newData })
    }
    const onCancel = () => {
        Modal.destroyAll()
        form.resetFields()
    }
    return (
        <div className="confirm-status">
            <p className="confirm-status-tips">
                <img src={img} className="icon-img" />
                <span className="tips-content">请先确认个人信息，再录入工资。</span>
            </p>
            <Form
                className="confirm-status-form"
                form={form}
                labelCol={{ flex: '80px' }}
                onFinish={onFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="个人账号" name={['data', '个人账号']}>
                            <Input disabled style={{ boxShadow: 'none', color: '#000' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="工资"
                            name={['data', '工资']}
                            onChange={inputChange}
                            required={false}
                            rules={[{ required: true, whitespace: true, message: '此项不得为空' }]}
                        >
                            <Input maxLength={10} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="姓名" name={['data', '姓名']}>
                            <Input disabled style={{ boxShadow: 'none', color: '#000' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="缴存比例" name={['data', '缴存比例']}>
                            <Input disabled style={{ boxShadow: 'none', color: '#000' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="身份证"
                            name="identity"
                            required={false}
                            rules={[{ required: true, whitespace: true, message: '此项不得为空' }]}
                        >
                            <Input maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="月缴存额" name={['data', '月缴存额']}>
                            <Input disabled style={{ boxShadow: 'none', color: '#000' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className="confirm-notice-btn">
                    <Col>
                        <Button onClick={onCancel}>关闭</Button>
                        <Button htmlType="submit">确定</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default (record, form, onNewRecordSave, data) => {
    return Confirm({
        className: 'confirm-QiFeng-status',
        width: 720,
        title: '启封住房公积金账号',
        content: <QiFengForm record={record} onNewRecordSave={onNewRecordSave} data={data} />,
        onCancel: () => {
            Modal.destroyAll()
            form.resetFields()
        },
    })
}
