import React, { useState, useEffect, useCallback } from 'react'
import produce from 'immer'
import { Link } from 'react-router-dom'
import { Button, Modal, Pagination, Checkbox, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { difference, union, findIndex, filter } from 'lodash'

import { getQuestions } from '../../../../services/QuestionRequest'
import Loading from '../../../../components/loading'
import message from '../../../../components/message'
import Filter from '../../question/list/Filter'
import './AddQuestion.styl'

const AddQuestionView = ({ existIds, onConfirmAdd, onClose }) => {
    const [loading, setLoading] = useState(true)
    const [filters, setFilters] = useState({})
    const [data, setData] = useState({})
    const [selects, setSelects] = useState([])
    const getData = useCallback(
        async (page = 1) => {
            setLoading(true)
            let params = `page=${page}`
            Object.keys(filters).forEach((k) => {
                params = `${params}&${k}=${filters[k]}`
            })
            const res = await getQuestions(`${params}`)
            await new Promise((resolve) => setTimeout(resolve, 200))
            setData(res)
            setLoading(false)
        },
        [filters]
    )
    useEffect(() => {
        getData()
    }, [getData])
    const onFilter = (filters) => {
        setFilters(filters)
        setSelects([])
    }
    const handleSelect = (e, item, isSelect) => {
        e.stopPropagation()
        const newSelects = produce(selects, (draft) => {
            if (isSelect) {
                const idx = findIndex(selects, (s) => s.id === item.id)
                draft.splice(idx, 1)
            } else {
                draft.push(item)
            }
        })
        setSelects(newSelects)
    }
    const handleSelectAll = () => {
        const pageIds = data.content?.map((item) => item.id) || []
        const selectIds = selects.map((s) => s.id)
        const unselects = difference(pageIds, selectIds, existIds)
        if (unselects.length === 0) {
            // 当前页已全选
            const newSelects = filter(selects, (s) => pageIds.indexOf(s.id) === -1)
            setSelects(newSelects)
        } else {
            const newSelects = filter(
                data.content,
                (item) => existIds.indexOf(item.id) === -1 && findIndex(selects, (s) => s.id === item.id) === -1
            )
            setSelects(union(selects, newSelects))
        }
    }
    const onConfirm = () => {
        onConfirmAdd(selects)
        message.success('操作成功！')
        onClose()
    }
    return (
        <>
            <Filter onFilter={onFilter} closer />
            <div className="page-list-container scrollbar">
                {loading ? (
                    <Loading />
                ) : (
                    <div className="page-list">
                        {data.content?.map((item, index) => {
                            const selected = findIndex(selects, (s) => s.id === item.id) !== -1
                            const isExist = existIds.indexOf(item.id) !== -1
                            return (
                                <div
                                    className="list-item"
                                    key={item.id}
                                    onClick={(e) => !isExist && handleSelect(e, item, selected)}
                                >
                                    <Checkbox
                                        checked={isExist || selected}
                                        disabled={isExist}
                                        onClick={(e) => !isExist && handleSelect(e, item, selected)}
                                    />
                                    <div className="item-no">{data.number * 10 + index + 1}</div>
                                    <div className="item-detail">
                                        <div className="row-1">
                                            <span className="item-type">{item.typeName}</span>
                                            <span>{item.certificateName}</span>
                                            <span>{item.levelName}</span>
                                            <span>{item.subjectName}</span>
                                            <span>{item.fieldName}</span>
                                            <Link to={`/kw/question/${item.type}/detail/${item.id}`} target="_blank">
                                                <Button onClick={(e) => e.stopPropagation()}>查看原题</Button>
                                            </Link>
                                        </div>
                                        <div className="row-2">{item.content || item.key}</div>
                                        <div className="row-3">
                                            {item.tags?.map((tag) => (
                                                <Tag key={tag}>{tag}</Tag>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <Pagination
                            defaultCurrent={1}
                            total={data.totalElements}
                            current={data.number + 1}
                            hideOnSinglePage={true}
                            onChange={getData}
                            showSizeChanger={false}
                        />
                    </div>
                )}
            </div>
            <div className="action-bar">
                <div className="selects-num">已选中 {selects.length || 0} 项</div>
                <Button onClick={handleSelectAll}>全选</Button>
                <div className="right-action">
                    <Button onClick={onClose}>取消</Button>
                    <Button disabled={selects.length === 0} onClick={onConfirm}>
                        确认添加
                    </Button>
                </div>
            </div>
        </>
    )
}

const AddQuestion = ({ existIds = [], onConfirmAdd }) => {
    const [visible, setVisible] = useState(false)

    const onVisibleToggle = () => setVisible((prev) => !prev)

    return (
        <>
            <Button className="add-btn" icon={<PlusOutlined />} onClick={onVisibleToggle}>
                添加题目
            </Button>
            <Modal
                visible={visible}
                title="添加题目"
                width={'80%'}
                className="add-question-modal"
                footer={null}
                destroyOnClose
                onCancel={onVisibleToggle}
            >
                <AddQuestionView existIds={existIds} onConfirmAdd={onConfirmAdd} onClose={onVisibleToggle} />
            </Modal>
        </>
    )
}

export default AddQuestion
