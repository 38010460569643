import React, { useEffect, useState } from 'react'
import Header from './Header'
import PaperPreview from './PaperPreview'
import { getPaper } from '../../../../../services/PaperRequest'
import message from '../../../../../components/message'

const PaperSetting = ({ exam }) => {
    const [paper, setPaper] = useState({})
    useEffect(() => {
        if (!exam.paperSnapshotId) {
            return
        }

        getPaper(exam.paperSnapshotId)
            .then((res) => {
                setPaper(res)
            })
            .catch((error) => {
                const status = error?.response?.status
                if (status === 403) {
                    message.error('没有权限查看试卷')
                    return
                }
                if (status === 404) {
                    message.error('没有找到试卷')
                    return
                }

                message.error('试卷获取错误')
            })
    }, [exam.paperSnapshotId])

    return (
        <div className="exam-paper-setting">
            <Header paper={paper} setPaper={setPaper} />
            <PaperPreview paper={paper} />
        </div>
    )
}

export default PaperSetting
