import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Checkbox } from 'antd'
import { find } from 'lodash'

import Loading from '../../../../components/loading'
import { getPaper } from '../../../../services/PaperRequest'
import { getExamNames } from '../../../../services/ExamsRequest'
import { getUserNames } from '../../../../services/UserRequest'
import ListItem from '../list/ListItem'
import DetailView from './DetailView'
import Preview from './Preview'
import './DetailView.styl'
import './PrintPage.styl'

const PaperPrint = () => {
    const { id } = useParams()
    const [data, setData] = useState({})
    const [viewConfig, setViewConfig] = useState({ info: false, answer: false })

    useEffect(() => {
        const fetchData = async () => {
            const data = await getPaper(id)
            const userNames = await getUserNames([data.createdUser, data.modifiedUser])
            const paperNames = await getExamNames(data.examIds)
            data.createdUserName = find(userNames, (u) => u.id === data.createdUser)?.username || '-'
            data.modifiedUserName = find(userNames, (u) => u.id === data.modifiedUser)?.username || '-'
            data.paperNames = paperNames.map((p) => p.name).join('、')
            setData(data)
        }
        fetchData()
    }, [id])

    const onConfigChange = (e) => {
        setViewConfig((prev) => ({ ...prev, [e.target.name]: e.target.checked }))
    }

    if (!data.id) return <Loading />

    return (
        <div className="paper-print-wrap">
            <div className="paper-detail-page">
                <div className="page-header">
                    <Checkbox name="info" checked={viewConfig.info} onChange={onConfigChange}>
                        显示试卷基本信息
                    </Checkbox>
                    <Checkbox name="answer" checked={viewConfig.answer} onChange={onConfigChange}>
                        显示答案及解析
                    </Checkbox>
                    <Button onClick={() => window.print?.()}>打印</Button>
                </div>
                <div className="page-content">
                    {viewConfig.info && (
                        <>
                            <div className="list-item-wrap">
                                <ListItem data={data} />
                            </div>
                            <DetailView data={data} />
                        </>
                    )}
                    <div className="paper-preview-container">
                        <Preview paper={data} answerVisible={viewConfig.answer} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaperPrint
