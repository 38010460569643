import React, { createContext, useReducer } from 'react'
import { find, findIndex, filter, sortBy, camelCase } from 'lodash'
import { useParams } from 'react-router'
import { typeNames, chNos, getCompletedStatus } from './configs'

export const ExamContext = createContext({})

export const UPDATE_DATA = 'UPDATE_DATA' // 更新源数据
export const EDIT_ANSWER = 'EDIT_ANSWER' // 编辑数据（未保存)
export const CHANGE_STEP = 'CHANGE_STEP' // 跳到指定步骤

const reducer = (state, action) => {
    const payload = action.payload || {}
    const { questions } = state
    switch (action.type) {
        case UPDATE_DATA:
            return { ...state, ...payload }
        case EDIT_ANSWER:
            return { ...state, editAnswer: payload.editAnswer }
        case CHANGE_STEP:
            if (payload.step === state.currentStep?.index + 1) return state
            return { ...state, editAnswer: null, currentStep: questions[payload.step - 1] }
        default:
            return state
    }
}

let initialState = null
const getInitialState = (params, originData) => {
    if (initialState) return initialState
    const isMock = location.href.indexOf('mock') !== -1
    const { user, examData, answers } = originData
    const { step } = params
    const { questions, exam, paper } = examData
    let currentStep = null
    // 生成侧栏数据
    const catalog = []
    // 给题目按题型排序
    const sortType = ['MultipleChoice', 'MultipleSelection', 'TrueOrFalse', 'Sorting', 'Operate', 'Text', 'Analyze']
    // 先按类型排序，同类型再按选题时的顺序排序
    const sortedQuestions = sortBy(
        questions,
        (q) => sortType.indexOf(q.type),
        (q) => paper[`${camelCase(q.type)}List`]?.indexOf(q.id)
    )

    sortedQuestions.forEach((question, index) => {
        const type = question.type
        question.index = index
        question.isLast = index === sortedQuestions.length - 1

        // 改成与后端保存的答案结构一致，便于做显示、校验、替换
        if (question.type === 'Analyze' || question.type === 'Operate') {
            const scores = filter(paper.scores, (s) => s.questionId === question.id)
            let totalScore = 0
            scores.forEach((s) => (totalScore += s.score))
            question.score = totalScore
            question.baseQuestions?.forEach((q, baseIndex) => {
                q.index = baseIndex
                q.score = find(scores, (s) => s.index === baseIndex)?.score || 0
                const answer = find(answers, (as) => as.questionId === question.id && as.questionIndex === baseIndex)
                q.userAnswer = answer || {
                    questionAnswer: {},
                    questionId: question.id,
                    questionIndex: baseIndex,
                }
                q.completed = getCompletedStatus(q)
            })
        } else {
            question.score = find(paper.scores, (s) => s.questionId === question.id)?.score || 0
            // mock时的标准答案
            const answer = find(answers, (as) => as.questionId === question.id)
            question.userAnswer = answer || {
                questionAnswer: {},
                questionId: question.id,
                questionIndex: 0,
            }
        }

        question.completed = getCompletedStatus(question)

        if (step === `${index + 1}`) currentStep = { ...question }

        // 考试页侧边栏数据
        const cIndex = findIndex(catalog, (c) => c.type === type)
        const catalogItem =
            cIndex !== -1 ? catalog[cIndex] : { no: chNos[catalog.length], type, name: typeNames[type], list: [] }
        catalogItem.list.push({
            id: question.id,
            score: question.score,
            index: question.index,
        })
        if (cIndex === -1) {
            catalog.push(catalogItem)
        } else {
            catalog[cIndex] = catalogItem
        }
    })
    if (!currentStep) currentStep = sortedQuestions[0]
    initialState = {
        isMock,
        user,
        exam,
        paper,
        questions: sortedQuestions,
        catalog,
        currentStep: currentStep || {},
    }
    return initialState
}

export const ExamProvider = (props) => {
    const params = useParams()
    const [state, dispatch] = useReducer(reducer, getInitialState(params, props.originData))
    return <ExamContext.Provider value={{ state, dispatch }}>{props.children}</ExamContext.Provider>
}
