//cSpell:word shebao
import s_1 from '../../../../../resources/shebao/2_s_1.png'
import s_1_hover from '../../../../../resources/shebao/2_s_1_hover.png'
import s_2 from '../../../../../resources/shebao/2_s_2.png'
import s_2_hover from '../../../../../resources/shebao/2_s_2_hover.png'
import s_3 from '../../../../../resources/shebao/2_s_3.png'
import s_3_hover from '../../../../../resources/shebao/2_s_3_hover.png'
import s_4 from '../../../../../resources/shebao/2_s_4.png'
import s_4_hover from '../../../../../resources/shebao/2_s_4_hover.png'
import s_5 from '../../../../../resources/shebao/2_s_5.png'
import s_5_hover from '../../../../../resources/shebao/2_s_5_hover.png'
import s_6 from '../../../../../resources/shebao/2_s_6.png'
import s_6_hover from '../../../../../resources/shebao/2_s_6_hover.png'
import s_7 from '../../../../../resources/shebao/2_s_7.png'
import s_7_hover from '../../../../../resources/shebao/2_s_7_hover.png'
import s_8 from '../../../../../resources/shebao/2_s_8.png'
import s_8_hover from '../../../../../resources/shebao/2_s_8_hover.png'
import s_9 from '../../../../../resources/shebao/2_s_9.png'
import s_9_hover from '../../../../../resources/shebao/2_s_9_hover.png'
import s_10 from '../../../../../resources/shebao/2_s_10.png'
import s_10_hover from '../../../../../resources/shebao/2_s_10_hover.png'
import s_11 from '../../../../../resources/shebao/2_s_11.png'
import s_11_hover from '../../../../../resources/shebao/2_s_11_hover.png'
import s_12 from '../../../../../resources/shebao/2_s_12.png'
import s_12_hover from '../../../../../resources/shebao/2_s_12_hover.png'
import s_13 from '../../../../../resources/shebao/2_s_13.png'
import s_13_hover from '../../../../../resources/shebao/2_s_13_hover.png'

export const sidebarData = {
    劳动就业: [
        { name: '招工登记', icon: s_1, iconHover: s_1_hover, children: [{ name: '就业参保登记', allow: '招工登记' }] },
        { name: '退工登记', icon: s_2, iconHover: s_2_hover, children: [{ name: '退工登记办理', allow: '退工登记' }] },
        { name: '信息查询', icon: s_3, iconHover: s_3_hover },
        { name: '打印服务', icon: s_4, iconHover: s_4_hover },
        { name: '单位管理', icon: s_5, iconHover: s_5_hover },
        { name: '来沪人员信息管理', icon: s_6, iconHover: s_6_hover },
        { name: '国企退休人员档案管理', icon: s_7, iconHover: s_7_hover },
    ],
    社会保险: [
        {
            name: '缴费变更申报',
            icon: s_8,
            iconHover: s_8_hover,
            children: [
                { name: '转入人员', allow: '社保转入' },
                { name: '转出人员', allow: '社保转出' },
                { name: '补缴人员' },
                { name: '封存人员' },
                { name: '启封人员' },
                { name: '缴费基数调整人员', allow: '社保缴费基数调整' },
            ],
        },
        { name: '业务撤销', icon: s_9, iconHover: s_9_hover },
        { name: '查询打印', icon: s_10, iconHover: s_10_hover },
        { name: '经办规程', icon: s_11, iconHover: s_11_hover },
        { name: '操作手册', icon: s_12, iconHover: s_12_hover },
        { name: '问题解答', icon: s_13, iconHover: s_13_hover },
    ],
}
