import { matchPath } from 'react-router'
import { buildFullPath, setData, getData, removeKeys } from './adapterHelp'
// eslint-disable-next-line
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'

const mockUser = (examId) => {
    const _examId = examId || getData(MOCK_KEYS.examId)
    return {
        id: 'mock_user',
        roles: ['Candidate'],
        loginName: '12345678901234567890',
        nickName: '模拟考生',
        type: 'Candidate',
        _examId,
        password: '******',
        username: '模拟演练',
        status: 'Init',
    }
}

const getFullPath = (config) => {
    return buildFullPath(config.baseURL, config.url)
}

const MOCK_KEYS = {
    examId: 'mock_exam_id',
    answers: 'mock_answers',
    examData: 'mock_exam_data',
    startDate: 'mock_start_date',
}

const buildAxiosResponse = (data, status = 200) => {
    const response = {
        data,
        status,
        statusText: 'ok',
        headers: {},
        config: {},
        request: {},
    }

    if (status >= 200 && status < 300) {
        return Promise.resolve(response)
    }
    response.statusText = `${status} code`
    return Promise.reject(response)
}
const buildBadRequest = (msg, path) => {
    return buildAxiosResponse({ message: `${msg}`, path }, 400)
}

const loginIn = (config) => {
    removeKeys(Object.values(MOCK_KEYS))
    const examId = config.data.get('examId')
    setData(MOCK_KEYS.examId, examId)
    setData(MOCK_KEYS.startDate, new Date().valueOf())

    return getExamDataFromServer(config, examId).then((data) => {
        if (data?.exam?.hidden) {
            return buildBadRequest('考试状态异常，请刷新考试列表')
        }
        setData(MOCK_KEYS.examData, data)
        return buildAxiosResponse(mockUser(examId))
    })
}

const ping = (config) => {
    return buildAxiosResponse(mockUser())
}

const releaseExams = (config) => {
    const category = new window.URLSearchParams(location.search).get('category')

    return axios.request({ ...config, params: { category }, url: '/mock_exams/mock/exams', adapter: null })
}

const getExamDataFromServer = (config, id) => {
    return axios.request({ ...config, method: 'GET', url: `/mock_exams/mock/${id}`, adapter: null }).then((res) => {
        const data = {
            ...res.data,
            candidate: mockUser(),
        }

        const now = getData(MOCK_KEYS.startDate, new Date().valueOf())
        data.exam.startDate = now
        data.exam.endDate = now + 1000 * 60 * 60

        data.exam.notes = `1、考试全程请勿退出全屏或离开答题界面！系统将会自动记录您的各项操作记录并上传至服务器！违规操作将可能影响您的考试成绩！请务必遵守考试规则！
2、考试倒计时将会实时显示在答题界面右上角，请注意剩余答题时间！
3、答题完成后，请点击交卷，交卷成功后答案不可再做修改！系统将会自动退出！
4、答题时间剩余 5 分钟时，系统将会进行提示，答题时间用完后，系统将会强制自动提交！`
        return data
    })
}

const getExamById = (config, matchParams) => {
    const id = matchParams?.id

    if (!id) {
        return buildBadRequest('没有找到id参数')
    }

    const examData = getData(MOCK_KEYS.examData)
    if (!examData) {
        return buildBadRequest('没有找到考试')
    }

    return buildAxiosResponse(examData).then((res) => res.data)
}

const recordAction = () => {
    return buildAxiosResponse({}, 201)
}

const submitPaper = () => {
    removeKeys(Object.keys(MOCK_KEYS))
    return buildAxiosResponse({}, 201)
}

/**
 *
 * @param {AxiosRequestConfig} config
 * @returns {AxiosPromise<any>} promise
 */
const postAnswer = (config) => {
    const questionId = config.data.questionId
    const questionIndex = config.data.questionIndex

    if (!questionId || !Number.isInteger(questionIndex)) {
        return buildBadRequest(`参数缺失 questionId = ${questionId} , questionIndex = ${questionIndex}`)
    }

    const answersInLocal = getData(MOCK_KEYS.answers, {})

    answersInLocal[`${questionId}_${questionIndex}`] = config.data

    setData(MOCK_KEYS.answers, answersInLocal)

    return buildAxiosResponse({}, 201)
}

const postAnswers = (config) => {
    const data = JSON.parse(config.data)
    if (!Array.isArray(data)) {
        return buildBadRequest(`参数缺失 data 不是集合类型`)
    }
    const checkData = data.some((val) => !val.questionId || !Number.isInteger(val.questionIndex))
    if (checkData) {
        console.warn('data', data)
        return buildBadRequest(`参数缺失 data 中有元素不包含 questionId 或 questionIndex`)
    }

    const answersInLocal = getData(MOCK_KEYS.answers, {})

    for (let item of data) {
        answersInLocal[`${item.questionId}_${item.questionIndex}`] = item
    }

    setData(MOCK_KEYS.answers, answersInLocal)
    return buildAxiosResponse({}, 201)
}

const postOperationRecord = (config, matchParams) => {
    const data = JSON.parse(config.data)

    const answersInLocal = getData(MOCK_KEYS.answers, {})

    const examAnswer = answersInLocal[`${data.questionId}_${data.questionIndex}`] || {}

    examAnswer.questionId = data.questionId
    examAnswer.questionIndex = data.questionIndex
    examAnswer.examId = matchParams.examId

    if (!examAnswer.questionAnswer) {
        examAnswer.questionAnswer = {
            type: 'Operate',
            content: data.operateObject,
            operationRecord: [
                {
                    type: data.type,
                    operateObject: data.operateObject,
                    update: Date.now(),
                },
            ],
        }
    } else {
        // 有答案 有数据。进行合并
        examAnswer.questionAnswer.operationRecord.push({
            type: data.type,
            operateObject: data.operateObject,
            update: Date.now(),
        })
        for (let item of data.operateObject) {
            const index = examAnswer.questionAnswer.content.findIndex(
                (it) => it.identity === item.identity && it.data['操作类型'] === item.data['操作类型']
            )

            if (index > -1) {
                const oldData = examAnswer.questionAnswer.content[index]
                examAnswer.questionAnswer.content[index] = { ...oldData, ...item }
            } else {
                examAnswer.questionAnswer.content.push(item)
            }
        }
    }

    answersInLocal[`${data.questionId}_${data.questionIndex}`] = examAnswer

    setData(MOCK_KEYS.answers, answersInLocal)

    return buildAxiosResponse(examAnswer, 200)
}

const getAllAnswers = (config, matchParams) => {
    const examId = matchParams?.examId

    if (!examId) {
        return buildBadRequest('没有找到examId参数')
    }
    const answersInLocal = getData(MOCK_KEYS.answers, {})

    return buildAxiosResponse(Object.values(answersInLocal), 200)
}

const getAnswerByQuestionId = (config, matchParams) => {
    const questionId = matchParams?.questionId

    if (!questionId) {
        return buildBadRequest('没有找到questionId参数')
    }
    const answersInLocal = getData(MOCK_KEYS.answers, {})
    const answers = Object.values(answersInLocal)

    return buildAxiosResponse(
        answers.filter((val) => val.questionId === questionId),
        200
    )
}

const logout = () => {
    removeKeys(Object.values(MOCK_KEYS))

    return buildAxiosResponse({}, 200)
}

const urlMaps = [
    {
        path: '/api/logout',
        handler: logout,
    },
    {
        path: '/api/ping',
        handler: ping,
    },
    {
        path: '/api/candidate/login',
        handler: loginIn,
    },
    {
        path: '/api/candidate/release_exams',
        handler: releaseExams,
    },
    {
        path: '/api/candidate/exam/:id',
        handler: getExamById,
    },
    {
        path: '/api/candidate/exam/:id/status',
        handler: getExamById,
    },
    {
        path: '/api/candidate/action/:actionName',
        handler: recordAction,
    },
    {
        path: '/api/candidate/exam/:examId/submit_date',
        handler: submitPaper,
    },
    {
        path: '/api/candidate/exam/:examId/paper/:paperId/answer',
        handler: postAnswer,
    },
    {
        path: '/api/candidate/exam/:examId/paper/:paperId/answers',
        handler: postAnswers,
    },
    {
        path: '/api/candidate/exam/:examId/answers',
        handler: getAllAnswers,
    },
    {
        path: '/api/candidate/question/:questionId/answer',
        handler: getAnswerByQuestionId,
    },
    {
        path: '/api/candidate/exam/:examId/paper/:paperId/operationRecord',
        handler: postOperationRecord,
    },
]

/**
 *
 * @param {AxiosRequestConfig} config
 * @returns {AxiosPromise<any>} promise
 */
const adapter = (config) => {
    const fullPath = getFullPath(config)

    for (let map of urlMaps) {
        const match = matchPath(fullPath, { path: map.path, exact: true })
        if (match) {
            return map.handler(config, match.params)
        }
    }
    //没有匹配到任何结果
    // console.log('还没有mock这个url', fullPath)
    return buildAxiosResponse({}, 404)
}

export default adapter
