import React from 'react'
import { Form, Input } from 'antd'

export default ({ prevPath, maxLength = 800 }) => (
    <Form.Item
        name={[...prevPath, 'answer', 'content']}
        label="答案"
        validateTrigger="onBlur"
        rules={[{ required: true, whitespace: true, message: '内容不能为空' }]}
    >
        <Input.TextArea showCount maxLength={maxLength} autoSize={{ minRows: 3, maxRows: 5 }} />
    </Form.Item>
)
