import Confirm from '../../../../../components/modal/Confirm'

const SplitConfirm = (props) => {
    const className = `exam-simulation-confirm ${props.className || ''}`
    return Confirm({
        getContainer: '.layout-pane-primary',
        ...props,
        className,
    })
}

export default SplitConfirm
