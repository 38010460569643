import React, { useState, useEffect } from 'react'
import { useParams, Route, Switch } from 'react-router-dom'
import { getCandidateExam } from '../../../services/CandidateRequest'
import { ping, logout } from '../../../services/UserRequest'
import Confirm, { ErrorConfirm } from '../../../components/modal/Confirm'
import message from '../../../components/message'
import Layout from '../login/layout'
import BaseInfo from './BaseInfo'
import Instruction from './Instruction'
import { getBaseUrl } from '../../../common/urlHelp'
import './index.styl'

const ExamInfo = ({ match }) => {
    const [loginData, setLoginData] = useState(null)
    const { id } = useParams()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const exam = await getCandidateExam(id)
                exam.user = await ping()
                setLoginData(exam)
            } catch (error) {
                const msg = error?.response?.data?.message || '操作失败！请刷新页面后重试'
                if (msg === '操作失败！请刷新页面后重试') return message.error(msg)
                ErrorConfirm({ message: msg })
            }
        }
        fetchData()
    }, [id])
    const onLogout = () => {
        Confirm({
            title: '退出确认',
            content: '系统将会记录您的退出操作\n确认退出登录？',
            onOk() {
                logout().finally(() => location.replace(`${getBaseUrl()}/login`))
            },
        })
    }
    return (
        <div className="exam-info-page">
            <Layout loading={!loginData}>
                <Switch>
                    <Route path={`${match.path}/base`} component={() => <BaseInfo loginData={loginData} />} />
                    <Route path={`${match.path}/instruction`} component={() => <Instruction loginData={loginData} />} />
                </Switch>
            </Layout>
            <div className="exam-info-logout" onClick={onLogout}>
                <i className="iconfont icon-quit" />
                <span>退出登录</span>
            </div>
        </div>
    )
}

export default ExamInfo
