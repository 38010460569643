export const levelNames = {
    Beginner: '初级',
    Intermediate: '中级',
    Advanced: '高级',
}

export const types = {
    Official: '正式考试',
    Simulation: '模拟考试',
}

export const subjects = {
    Theory: '理论知识',
    Practice: '实操技能',
}

// Init, //  试卷初始状态

// None, // 都是客观题，不需要阅卷
// NotScored, //需要人工打分，但是还未打分

// Done // 完成打分
export const scoreStatus = {
    Done: '已评分',
    Init: '待评分',
    None: '待评分',
    NotScored: '待评分',
}

export const status = {
    Release: '已发布',
    Unpublished: '未发布/停用',
}
