import { DatePicker } from 'antd'
import StringDateWrap from '../components/StringDatePicker'
import { Link, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'

import './HomePage.styl'

const featureItem = [
    {
        title: '人员信息采集',
        text: '自然人基础信息登记，报送和公安系统身份证',
        questionName: ['人员信息登记', '人员信息维护'],
    },
    {
        title: '专项附加扣除信息采集',
        text: '子女教育支出，住房租金支出等信息采集',
        questionName: [],
    },
    {
        title: '综合所得申报',
        text: '工资薪金、劳务报酬、酬薪、特许权使用费等月度申报',
        questionName: ['正常工资薪金所得'],
    },
    {
        title: '分类所得申报',
        text: '利息股息红利、财产租赁、财产转让等月度申报',
        questionName: [],
    },
    {
        title: '非居民所得申报',
        text: '工资薪金、劳务报酬、稿酬、特许权使用费等月度申报',
        questionName: [],
    },
    {
        title: '税款缴纳',
        text: '个人所得税款在线缴纳',
        questionName: [],
    },
]

function HomePage({ presetDate, onTaxMonthChange, taxMonth, name }) {
    const match = useRouteMatch()
    const dateRange = (moment) => {
        return !presetDate.dateRange.includes(moment.format('yyyy-MM'))
    }
    return (
        <div className="home-page">
            <nav className="breadcrumb">
                <span className="breadcrumb-item current">
                    <div className="home-icon active" />
                    首页
                </span>
            </nav>
            <div className="geshui-date-picker">
                税款所属月份：
                <StringDateWrap
                    component={<DatePicker.MonthPicker />}
                    disabledDate={dateRange}
                    onChange={onTaxMonthChange}
                    value={taxMonth}
                />
            </div>
            <div className="cards">
                <article>
                    <h3>
                        <div className="line" />
                        常用功能
                    </h3>
                    <div className="feature">
                        {featureItem.map((item) => {
                            const cl = classNames({ 'no-answer': !item.questionName.includes(name) }, 'feature-item')

                            return (
                                <Link to={`${match.url}/${item.title}`} className={cl} key={item.title}>
                                    <div className={`icon bg-menu bg-menu-${item.title}`} />
                                    <div className="info">
                                        <h3>{item.title}</h3>
                                        <div>{item.text}</div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </article>
                <article className="extend-to-bottom">
                    <h3>
                        <div className="line" />
                        待处理事项
                        <span className="todo-count">（0）</span>
                    </h3>
                    <div className="notes">暂无待处理事项</div>
                </article>
            </div>
        </div>
    )
}

export default HomePage
