import React from 'react'
import { Row, Col, Space } from 'antd'
import { formatDate } from '../../../../common/utils'
import './DetailView.styl'

const ItemRow = ({ label, value, children }) => (
    <Row wrap={false}>
        <Col flex="none">
            <div className="item-label">{`${label}：`}</div>
        </Col>
        <Col fill="auto" className="item-value">
            {!value && value !== 0 ? children : value}
        </Col>
    </Row>
)

const AnswerAndExplanation = ({ data }) => {
    const SingleQuestion = ({ question, index }) => {
        const answer = question.type === 'Text' ? question.answer.content : question.answer.options?.join('')
        return (
            <>
                <ItemRow label={`参考答案${index || ''}`} value={answer} />
                <ItemRow label={`答案解析${index || ''}`} value={question.explanation} />
            </>
        )
    }
    const PracticeQuestion = ({ question, index }) => (
        <>
            <ItemRow
                label={`参考答案${index || ''}`}
                value={question.answer.content?.map((item, i) =>
                    Object.keys(item.data).map((v, j) => {
                        if (item.data[v] instanceof Array) {
                            item.data[v].forEach((a) => {
                                if (a === '') {
                                    item.data[v] = `${item.data[v]}空`
                                }
                            })
                            item.data[v] = item.data[v] + ','
                            item.data[v] = item.data[v].substring(0, item.data[v].lastIndexOf(','))
                        } else if (item.data[v] === false) {
                            item.data[v] = '否'
                        } else if (item.data[v] === true) {
                            item.data[v] = '是'
                        }
                        return (
                            <p key={j}>
                                <span>
                                    {v}：{item.data[v]}
                                </span>
                            </p>
                        )
                    })
                )}
            />
            <ItemRow label={`参考答案与解析${index || ''}`}>
                <div dangerouslySetInnerHTML={{ __html: question.reference }} />
            </ItemRow>
        </>
    )
    if (data.type !== 'Analyze' && data.type !== 'Operate') {
        return <SingleQuestion question={data} />
    } else if (data.type === 'Operate') {
        return data.baseQuestions?.map((question, index) => (
            <PracticeQuestion key={index} question={question} index={index + 1} />
        ))
    }
    return data.baseQuestions?.map((question, index) => (
        <SingleQuestion key={index} question={question} index={index + 1} />
    ))
}

export default ({ data }) => {
    return (
        <div className="question-detail">
            <ItemRow label="题目分类">
                <Space>
                    <span>{data.certificateName}</span>
                    <span>{data.levelName}</span>
                    <span>{data.subjectName}</span>
                    <span>{data.fieldName}</span>
                </Space>
            </ItemRow>
            <ItemRow label="题目标签">
                {data.tags?.length > 0
                    ? data.tags.map((tag, index) => (
                          <div key={tag + index} className="tag">
                              {tag}
                          </div>
                      ))
                    : '无'}
            </ItemRow>
            {data.type === 'Operate' ? <ItemRow label="搜索标识" value={data.key} /> : <></>}
            <AnswerAndExplanation data={data} />
            <ItemRow label="创建人" value={data.createdUserName} />
            <ItemRow label="创建时间" value={formatDate(data.createdDate, true, '-')} />
            <ItemRow label="最后修改人" value={data.modifiedUserName} />
            <ItemRow label="最后修改时间" value={formatDate(data.modifiedDate, true, '-')} />
            <ItemRow label="题目使用次数" value={data.paperIds?.length || 0} />
            <ItemRow label="相关试卷" value={data.paperNames || '无'} />
        </div>
    )
}
