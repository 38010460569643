import React, { useState, useEffect, useCallback } from 'react'
import { getQuestions, deleteQuestions } from '../../../../services/QuestionRequest'
import message from '../../../../components/message'
import Confirm from '../../../../components/modal/Confirm'
import Header from './Header'
import Filter from './Filter'
import List from './List'

const QuestionList = () => {
    const [filters, setFilters] = useState({})
    const [data, setData] = useState({})
    const [selects, setSelects] = useState([])
    const [loading, setLoading] = useState(false)
    const getData = useCallback(
        async (page = 1) => {
            let params = `page=${page}`
            Object.keys(filters).forEach((k) => {
                params = `${params}&${k}=${filters[k]}`
            })
            setLoading(true)
            const res = await getQuestions(`${params}`)
            await new Promise((resolve) => setTimeout(resolve, 200))
            setData(res)
            setLoading(false)
        },
        [filters]
    )
    useEffect(() => {
        getData()
    }, [getData])
    const onFilter = (filters) => {
        setFilters(filters)
        setSelects([])
    }
    const handleDelete = () => {
        const deleteFunc = () =>
            deleteQuestions(selects)
                .then(() => {
                    message.success('操作成功！')
                    setSelects([])
                    getData()
                })
                .catch(() => message.error('操作失败，请稍后重试！'))
        Confirm({
            title: '提示',
            content: '确认删除所有已选中的项么？',
            onOk: deleteFunc,
        })
    }
    return (
        <div className="question-list-page">
            <Header onRefresh={getData} />
            <Filter onFilter={onFilter} />
            <List
                loading={loading}
                data={data}
                getData={getData}
                selects={selects}
                setSelects={setSelects}
                handleDelete={handleDelete}
            />
        </div>
    )
}

export default QuestionList
