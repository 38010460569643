// 特性参考设计规范及antd
import React from 'react'
import './index.styl'

const combineClass = (base, opt = {}) => {
    let result = base || ''
    Object.keys(opt).forEach((key) => (result += opt[key] ? ` ${key}` : ''))
    return result
}

const CustomButton = ({ icon, className, primary, warn, danger, disabled, text, bold, children, ...rest }) => {
    return (
        <button
            className={combineClass(`ks-btn${className ? ` ${className}` : ''}`, {
                primary,
                warn,
                danger,
                disabled,
                text,
                bold,
            })}
            {...rest}
        >
            {icon && <i className={`iconfont ${icon}`} />}
            <span>{children}</span>
        </button>
    )
}

export default CustomButton
