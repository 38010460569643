import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'

import message from '../components/Message'
import { sidebarData } from './config'
import './DetailPageSidebar.styl'

const DetailPageSidebar = ({ allow, prevUrl, level1, level2 }) => {
    const data = sidebarData[level1]

    const onTipForbidden = (e) => {
        const className = e.target?.className || ''
        if (className.indexOf('ant-menu ant-menu-sub') !== -1) {
            message.error('请根据任务说明完成“' + allow + '”')
        }
    }

    return (
        <div className="sb-detail-page-sidebar scrollbar" onClick={onTipForbidden}>
            <Link className="view-title" to={prevUrl}>
                <i className="iconfont icon-down back-icon" />
                <span className="back-title">{level1}</span>
            </Link>
            <Menu mode="inline">
                {data.map((l1) => {
                    return (
                        <Menu.SubMenu
                            key={l1.name}
                            title={l1.name}
                            className={`${l1.children ? '' : 'no-children'}`}
                            disabled={!l1.children}
                            icon={
                                <>
                                    <img className="item-icon" src={l1.icon} />
                                    <img className="item-icon-hover" src={l1.iconHover} />
                                </>
                            }
                        >
                            {l1.children?.map((l2) => (
                                <Menu.Item key={l2.name} disabled={l2.allow !== allow}>
                                    <Link className="item-link" to={`${prevUrl}/${level1}/${l2.name}`}>
                                        {l2.name}
                                    </Link>
                                </Menu.Item>
                            ))}
                        </Menu.SubMenu>
                    )
                })}
            </Menu>
        </div>
    )
}

export default DetailPageSidebar
