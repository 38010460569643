import React from 'react'
import { InputNumber, Space } from 'antd'
import { findIndex } from 'lodash'
import message from '../../../../components/message'
import Confirm from '../../../../components/modal/Confirm'

const ConfirmView = ({ defaultScore, setScore }) => {
    return (
        <div>
            <span>分值：</span>
            <InputNumber
                defaultValue={defaultScore}
                min={0}
                max={100}
                onChange={setScore}
                style={{ width: '140px' }}
                precision={1}
            />
        </div>
    )
}

export default ({ totalScore, onConfirm }) => {
    let score = null
    const onChange = (value) => (score = value)
    return Confirm({
        className: 'confirm-modal score-set',
        title: '设置分数',
        width: 300,
        icon: null,
        cancelText: '取消',
        okText: '确认',
        closable: true,
        content: <ConfirmView setScore={onChange} />,
        onOk: () => {
            if (!score && score !== 0) {
                message.error('请设置分数！')
                return Promise.reject('请设置分数！')
            } else if (score > totalScore) {
                message.error('评分不得超出题目满分！')
                return Promise.reject('评分不得超出题目满分！')
            }
            message.success('操作成功！')
            onConfirm(score)
        },
    })
}

export const AnalyzeConfirm = ({ data, defaultScores, onConfirm }) => {
    const questions = data.baseQuestions || []
    const scores = []
    const onChange = (index, value) => {
        const sIndex = findIndex(scores, (s) => s.index === index)
        if (sIndex !== -1) {
            scores.splice(sIndex, 1, { questionId: data.id, score: value, index })
        } else {
            scores.push({ questionId: data.id, score: value, index })
        }
    }
    return Confirm({
        className: 'confirm-modal score-set',
        title: '设置分数',
        width: '50%',
        icon: null,
        cancelText: '取消',
        okText: '确认',
        closable: true,
        content: (
            <div>
                {questions.map((q, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <Space>
                            <div>（{index + 1}）</div>
                            <div style={{ color: '#6076FF' }}>{q.typeName}</div>
                            <ConfirmView
                                defaultScore={defaultScores[index]?.score}
                                setScore={(value) => onChange(index, value)}
                            />
                        </Space>
                        <div>{q.content}</div>
                    </div>
                ))}
            </div>
        ),
        onOk: () => {
            if (scores.length < questions.length) {
                message.error('请给各小题分数填写有效分数！')
                return Promise.reject('请给各小题分数填写有效分数！')
            }
            message.success('操作成功！')
            onConfirm(scores)
        },
    })
}
