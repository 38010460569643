//cSpell:word Feng
import React from 'react'
import produce from 'immer'
import { Button, Modal, Form, Row, Col, Input, DatePicker, Checkbox } from 'antd'

import img from '../../../../../../assets/提示.png'
import 'moment/locale/zh-cn'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import { formatDate } from '../../../../../../common/utils'
import Confirm from '../../components/Confirm'
import './QiFengModal.styl'

const ConfirmNotice = ({ checked, setChecked }) => {
    const onChange = (e) => {
        setChecked(e.target.checked)
    }
    return (
        <div>
            <p>注意：封存业务尚未被提交。</p>
            <p>需点击“封存”按钮后，方可提交业务！</p>
            <p>
                <Checkbox
                    onChange={onChange}
                    style={{
                        marginLeft: '130px',
                        marginTop: '20px',
                        fontSize: '12px',
                        color: '#cccccc',
                        lineHeight: '16px',
                    }}
                >
                    本次业务不再提醒
                </Checkbox>
            </p>
        </div>
    )
}
const FengCunForm = ({ selects, setSelects, resData, list, setList, checked, setChecked }) => {
    const [form] = Form.useForm()
    const onFinish = () => {
        const values = produce(form.getFieldsValue(true), (draft) => {
            draft.data.退工日期 = formatDate(draft.data.退工日期.valueOf(), false, '-')
        })
        const dataObj = produce(values, (draft) => {
            const phone = draft.data?.个人手机号码 || ''
            draft.data.身份证号码 = draft.identity.trim()
            draft.identity = resData.identity
            draft.data.个人手机号码 = phone.trim()
        })
        const newTolData = produce(list, (draft) => {
            draft.forEach((item) => {
                if (item.identity === resData.identity) {
                    item.data['封存原因'] = dataObj.data.封存原因
                    item.data['退工日期'] = dataObj.data.退工日期
                    item.data['封存年月'] = dataObj.data.封存年月.toString()
                    item.data['手机号码'] = dataObj.data.个人手机号码
                    item.identity = dataObj.identity
                    item.data['身份证号码'] = dataObj.data.身份证号码
                }
            })
        })
        form.resetFields()
        setList(newTolData)
        Modal.destroyAll()
        !checked ? (
            Confirm({
                className: 'confirm-submit',
                title: '提示',
                width: 480,
                content: <ConfirmNotice checked={checked} setChecked={setChecked} />,
            })
        ) : (
            <></>
        )
    }
    const initialValues = {
        data: {
            姓名: resData.data?.姓名,
            封存年月: 202106 + 1,
            个人账号: resData.data?.职工账号,
            封存原因: '终止劳动关系',
            末次汇缴年月: 202106,
        },
    }
    const onCancel = () => {
        Modal.destroyAll()
        const newSelects = produce(selects, (draft) => {
            const idx = selects.indexOf(resData.data.职工账号)
            if (idx > -1) {
                draft.splice(idx, 1)
            }
        })
        setSelects(newSelects)
    }
    return (
        <div className="confirm-status">
            <p className="confirm-status-tips">
                <img src={img} className="icon-img" />
                <span className="tips-content">
                    终止劳动关系以外的其他封存原因请直接至单位公积金账户所属区公积金业务网点办理
                </span>
            </p>
            <Form
                form={form}
                className="confirm-status-form"
                initialValues={initialValues}
                onFinish={onFinish}
                labelCol={{ flex: '100px' }}
                preserve={false}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="个人姓名" name={['data', '姓名']}>
                            <Input disabled style={{ boxShadow: 'none', color: '#000' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="封存年月" name={['data', '封存年月']}>
                            <Input disabled style={{ boxShadow: 'none', color: '#000' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="个人账号" name={['data', '个人账号']}>
                            <Input disabled style={{ boxShadow: 'none', color: '#000' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="封存原因" name={['data', '封存原因']}>
                            <Input disabled style={{ boxShadow: 'none', color: '#000' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="个人手机号码" name={['data', '个人手机号码']}>
                            <Input maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="身份证号"
                            name="identity"
                            required={false}
                            rules={[{ required: true, message: '此项不得为空' }]}
                        >
                            <Input maxLength={30} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="退工日期"
                            name={['data', '退工日期']}
                            required={false}
                            rules={[{ required: true, message: '此项不得为空' }]}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                locale={locale}
                                style={{ width: '205px', borderRadius: '5px' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="末次汇缴年月" name={['data', '末次汇缴年月']}>
                            <Input disabled style={{ boxShadow: 'none', color: '#000' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className="confirm-notice-btn">
                    <Col>
                        <Button onClick={onCancel}>关闭</Button>
                        <Button htmlType="submit">确定</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const FengCunModal = (selects, setSelects, resData, list, setList, checked, setChecked) => {
    return Confirm({
        className: 'confirm-QiFeng-status',
        width: 720,
        title: '封存详细信息',
        destroyOnClose: true,
        content: (
            <FengCunForm
                selects={selects}
                setSelects={setSelects}
                resData={resData}
                list={list}
                setList={setList}
                checked={checked}
                setChecked={setChecked}
            />
        ),
        onCancel: () => {
            Modal.destroyAll()
            const newSelects = produce(selects, (draft) => {
                const idx = selects.indexOf(resData.data.职工账号)
                if (idx > -1) {
                    draft.splice(idx, 1)
                }
            })
            setSelects(newSelects)
        },
    })
}

export default FengCunModal
