import React from 'react'
import './DefaultPage.styl'

const DefaultPage = ({ level1 }) => {
    const config = {
        劳动就业: {
            title: '目前招退工自助经办系统开通的功能如下：',
            list: [
                [
                    {
                        num: '一',
                        title: '招工登记',
                        content: '用人单位为招用的本市户籍或来沪从业人员办理招工备案登记以及就业参保登记手续。',
                    },
                    {
                        num: '二',
                        title: '退工登记',
                        content: '用人单位为已终止或解除劳动关系的本市户籍或来沪从业人员办理退工备案登记手续。',
                    },
                ],
                [
                    {
                        num: '三',
                        title: '单位管理',
                        content: '用人单位可维护地址、联系电话等基本信息。',
                    },
                    {
                        num: '四',
                        title: '信息查询',
                        content: '用人单位可查询任意一周内办理的招退工登记信息，以及员工在本单位办理招退工登记的情况。',
                    },
                ],
                [
                    {
                        num: '五',
                        title: '打印服务',
                        content:
                            '用人单位可打印任意一周内办理的来沪从业人员招退工登记表，以及本市户籍从业人员的退工证明。',
                    },
                ],
            ],
        },
        社会保险: {
            title:
                '目前社会保险自助经办系统开通的经办和服务项目包括就业参保登记、转入、转出、补缴、启封、业务撤销等X项业务功能和其他辅助查询打印等功能。',
            list: [
                [
                    {
                        num: '一',
                        title: '转入',
                        content:
                            '用人单位为已办理招（录）用手续且已建立本市城镇职工养老保险个人账户的单位从业人员申报办理继续缴费手续',
                    },
                    {
                        num: '二',
                        title: '转出',
                        content: '用人单位为已办理退工手续的单位从业人员申报办理停止缴费手续',
                    },
                ],
                [
                    {
                        num: '三',
                        title: '补缴',
                        content: '用人单位为本单位内未按时缴纳社会保险的单位从业人员申报办理补缴社会保险手续',
                    },
                    {
                        num: '四',
                        title: '封存',
                        content:
                            '用人单位为本单位内发生羁押、失踪、下落不明、劳动合同中止执行等情况的单位从业人员办理暂停缴费手续',
                    },
                ],
                [
                    {
                        num: '五',
                        title: '启封',
                        content: '用人单位为本单位内暂停缴费的单位从业人员申报办理恢复缴费手续',
                    },
                ],
            ],
        },
    }
    const data = config[level1] || {}
    return (
        <div className="sb-detail-default-page panel">
            <div className="default-name">欢迎使用自助经办系统</div>
            <div className="default-title">{data.title}</div>
            {data.list?.map((item, index) => (
                <div className="default-content" key={index}>
                    {item.map((strip, i) => (
                        <div className={`function-${i + 1}`} key={strip.num}>
                            <div className="function-num">{strip.num}</div>
                            <div className="function-introduce">
                                <p className="introduce-title">{strip.title}</p>
                                <p className="introduce-content">{strip.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default DefaultPage
