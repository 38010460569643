import React from 'react'
import { Form, Input } from 'antd'

export default ({ prevPath, maxLength = 300 }) => (
    <Form.Item
        name={[...prevPath, 'content']}
        label="题干"
        validateTrigger="onBlur"
        rules={[{ required: true, whitespace: true, message: '内容不能为空' }]}
    >
        <Input.TextArea showCount maxLength={maxLength} onPressEnter={(e) => e.preventDefault()} />
    </Form.Item>
)
