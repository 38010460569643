//cSpell:word Feng
import React, { useState, useEffect } from 'react'
import produce from 'immer'
import { Alert, Form, Button, Table, Space } from 'antd'
import { Link } from 'react-router-dom'

import img from '../../../../../../assets/提示.png'
import PublicForm from './PublicForm'
import Confirm from '../components/QiFengModal'
import './QiFeng.styl'

const QiFeng = ({ data, selectChecked, onNewRecordSave, prevUrl }) => {
    const [list, setList] = useState(data.question.presetData)
    useEffect(() => {
        const content = data.editAnswer.questionAnswer?.content || []
        setList(
            produce((draft) => {
                for (const item of content) {
                    const index = draft.findIndex((val) => val.data.个人账号 === item.data.个人账号)
                    if (item.data.操作类型 === '转入' && !draft.find((s) => s.data.个人账号 === item.data.个人账号)) {
                        draft.push(item)
                    } else if (index > -1) {
                        draft[index] = Object.assign({}, draft[index], item)
                    } else {
                        draft.push(item)
                    }
                }
            })
        )
    }, [data])
    const prevData = list.filter((s) => s.data.操作类型 === '转入' || !s.data.操作类型)
    const prevList = produce(prevData, (draft) => {
        draft.forEach((d, index) => {
            d.key = index
        })
    })
    const [form] = Form.useForm()
    const columns = [
        {
            title: '账户类型',
            key: '账户类型',
            render: (record) => (
                <span>
                    {record.data.账户类型 === '住房公积金' || record.data.账户类型 === '基本账户类型'
                        ? '基本账户类型'
                        : '补充账户类型'}
                </span>
            ),
        },
        {
            title: '个人账号',
            key: '个人账号',
            render: (record) => <span>{record.data.个人账号}</span>,
        },
        {
            title: '所属部门',
            key: '所属部门',
            render: (record) => <span>{record.data.所属部门}</span>,
        },
        {
            title: '工号',
            key: '工号',
            render: (record) => <span>{record.data?.工号 || '-'}</span>,
        },
        {
            title: '姓名',
            key: '姓名',
            render: (record) => <span>{record.data?.姓名 || '-'}</span>,
        },
        {
            title: '修正名',
            key: '修正名',
            render: (record) => <span>{record.data?.修正名 || '-'}</span>,
        },
        {
            title: '身份证号',
            key: '身份证号',
            render: (record) => <span>{record?.identity || '-'}</span>,
        },
        {
            title: '账户状态',
            key: '账户状态',
            render: (record) => <span>{record.data?.账户状态 || '-'}</span>,
        },
        {
            title: '工资',
            key: '工资',
            render: (record) => <span>{record.data?.工资 || '-'}</span>,
        },
        {
            title: '缴存比例',
            key: '缴存比例',
            render: (record) => <span>7</span>,
        },
        {
            title: '月缴存额',
            key: '月缴存额',
            render: (record) => <span>{record.data?.月缴存额 || '-'}</span>,
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => Confirm(record, form, onNewRecordSave, data)} style={{ color: '#1497E2' }}>
                        启封
                    </a>
                </Space>
            ),
        },
    ]
    return (
        <div className="gjj-QiFeng-page">
            <div className="QiFeng-header">
                <Link to={prevUrl} onClick={selectChecked} className="current">
                    首页
                </Link>
                &gt;
                <span className="header-personal">启封</span>
            </div>
            <div className="QiFeng-main">
                <div className="main-header">
                    <PublicForm />
                </div>
                <div className="main-button">
                    <Button>批量导入</Button>
                </div>
                <Alert
                    className="alert-warn"
                    message="启封后职工账户状态将为正常，单位需为职工正常缴存，如暂不需要为职工缴存，请勿操作启封。"
                    type="warning"
                    showIcon={true}
                    icon={<img src={img} className="icon-img" />}
                />
                <Table
                    columns={columns}
                    dataSource={prevList}
                    className="main-table"
                    rowKey="key"
                    bordered
                    scroll={{ x: 'max-content' }}
                    style={{ margin: '20px 0 30px 0' }}
                />
            </div>
        </div>
    )
}

export default QiFeng
