import React, { useState } from 'react'
import moment from 'moment'
import produce from 'immer'
import { Button, Table, Checkbox, Space, Dropdown, message } from 'antd'
import { difference, union } from 'lodash'
import { EllipsisOutlined } from '@ant-design/icons'

import ListMenu from './ListMenu'
import { getExam } from '../../../../../services/ExamsRequest'

const List = ({ data, getData, selects, setSelects, handleDelete, detailId, exam }) => {
    const [settingData, setSettingData] = useState({ visible: false, record: {} })
    const answerSheet = (id) => {
        getExam(exam.id).then((res) => {
            if (!res.paperSnapshotId) {
                message.error('操作失败！刷新后重试！')
            } else {
                window.open(`/kw/exam/detail/${detailId}/paper/${id}`)
            }
        })
    }
    const handleSelect = (e, id, isSelect) => {
        e.stopPropagation()
        const newSelects = produce(selects, (draft) => {
            if (isSelect) {
                const idx = selects.indexOf(id)
                draft.splice(idx, 1)
            } else {
                draft.push(id)
            }
        })
        setSelects(newSelects)
    }
    const handleSelectAll = () => {
        const ss = data.content?.map((item) => item.id) || []
        const unselects = difference(ss, selects)
        if (unselects.length === 0) {
            // 当前页已全选
            setSelects(difference(selects, ss))
        } else {
            setSelects(union(selects, ss))
        }
    }
    const dataContent = produce(data.content, (draft) => {
        draft.reverse()
        draft.forEach((item, index) => {
            item.num = index + 1
        })
        draft.reverse()
    })
    const columns = [
        {
            title: '',
            key: 'checkout',
            render: (record) => (
                <Checkbox
                    checked={selects.indexOf(record.id) !== -1}
                    onClick={(e) => handleSelect(e, record.id, selects.indexOf(record.id) !== -1)}
                />
            ),
        },
        {
            title: '序号',
            key: 'num',
            render: (text, record, index) => <span>{record.num}</span>,
        },
        {
            title: '身份信息',
            key: 'identity',
            render: (record) => (
                <div onClick={(e) => handleSelect(e, record.id, selects.indexOf(record.id) !== -1)}>
                    <p>学生姓名：{record.name}</p>
                    <p>准考证号：{record.ticket}</p>
                    <p>身份证号：{record.cardId ? record.cardId : '-'}</p>
                    <p>登录密码：{record.password}</p>
                </div>
            ),
        },
        {
            title: '排考信息',
            key: 'arrangement',
            render: (record) => (
                <div onClick={(e) => handleSelect(e, record.id, selects.indexOf(record.id) !== -1)}>
                    <p>所属考核站点：{record.examinationSite || '-'}</p>
                    <p>考场名称：{record.examinationRoom || '-'}</p>
                </div>
            ),
        },
        {
            title: '操作信息统计',
            key: 'arrangement',
            render: (record) => {
                return (
                    <div onClick={(e) => handleSelect(e, record.id, selects.indexOf(record.id) !== -1)}>
                        <p>
                            交卷时间：
                            {!record.submitDate ? '-' : moment(record.submitDate).format('YYYY-MM-DD HH:mm:ss')}
                        </p>
                        <p>登录次数：{record.loginCount}</p>
                        <p>退出全屏次数：{record.switchFullscreenCount}</p>
                        <p>失焦次数：{record.lostFocusCount}</p>
                    </div>
                )
            },
        },
        {
            title: '考试成绩',
            key: 'score',
            render: (record) => (
                <span onClick={(e) => handleSelect(e, record.id, selects.indexOf(record.id) !== -1)}>
                    {record.score === null
                        ? '-'
                        : String(record.score).indexOf('.') + 1 > 0
                        ? record.score?.toFixed(1)
                        : record.score}
                </span>
            ),
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <div style={{ width: '90px' }}>
                        <Button type="primary" block onClick={() => answerSheet(record.id)}>
                            查看答卷
                            {/* <Link to={`/kw/exam/detail/${detailId}/paper/${record.id}`} target="_blank">
                                查看答卷
                            </Link> */}
                        </Button>
                    </div>
                    <Dropdown overlay={() => ListMenu(record, settingData, setSettingData, getData, detailId)}>
                        <EllipsisOutlined rotate={90} style={{ fontSize: '24px' }} />
                    </Dropdown>
                </Space>
            ),
        },
    ]
    return (
        <div className="page-list-container">
            <div className="action-bar" style={{ marginBottom: '10px', marginTop: '50px' }}>
                <Button onClick={handleSelectAll}>全选</Button>
                <Button disabled={selects.length === 0} onClick={handleDelete}>
                    删除选中项
                </Button>
                <div className="selects-num">已选中 {selects.length || 0} 项</div>
                <div className="results-num" style={{ lineHeight: '40px' }}>
                    共找到 {data.totalElements || 0} 个考生
                </div>
            </div>
            <Table
                style={{ cursor: 'pointer' }}
                columns={columns}
                dataSource={dataContent}
                rowKey="id"
                pagination={false}
            />
        </div>
    )
}

export default List
