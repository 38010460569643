export const setLocalStorage = (name, data) => {
    let dataType = typeof data
    //对象
    if (dataType === 'object') {
        window.localStorage.setItem(name, JSON.stringify(data))
    } else if (['number', 'string', 'boolean'].indexOf(dataType) >= 0) {
        window.localStorage.setItem(name, data)
    } else {
        console.log('该类型不能用于本地存储')
    }
}

export const getLocalStorage = (name) => {
    let data = window.localStorage.getItem(name)
    if (data) {
        return JSON.parse(data)
    }
    return ''
}

export const removeLocalStorage = (name) => {
    window.localStorage.removeItem(name)
}
