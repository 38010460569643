import React, { useEffect, useState } from 'react'
import produce from 'immer'
import { Form, Button } from 'antd'
import { find, camelCase, filter, findIndex, round } from 'lodash'

import { typeKeys, typeNames } from './configs'
import BatchScoreBtn from './BatchScoreBtn'
import AddQuestion from './AddQuestion'
import QuestionList from './QuestionList'
import './QuestionForm.styl'

const Questions = ({ data, setData }) => {
    const [typeLists, setTypeList] = useState([])
    // 当前左侧选中的题目类型
    const [currentTypeList, setCurrentTypeList] = useState({ key: '', list: [] })
    // 包含题目总数统计、各类型题目数量、已经插入的题目ids
    const [countData, setCountData] = useState({})
    // 源数据更新后需要重新处理数据分类
    useEffect(() => {
        const newList = produce(data, (draft) => {
            const tempList = []
            typeKeys.forEach((key) => {
                const questionList = draft[`${camelCase(key)}List`] || []
                if (questionList.length > 0) {
                    const typeName = typeNames[key]
                    tempList.push({
                        key,
                        name: typeName,
                        shortname: typeName.substr(0, typeName.length - 1),
                        list: questionList,
                    })
                }
            })
            return tempList
        })
        setTypeList(newList)
    }, [data])
    // 数据分类完成后需要重新统计数量、已插入的题目ids
    useEffect(() => {
        const ids = []
        let total = 0
        const typeCounts = typeLists.map((typeList) => {
            total += typeList.list.length
            ids.push(...typeList.list)
            return `${typeList.shortname}${typeList.list.length}`
        })
        setCountData({
            total,
            existIds: ids,
            content: typeCounts.join('，'),
        })
        setCurrentTypeList((prev) => {
            // 当前选择的类型（默认选择第一种、上次选中的类型题目被删除后默认选择第一种）
            const effectiveType = (find(typeLists, (typeList) => typeList.key === prev.key) || typeLists[0])?.key || ''
            // 当前右侧显示的列表
            const currentList = find(typeLists, (typeList) => typeList.key === effectiveType)?.list || []
            return {
                key: effectiveType,
                list: currentList,
            }
        })
    }, [typeLists])

    const onConfirmAdd = (questions) => {
        const scores = []
        const newData = produce(data, (draft) => {
            questions.forEach((question) => {
                const key = `${camelCase(question.type)}List`
                if (!Array.isArray(draft[key])) {
                    draft[key] = []
                }
                draft[key].push(question.id)
                // 实操题需保存默认分数
                if (question.type === 'Operate') {
                    question.baseQuestions.forEach((bq, index) => {
                        scores.push({ questionId: question.id, index, score: bq.defaultScore })
                    })
                }
            })
        })
        // 如果插入的题包含实操题或其它需要配置分数的情况，先配置分数后再一起更新
        if (scores.length > 0) return onScoresChange(scores, newData)

        setData(newData)
    }
    const onConfirmDelete = (ids, callback) => {
        const key = `${camelCase(currentTypeList.key)}List`
        const newData = produce(data, (draft) => {
            draft[key] = filter(draft[key], (id) => ids.indexOf(id) === -1)
            draft.scores = filter(draft.scores, (score) => ids.indexOf(score.questionId) === -1)
        })
        setData(newData)
        callback()
    }
    const onQuestionMove = (current, next) => {
        const key = `${camelCase(currentTypeList.key)}List`
        const newData = produce(data, (draft) => {
            const temp = draft[key][next]
            draft[key][next] = draft[key][current]
            draft[key][current] = temp
        })
        setData(newData)
    }
    const onScoresChange = (scores, prevData) => {
        const newData = produce(prevData || data, (draft) => {
            const draftScores = draft.scores || []
            scores.forEach((score) => {
                const index = findIndex(
                    draftScores,
                    (s) => s.questionId === score.questionId && s.index === score.index
                )
                if (index !== -1) {
                    draftScores.splice(index, 1, score)
                } else {
                    draftScores.push(score)
                }
            })
            draft.scores = draftScores
            let totalScore = 0
            draftScores.forEach((s) => (totalScore += s.score))
            draft.totalScore = round(totalScore, 1)
        })
        setData(newData)
    }

    return (
        <div className="form-question-list">
            <Form.Item label="试卷题目">
                <div>
                    <span>{`当前共 ${countData.total} 题`}</span>
                    <span>{countData.total > 0 ? `（${countData.content}），满分${data.totalScore || 0}分` : ''}</span>
                </div>
            </Form.Item>
            <div className="list-wrap">
                <div className="left-side">
                    <div className="type-btn-list">
                        {typeLists.map((typeList) => (
                            <Button
                                block
                                key={typeList.key}
                                className={typeList.key === currentTypeList.key ? 'selected' : ''}
                                onClick={() => setCurrentTypeList(typeList)}
                            >
                                {typeList.name}
                            </Button>
                        ))}
                    </div>
                    <AddQuestion existIds={countData.existIds} onConfirmAdd={onConfirmAdd} />
                    <BatchScoreBtn typeLists={typeLists} onScoresChange={onScoresChange} />
                </div>
                <div className="right-side">
                    <QuestionList
                        scores={data.scores}
                        type={currentTypeList.key}
                        list={currentTypeList.list}
                        onConfirmDelete={onConfirmDelete}
                        onQuestionMove={onQuestionMove}
                        onScoresChange={onScoresChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default Questions
