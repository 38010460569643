import React from 'react'
import { Form, Row, Col, Space } from 'antd'
import { find } from 'lodash'
import message from '../../components/Message'
import Input from './Input'
import Button from './Button'

const SearchPanel = ({ title, form, data, onConfirm }) => {
    const { editAnswer, question } = data
    const onSearch = (values) => {
        const identity = values.identity?.trim() || ''
        const 姓名 = values.姓名?.trim() || ''
        form.setFieldsValue({
            identity,
            姓名,
        })
        const answers = editAnswer?.questionAnswer?.content || []
        const hasAnswer = find(answers, (ans) => ans.identity === identity)
        if (hasAnswer) return message.error('不可重复操作！')
        const user = find(question.presetData, (d) => d.identity === identity && d.data?.姓名 === 姓名)
        if (!user) return message.error('输入信息错误！')
        onConfirm(user)
    }
    const onReset = () => {
        form.resetFields()
        onConfirm(null)
    }
    return (
        <Form form={form} className="panel sb-search-panel" onFinish={onSearch}>
            <div className="panel-title">{title || '个人信息查询'}</div>
            <div className="panel-content">
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="公民身份证号码">
                            <Form.Item
                                name="identity"
                                rules={[{ required: true, whitespace: true, message: '内容不能为空' }]}
                                noStyle
                            >
                                <Input maxLength={30} />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="姓名">
                            <Space>
                                <Form.Item
                                    name="姓名"
                                    noStyle
                                    rules={[{ required: true, whitespace: true, message: '内容不能为空' }]}
                                >
                                    <Input maxLength={10} />
                                </Form.Item>
                                <Button htmlType="submit" className="theme" style={{ marginLeft: '20px' }}>
                                    确定
                                </Button>
                                <Button onClick={onReset} htmlType="button">
                                    重置
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Form>
    )
}

export default SearchPanel
