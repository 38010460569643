import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import immer from 'immer'
import message from '../components/Message'
import { Button, Table, Tabs, Modal, DatePicker, Form, Input, Select, InputNumber } from 'antd'
import orderBy from 'lodash/orderBy'

import StringDateWrap from '../components/StringDatePicker'
import FormGroup from './components/FormGroup'
import Confirm from '../components/Confirm'
import './Tax.styl'

const fixedRowNames = [
    '全年一次性奖金收入',
    '内退一次性补偿金',
    '年金领取',
    '解除劳动合同一次性补偿金',
    '央企负责人绩效薪金延期兑现收入和任期奖励',
    '单位低价向职工售房',
    '劳动报酬（保险营销员、证券经纪人、其他连续劳务）',
    '劳务报酬（一般劳务、其他非连续劳务）',
    '稿酬所得',
    '特许权使用费所得',
]

const defaultFormValue = {
    data: {
        本期收入: '0.00',
        本期免税收入: '0.00',
        基本养老保险费: '0.00',
        基本医疗保险费: '0.00',
        失业保险费: '0.00',
        住房公积金: '0.00',
        子女教育: '0.00',
        住房贷款利息: '0.00',
        住房租金: '0.00',
        赡养老人: '0.00',
        继续教育: '0.00',
        '企业（职业）年金': '0.00',
        商业健康保险: '0.00',
        税延养老保险: '0.00',
        其他: '0.00',
        准予扣除的捐赠额: '0.00',
        减免税额: '0.00',
        减除费用标准: '5000.00',
        已缴税额: '0.00',
    },
}

const toFixed = (val) => {
    const float = parseFloat(val)
    if (Number.isNaN(float)) {
        return '0.00'
    }
    return float.toFixed(2)
}

const columns = [
    {
        title: '工号',
        dataIndex: ['data', '工号'],
        width: 100,
        fixed: 'left',
    },
    {
        title: '姓名',
        dataIndex: ['data', '姓名'],
        width: 100,
        fixed: 'left',
    },
    {
        title: '证件类型',
        width: 110,
        dataIndex: ['data', '证件类型'],
    },
    {
        title: '证件号码',
        width: 200,
        dataIndex: ['identity'],
    },
    {
        title: '本期收入',
        width: 100,
        dataIndex: ['data', '本期收入'],
        render: toFixed,
    },
    {
        title: '本期免税收入',
        width: 130,
        dataIndex: ['data', '本期免税收入'],
        render: toFixed,
    },
    {
        title: '基本养老保险费',
        width: 135,
        dataIndex: ['data', '基本养老保险费'],
        render: toFixed,
    },
    {
        title: '基本医疗保险费',
        width: 135,
        dataIndex: ['data', '基本医疗保险费'],
        render: toFixed,
    },
    {
        title: '失业保险费',
        width: 110,
        dataIndex: ['data', '失业保险费'],
        render: toFixed,
    },
    {
        title: '住房公积金',
        width: 110,
        dataIndex: ['data', '住房公积金'],
        render: toFixed,
    },
    {
        title: '子女教育',
        width: 100,
        dataIndex: ['data', '子女教育'],
        render: toFixed,
    },
    {
        title: '住房贷款利息',
        width: 130,
        dataIndex: ['data', '住房贷款利息'],
        render: toFixed,
    },
    {
        title: '住房租金',
        width: 100,
        dataIndex: ['data', '住房租金'],
        render: toFixed,
    },
    {
        title: '赡养老人',
        width: 100,
        dataIndex: ['data', '赡养老人'],
        render: toFixed,
    },
    {
        title: '继续教育',
        width: 100,
        dataIndex: ['data', '继续教育'],
        render: toFixed,
    },
]

const get = (obj, path, defaultValue = undefined) => {
    const travel = (regexp) =>
        String.prototype.split
            .call(path, regexp)
            .filter(Boolean)
            .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj)
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
    return result === undefined || result === obj ? defaultValue : result
}

function sum(path, array = []) {
    const _sum =
        array
            ?.map((val) => get(val, path))
            ?.map(parseFloat)
            ?.filter((val) => !Number.isNaN(val))
            ?.reduce((p, c) => p + c, 0) || 0.0

    return _sum.toFixed(2)
}

function Total({ dependencies }) {
    return (
        <Form.Item className="total-form-item" label="小计" dependencies={dependencies}>
            {({ getFieldsValue }) => {
                const valus = getFieldsValue(dependencies)
                const sum = Object.values(valus.data)
                    .map(parseFloat)
                    .filter((val) => !Number.isNaN(val))
                    .reduce((p, c) => p + c, 0)
                    .toFixed(2)
                return <Input disabled value={sum} />
            }}
        </Form.Item>
    )
}

export function TaxHome({ match, taxMonth, presetData = [], questionAnswer }) {
    const dateStringArray = taxMonth?.split('-') || ['xxxx', 'xx']

    const total = useMemo(() => sum('data.本期收入', questionAnswer?.content), [questionAnswer])
    const listTotal = questionAnswer?.content?.length || 0
    return (
        <div className="tax-panel">
            <nav className="breadcrumb">
                <Link to={match.url.replace('/综合所得申报', '')} className="breadcrumb-item">
                    <div className="home-icon" /> 首页
                </Link>
                &gt;&gt;
                <span className="breadcrumb-item current">综合所得申报</span>
            </nav>
            <article>
                <ul className="steps">
                    <li className="current">1.收入及减除填写</li>
                    <li>
                        2.税款计算 <span>（请重新计税）</span>
                    </li>
                    <li>3.附表填写</li>
                    <li>4.申报表报送</li>
                </ul>
                <h3>
                    <div className="line" />
                    <span>
                        {dateStringArray[0]}年{dateStringArray[1]}月 综合所得预扣预缴表
                    </span>
                </h3>
                <table className="menu-table">
                    <thead>
                        <tr>
                            <th>所得项目名称</th>
                            <th>填写人数</th>
                            <th>收入合计</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Link className="text" to={`${match.url}/正常工资薪金所得`}>
                                    正常工资薪金所得
                                </Link>
                            </td>
                            <td>{listTotal}</td>
                            <td>{total}</td>
                            <td>
                                <Link to={`${match.url}/正常工资薪金所得`}>填写</Link>
                                {listTotal > 0 && <button className="no-answer link">清除数据</button>}
                            </td>
                        </tr>
                        {fixedRowNames.map((val) => (
                            <tr key={val}>
                                <td>{val}</td>
                                <td>0</td>
                                <td>0.00</td>
                                <td>
                                    <button className="no-answer link">填写</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </article>
        </div>
    )
}

export function TaxList({ match, taxMonth, presetData = [], questionAnswer, onNewRecordSave }) {
    const [visible, setVisible] = useState(false)
    const [list, setList] = useState(questionAnswer?.content || [])
    const dateStringArray = taxMonth?.split('-') || ['xxxx', 'xx']
    const history = useHistory()
    const [form] = Form.useForm()

    useEffect(() => {
        const sorted = orderBy(questionAnswer?.content || [], ['data._update'], ['desc'])

        setList(sorted)
    }, [questionAnswer])

    const personChange = (val) => {
        // val 可能是工号 姓名 证件号
        const person = presetData.find((item) => {
            return item.identity === val || item?.data?.['姓名'] === val || item?.data?.['工号'] === val
        })
        form.setFieldsValue(person)
    }

    const options = useMemo(() => {
        const names = presetData
            .map((val) => val?.data?.['姓名'])
            .filter((val) => !!val)
            .map((value) => ({
                value,
                disabled: questionAnswer?.content?.some((val) => val?.data?.['姓名'] === value),
            }))
        const ids = presetData
            .map((val) => val?.identity)
            .filter((val) => !!val)
            .map((value) => ({ value, disabled: questionAnswer?.content?.some((val) => val?.identity === value) }))
        const jobNos = presetData
            .map((val) => val?.data?.['工号'])
            .filter((val) => !!val)
            .map((value) => ({
                value,
                disabled: questionAnswer?.content?.some((val) => val?.data?.['工号'] === value),
            }))
        return {
            names,
            ids,
            jobNos,
        }
    }, [presetData, questionAnswer?.content])

    const closeModal = () => {
        form.resetFields()
        setVisible(false)
    }
    const openModal = () => {
        form.resetFields()
        const [year, month] = dateStringArray.map((val) => parseInt(val, 10)).filter((val) => !Number.isNaN(val))
        const days = new Date(year, month, 0).getDate()
        form.setFieldsValue({
            data: {
                所得期间起: `${taxMonth}-01`,
                所得期间止: `${taxMonth}-${days}`,
            },
        })
        setVisible(true)
    }

    const submitForm = () => {
        Confirm({
            okText: '确认',
            className: 'simulation-geshui-confirm',
            title: '提示',
            content: '保存后，不可修改或删除信息。是否保存？',
            getContainer: null,
            onOk: form.submit,
            centered: true,
        })
    }
    const onFormFinish = (values) => {
        if (!values.identity || !values.data['工号'] || !values.data['姓名']) {
            message.error('操作失败！请选择填写对象')
            return
        }

        const newValues = immer(values, (darft) => {
            Object.keys(defaultFormValue.data).forEach((key) => {
                darft.data[key] = toFixed(darft.data[key])
            })
            darft.data['_update'] = Date.now()
            darft.data['证件号码'] = values.identity
        })

        onNewRecordSave({
            type: '正常工资薪金所得填写',
            operateObject: [newValues],
        }).then(() => {
            message.success('保存成功！')
            setVisible(false)
        })
    }

    return (
        <div className="tax-panel">
            <nav className="breadcrumb">
                <Link to={match.url.replace('/综合所得申报/正常工资薪金所得', '')} className="breadcrumb-item">
                    <div className="home-icon" />
                    首页
                </Link>
                &gt;&gt;
                <Link to={match.url.replace('/正常工资薪金所得', '')} className="breadcrumb-item">
                    综合所得申报
                </Link>
                &gt;&gt;
                <span className="breadcrumb-item current">正常工资薪金所得</span>
            </nav>
            <article>
                <div className="summary">
                    <div className="summary-info">
                        <div className="line" />
                        <span className="title">
                            {dateStringArray[0]}年{dateStringArray[1]}月 综合所得预扣预缴表
                        </span>
                        申报总人数：
                        <span data-unit="人">{questionAnswer?.content?.length || 0}</span>
                        收入总额：
                        <span data-unit="元">{sum('data.本期收入', questionAnswer?.content)}</span> 免税收入合计：
                        <span data-unit="元">{sum('data.本期免税收入', questionAnswer?.content)}</span>
                    </div>
                    <div className="summary-btn-group">
                        <div>
                            <Button onClick={() => history.goBack()}>返回</Button>
                            <Button type="primary" onClick={openModal}>
                                添加
                            </Button>
                            <Button type="primary" className="no-answer">
                                导入
                            </Button>
                            <Button type="primary" className="no-answer">
                                预填专项附加扣除
                            </Button>
                        </div>
                        <div>
                            <Button className="no-answer" disabled>
                                导出
                            </Button>
                            <Button className="no-answer">展开查询条件</Button>
                            <Button className="no-answer">更多操作</Button>
                        </div>
                    </div>
                </div>
                <Tabs activeKey="境内人员">
                    <Tabs.TabPane tab="境内人员" key="境内人员">
                        <Table
                            scroll={{ x: 'max-content' }}
                            tableLayout="fixed"
                            bordered
                            columns={columns}
                            rowKey="identity"
                            pagination={{
                                defaultPageSize: 10,
                                position: ['bottomCenter'],
                            }}
                            dataSource={list}
                            rowSelection={{
                                type: 'checkbox',
                            }}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="境外人员" key="境外人员" />
                </Tabs>
            </article>
            <Modal
                visible={visible}
                className="simulation-geshui-modal"
                width={900}
                destroyOnClose
                maskClosable={false}
                style={{ top: 110, bottom: 110 }}
                getContainer=".layout-pane-primary"
                bodyStyle={{ height: 'calc(100vh - 331px)', overflowY: 'auto' }}
                title="正常工资薪金所得 新增"
                footer={
                    <>
                        <Button onClick={closeModal}>取消</Button>
                        <Button type="primary" style={{ marginLeft: 20 }} onClick={submitForm}>
                            保存
                        </Button>
                    </>
                }
                onCancel={closeModal}
            >
                <div className="geshui-modal-form">
                    <Form initialValues={defaultFormValue} scrollToFirstError onFinish={onFormFinish} form={form}>
                        <FormGroup title="基本信息">
                            <Form.Item label="工号" name={['data', '工号']}>
                                <Select placeholder="请选择" options={options.jobNos} onChange={personChange} />
                            </Form.Item>
                            <Form.Item label="姓名" name={['data', '姓名']}>
                                <Select placeholder="请选择" options={options.names} onChange={personChange} />
                            </Form.Item>
                            <Form.Item label="证件类型" name={['data', '证件类型']}>
                                <Select disabled options={[{ value: '居民身份证' }]} />
                            </Form.Item>
                            <Form.Item label="证件号码" required={false} name={['identity']}>
                                <Select placeholder="请选择" options={options.ids} onChange={personChange} />
                            </Form.Item>
                            <Form.Item label="所得期间起" name={['data', '所得期间起']}>
                                <StringDateWrap
                                    placeholder="请选择"
                                    disabled={true}
                                    allowClear={false}
                                    component={<DatePicker />}
                                />
                            </Form.Item>
                            <Form.Item label="所得期间止" name={['data', '所得期间止']}>
                                <StringDateWrap
                                    placeholder="请选择"
                                    disabled={true}
                                    allowClear={false}
                                    component={<DatePicker />}
                                />
                            </Form.Item>
                        </FormGroup>
                        <FormGroup title="本期收入及免税收入">
                            <Form.Item label="适用公式" name={['data', '适用公式']} style={{ width: '100%' }}>
                                <Select placeholder="请选择" disabled style={{ width: 322 }} />
                            </Form.Item>
                            <Form.Item style={{ width: 0 }} />
                            <Form.Item label="本期收入" name={['data', '本期收入']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="本期免税收入" name={['data', '本期免税收入']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                        </FormGroup>
                        <FormGroup title="本期专项扣除">
                            <Form.Item label="基本养老保险费" name={['data', '基本养老保险费']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="基本医疗保险费" name={['data', '基本医疗保险费']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="失业保险费" name={['data', '失业保险费']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="住房公积金" name={['data', '住房公积金']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Total
                                dependencies={[
                                    ['data', '基本养老保险费'],
                                    ['data', '基本医疗保险费'],
                                    ['data', '失业保险费'],
                                    ['data', '住房公积金'],
                                ]}
                            />
                        </FormGroup>
                        <FormGroup title="累计专项附加扣除">
                            <Form.Item label="子女教育" name={['data', '子女教育']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="住房贷款利息" name={['data', '住房贷款利息']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="住房租金" name={['data', '住房租金']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="赡养老人" name={['data', '赡养老人']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="继续教育" name={['data', '继续教育']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Total
                                dependencies={[
                                    ['data', '子女教育'],
                                    ['data', '住房贷款利息'],
                                    ['data', '住房租金'],
                                    ['data', '赡养老人'],
                                    ['data', '继续教育'],
                                ]}
                            />
                        </FormGroup>
                        <FormGroup title="本期其他扣除">
                            <Form.Item label="企业（职业）年金" name={['data', '企业（职业）年金']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="商业健康保险" name={['data', '商业健康保险']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="税延养老保险" name={['data', '税延养老保险']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} disabled />
                            </Form.Item>
                            <Form.Item label="其他" name={['data', '其他']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Total
                                dependencies={[
                                    ['data', '企业（职业）年金'],
                                    ['data', '商业健康保险'],
                                    ['data', '税延养老保险'],
                                    ['data', '其他'],
                                ]}
                            />
                        </FormGroup>
                        <FormGroup title="本期其他">
                            <Form.Item label="准予扣除的捐赠额" name={['data', '准予扣除的捐赠额']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="减免税额" name={['data', '减免税额']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="减除费用标准" name={['data', '减除费用标准']}>
                                <InputNumber placeholder="请输入" stringMode disabled precision={2} />
                            </Form.Item>
                            <Form.Item label="已缴税额" name={['data', '已缴税额']}>
                                <InputNumber placeholder="请输入" stringMode disabled precision={2} />
                            </Form.Item>
                        </FormGroup>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}
