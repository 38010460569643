//cSpell:word Feng
import produce from 'immer'
import { Button, Modal, Form, Row, Col, Select } from 'antd'
import img from '../../../../../../assets/提示.png'

import Confirm from '../../components/Confirm'
import './QiFengModal.styl'

export default (resData, form, onNewRecordSave, data, selectChecked, history, prevUrl, setSelects) => {
    const onFinish = (values) => {
        const resultOnOk = () => {
            Modal.destroyAll()
            history.push(prevUrl + '/职工变更业务查询')
            selectChecked('业务查询')
        }
        const modal = Confirm({
            className: 'confirm-submit',
            title: '确认',
            width: 480,
            closable: true,
            centered: true,
            okText: '确认',
            content: '提交正式受理，请仔细核对所填信息，您是否确认提交？',
            onOk: () => {
                const operateObject = produce(resData, (draft) => {
                    draft.forEach((item, index) => {
                        item.data['停缴原因'] = values.data.停缴理由
                        item.data['操作类型'] = '待销户停缴'
                    })
                })
                onNewRecordSave({
                    type: '待销户停缴',
                    operateObject,
                }).then(form.resetFields)
                Modal.destroyAll()
                setSelects([])
                Confirm({
                    className: 'confirm-result',
                    title: '提示',
                    width: 480,
                    content: (
                        <div>
                            <p>住房公积金待销户停缴业务提交成功！</p>
                            <p>操作流水号：XXX</p>
                            <p style={{ fontWeight: 'bold' }}>是否去变更管理页面查看结果？</p>
                            <div className="confirm-notice-btn">
                                <Button onClick={() => Modal.destroyAll()}>取消</Button>
                                <Button>申请表</Button>
                                <Button onClick={resultOnOk}>确认</Button>
                            </div>
                        </div>
                    ),
                    onCancel: () => {
                        Modal.destroyAll()
                    },
                })
            },
            onCancel: () => {
                modal.destroy()
            },
        })
    }
    const initialValues = {
        data: { 停缴理由: '离退休' },
    }
    return Confirm({
        className: 'confirm-QiFeng-status',
        width: 720,
        title: '待销户停缴确认页面',
        content: (
            <div className="confirm-status">
                <p className="confirm-status-tips">
                    <img src={img} className="icon-img" />
                    <span className="tips-content">
                        目前仅对符合法定退休年龄的职工开放“待销户停缴”网上业务， 非该条件职工请去区公积金业务网点处理。
                    </span>
                </p>
                <Form
                    form={form}
                    initialValues={initialValues}
                    className="confirm-status-form"
                    onFinish={onFinish}
                    labelCol={{ flex: '200px' }}
                >
                    <Row gutter={24}>
                        <Col span={20}>
                            <Form.Item label="停缴理由" name={['data', '停缴理由']}>
                                <Select disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} className="confirm-notice-btn">
                        <Col>
                            <Button onClick={() => Modal.destroyAll()}>关闭</Button>
                            <Button htmlType="submit">提交</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        ),
    })
}
