import sum from 'lodash/sum'
import isArray from 'lodash/isArray'

export const types = [
    { name: '单选题', value: 'MultipleChoice' },
    { name: '多选题', value: 'MultipleSelection' },
    { name: '判断题', value: 'TrueOrFalse' },
    { name: '排序题', value: 'Sorting' },
    { name: '简答题', value: 'Text' },
    { name: '情景分析题', value: 'Analyze' },
    { name: '实操题', value: 'Operate' },
]

export const typeNames = {
    MultipleChoice: '单选题',
    MultipleSelection: '多选题',
    TrueOrFalse: '判断题',
    Sorting: '排序题',
    Text: '简答题',
    Analyze: '情景分析题',
    Operate: '实操题',
}

export const chNos = '一二三四五六七八九'

export const answerNos = 'ABCDEFGHIJKL'

export const getCompletedStatus = (question) => {
    // 计算基础题目类型的完成度
    const calculateCompletedStatus = (baseQuestion) => {
        const type = baseQuestion.type
        const options = baseQuestion.options || []
        const answer = baseQuestion.userAnswer?.questionAnswer || baseQuestion.userAnswer || {}
        switch (type) {
            case 'MultipleChoice':
                return answer.options?.length > 0
            case 'MultipleSelection':
                return answer.options?.length > 1
            case 'Sorting':
                return answer.options?.length === options.length
            case 'TrueOrFalse':
                return answer.options?.length > 0
            case 'Text':
                return answer.content?.length > 0
            case 'Operate':
                return answer.operationRecord?.length > 0
            default:
                return false
        }
    }

    if (!question.baseQuestions) return calculateCompletedStatus(question)
    return question.baseQuestions.every(calculateCompletedStatus)
}

export const getAnswer = (answer = {}) => {
    const type = answer.type
    if (type === 'MultipleChoice' || type === 'MultipleSelection' || type === 'Sorting') {
        return answer.options?.join('、')
    } else if (type === 'Text') {
        return answer.content
    } else if (type === 'TrueOrFalse') {
        return { A: '正确', B: '错误' }[answer.options?.[0]]
    }
    return '无'
}

const getOperateQuestionScore = (type, userAnswer, questionAnswer, questionScore) => {
    const operateQuestionScoreFunc = OperateQuestionScoreTool[type] ?? console.log
    const score = operateQuestionScoreFunc(userAnswer, questionAnswer)
    console.groupEnd()
    return score
}

/**
 *
 * @param {*} question
 * @returns { { correct: boolean, score: number } }
 */
export const getQuestionResult = (question) => {
    const type = question.type
    if (type === 'Operate') {
        // 实操题计算每道小题的分数之和
        const subScores = question.baseQuestions?.map((child) => {
            return getOperateQuestionScore(child.name, child.userAnswer?.questionAnswer, child.answer, child.score)
        })
        return { score: subScores.reduce((p, c) => p + c, 0) }
    }
    const userAnswer = question.userAnswer?.questionAnswer || {}
    const questionAnswer = question.answer || {}
    if (type === 'MultipleChoice' || type === 'MultipleSelection' || type === 'Sorting') {
        const correct = userAnswer.options?.join('') === questionAnswer.options?.join('')
        return { correct, score: correct ? question.score : 0 }
    } else if (type === 'TrueOrFalse') {
        const correct = userAnswer.options?.[0] === questionAnswer.options?.[0]
        return { correct, score: correct ? question.score : 0 }
    }
    return {}
}

function toFloat(value) {
    const float = Number.parseFloat(value)
    if (Number.isNaN(float)) return 0
    return float
}
function toBoolean(value) {
    if (value === null || value === undefined) {
        return false
    }
    if (typeof value === 'string' && value.trim().length === 0) {
        return false
    }
    if (isArray(value) && value.length === 0) {
        return false
    }
    return value
}
function inValuesComparator(a, b) {
    if (isArray(a)) {
        return a.some((item) => valueComparator(b, item))
    }
    return valueComparator(a, b)
}
function valueComparator(a, b) {
    // eslint-disable-next-line eqeqeq
    return toBoolean(a) == toBoolean(b)
}
function arrayComparator(a, b) {
    if (!isArray(a) || !isArray(b)) {
        return valueComparator(a, b)
    }
    return a.join(',') === b.join(',')
}
function demerit(questionScore, unit, unitScore) {
    if (unit > 0) {
        const score = questionScore - unit * unitScore
        return Math.max(score, 0)
    }

    return questionScore
}

function logResult(type, key, answer, userAnswer, correct) {
    const isDevelop = location.host.indexOf('localhost') !== -1
    if (!isDevelop) return
    const logFunc = correct ? console.log : console.error
    logFunc('type:', type, ' key:', key, ' answer:', answer, ' userAnswer:', userAnswer, ' correct:', correct)
}

function answerComparator(studentAnswer, questionAnswer, socre, comparator = {}) {
    const stuScores = questionAnswer.content.map((questionObject) => {
        const studentObject = studentAnswer.content?.find?.((item) => item.identity === questionObject.identity)

        if (!studentObject) {
            return 0
        }

        const res = Object.keys(questionObject.data).every((key) => {
            const keyComparator = comparator[key] ?? valueComparator
            const eq = keyComparator(questionObject.data[key], studentObject.data?.[key])
            logResult('通用', key, questionObject.data[key], studentObject.data?.[key], eq)
            return eq
        })
        return res ? socre : 0
    })
    return sum(stuScores)
}
function 招工登记(studentAnswer, questionAnswer) {
    const questionSocre = answerComparator(studentAnswer, questionAnswer, 5, {
        本次劳动合同终止日期: inValuesComparator,
        职业工种: inValuesComparator,
        '联系地址（省市区）': arrayComparator,
    })

    return questionSocre
}
function 社保转入(studentAnswer, questionAnswer) {
    const questionSocre = answerComparator(studentAnswer, questionAnswer, 5)

    return questionSocre
}
function 社保缴费基数调整(studentAnswer, questionAnswer) {
    const questionSocre = answerComparator(studentAnswer, questionAnswer, 5)

    return questionSocre
}
function 退工登记(studentAnswer, questionAnswer) {
    let questionSocre = answerComparator(studentAnswer, questionAnswer, 5)

    questionSocre = demerit(questionSocre, (studentAnswer.operationRecord?.length ?? 0) - 1, 2.5)

    return questionSocre
}
function 社保转出(studentAnswer, questionAnswer) {
    const questionSocre = answerComparator(studentAnswer, questionAnswer, 5)

    return questionSocre
}
function 人员信息登记(studentAnswer, questionAnswer) {
    let questionSocre = answerComparator(studentAnswer, questionAnswer, 2, {
        职务: inValuesComparator,
        学历: inValuesComparator,
        任职受雇从业日期: inValuesComparator,
        入职年度就业情况: inValuesComparator,
        经常居住地: arrayComparator,
        联系地址: arrayComparator,
        户籍所在地: arrayComparator,
    })

    const count =
        studentAnswer.content?.filter(
            // eslint-disable-next-line eqeqeq
            (item) => item?.data['报送状态'] === '报送成功' && item.data['身份验证'] == '验证通过'
        ).length ?? 0
    questionSocre = demerit(questionSocre, count - 4, 2)

    return questionSocre
}
function 人员信息维护(studentAnswer, questionAnswer) {
    let questionSocre = answerComparator(studentAnswer, questionAnswer, 3, {
        任职受雇从业日期: inValuesComparator,
        学历: inValuesComparator,
        离职日期: inValuesComparator,
        经常居住地: arrayComparator,
        联系地址: arrayComparator,
        户籍所在地: arrayComparator,
    })

    const count =
        studentAnswer.content?.filter(
            (item) => item?.data['报送状态'] === '报送成功' && item.data['身份验证'] === '验证通过'
        ).length ?? 0
    questionSocre = demerit(questionSocre, count - 4, 2)

    return questionSocre
}
function 正常工资薪金所得(studentAnswer, questionAnswer) {
    const studentScores = questionAnswer.content.map((it) => {
        const studentOperateObject = studentAnswer.content?.find((item) => item.identity === it.identity)
        if (!studentOperateObject) return 0

        if (it.identity === '410182199908226009') {
            const 住房租金 = toFloat(studentOperateObject.data?.['住房租金'])
            const 住房贷款利息 = toFloat(studentOperateObject.data?.['住房贷款利息'])

            //都不选
            if (住房租金 + 住房贷款利息 === 0) {
                return 0
            }
            if (住房租金 > 0 && 住房贷款利息 > 0) {
                return 0
            }
        }

        const res = Object.keys(it.data).every((key) => {
            const keyComparator = ['住房租金', '住房贷款利息'].includes(key) ? inValuesComparator : valueComparator

            const eq = keyComparator(it.data[key], studentOperateObject.data?.[key])
            logResult('正常工资薪金所得', key, it.data[key], studentOperateObject.data?.[key], eq)
            return eq
        })

        return res ? 4 : 0
    })
    let questionSocre = sum(studentScores)

    questionSocre = demerit(questionSocre, (studentAnswer.content?.length ?? 0) - 5, 2.5)

    return questionSocre
}
function 增员办理(studentAnswer, questionAnswer) {
    const studentScores = questionAnswer.content.map((it) => {
        const studentOperateObject = studentAnswer.content?.find((item) => item.identity === it.identity)
        if (!studentOperateObject) return 0
        const newData = questionAnswer.content.find(
            (item) =>
                item.identity === studentOperateObject.identity &&
                item.data['操作类型'] === studentOperateObject.data?.['操作类型']
        )
        if (!newData || !newData.data) {
            return 0
        }

        const res = Object.keys(newData.data).every((key) => {
            const eq = valueComparator(newData.data[key], studentOperateObject.data?.[key])
            logResult('增员办理', key, newData.data[key], studentOperateObject.data?.[key], eq)
            return eq
        })

        if (!res) return 0

        if (['310109199701034321', '310119199401034312'].includes(it.identity)) {
            return 5
        }
        if (it.identity === '310119199501034312') {
            if (it.data['操作类型'] === '转入') return 3
            if (it.data['操作类型'] === '启封') return 2
        }
        return 0
    })
    let questionSocre = sum(studentScores)

    questionSocre = demerit(questionSocre, (studentAnswer.operationRecord?.length ?? 0) - 4, 2.5)

    return questionSocre
}
function 批量开户(studentAnswer, questionAnswer) {
    //只拿操作记录中第一次出现的操作对象做比较
    const studentObject = studentAnswer.operationRecord?.flatMap?.((it) => it.operateObject) ?? []

    const studentScores = questionAnswer.content.map((it) => {
        const studentOperateObject = studentObject.find((item) => item.identity === it.identity)
        if (!studentOperateObject) return 0

        const res = Object.keys(it.data).every((key) => {
            const eq = valueComparator(it.data[key], studentOperateObject.data?.[key])
            logResult('批量开户', key, it.data[key], studentOperateObject.data?.[key], eq)
            return eq
        })

        return res ? 1 : 0
    })
    let questionSocre = sum(studentScores)

    questionSocre = demerit(questionSocre, studentObject.length - 5, 2.5)

    return questionSocre
}
function 减员办理(studentAnswer, questionAnswer) {
    let questionSocre = answerComparator(studentAnswer, questionAnswer, 5)

    questionSocre = demerit(questionSocre, (studentAnswer.operationRecord?.length ?? 0) - 4, 2.5)

    return questionSocre
}

const OperateQuestionScoreTool = {
    招工登记,
    社保转入,
    社保缴费基数调整,
    退工登记,
    社保转出,
    人员信息登记,
    人员信息维护,
    正常工资薪金所得,
    增员办理,
    批量开户,
    减员办理,
}
