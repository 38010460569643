import React from 'react'
import { Modal } from 'antd'

import ModalForm from './ModalForm'
import './Modal.styl'

const Modals = ({ isModalVisible, setIsModalVisible, getData }) => {
    const handleCancel = () => {
        setIsModalVisible({ visible: false })
    }
    return (
        <Modal
            title={!isModalVisible.record ? '添加考生信息' : '编辑考生信息'}
            className="create-detail-modal"
            visible={isModalVisible.visible}
            centered
            footer={null}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <ModalForm getData={getData} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />
        </Modal>
    )
}

export default Modals
