import React, { useState } from 'react'
import { Button } from 'antd'
import ExcelUpload from '../../../../../components/upload/ExcelUpload'
import { postCandidateByExcel, postCandidateByExcelScore } from '../../../../../services/ExamCandidateRequest'
import excelHref from '../../../../../resources/考生信息批量导入模版.xlsx'
import Modals from './Modal'

const Header = ({ id, getData }) => {
    const [isModalVisible, setIsModalVisible] = useState({ visible: false })
    const [visible, setVisible] = useState({ examinee: false, score: false })
    const showModal = () => {
        setIsModalVisible({ visible: true })
    }
    return (
        <div className="exam-student-header" style={{ width: '100%', height: '35px' }}>
            <div className="box" style={{ float: 'right' }}>
                <Button onClick={showModal} style={{ marginRight: '15px', width: '130px' }}>
                    添加考生
                </Button>
                <Button
                    type="primary"
                    style={{ marginRight: '15px', width: '130px' }}
                    onClick={() => setVisible({ examinee: true })}
                >
                    导入考生
                </Button>
                <Button
                    type="primary"
                    style={{ marginRight: '15px', width: '130px' }}
                    onClick={() => setVisible({ score: true })}
                >
                    导入主观题评分
                </Button>
                <ExcelUpload
                    title="批量导入考生"
                    visible={visible.examinee}
                    setVisible={setVisible}
                    titleDownload={{ name: '考生信息-批量导入模板.xlsx', href: excelHref }}
                    confirmFunc={postCandidateByExcel}
                    CustomView={
                        <div>
                            <p style={{ color: 'red', fontSize: '13px' }}>
                                请从业务平台-考试管理-排考管理中下载《批量导出考场编排明细》
                            </p>
                            <p style={{ fontSize: '12px' }}>模拟考试可 下载导入模板 ，填写后上传导入</p>
                        </div>
                    }
                    onComplete={getData}
                    extra={{ id, type: 'candidate' }}
                />
                <ExcelUpload
                    title="批量导入主观题评分"
                    visible={visible.score}
                    setVisible={setVisible}
                    confirmFunc={postCandidateByExcelScore}
                    CustomView={
                        <div>
                            <p style={{ color: 'red', fontSize: '12px' }}>
                                请先导出考生主观题答案，在 Excel 中完成评分后上传文件
                            </p>
                        </div>
                    }
                    extra={{ id, type: 'questionScore' }}
                    onComplete={getData}
                />
            </div>
            <Modals isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} getData={getData} />
        </div>
    )
}

export default Header
