import React, { useState } from 'react'
import { Image } from 'antd'
import './ImageOption.styl'

export default ({ src }) => {
    const [visible, setVisible] = useState(false)
    const onPreview = (e) => {
        e.preventDefault()
        setVisible(true)
    }
    const onVisibleChange = (nextVisible) => {
        // 仅让组件可控制关闭
        if (!nextVisible) setVisible(false)
    }
    return (
        <div className="exam-image-container">
            <div className="image-wrap">
                <Image
                    src={src}
                    width="100%"
                    height="100%"
                    preview={{
                        visible,
                        onVisibleChange,
                        maskClassName: 'transparent-mask',
                        mask: (
                            <div className="image-preview" onClick={onPreview}>
                                <i className="iconfont icon-enlarge" />
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    )
}
