import React, { useState, useEffect } from 'react'
import { Table, Popover } from 'antd'
import { isString } from 'lodash'

import RecordTable from './RecordTable'
import RecordItem from '../../../../ks/exam/simulation/task&records/RecordItem'
import Button from '../../../../../components/button'
import { formatDate } from '../../../../../common/utils'
import './Operate.styl'

const Operate = ({ question, index, data, getData, inScored }) => {
    const [recordDetail, setRecordDetail] = useState(null)
    const [scores, setScores] = useState(0)
    useEffect(() => {
        let paperCount = 0
        question.baseQuestions.forEach((item) => {
            paperCount += item.totalScore
        })
        setScores(paperCount)
    }, [question])
    const columns = [
        {
            title: '序号',
            key: '序号',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: '操作时间',
            key: '操作时间',
            render: (record) => <span>{record.update ? formatDate(record.update, true, '-') : '-'}</span>,
        },
        {
            title: '操作类型',
            key: '操作类型',
            render: (record) => <span>{record.type}</span>,
        },
        {
            title: '操作对象',
            key: '操作对象',
            render: (record) => {
                const item = record.operateObject.map((a) => `${a.data?.姓名}（${a.identity}）`)
                if (isString(item)) return <div>{item}</div>
                if (!Array.isArray(item)) return '-'
                return (
                    <div className="operate-targets">
                        {item?.slice(0, 2)?.map((it) => (
                            <div key={it} style={{ whiteSpace: 'pre-wrap' }}>
                                {it}
                            </div>
                        ))}
                        {item?.length > 2 && (
                            <Popover
                                placement="topLeft"
                                content={
                                    <>
                                        {item?.map((it) => (
                                            <div key={it} style={{ lineHeight: '26px' }}>
                                                {it}
                                            </div>
                                        ))}
                                    </>
                                }
                                title="所有操作对象"
                            >
                                <div className="show-more">...</div>
                            </Popover>
                        )}
                    </div>
                )
            },
        },
        {
            title: '详细信息',
            key: 'originData',
            render: (record) => {
                const data = {
                    operateObject: record.operateObject || [],
                    操作时间: record.update ? formatDate(record.update, true, '-') : '-',
                    操作类型: record.type,
                    操作对象: record.operateObject.map((oo) => `${oo.data?.姓名}（${oo.identity}）`),
                }
                return (
                    <Button text onClick={() => setRecordDetail(data)}>
                        查看
                    </Button>
                )
            },
        },
        {
            title: '',
            key: 'empty',
        },
    ]
    return (
        <div className="operate-box">
            <div className="operate-name">
                {index}（共{question.baseQuestions.length}题，满分
                {String(scores).indexOf('.') + 1 > 0 ? scores?.toFixed(1) : scores}分）
            </div>
            {question.baseQuestions.map((item, index) => (
                <div className="operate-content" key={index}>
                    <div className="content-score">
                        （{index + 1}）学生答案（得分：
                        {!inScored
                            ? '-'
                            : item.score
                            ? String(item.score).indexOf('.') + 1 > 0
                                ? item.score?.toFixed(1)
                                : item.score
                            : '0'}
                        ）
                    </div>
                    {!item.questionAnswer?.operationRecord ? (
                        <div className="none-list">暂无数据</div>
                    ) : !recordDetail ? (
                        <Table
                            columns={columns}
                            dataSource={item?.questionAnswer?.operationRecord || []}
                            pagination={{ position: ['bottomCenter'] }}
                            rowKey="update"
                            style={{ width: '100%', marginTop: '20px' }}
                            className="resources-table"
                        />
                    ) : (
                        <RecordItem data={recordDetail} setRecordDetail={setRecordDetail} />
                    )}
                    <div className="resources-score">参考答案（分值：{item.totalScore}）</div>
                    <RecordTable data={item.answer.content} name={item.name} />
                </div>
            ))}
        </div>
    )
}

export default Operate
