import React, { useState } from 'react'
import produce from 'immer'
import { Input } from 'antd'

import { typeNames } from '../configs'
import Answer from '../components/Answer'

import './common.styl'

const Text = ({ stepData, editAnswer, onChange, preview, answerVisible }) => {
    const [customAnswer, setCustomAnswer] = useState(null)

    const answer = editAnswer || customAnswer || stepData?.userAnswer || {}
    const onCustomChange = (e) => {
        const value = e.target.value
        const newAnswer = produce(answer, (draft) => {
            draft.questionAnswer = {
                content: value,
                type: stepData.type,
            }
        })
        if (onChange) {
            onChange(newAnswer)
        } else {
            setCustomAnswer(newAnswer)
        }
    }

    return (
        <div className={'exam-question question-text' + (!stepData.id ? ' child-question' : '')}>
            <div className="question-header">
                <div className="question-no">{(stepData.index || 0) + 1}</div>
                <div className="question-title">
                    {(preview || !stepData.id) && <span className="question-type">【{typeNames[stepData.type]}】</span>}
                    {stepData.content}
                    <span className="question-score">{`${stepData.score}分`}</span>
                </div>
            </div>
            {stepData.image && (
                <div className="question-image">
                    <img src={stepData.image} />
                </div>
            )}
            <div className="question-answer">
                <Input.TextArea
                    className="question-textarea"
                    defaultValue={answer.questionAnswer?.content || ''}
                    showCount
                    maxLength={800}
                    onChange={onCustomChange}
                    onCopy={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                    rows={6}
                />
            </div>
            <Answer visible={answerVisible} answer={stepData.answer} explanation={stepData.explanation} />
        </div>
    )
}

export default Text
