import React from 'react'

import Type from '../formItem/Type'
import InfoGroup from '../formItem/InfoGroup'
import Tags from '../formItem/Tags'
import Key from '../formItem/Key'
import Reference from '../formItem/Reference'

export const Operate = () => {
    return (
        <>
            <Key />
            <Reference />
        </>
    )
}

export default () => (
    <>
        <Type type="Operate" />
        <InfoGroup type="Operate" />
        <Tags />
        <Operate />
    </>
)
