import React, { useMemo } from 'react'
import produce from 'immer'
import { Table, Popover } from 'antd'
import { isString } from 'lodash'
import Button from '../../../../../components/button'
import { formatDate } from '../../../../../common/utils'
import './RecordList.styl'

const RecordList = ({ data, setRecordDetail }) => {
    const list = useMemo(() => {
        const newData = produce(data, (draft) => {
            try {
                draft.editAnswer.questionAnswer.operationRecord = draft.editAnswer.questionAnswer.operationRecord || []
            } catch (error) {}
        })
        return newData.editAnswer?.questionAnswer?.operationRecord || []
    }, [data])

    if (list.length === 0) return <div className="simulation-record-list">暂无记录</div>
    const columns = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: '122px',
        },
        {
            title: '操作时间',
            dataIndex: '操作时间',
            key: '操作时间',
            width: '200px',
        },
        {
            title: '操作类型',
            dataIndex: '操作类型',
            key: '操作类型',
            width: '190px',
        },
        {
            title: '操作对象',
            dataIndex: '操作对象',
            key: '操作对象',
            width: '274px',
            render: (item) => {
                if (isString(item)) return <div>{item}</div>
                if (!Array.isArray(item)) return '-'
                return (
                    <div className="operate-targets">
                        {item?.slice(0, 2)?.map((it) => (
                            <div key={it} style={{ whiteSpace: 'pre-wrap' }}>
                                {it}
                            </div>
                        ))}
                        {item?.length > 2 && (
                            <Popover
                                placement="topLeft"
                                content={
                                    <>
                                        {item?.map((it) => (
                                            <div key={it} style={{ lineHeight: '26px' }}>
                                                {it}
                                            </div>
                                        ))}
                                    </>
                                }
                                title="所有操作对象"
                            >
                                <div className="show-more">...</div>
                            </Popover>
                        )}
                    </div>
                )
            },
        },
        {
            title: '详细信息',
            width: '140px',
            render: (item) => (
                <Button text onClick={() => setRecordDetail(item)}>
                    查看
                </Button>
            ),
        },
        {
            title: '',
            key: 'empty',
        },
    ]

    const dataSource = list.map((item, index) => {
        const operateObject = item.operateObject || []
        return {
            index: index + 1,
            key: item.update + index,
            operateObject,
            操作时间: item.update ? formatDate(item.update, true, '-') : '-',
            操作类型: item.type,
            操作对象: operateObject.map((oo) => `${oo.data?.姓名}（${oo.identity}）`),
        }
    })
    return (
        <div className="simulation-record-list">
            <Table columns={columns} dataSource={dataSource} pagination={{ position: ['bottomCenter'] }} />
        </div>
    )
}

export default RecordList
