import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import message from '../../../components/message'
import { login } from '../../../services/UserRequest'
import './index.styl'

const Login = () => {
    const history = useHistory()
    const onFinish = (values) => {
        const formData = new FormData()
        formData.append('username', values.username)
        formData.append('password', values.password)
        login(formData)
            .then(() => {
                const params = new URLSearchParams(history.location.search)
                history.push(params.get('back') || '/kw/exam')
            })
            .catch((err) => {
                message.error(err.response.data.message)
            })
    }
    return (
        <div className="login-page">
            <div className="login-view">
                <div className="view-title">考务系统</div>
                <Form name="normal_login" className="login-form" onFinish={onFinish} requiredMark={false} colon={false}>
                    <Form.Item
                        label="账号"
                        name="username"
                        validateTrigger="onBlur"
                        rules={[{ required: true, message: '请输入账号!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="密码" name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item className="login-item">
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            登录
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <div className="login-tip">可联系开发人员为您开通账号</div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Login
