import React, { useState, useEffect, useCallback } from 'react'
import { Breadcrumb } from 'antd'
import { Link, useParams } from 'react-router-dom'
import Loading from '../../../../../components/loading'
import { getExam } from '../../../../../services/ExamsRequest'
import { getCandidateScore } from '../../../../../services/ExamCandidateRequest'
import ListItem from './ListItem'

const ExamStudentPaper = () => {
    const { id, paperId } = useParams()
    const [data, setData] = useState(null)
    const getData = useCallback(async () => {
        const exam = await getExam(id)
        const candidateData = await getCandidateScore(id, paperId)
        candidateData.exam = exam
        setData(candidateData)
    }, [id, paperId])
    useEffect(() => {
        getData()
    }, [getData])
    if (!data) return <Loading />
    return (
        <div className="paper-detail-page">
            <div className="page-header">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item key="/kw/exam">
                        <Link to="/kw/exam">考试管理</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key="/kw/exam/detail">
                        <Link to={`/kw/exam/detail/${id}`}>考试详情</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>考试答卷</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="paper-list-item">
                <ListItem data={data} getData={getData} />
            </div>
        </div>
    )
}

export default ExamStudentPaper
