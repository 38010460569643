import React from 'react'
import { Form, Select } from 'antd'
import { uniq } from 'lodash'
import { indexNo } from '../configs'
const Option = Select.Option

export default ({ form, prevPath }) => {
    const onSelectChange = (value) => {
        if (prevPath.length > 0) {
            // 暂时做全局校验，没找到情景分析单独校验小题的方法
            form.validateFields()
        } else {
            form.validateFields([...prevPath, 'options'])
        }
    }
    return (
        <Form.List
            name={[...prevPath, 'options']}
            rules={[
                {
                    validator: (_, options) => {
                        const orders = options.map((o) => o.order)
                        if (uniq(orders).length < orders.length) {
                            return Promise.reject(new Error('排序答案不可重复'))
                        }
                        return Promise.resolve()
                    },
                },
            ]}
        >
            {(fields, {}, { errors }) => (
                <Form.Item label="答案">
                    {fields.map((field) => (
                        <Form.Item key={field.key} {...field} name={[field.name, 'order']} noStyle>
                            <Select style={{ width: '10%', marginRight: '10px' }} onChange={onSelectChange}>
                                {fields.map((f, index) => (
                                    <Option key={index} value={indexNo[index]}>
                                        {indexNo[index]}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ))}
                    <Form.ErrorList errors={errors} />
                </Form.Item>
            )}
        </Form.List>
    )
}
