import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Select, Popover } from 'antd'

import Button from '../../../components/button'
import message from '../../../components/message'
import Layout from './layout'
import { getReleaseExams } from '../../../services/CandidateRequest'
import { login } from '../../../services/UserRequest'
import { getBaseUrl } from '../../../common/urlHelp'

import logo from '../../../assets/踏瑞新logo最终版.png'
import './index.styl'
const Option = Select.Option

const Login = () => {
    const [form] = Form.useForm()
    const history = useHistory()
    const [data, setData] = useState([])
    const [errorData, setErrorData] = useState({})
    const [loading, setLoading] = useState(false)
    const fetchData = useCallback(() => {
        getReleaseExams().then((res) => {
            setData(res)
            setLoading(false)
        })
    }, [])
    useEffect(() => {
        fetchData()
    }, [fetchData])

    const isMock = location.pathname.includes('/mock')

    const onLogin = async (values) => {
        // 校验仅进行到第一个出错项
        const examId = values.examId
        const username = values.username?.trim()
        const password = values.password
        if (!examId) return setErrorData({ examId: true })
        if (!username) return setErrorData({ username: true })
        if (!password) return setErrorData({ password: true })
        setErrorData({})

        const formData = new FormData()
        formData.append('examId', examId)
        formData.append('username', username)
        formData.append('password', password)
        try {
            // 模拟考试登录前先校验考试有效性
            await login(formData, values.examId)
            message.success('登录成功')
            history.replace(`${getBaseUrl()}/${values.examId}/info/base`)
        } catch (error) {
            let msg = error.response?.data?.message || error.data?.message || '操作失败！请刷新页面后重试'
            if (msg === '没有找到考试') msg = '考试不存在，请刷新考试列表'
            message.error(msg)
        }
    }
    const onRefresh = () => {
        setLoading(true)
        setTimeout(() => {
            fetchData()
            form.getFieldsValue(true).examId = null
        }, 600)
    }
    const initialValues = !isMock ? {} : { username: '12345678901234567890', password: '**********' }
    return (
        <div className="login-page">
            <Layout>
                <img src={logo} alt="" className="login-logo" />
                <h2 className="login-title">{isMock ? '考试模拟演练系统' : '人力资源管理职业基础'}</h2>
                <Form
                    form={form}
                    labelAlign="right"
                    requiredMark={false}
                    labelCol={{ span: 6 }}
                    onFinish={onLogin}
                    initialValues={initialValues}
                >
                    <Form.Item
                        label="考试:"
                        validateStatus={errorData.examId ? 'error' : 'success'}
                        help={errorData.examId ? '请选择考试！' : null}
                    >
                        <Form.Item name="examId">
                            <Select
                                placeholder="请选择参与的考试"
                                style={{ width: 368 }}
                                dropdownMatchSelectWidth={false}
                                dropdownClassName="login-dropdown"
                                notFoundContent="当前没有正在进行的考试"
                            >
                                {data?.map((item, index) => {
                                    return (
                                        <Option value={item.id} key={index}>
                                            {item.name}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Popover
                            overlayClassName="top-popover"
                            placement="top"
                            content={<span className="title">刷新</span>}
                        >
                            <i className={'iconfont icon-refresh' + (loading ? ' active' : '')} onClick={onRefresh} />
                        </Popover>
                    </Form.Item>
                    <Form.Item
                        name="username"
                        label="准考证号:"
                        validateStatus={errorData.username ? 'error' : 'success'}
                        help={errorData.username ? '请填写准考证号！' : null}
                    >
                        <Input disabled={isMock} placeholder="请输入准考证号" maxLength={50} style={{ width: 368 }} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        maxLength={10}
                        label="密码:"
                        validateStatus={errorData.password ? 'error' : 'success'}
                        help={errorData.password ? '请填写密码！' : null}
                    >
                        <Input.Password
                            disabled={isMock}
                            placeholder="请输入密码"
                            maxLength={10}
                            style={{ width: 368 }}
                        />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Button primary>登录</Button>
                    </Form.Item>
                    <Form.Item noStyle>
                        <div className="login-bottom login-remind">
                            您可在考试正式开始前 <b>15</b> 分钟内<b>提前登录</b>
                        </div>
                        <div className="login-bottom login-one">
                            登录后，请<b>确认个人信息并阅读《考试须知》《试卷说明》</b>
                        </div>
                    </Form.Item>
                </Form>
            </Layout>
        </div>
    )
}

export default Login
