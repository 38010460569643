//cSpell:word Zhuan
import React, { useState } from 'react'
import produce from 'immer'
import { Form, Checkbox, Space } from 'antd'
import Confirm from '../components/Confirm'
import Input from '../components/Input'
import SearchPanel from '../components/SearchPanel'
import Button from '../components/Button'
import { BaseInfo } from './SheBaoZhuanRu'

const SheBaoZhuanChu = ({ data, onNewRecordSave }) => {
    const [user, setUser] = useState(null)
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()

    const onSearchConfirm = (result) => {
        setUser(null)
        form.resetFields()
        if (!result) return
        const newUser = {
            identity: result.identity,
            data: {
                ...result.data,
                是否勾选承诺: false,
            },
        }
        setUser(newUser)
        form.setFieldsValue(newUser)
    }

    const onResetForm = () => {
        setUser(null)
        form.resetFields()
        searchForm.resetFields()
    }

    const onFinish = (values) => {
        Confirm({
            title: '考试系统提示',
            content: '提交后，不可修改或删除信息。是否提交？',
            onOk: () => {
                const obj = produce(values, (draft) => {})
                onNewRecordSave({
                    type: '社保转出',
                    operateObject: [obj],
                }).then(onResetForm)
            },
        })
    }

    const renderChildren = () => {
        if (!user) return null
        return (
            <Form className="data-form sub-page-s-b-z-c" form={form} onFinish={onFinish} initialValues={user}>
                <BaseInfo />
                <div className="panel extra-info-panel">
                    <div className="panel-title">申报信息</div>
                    <div className="panel-content">
                        <Form.Item name={['data', '缴费方式']} label="缴费方式">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name={['data', '个人序号']} label="个人序号">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name={['data', '月缴费基数']} label="月缴费基数">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name={['data', '缴费状态']} label="缴费状态">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item label="月平均工资性收入" name={['data', '月平均工资性收入']}>
                            <Space>
                                <Form.Item noStyle>
                                    <Input maxLength={10} />
                                </Form.Item>
                                <div className="form-tip">元</div>
                            </Space>
                        </Form.Item>
                        <Form.Item name={['data', '是否勾选承诺']} valuePropName="checked">
                            <Checkbox>单位承诺已经完成退工备案登记。</Checkbox>
                        </Form.Item>
                    </div>
                </div>
                <div className="bottom-action">
                    <Button className="commit-btn" type="primary" htmlType="submit">
                        提交
                    </Button>
                    <Button className="cancel-btn" onClick={onResetForm}>
                        取消
                    </Button>
                </div>
            </Form>
        )
    }
    return (
        <div className="sb-detail-sub-page">
            <SearchPanel title="转出人员" form={searchForm} data={data} onConfirm={onSearchConfirm} />
            {renderChildren()}
        </div>
    )
}

export default SheBaoZhuanChu
