import React, { useState, useEffect } from 'react'
import { Table, Row } from 'antd'
import Button from '../../../../../components/button'
import { operateKeys } from './configs'
import './RecordItem.styl'

export const RecordTable = ({ data }) => {
    const [tableData, setTableData] = useState(null)
    useEffect(() => {
        const newTableData = {}
        const dataSource = []
        const operateObject = data.operateObject || []
        const categories = operateKeys[data.操作类型]
        // 是否包含类别
        const unCategory = categories.length === 1 && !categories[0]?.category
        // 保存类别，用来做单元格合并
        const existCategories = []
        categories.forEach((category) => {
            category.answerKeys.forEach((child) => {
                // 展示的key和获取数据的key不一样
                const shouldMerge = existCategories.indexOf(category.category) !== -1
                const newRow = {
                    key: child,
                    index: dataSource.length + 1,
                    rowSpan: !shouldMerge ? category.answerKeys.length : 0,
                    category: category.category,
                    answerKey: child,
                }
                // 根据操作记录中操作对象的数量动态添加答案
                operateObject.forEach((oo, index) => {
                    const answerKey = `答案${index + 1}`
                    let answer = '-'
                    if (child.indexOf('身份证') !== -1) {
                        answer = operateObject[index].data.身份证号码 || operateObject[index].identity
                    } else if (child === '职工账号') {
                        answer = operateObject[index].data?.['个人账号']
                    } else if (child === '职工转出前需汇缴到的年月') {
                        answer = operateObject[index].data?.['汇缴到的年月']
                    } else if (child === '停缴期限') {
                        answer = operateObject[index].data['停缴期限']
                            ? operateObject[index].data['停缴期限'] + '个月'
                            : '-'
                    } else if (child === '个人手机号码') {
                        answer = operateObject[index].data?.['手机号码'] || '-'
                    } else {
                        answer = operateObject[index].data?.[child]
                        if (answer === true) answer = '是'
                        else if (answer === false) answer = '否'
                        else if (Array.isArray(answer)) answer = answer.join('/')
                        else if (!answer) answer = '-'
                    }
                    newRow[answerKey] = answer
                })
                dataSource.push(newRow)
                // 将已添加的类别暂存，以便下次遇到同类别的数据则合并
                existCategories.push(category.category)
            })
        })

        const columns = [
            {
                title: '序号',
                width: 78,
                dataIndex: 'index',
                key: 'index',
                fixed: 'left',
            },
            ...(unCategory
                ? []
                : [
                      {
                          title: '类别',
                          width: 164,
                          dataIndex: 'category',
                          key: 'category',
                          fixed: 'left',
                          render: (value, row, index) => {
                              return {
                                  children: value,
                                  props: { rowSpan: row.rowSpan },
                              }
                          },
                      },
                  ]),
            {
                title: '项目',
                dataIndex: 'answerKey',
                key: 'answerKey',
                fixed: 'left',
            },
        ]
        // 根据操作记录中操作对象的数量动态添加列数
        operateObject.forEach((oo, index) => {
            const answerKey = `答案${index + 1}`
            columns.push({
                title: answerKey,
                width: 210,
                dataIndex: answerKey,
                key: answerKey,
            })
        })
        newTableData.columns = columns
        newTableData.dataSource = dataSource
        setTableData(newTableData)
    }, [data])

    if (!tableData) return null
    if (tableData.dataSource.length === 0) return '无'
    return (
        <Table
            columns={tableData.columns}
            dataSource={tableData.dataSource}
            bordered
            pagination={false}
            scroll={{ x: 'max-content' }}
        />
    )
}

const RecordItem = ({ data, setRecordDetail }) => {
    return (
        <div className="simulation-record-item">
            <Button text className="back-btn" onClick={() => setRecordDetail(null)}>
                {'<返回列表'}
            </Button>
            <Row>
                <div className="item-label">操作时间：</div>
                <div className="item-value">{data.操作时间}</div>
            </Row>
            <Row>
                <div className="item-label">操作类型：</div>
                <div className="item-value">{data.操作类型}</div>
            </Row>
            <Row>
                <div className="item-label">操作对象：</div>
                <div className="item-value">
                    {data.操作对象.map((obj, index) => (
                        <span key={index} className="operate-obj">
                            {obj}
                        </span>
                    ))}
                </div>
            </Row>
            <div className="item-label">详细信息：</div>
            <RecordTable data={data} />
        </div>
    )
}

export default RecordItem
