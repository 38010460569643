import React from 'react'
import { Select } from 'antd'
import './Select.styl'

const SbSelect = ({ className, ...props }) => (
    <Select
        className={`sb-select ${className || ''}`}
        dropdownMatchSelectWidth={false}
        getPopupContainer={(e) => e.parentNode}
        placeholder={props.disabled ? '' : '请选择'}
        {...props}
    />
)

export const Option = Select.Option

export default SbSelect
