//cSpell:word Feng
import produce from 'immer'
import { Button, Modal, Form, Row, Col, Select } from 'antd'

import Confirm from '../../components/Confirm'
import './QiFengModal.styl'

const Option = Select.Option

export default (resData, form, onNewRecordSave, data, selectChecked, history, prevUrl, setSelects) => {
    const onFinish = (values) => {
        const resultOnOk = () => {
            Modal.destroyAll()
            history.push(prevUrl + '/职工变更业务查询')
            selectChecked('业务查询')
        }
        const modal = Confirm({
            className: 'confirm-submit',
            title: '确认',
            width: 480,
            closable: true,
            centered: true,
            okText: '确认',
            content: '提交正式受理，请仔细核对所填信息，您是否确认提交？',
            onOk: () => {
                const operateObject = produce(resData, (draft) => {
                    draft.forEach((item, index) => {
                        const date = values.data?.停缴期限 || ''
                        item.data['停缴期限'] = date.slice(0, 1)
                        item.data['操作类型'] = '待转出停缴'
                    })
                })
                onNewRecordSave({
                    type: '待转出停缴',
                    operateObject,
                }).then(form.resetFields)
                Modal.destroyAll()
                setSelects([])
                Confirm({
                    className: 'confirm-result',
                    title: '提示',
                    width: 480,
                    content: (
                        <div>
                            <p>住房公积金待销户停缴业务提交成功！</p>
                            <p>操作流水号：XXX</p>
                            <p style={{ fontWeight: 'bold' }}>是否去变更管理页面查看结果？</p>
                            <div className="confirm-notice-btn">
                                <Button onClick={() => Modal.destroyAll()}>取消</Button>
                                <Button>申请表</Button>
                                <Button onClick={resultOnOk}>确认</Button>
                            </div>
                        </div>
                    ),
                })
            },
            onCancel: () => {
                modal.destroy()
            },
        })
    }
    const cancel = () => {
        Modal.destroyAll()
        form.resetFields()
    }
    return Confirm({
        className: 'confirm-QiFeng-status',
        width: 720,
        title: '待转出停缴确认页面',
        content: (
            <div className="confirm-status">
                <Form form={form} className="confirm-status-form" onFinish={onFinish} labelCol={{ flex: '200px' }}>
                    <Row gutter={24}>
                        <Col span={20}>
                            <Form.Item label="选择停缴期限" name={['data', '停缴期限']}>
                                <Select allowClear placeholder="请选择">
                                    <Option value="1个月">1个月</Option>
                                    <Option value="2个月">2个月</Option>
                                    <Option value="3个月">3个月</Option>
                                    <Option value="4个月">4个月</Option>
                                    <Option value="5个月">5个月</Option>
                                    <Option value="6个月">6个月</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} className="confirm-notice-btn">
                        <Col>
                            <Button onClick={cancel}>关闭</Button>
                            <Button htmlType="submit">提交</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        ),
        onCancel: () => {
            Modal.destroyAll()
            form.resetFields()
        },
    })
}
