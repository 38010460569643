//cSpell:word datas
import React, { useState } from 'react'
import { Modal, Upload, Button } from 'antd'
import message from '../message'
import produce from 'immer'

import './ExcelUpload.styl'

const { Dragger } = Upload

const UploadView = ({ extra, confirmFunc, onComplete }) => {
    const [file, setFile] = useState({})
    const onFileChange = (info) => {
        const { status } = info.file
        if (status === 'uploading') {
            setFile({ status: 'uploading' })
        } else if (status === 'done') {
            setFile({ file: info.file, status: 'done' })
            message.success(`${info.file.name} 上传成功`)
        } else if (status === 'error') {
            setFile({})
            message.error(`操作失败，请稍后重试！`)
        }
    }
    if (file.status === 'done') {
        const onConfirm = () => {
            const p = extra?.id ? confirmFunc(extra.id, file.file?.response) : confirmFunc(file.file?.response)
            p.then((result) => {
                setFile((prev) =>
                    produce(prev, (draft) => {
                        draft.status = 'result'
                        draft.result = result
                    })
                )
                onComplete && onComplete()
            }).catch((err) =>
                message.error(err.response.message || err.response.data?.message || '操作失败，请稍后重试！')
            )
        }
        return (
            <div className="uploaded-done">
                <p className="upload-text">已上传文件</p>
                <p className="upload-hint">{file.file?.name}</p>
                <Button onClick={onConfirm}>确认导入</Button>
            </div>
        )
    }

    if (file.status === 'result') {
        const onHandleResult = async () => {
            const { type } = extra
            if (type === 'question') {
                const result = file.result || {}
                if (!result.fail) return message.error('无失败信息！')
                // 有失败信息，生成excel文档
                const Excel = await import('js-export-excel').then((modules) => modules.default)
                let fileName = file.file?.name || '批量导入'
                fileName = fileName.substring(0, fileName.lastIndexOf('.'))
                const options = {}
                options.fileName = `失败信息-${fileName}`
                options.datas = [
                    {
                        sheetData: result.errorRows.map((row) => ({
                            one: row.type,
                            two: row.number,
                            three: row.msg,
                        })),
                        sheetName: 'sheet',
                        sheetHeader: ['题型', '行号', '失败信息'],
                        columnWidths: [10, 10, 10],
                    },
                ]
                new Excel(options).saveExcel()
            } else if (type === 'candidate') {
                const result = file.result || {}
                if (result.failRows.length === 0) return message.error('无失败信息！')
                // 有失败信息，生成excel文档
                const Excel = await import('js-export-excel').then((modules) => modules.default)
                let fileName = file.file?.name || '批量导入'
                fileName = fileName.substring(0, fileName.lastIndexOf('.'))
                const options = {}
                options.fileName = `失败信息-${fileName}`
                options.datas = [
                    {
                        sheetData: result.failRows.map((row) => ({
                            one: row.cellMap.准考证号,
                            two: row.cellMap.错误信息,
                        })),
                        sheetName: 'sheet',
                        sheetHeader: ['准考证号', '失败原因'],
                        columnWidths: [10, 10],
                    },
                ]
                new Excel(options).saveExcel()
            } else if (type === 'questionScore') {
                const result = file.result || {}
                if (result.failRows.length === 0) return message.error('无失败信息！')
                // 有失败信息，生成excel文档
                const Excel = await import('js-export-excel').then((modules) => modules.default)
                let fileName = file.file?.name || '批量导入'
                fileName = fileName.substring(0, fileName.lastIndexOf('.'))
                const options = {}
                options.fileName = `失败信息-${fileName}`
                options.datas = [
                    {
                        sheetData: result.failRows.map((row) => ({
                            one: row.rowIndex,
                            two: row.ticket,
                            three: row.message,
                        })),
                        sheetName: 'sheet',
                        sheetHeader: ['序号', '准考证号', '失败原因'],
                        columnWidths: [10, 10, 10],
                    },
                ]
                new Excel(options).saveExcel()
            } else {
                message.info(`暂时无法获取失败信息-${file.file?.name || '批量导入'}`)
            }
        }

        const successCount = file.result?.success || file.result?.successCount || 0
        const failCount = file.result?.fail || file.result?.failCount || 0

        return (
            <div className="uploaded-result">
                <p className="upload-text">导入完成</p>
                <p className="upload-hint">{`成功数：${successCount} 失败数：${failCount}`}</p>
                <Button onClick={onHandleResult}>导出失败信息</Button>
            </div>
        )
    }

    return (
        <Dragger
            name="file"
            accept=".xls,.xlsx"
            multiple={false}
            action="/api/files/excel"
            showUploadList={false}
            onChange={onFileChange}
        >
            {file?.status === 'uploading' ? (
                <p>上传中...</p>
            ) : (
                <>
                    <p className="upload-text">点击上传Excel</p>
                    <p className="upload-hint">请上传xlsx/xls格式的文件</p>
                </>
            )}
        </Dragger>
    )
}

export default ({ visible, setVisible, extra, confirmFunc, title, titleDownload, CustomView, onComplete }) => (
    <Modal destroyOnClose centered visible={visible} onCancel={() => setVisible(false)} footer={null} width={400}>
        <div className="excel-import-view">
            <div className="view-header">
                <div className="view-title">{title}</div>
                {titleDownload && (
                    <a
                        download={titleDownload.name}
                        target="_blank"
                        href={titleDownload.href}
                        rel="noreferrer"
                        className="excel-link"
                    >
                        下载导入模板
                    </a>
                )}
            </div>
            {CustomView}
            <div className="excel-upload">
                <UploadView confirmFunc={confirmFunc} onComplete={onComplete} extra={extra} />
            </div>
        </div>
    </Modal>
)
