import React from 'react'
import { Modal } from 'antd'
import SetUpForm from './SetUpForm'

const SetupList = ({ settingData, setSettingData, onRefresh }) => {
    const { visible } = settingData
    const handleCancel = () => {
        setSettingData({ visible: false })
    }
    return (
        <Modal
            title="考试设置"
            className="create-exam-modal"
            visible={visible}
            centered
            footer={null}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <SetUpForm settingData={settingData} setSettingData={setSettingData} onRefresh={onRefresh} />
        </Modal>
    )
}

export default SetupList
