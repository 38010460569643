//cSpell:word Zhuan Jiao
import React from 'react'
import { Form, Row, Col, Input, Select, Button } from 'antd'

const PublicForm = () => {
    const initialValues = {
        housing: '住房公积金',
    }
    return (
        <Form className="search-form" initialValues={initialValues}>
            <Row gutter={24} className="form-row">
                <Col span={10}>
                    <Form.Item label="账户类型" name="housing" labelCol={{ flex: '70px' }}>
                        <Select style={{ width: '62.26%' }} />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="部分" labelCol={{ flex: '110px' }}>
                        <Select placeholder="请选择" style={{ width: '62.26%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} className="form-row">
                <Col span={10}>
                    <Form.Item label="职工账号" labelCol={{ flex: '70px' }}>
                        <Input style={{ width: '62.26%' }} />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="工号" labelCol={{ flex: '110px' }}>
                        <Input style={{ width: '62.26%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} className="form-row">
                <Col span={10}>
                    <Form.Item label="姓名[%]" labelCol={{ flex: '70px' }}>
                        <Input style={{ width: '62.26%' }} />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="修正名[%]" labelCol={{ flex: '110px' }}>
                        <Select placeholder="请选择" style={{ width: '62.26%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} className="form-row">
                <Col span={10}>
                    <Form.Item label="身份证号" labelCol={{ flex: '70px' }}>
                        <Input style={{ width: '62.26%' }} />
                    </Form.Item>
                </Col>

                <Col span={10}>
                    <Form.Item label="当年缴存情况" labelCol={{ flex: '110px' }}>
                        <Select placeholder="请选择" style={{ width: '62.26%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} className="form-btn">
                <Col span={24} style={{ textAlign: 'left' }}>
                    <Button>查询</Button>
                    <Button className="submit-btn">收起查询</Button>
                    <span className="form-tagging">注：[%]为模糊搜索</span>
                </Col>
            </Row>
        </Form>
    )
}

export default PublicForm
