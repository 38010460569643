function FormGroup({ title = 'FormGroup', children }) {
    return (
        <section className="form-section">
            <h3 className="form-section-title">
                <div className="line" />
                {title}
            </h3>
            <div>{children}</div>
        </section>
    )
}

export default FormGroup
