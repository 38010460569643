import { useState, useMemo } from 'react'

function useBoolean(initialState = false) {
    const [visible, setVisible] = useState(initialState)
    const funs = useMemo(() => {
        function setTrue() {
            setVisible(true)
        }
        function setFalse() {
            setVisible(false)
        }

        return {
            setTrue,
            setFalse,
            setValue: setVisible,
        }
    }, [])

    return [visible, funs]
}

export default useBoolean
