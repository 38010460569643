import moment from 'moment'

function check() {
    return !!window.__bl
}

/**
 * 初始化
 */
export function initLogger(environment) {
    //console.log('initLogger', environment)
    //详细配置 https://help.aliyun.com/document_detail/58655.htm
    // const __bl = BrowserLogger.singleton({
    //     //与项目强绑定，不可编辑
    //     pid: 'apnr6gkkh0@c32bd9c92b0c189',
    //     //与项目强绑定，不可编辑
    //     appType: 'web',
    //     //与项目强绑定，不可编辑
    //     imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    //     //JS错误诊断可提供用户行为回溯
    //     behavior: true,
    //     //会监听页面的hashchange事件并自动上报PV，适用于SPA应用场景。
    //     enableSPA: true,
    //     //prod 线上环境。 gray 灰度环境。pre 预发环境。daily 测试环境。local 本地环境。
    //     environment: environment || 'local',
    //     //不自定上报api
    //     //disableHook: true,
    // })
    // window.__bl = __bl
}

/**
 * 设置用户id
 */
export function setUid(id) {
    if (id && check()) {
        window.__bl.setConfig({
            uid: id,
        })
    }
}

/**
 * 禁用
 */
export function setDisabled() {
    if (check()) {
        window.__bl.setConfig({
            disabled: true,
        })
    }
}

/**
 * 测速关键字，必须是 s0-s10
 * 测速 默认是当前时间-页面起始时间
 */
export function speed(key = 's0', time) {
    if (check()) {
        if (time) {
            window.__bl.speed(key, time)
        } else {
            window.__bl.speed(key)
        }
    }
}

export function submitPaperEvent(success = true, time = 0, errorMsg) {
    if (check()) {
        window.__bl.event({
            key: 'submitPaper',
            success,
            time,
            c1: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
            c2: errorMsg,
        })
    }
}

export function loginEvent(success = true, time = 0) {
    if (check()) {
        window.__bl.event({
            key: 'loginEvent',
            success,
            time,
            c1: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
        })
    }
}
