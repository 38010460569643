const ExamLoading = () => {
    return (
        <div style={{ height: '100vh', position: 'relative' }}>
            <div
                style={{
                    display: 'block',
                    position: 'absolute',
                    top: ' 50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <div className="spinner-first">
                    <div className="rect1" style={{ marginRight: 5 }} />
                    <div className="rect2" style={{ marginRight: 5 }} />
                    <div className="rect3" style={{ marginRight: 5 }} />
                    <div className="rect4" />
                </div>
                <div className="load-tip-text">正在加载中，请稍后…</div>
            </div>
        </div>
    )
}

export default ExamLoading
