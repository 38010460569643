//cSpell:word Cascader
import React from 'react'
import { Cascader } from 'antd'
import options, { provinceOptions } from '../../../../../common/address'
import './CasCader.styl'

const KsCascader = ({ className, ...props }) => {
    return <Cascader className={`ks-cascader ${className || ''}`} changeOnSelect {...props} placeholder="请选择" />
}

export const AddressCascader = (props) => {
    return <KsCascader className="address-cascader" options={options} {...props} />
}

export const ProvinceCascader = (props) => {
    return <KsCascader className="address-cascader" options={provinceOptions} {...props} />
}

export default KsCascader
