//cSpell:word Liang
import React, { useState } from 'react'
import produce from 'immer'
import { find } from 'lodash'
import { Alert, Form, Row, Col, Input, Select, Button } from 'antd'
import { useHistory, Link } from 'react-router-dom'

import img from '../../../../../../assets/提示.png'
import message from '../../components/Message'
import Confirm from '../../components/Confirm'
import KHConfirm from '../components/PiLiangKaiHuModal'
import './DefaultPage.styl'
import '../components/NoticeModal.styl'

const Option = Select.Option

const GeRenKaiHu = ({ data, selectChecked, onNewRecordSave, prevUrl }) => {
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const history = useHistory()
    const sources1 = ['住房公积金开户', '补充住房公积金开户']

    const onFinish = (values) => {
        const dataObj = produce(values, (draft) => {
            draft.identity = draft.identity.trim()
            draft.data.姓名 = draft.data.姓名.trim()
        })
        Confirm({
            className: 'confirm-submit',
            title: '确认',
            width: 480,
            content:
                '提交正式受理，请仔细核对所填信息。\n贵单位汇缴至20XX年X月，该员工开户后将参与X月份的汇缴，是否开户？',
            onOk: () => {
                onReset()
                const prevData = data.question.presetData.concat(data.editAnswer.questionAnswer?.content || [])
                if (!find(prevData, (s) => s.identity === dataObj.identity)) {
                    const obj = produce(dataObj, (draft) => {
                        draft.identity = dataObj.identity
                        draft.data['身份证号码'] = dataObj.identity
                        draft.data['个人账号'] = parseInt(Math.random() * 1000000000, 10) + '205'
                        draft.data['操作类型'] = '个人开户'
                    })
                    onNewRecordSave({
                        type: '个人开户',
                        operateObject: [obj],
                    })
                    Confirm({
                        className: 'confirm-submit',
                        title: '确认',
                        content: (
                            <div>
                                <p>住房公积金账户开户成功</p>
                                <p>账户：123456012205</p>
                                <p style={{ fontWeight: 'bold' }}>是否继续进行补充公积金账户开户业务？</p>
                            </div>
                        ),
                        onOk: () => {
                            message.error('模拟系统暂不支持此操作')
                        },
                    })
                } else {
                    message.error('该身份证号已有开户记录，无法重复操作')
                }
            },
        })
    }

    const onReset = () => {
        form.resetFields()
    }

    const onExport = () => {
        setVisible(true)
    }

    const inputChange = () => {
        const prevData = form.getFieldValue('data')
        const newData = produce(prevData, (draft) => {
            if (!isNaN(draft.工资) && draft.工资 !== '') {
                const deposit = Number(draft.工资) * 0.14
                draft['月缴存额'] = Math.round(deposit).toString()
            } else {
                draft['月缴存额'] = ''
            }
        })
        form.setFieldsValue({ data: newData })
    }

    const initialValues = {
        data: { 缴存比例: '7%' },
    }
    return (
        <div className="gjj-default-page">
            <div className="default-header">
                <Link to={prevUrl} onClick={selectChecked} className="current">
                    首页
                </Link>
                &gt;
                <span className="header-personal">个人开户</span>
            </div>
            <div className="default-main">
                <div className="main-tab">
                    {sources1.map((item) => (
                        <span key={item} className="tab-item">
                            {item}
                        </span>
                    ))}
                </div>
                <Alert
                    className="alert-warn"
                    message="开户后职工账户状态将为正常，单位需为职工正常缴存，如暂不需要为职工缴存，请勿操作开户。"
                    type="warning"
                    showIcon={true}
                    icon={<img src={img} className="icon-img" />}
                />
                <div className="main-from">
                    <Form
                        form={form}
                        initialValues={initialValues}
                        className="search-form"
                        onFinish={onFinish}
                        labelCol={{ flex: '70px' }}
                    >
                        <Row gutter={24}>
                            <Col span={9}>
                                <Form.Item
                                    name={['data', '姓名']}
                                    label="姓名"
                                    required={false}
                                    rules={[{ required: true, whitespace: true, message: '此项不得为空' }]}
                                >
                                    <Input style={{ width: '62.26%' }} maxLength={10} />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    name={['data', '工资']}
                                    onChange={inputChange}
                                    label="工资"
                                    required={false}
                                    rules={[{ required: true, whitespace: true, message: '此项不得为空' }]}
                                >
                                    <Input style={{ width: '62.26%' }} maxLength={10} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={9}>
                                <Form.Item
                                    name="identity"
                                    label="身份证号"
                                    required={false}
                                    rules={[{ required: true, whitespace: true, message: '此项不得为空' }]}
                                >
                                    <Input style={{ width: '62.26%' }} maxLength={30} />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item name={['data', '户籍']} label="户籍">
                                    <Select allowClear placeholder="请选择" style={{ width: '62.26%' }}>
                                        <Option value="非沪籍">非沪籍</Option>
                                        <Option value="沪籍">沪籍</Option>
                                        <Option value="境外">境外</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={9}>
                                <Form.Item name={['data', '月缴存额']} label="月缴存额">
                                    <Input disabled style={{ width: '62.26%', boxShadow: 'none', color: '#000' }} />
                                </Form.Item>
                            </Col>

                            <Col span={9}>
                                <Form.Item name={['data', '缴存比例']} label="缴存比例">
                                    <Input disabled style={{ width: '62.26%', boxShadow: 'none', color: '#000' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={20} style={{ textAlign: 'left', marginTop: '20px' }}>
                                <Button
                                    type="primary"
                                    className="submit-btn"
                                    htmlType={data?.question?.name !== '增员办理' ? 'button' : 'submit'}
                                >
                                    提交
                                </Button>
                                <Button className="submit-btn" onClick={onReset}>
                                    清空
                                </Button>

                                <Button onClick={data?.question?.name !== '批量开户' ? () => {} : onExport}>
                                    批量导入
                                </Button>
                                <Button>职工封存状态查询</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <KHConfirm
                data={data}
                selectChecked={selectChecked}
                onNewRecordSave={onNewRecordSave}
                form={form}
                prevUrl={prevUrl}
                history={history}
                visible={visible}
                setVisible={setVisible}
            />
        </div>
    )
}

export default GeRenKaiHu
