import React, { useState, useContext } from 'react'
import { Modal, Tabs } from 'antd'
import { ExamContext } from '../Context'
import Button from '../../../../components/button'
import './ExamInfoModal.styl'

const ExamInfoModal = () => {
    const [visible, setVisible] = useState(false)
    const { state } = useContext(ExamContext)
    const { exam, paper } = state
    const onToggleVisible = () => setVisible((prev) => !prev)
    return (
        <div className="exam-info-btn">
            <Button className="info-btn" icon="icon-notice" onClick={onToggleVisible} text>
                试卷说明/考试须知
            </Button>
            <Modal
                visible={visible}
                width={600}
                className="exam-info-modal"
                centered
                onCancel={onToggleVisible}
                footer={null}
            >
                <div className="exam-info-view">
                    <div className="view-title">《试卷说明》和《考试须知》</div>
                    <Tabs type="card" className="view-tabs">
                        <Tabs.TabPane tab="试卷说明" key="1" className="scrollbar">
                            <div className="tab-content">{paper.description}</div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="考试须知" key="2" className="scrollbar">
                            <div className="tab-content">{exam.notes}</div>
                        </Tabs.TabPane>
                    </Tabs>
                    <Button primary className="close-btn" onClick={onToggleVisible}>
                        关闭
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default ExamInfoModal
