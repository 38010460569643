//cSpell:word shebao
import React from 'react'
import { Link } from 'react-router-dom'
import s_1 from '../../../../../resources/shebao/1_s_1.png'
import s_2 from '../../../../../resources/shebao/1_s_2.png'
import s_3 from '../../../../../resources/shebao/1_s_3.png'
import s_4 from '../../../../../resources/shebao/1_s_4.png'
import s_5 from '../../../../../resources/shebao/1_s_5.png'
import s_6 from '../../../../../resources/shebao/1_s_6.png'
import c_1 from '../../../../../resources/shebao/1_c_1.png'
import c_2 from '../../../../../resources/shebao/1_c_2.png'
import c_3 from '../../../../../resources/shebao/1_c_3.png'
import c_4 from '../../../../../resources/shebao/1_c_4.png'
import c_5 from '../../../../../resources/shebao/1_c_5.png'
import c_6 from '../../../../../resources/shebao/1_c_6.png'
import search from '../../../../../resources/shebao/1_search.png'
import './HomePage.styl'

const HomePage = ({ path }) => {
    const source1 = [
        { name: '劳动就业', icon: s_1, to: `${location.pathname}/劳动就业` },
        { name: '社会保险', icon: s_2, to: `${location.pathname}/社会保险` },
        { name: '人事人才', icon: s_3 },
        { name: '打印中心', icon: s_4 },
        { name: '新手指南', icon: s_5 },
        { name: '收藏事项', icon: s_6 },
    ]
    const sources2 = [
        { name: '待办事项', icon: c_1 },
        { name: '办理中事项', icon: c_2 },
        { name: '已办事项', icon: c_3 },
        { name: '已预约事项', icon: c_4 },
    ]
    const sources3 = ['企业以工代训补贴申请', '申请就业见习带教费', '申请就业见习带教费', '初创期创业场地房租']
    return (
        <div className="sb-home-page">
            <div className="sidebar panel scrollbar">
                {source1.map((item) => {
                    if (!item.to)
                        return (
                            <div className="sidebar-item disabled" key={item.name}>
                                <img src={item.icon} />
                                <span>{item.name}</span>
                            </div>
                        )
                    return (
                        <Link key={item.name} className="sidebar-item" to={item.to}>
                            <img src={item.icon} />
                            <span>{item.name}</span>
                        </Link>
                    )
                })}
            </div>
            <div className="page-right scrollbar">
                {sources2.map((item, index) => (
                    <div className={`panel panel-${index + 1}`} key={item.name}>
                        <div className="panel-left">
                            <img src={item.icon} />
                        </div>
                        <div className="panel-right">
                            <div className="type-num">0</div>
                            <div className="type-name">{item.name}</div>
                        </div>
                    </div>
                ))}
                <div className="panel panel-5">
                    <img src={c_5} className="c-5-img" />
                    <img src={c_6} className="c-6-img" />
                </div>
                <div className="panel panel-6">
                    <div className="fake-input">
                        <div className="fake-placeholder">输入事项名称</div>
                        <div>
                            <img src={search} className="search-img" />
                        </div>
                    </div>
                    <div className="search-title">热门搜索</div>
                    <div className="search-result">
                        {sources3.map((item, index) => (
                            <div key={index} className="result-item">
                                <span>{index + 1}</span>
                                <span>{item}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="panel panel-7">
                    <div className="panel-header">
                        <div className="panel-title">收藏事项</div>
                        <div className="panel-action">{'更多 >'}</div>
                    </div>
                </div>
                <div className="panel panel-8">
                    <div className="panel-header">
                        <div className="panel-title">待办事项</div>
                        <div className="panel-action">{'更多 >'}</div>
                    </div>
                    <div className="panel-row">
                        <div className="row-item">申请对象</div>
                        <div className="row-item">事项名称</div>
                        <div className="row-item">协办天数</div>
                        <div className="row-item">操作</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage
