import React from 'react'
import { Menu } from 'antd'
import message from '../../../../components/message'
import Confirm from '../../../../components/modal/Confirm'
import { deleteExam, putPin } from '../../../../services/ExamsRequest'

const ListMenu = (record, setSettingData, setCopyData, onStatusChange, onRefresh) => {
    const handleChildActions = (menuItem) => {
        const key = menuItem.key
        if (key === 'pin' || key === 'unpin') {
            putPin(record.id, { pin: key === 'pin' })
                .then(onRefresh)
                .catch((err) => {
                    const error = err.response.data?.message || '操作失败！刷新后重试'
                    message.error(error)
                })
        } else if (key === 'setup') {
            setSettingData({
                visible: true,
                record,
            })
        } else if (key === 'copy') {
            setCopyData({ visible: true, id: record.id, paper: true, candidate: true })
        } else if (key === 'service') {
            Confirm({
                title: '停用确认',
                content: '停用后，考生将无法参与此次考试！是否确认停用？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => onStatusChange(record),
            })
        } else if (key === 'delete') {
            Confirm({
                title: '删除确认',
                content: '是否确认删除？',
                okText: '确认',
                cancelText: '取消',
                onOk: () =>
                    deleteExam(record.id)
                        .then(() => {
                            message.success('操作成功！')
                            onRefresh()
                        })
                        .catch(
                            (err) =>
                                new Promise(() => {
                                    if (err.response.status === 404) {
                                        message.error('操作失败！刷新后重试')
                                    } else {
                                        message.error(err.response.data.message)
                                    }
                                })
                        ),
            })
        }
    }
    return (
        <Menu onClick={handleChildActions} record={record} style={{ width: 80, textAlign: 'center' }}>
            <Menu.Item key="pin">
                <span>置顶</span>
            </Menu.Item>
            {record.pin && (
                <Menu.Item key="unpin">
                    <span>取消置顶</span>
                </Menu.Item>
            )}
            <Menu.Item key="setup">
                <span>设置</span>
            </Menu.Item>
            <Menu.Item key="copy">
                <span>复制</span>
            </Menu.Item>
            {record.status === 'Release' && (
                <Menu.Item key="service">
                    <span style={{ color: 'red' }}>紧急停用</span>
                </Menu.Item>
            )}
            <Menu.Item key="delete">
                <span style={{ color: 'red' }}>删除</span>
            </Menu.Item>
        </Menu>
    )
}

export default ListMenu
