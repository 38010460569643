import React from 'react'
import { Form } from 'antd'
import { isString } from 'lodash'
import { uploadFn, validateFn } from './Case'

import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import 'braft-editor/dist/index.css'
import 'braft-extensions/dist/table.css'

const options = {
    defaultColumns: 4, // 默认列数
    defaultRows: 4, // 默认行数
    withDropdown: false, // 插入表格前是否弹出下拉菜单
    columnResizable: false, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
}
BraftEditor.use(Table(options))

const controls = [
    'undo',
    'redo',
    'separator',
    'bold',
    'italic',
    'underline',
    'strike-through',
    'separator',
    'superscript',
    'subscript',
    'remove-styles',
    'emoji',
    'separator',
    'table',
    'separator',
    'text-indent',
    'text-align',
    'separator',
    'headings',
    'list-ul',
    'list-ol',
    'blockquote',
    'code',
    'separator',
    'link',
    'separator',
    'hr',
    'separator',
    'media',
    'separator',
    'clear',
]

export default ({ prevPath }) => (
    <Form.List name="baseQuestions">
        {(fields, {}, { errors }) => (
            <Form.Item noStyle>
                {fields.map((field, index) => (
                    <Form.Item key={field.key} {...field} noStyle>
                        <>
                            <div style={{ marginBottom: '10px' }}>
                                <span>{`（${index + 1}）参考答案与解析`}</span>
                                <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>（用于模拟演练端展示）</span>
                            </div>
                            <Form.Item
                                name={[field.name, 'reference']}
                                validateTrigger="onBlur"
                                getValueProps={(value) => {
                                    if (isString(value) || !value)
                                        return { value: BraftEditor.createEditorState(value) }
                                    return { value }
                                }}
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => {
                                            if (isString(value) ? !value.trim() : value?.isEmpty?.()) {
                                                return Promise.reject(new Error('请输入正文内容'))
                                            }
                                            return Promise.resolve()
                                        },
                                        message: '内容不能为空',
                                    },
                                ]}
                            >
                                <BraftEditor
                                    placeholder="请输入"
                                    style={{ border: '1px solid #d9d9d9' }}
                                    controls={controls}
                                    media={{ uploadFn, validateFn, accepts: { image: 'image/png,image/jpeg' } }}
                                />
                            </Form.Item>
                        </>
                    </Form.Item>
                ))}
                <Form.ErrorList errors={errors} />
            </Form.Item>
        )}
    </Form.List>
)
