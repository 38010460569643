import React, { useState } from 'react'
import produce from 'immer'

import { answerNos, typeNames } from '../configs'
import Answer from '../components/Answer'

import './common.styl'

const Sorting = ({ stepData, editAnswer, onChange, preview, answerVisible }) => {
    const [customAnswer, setCustomAnswer] = useState(null)

    const answer = editAnswer || customAnswer || stepData?.userAnswer || {}
    const onCustomChange = (value) => {
        const newAnswer = produce(answer, (draft) => {
            const options = draft.questionAnswer?.options || []
            const answerIndex = options.indexOf(value)
            if (answerIndex !== -1) {
                options.splice(answerIndex, 1)
            } else {
                options.push(value)
            }
            draft.questionAnswer = {
                options,
                type: stepData.type,
            }
        })
        if (onChange) {
            onChange(newAnswer)
        } else {
            setCustomAnswer(newAnswer)
        }
    }

    return (
        <div className={'exam-question question-sorting' + (!stepData.id ? ' child-question' : '')}>
            <div className="question-header">
                <div className="question-no">{(stepData.index || 0) + 1}</div>
                <div className="question-title">
                    {(preview || !stepData.id) && <span className="question-type">【{typeNames[stepData.type]}】</span>}
                    {stepData.content}
                    <span className="question-score">{`${stepData.score}分`}</span>
                </div>
            </div>
            {stepData.image && (
                <div className="question-image">
                    <img src={stepData.image} />
                </div>
            )}
            <div className="question-answer">
                {stepData.options?.map((option, index) => {
                    const no = answerNos[index]
                    const optionIndex = answer?.questionAnswer?.options?.indexOf(no) + 1 || 0
                    return (
                        <div className="question-option" key={index} onClick={() => onCustomChange(no)}>
                            <span className={'sorting-checkbox' + (optionIndex ? ' active' : ' inactive')}>
                                {optionIndex || ''}
                            </span>
                            <span className="sorting-text">
                                <span>{no}. </span>
                                <span className="option-content">{option.content}</span>
                            </span>
                        </div>
                    )
                })}
            </div>
            <Answer visible={answerVisible} answer={stepData.answer} explanation={stepData.explanation} />
        </div>
    )
}

export default Sorting
