import AxiosRequest from './AxiosRequest'
const url = 'papers'

export const getPaper = (id) => {
    return AxiosRequest.get(`${url}/${id}`)
}

export const getPapers = (params = '') => {
    return AxiosRequest.get(`${url}/search?${params}`)
}

export const getPapersByPage = (params) => {
    return AxiosRequest.get(`${url}/search`, {
        params,
    })
}

export const postPaper = (data) => {
    return AxiosRequest.post(`${url}`, data)
}

export const putPaper = (id, data) => {
    return AxiosRequest.put(`${url}/${id}`, data)
}

export const deletePapers = (ids = []) => {
    return AxiosRequest.delete(`${url}?ids=${ids.join('&ids=')}`)
}

export const getPaperNames = (ids = []) => {
    return AxiosRequest.get(`${url}/names?ids=${ids.join('&ids=')}`)
}

export const postExamsPaper = (id, paperId) => {
    return AxiosRequest.post(`exams/${id}/paper/${paperId}`)
}

/**
 *
 * @param {string} id
 * @param {{name:string,description:string,level:'Beginner'|'Intermediate'|'Advancedv',subject:'Theory'|'Practice'}} data
 * @returns
 */
export const copyPaper = (id, data) => {
    return AxiosRequest.post(`${url}/${id}/copy`, data)
}

export const getPaperQuestions = (id) => {
    return AxiosRequest.get(`${url}/${id}/questions`)
}
