import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

const Header = () => {
    return (
        <div className="page-header">
            <div className="page-title">试卷管理</div>
            <Link to="/kw/paper/create">
                <Button type="primary">添加试卷</Button>
            </Link>
        </div>
    )
}

export default Header
