import React, { useState } from 'react'
import { Form, Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import message from '../../../../../components/message'

export const UploadView = (props) => {
    const prevPath = props.prevPath || []
    const defaultList = props.value ? [{ url: props.value }] : []
    const [fileList, setFileList] = useState(defaultList)
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
    }

    const onPreview = async (file) => {
        let src = file.url
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }
        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return `${e?.[0]?.response}`
        }
        return `${e?.file?.response}`
    }

    const beforeUpload = (file) => {
        const isLt300K = file.size / 1024 < 300
        if (!isLt300K) {
            message.error('请上传小于300kb的图片')
        }
        return !isLt300K ? Upload.LIST_IGNORE : true
    }

    return (
        <Form.Item
            name={props.name || [...prevPath, 'image']}
            getValueFromEvent={normFile}
            style={{ marginBottom: 0, display: 'inline-block' }}
        >
            <Upload
                accept=".png,.jpg,.jpeg"
                maxCount={1}
                listType={props.listType || 'picture-card'}
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                action="/api/files"
            >
                {fileList.length < 1 &&
                    (props.listType === 'picture' ? <Button icon={<UploadOutlined />}>点击上传</Button> : '点击上传')}
            </Upload>
        </Form.Item>
    )
}

export default ({ prevPath }) => (
    <Form.Item name={[...prevPath, 'image']} label="配图" extra="格式要求：png/jpg/jpeg">
        <UploadView prevPath={prevPath} />
    </Form.Item>
)
