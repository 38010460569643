import React, { useState } from 'react'
import produce from 'immer'
import moment from 'moment'
import 'moment/locale/zh-cn'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import { Button, Form, Input, Select, DatePicker } from 'antd'
import { createExam } from '../../../../services/ExamsRequest'
import message from '../../../../components/message'
import './Header.styl'

const Option = Select.Option

const Header = ({ onRefresh, setIsModalVisible }) => {
    const [form] = Form.useForm()
    const [showDate, setShowDate] = useState(true)
    const handleOk = () => {
        const values = produce(form.getFieldsValue(true), (draft) => {
            draft.name = draft.name.trim()
            draft.startDate = draft.startDate.valueOf()
            draft.durationMinute = Number(draft.durationMinute)
        })
        createExam(values)
            .then(() => {
                setIsModalVisible(false)
                message.success('操作成功！')
                onRefresh()
            })
            .catch((err) => {
                message.error(err.response.data.message)
            })
    }
    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const inputChange = (changedValues, allValues) => {
        const endTime = moment.duration(allValues.durationMinute ? allValues.durationMinute : 60, 'minutes')
            ._milliseconds
        const startTime = allValues.startDate && allValues.startDate.valueOf()
        startTime ? setShowDate(false) : setShowDate(true)
        const endDate = startTime + endTime
        form.setFieldsValue({ outDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss') })
    }
    const initialValues = {
        durationMinute: 60,
        notes: `1、考试全程请勿退出全屏或离开答题界面！系统将会自动记录您的各项操作记录并上传至服务器！违规操作将可能影响您的考试成绩！请务必遵守考试规则！\n2、考试倒计时将会实时显示在答题界面右上角，请注意剩余答题时间！\n3、答题完成后，请点击交卷，交卷成功后答案不可再做修改！系统将会自动退出！\n4、答题时间剩余 5 分钟时，系统将会进行提示，答题时间用完后，系统将会强制自动提交！`,
    }

    return (
        <Form
            form={form}
            initialValues={initialValues}
            onFinish={handleOk}
            labelAlign="right"
            labelCol={{ span: 8 }}
            onValuesChange={inputChange}
        >
            <h3 className="top-h3">一、基础信息</h3>
            <Form.Item
                name="name"
                label="考试名称"
                rules={[{ required: true, whitespace: true, message: '请填写考试名称' }]}
            >
                <Input style={{ width: 240 }} maxLength={50} placeholder="请输入考试名称" />
            </Form.Item>
            <Form.Item label="证书">
                <div>人力资源共享服务职业技能等级证书</div>
            </Form.Item>
            <Form.Item
                name="type"
                label="类型"
                rules={[{ required: true, whitespace: true, message: '请选择考试类型' }]}
            >
                <Select placeholder="请选择" style={{ width: 240 }}>
                    <Option value="">请选择</Option>
                    <Option value="Official">正式考试</Option>
                    <Option value="Simulation">模拟考试</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="subject"
                label="科目"
                rules={[{ required: true, whitespace: true, message: '请选择考试科目' }]}
            >
                <Select placeholder="请选择" style={{ width: 240 }}>
                    <Option value="">请选择</Option>
                    <Option value="Theory">理论知识</Option>
                    <Option value="Practice">实操技能</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="level"
                label="等级"
                rules={[{ required: true, whitespace: true, message: '请选择考试等级' }]}
            >
                <Select placeholder="请选择" style={{ width: 240 }}>
                    <Option value="">请选择</Option>
                    <Option value="Beginner">初级</Option>
                    <Option value="Intermediate">中级</Option>
                    <Option value="Advanced">高级</Option>
                </Select>
            </Form.Item>
            <h3>二、时间规则</h3>
            <Form.Item name="startDate" label="开始时间" rules={[{ required: true, message: '请填写开始时间' }]}>
                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" locale={locale} />
            </Form.Item>
            <Form.Item name="durationMinute" label="考试时长" rules={[{ required: true, message: '请填写考试时长' }]}>
                <Input style={{ width: 100 }} addonAfter="分钟" />
            </Form.Item>
            <Form.Item name="outDate" label="结束时间" hidden={showDate}>
                <Input readOnly style={{ border: 'none', boxShadow: 'none' }} />
            </Form.Item>
            <Form.Item label="允许提前登录时间">15 分钟</Form.Item>
            <Form.Item label="倒计时提醒">5 分钟</Form.Item>
            <h3>三、考试须知</h3>
            <Form.Item name="notes" style={{ marginLeft: '70px' }}>
                <Input.TextArea maxLength={500} autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
            <div className="right-action">
                <Button onClick={handleCancel}>取消</Button>
                <Button type="primary" htmlType="submit">
                    完成
                </Button>
            </div>
        </Form>
    )
}

export default Header
