import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import produce from 'immer'
import { Button, Tag, Checkbox, Pagination } from 'antd'
import { difference, union, slice, filter, find } from 'lodash'

import message from '../../../../components/message'
import Confirm from '../../../../components/modal/Confirm'
import ScoreConfirm, { AnalyzeConfirm } from './ScoreConfirm'
import { getQuestionsByIds } from '../../../../services/QuestionRequest'
import './QuestionList.styl'

const QuestionList = ({ scores, list, type, onConfirmDelete, onQuestionMove, onScoresChange }) => {
    const [selects, setSelects] = useState([])
    // 分页数据
    const [page, setPage] = useState({ type: '', content: [], number: 0 })
    // 存储当前显示过的所有题目数据
    const [cacheList, setCacheList] = useState([])

    useEffect(() => {
        // list更新 -> 更新page
        setPage((prev) => {
            let number = prev.number
            if (prev.type !== type) {
                // 切换类型时回到第一页，并清空选中项
                number = 0
                setSelects([])
            } else if (prev.number * 10 > list.length) {
                // 当前页面删除完，回到最后一页
                number = parseInt(list.length / 10, 10)
            }
            const content = slice(list, number * 10, number * 10 + 10)
            return {
                type,
                number,
                content,
            }
        })
    }, [type, list])

    useEffect(() => {
        // page更新 -> 更新缓存数据或仅作判断
        const ids = page.content || []
        const remains = filter(ids, (id) => !find(cacheList, (cache) => cache.id === id))
        // 若没有数据或题目数据都已缓存则不去调接口，并防止cacheList循环更新
        if (remains.length === 0) return
        getQuestionsByIds(ids).then((results) => {
            setCacheList((prev) =>
                produce(prev, (draft) => {
                    draft.push(...results)
                })
            )
        })
    }, [page, cacheList])

    const handleSelect = (e, id, isSelect) => {
        e.stopPropagation()
        const newSelects = produce(selects, (draft) => {
            if (isSelect) {
                const idx = selects.indexOf(id)
                draft.splice(idx, 1)
            } else {
                draft.push(id)
            }
        })
        setSelects(newSelects)
    }
    const handleSelectAll = () => {
        const ss = page.content || []
        const unselects = difference(ss, selects)
        if (unselects.length === 0) {
            // 当前页已全选
            setSelects(difference(selects, ss))
        } else {
            setSelects(union(selects, ss))
        }
    }
    const onDelete = () => {
        Confirm({
            title: '提示',
            content: '确认删除所有已选中的项么？',
            onOk: () => {
                onConfirmDelete(selects, () => setSelects([]))
                message.success('操作成功！')
            },
        })
    }
    const onCommonScoreSet = () => {
        ScoreConfirm({
            onConfirm: (score) => onScoresChange(selects.map((s) => ({ questionId: s, index: 0, score }))),
        })
    }
    const onAnalyzeScoreSet = (item, defaultScores) => {
        AnalyzeConfirm({ data: item, defaultScores, onConfirm: (scores) => onScoresChange(scores) })
    }
    const onPageChange = (number) => {
        const nextPage = number - 1
        setPage({
            type,
            number: nextPage,
            content: slice(list, nextPage * 10, nextPage * 10 + 10),
        })
    }
    return (
        <div className="question-list scrollbar">
            <div className="page-list-container">
                <div className="action-bar">
                    <Button onClick={handleSelectAll}>全选</Button>
                    <Button disabled={selects.length === 0} onClick={onDelete}>
                        删除选中项
                    </Button>
                    {page.type !== 'Analyze' && page.type !== 'Operate' && (
                        <Button disabled={selects.length === 0} onClick={onCommonScoreSet}>
                            设置分数
                        </Button>
                    )}
                    <div className="selects-num">已选中 {selects.length || 0} 项</div>
                </div>
                {page.content?.map((id, index) => {
                    const no = page.number * 10 + index + 1
                    const item = find(cacheList, (cache) => cache.id === id) || {}
                    const selected = selects.indexOf(item.id) !== -1
                    const idScores = filter(scores, (s) => s.questionId === id)
                    let totalScore = idScores.length === 0 ? '' : 0
                    idScores.forEach((s) => (totalScore += s.score))
                    return (
                        <div className="list-item" key={id + no} onClick={(e) => handleSelect(e, item.id, selected)}>
                            <Checkbox checked={selected} onClick={(e) => handleSelect(e, item.id, selected)} />
                            <div className="item-no">{no}</div>
                            <div className="item-detail">
                                <div className="row-1">
                                    <span className="item-type">{item.typeName}</span>
                                    <span>{item.certificateName}</span>
                                    <span>{item.levelName}</span>
                                    <span>{item.subjectName}</span>
                                    <span>{item.fieldName}</span>
                                </div>
                                {item.type === 'Analyze' && (
                                    <Button
                                        className="analyze-score-btn"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onAnalyzeScoreSet(item, idScores)
                                        }}
                                    >
                                        设置分数
                                    </Button>
                                )}
                                <Link to={`/kw/question/${item.type}/detail/${item.id}`} target="_blank">
                                    <Button className="link-btn">查看原题</Button>
                                </Link>
                                <div className="row-2">
                                    <div className="item-content">{item.content || item.key}</div>
                                    <div className="item-score">
                                        {!totalScore && totalScore !== 0 ? '-' : `${totalScore}分`}
                                    </div>
                                </div>
                                <div className="row-3">
                                    <div className="tags">
                                        {item.tags?.map((tag) => (
                                            <Tag key={tag}>{tag}</Tag>
                                        ))}
                                    </div>
                                    <div className="row-action" onClick={(e) => e.stopPropagation()}>
                                        <Button disabled={no === 1} onClick={() => onQuestionMove(no - 1, no - 2)}>
                                            向上移动
                                        </Button>
                                        <Button
                                            disabled={no === list.length}
                                            onClick={() => onQuestionMove(no - 1, no)}
                                        >
                                            向下移动
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <Pagination
                    defaultCurrent={1}
                    total={list.length}
                    current={page.number + 1}
                    hideOnSinglePage={true}
                    onChange={onPageChange}
                    showSizeChanger={false}
                />
            </div>
        </div>
    )
}

export default QuestionList
