import React from 'react'
import Preview from '../../../paper/detail/Preview'
import PaperInfo from '../../../paper/list/ListItem'
import './PaperPreview.styl'

export default ({ paper }) => {
    return (
        <div className="paper-snapshot">
            <span>试卷快照：</span>
            {!paper.id ? (
                <span className="empty">无</span>
            ) : (
                <div className="paper-snapshot-preview-container">
                    <PaperInfo data={paper} />
                    <div className="paper-description">
                        <div className="view-name">试卷说明</div>
                        <div className="view-content">{paper.description}</div>
                    </div>
                    <Preview paper={paper} />
                </div>
            )}
        </div>
    )
}
