//cSpell:word Feng
import produce from 'immer'
import { Modal, Button } from 'antd'

import Confirms from '../../components/Confirm'
import './QiFengModal.styl'

export default (resData, onNewRecordSave, data, selectChecked, history, prevUrl) => {
    const resultOnOk = () => {
        Modal.destroyAll()
        history.push(prevUrl + '/职工变更业务查询')
        selectChecked('业务查询')
    }
    const operateData = resData.filter((item) => !!item)
    return Confirms({
        className: 'confirm-submit',
        title: '确认',
        width: 480,
        content: '提交正式受理，请仔细核对所填信息，您是否确认提交？',
        onOk: () => {
            const operateObject = produce(operateData, (draft) => {
                draft.forEach((item, index) => {
                    item.data['操作类型'] = '封存'
                })
            })
            onNewRecordSave({
                type: '封存',
                operateObject,
            })
            Modal.destroyAll()
            Confirms({
                className: 'confirm-result',
                title: '提示',
                width: 480,
                content: (
                    <div>
                        <p>住房公积金封存业务提交成功！</p>
                        <p>操作流水号：XXX</p>
                        <p style={{ fontWeight: 'bold' }}>是否去变更管理页面查看结果？</p>
                        <div className="confirm-notice-btn">
                            <Button onClick={() => Modal.destroyAll()}>取消</Button>
                            <Button>申请表</Button>
                            <Button onClick={resultOnOk}>确认</Button>
                        </div>
                    </div>
                ),
            })
        },
    })
}
