export const operateKeys = {
    招工登记: [
        {
            category: '个人信息',
            answerKeys: [
                '公民身份证号码',
                '姓名',
                '联系电话（固定电话）',
                '联系电话（移动电话）',
                '联系地址（省市区）',
            ],
        },
        { category: '申报信息', answerKeys: ['缴费起始年月', '月平均工资性收入', '月缴费基数'] },
        {
            category: '被招用人员信息',
            answerKeys: [
                '劳动合同签订情况',
                '就业起始日期',
                '本次劳动合同起始日期',
                '本次劳动合同终止日期',
                '用工形式',
                '职业工种',
                '劳务派遣',
                '派遣去向',
                '派遣期限',
            ],
        },
    ],
    社保转入: [
        { category: '个人信息', answerKeys: ['公民身份证号码', '姓名'] },
        { category: '外来从业人员信息', answerKeys: ['是否选择人才引进居住证参保', '居住证号码'] },
        { category: '申报信息', answerKeys: ['缴费起始年月', '月平均工资性收入', '月缴费基数'] },
        { category: '其它', answerKeys: ['是否勾选下方承诺'] },
    ],
    社保缴费基数调整: [
        { category: '个人信息', answerKeys: ['公民身份证号码', '姓名'] },
        { category: '申报信息', answerKeys: ['月平均工资性收入', '调整后缴费基数'] },
    ],
    退工登记: [
        { category: '个人信息', answerKeys: ['公民身份证号码', '姓名'] },
        { category: '被退用人员信息', answerKeys: ['解除或终止日期', '退工原因', '转档需求', '退工备案区县'] },
    ],
    社保转出: [
        { category: '个人信息', answerKeys: ['公民身份证号码', '姓名'] },
        { category: '申报信息', answerKeys: ['月平均工资性收入', '是否勾选承诺'] },
    ],
    个人开户: [{ answerKeys: ['姓名', '身份证号', '工资', '户籍'] }],
    启封: [{ answerKeys: ['账户类型', '身份证号', '工资'] }],
    转入: [{ answerKeys: ['账户类型', '职工账号', '姓名', '身份证号'] }],
    批量开户: [{ answerKeys: ['姓名', '身份证号', '户籍', '账户类型', '公积金账号', '补充比例', '工资'] }],
    转出: [{ answerKeys: ['单位名称', '单位账号', '职工转出前需汇缴到的年月'] }],
    封存: [{ answerKeys: ['个人手机号码', '身份证号', '退工日期'] }],
    待转出停缴: [{ answerKeys: ['停缴期限'] }],
    待销户停缴: [{ answerKeys: [] }],
    人员信息登记: [
        {
            category: '个人信息',
            answerKeys: [
                '证件类型',
                '证件号码',
                '姓名',
                '国籍',
                '性别',
                '学历',
                '出生日期',
                '纳税人识别号',
                '是否残疾',
                '残疾证号',
                '是否烈属',
                '烈属证号',
                '是否孤老',
                '人员状态',
            ],
        },
        {
            category: '任职受雇从业信息',
            answerKeys: [
                '任职受雇从业类型',
                '任职受雇从业日期',
                '离职日期',
                '是否扣除减除费用',
                '入职年度就业情况',
                '工号',
                '职务',
            ],
        },
        {
            category: '联系方式',
            answerKeys: [
                '手机号码',
                '电子邮箱',
                '经常居住地',
                '联系地址',
                '开户银行',
                '开户行省份',
                '银行账号',
                '户籍所在地',
            ],
        },
        {
            category: '投资信息',
            answerKeys: ['个人投资额', '个人投资比例（%）'],
        },
    ],
    人员信息报送验证: [
        {
            category: '个人信息',
            answerKeys: [
                '证件类型',
                '证件号码',
                '姓名',
                '国籍',
                '性别',
                '学历',
                '出生日期',
                '纳税人识别号',
                '是否残疾',
                '残疾证号',
                '是否烈属',
                '烈属证号',
                '是否孤老',
                '人员状态',
            ],
        },
        {
            category: '任职受雇从业信息',
            answerKeys: [
                '任职受雇从业类型',
                '任职受雇从业日期',
                '离职日期',
                '是否扣除减除费用',
                '入职年度就业情况',
                '工号',
                '职务',
            ],
        },
        {
            category: '联系方式',
            answerKeys: [
                '手机号码',
                '电子邮箱',
                '经常居住地',
                '联系地址',
                '开户银行',
                '开户行省份',
                '银行账号',
                '户籍所在地',
            ],
        },
        {
            category: '投资信息',
            answerKeys: ['个人投资额', '个人投资比例（%）'],
        },
    ],
    人员信息维护: [
        {
            category: '个人信息',
            answerKeys: [
                '证件类型',
                '证件号码',
                '姓名',
                '国籍',
                '性别',
                '学历',
                '出生日期',
                '纳税人识别号',
                '是否残疾',
                '残疾证号',
                '是否烈属',
                '烈属证号',
                '是否孤老',
                '人员状态',
            ],
        },
        {
            category: '任职受雇从业信息',
            answerKeys: [
                '任职受雇从业类型',
                '任职受雇从业日期',
                '离职日期',
                '是否扣除减除费用',
                '入职年度就业情况',
                '工号',
                '职务',
            ],
        },
        {
            category: '联系方式',
            answerKeys: [
                '手机号码',
                '电子邮箱',
                '经常居住地',
                '联系地址',
                '开户银行',
                '开户行省份',
                '银行账号',
                '户籍所在地',
            ],
        },
        {
            category: '投资信息',
            answerKeys: ['个人投资额', '个人投资比例（%）'],
        },
    ],
    正常工资薪金所得填写: [
        {
            category: '个人信息',
            answerKeys: ['姓名', '证件类型', '证件号码', '工号', '所得期间起', '所得期间止'],
        },
        {
            category: '本期收入及免税收入',
            answerKeys: ['本期收入', '本期免税收入'],
        },
        {
            category: '本期专项扣除',
            answerKeys: ['基本养老保险费', '基本医疗保险费', '失业保险费', '住房公积金'],
        },
        {
            category: '累计专项附加扣除',
            answerKeys: ['子女教育', '住房贷款利息', '住房租金', '赡养老人', '继续教育'],
        },
        {
            category: '本期其他扣除',
            answerKeys: ['企业（职业）年金', '商业健康保险', '税延养老保险', '其他'],
        },
        {
            category: '本期其他',
            answerKeys: ['准予扣除的捐赠额', '减免税额', '减除费用标准', '已缴税额'],
        },
    ],
}
