import React, { useState, useEffect } from 'react'
import { isNumber, filter, camelCase, sortBy, find } from 'lodash'
import { typeNames, chNos } from '../../../../ks/exam/configs'

import Questions from './Questions'
import Text from './Text'
import Analyze from './Analyze'
import Operate from './Operate'
import './AnswerSituation.styl'

const AnswerSituation = ({ inScored, data, getData, addScoreQuestion }) => {
    const [questions, setQuestions] = useState([])
    useEffect(() => {
        const qs = []
        let allList = []
        const list = { paper: [], answers: [] }
        const typeList = ['MultipleChoice', 'MultipleSelection', 'TrueOrFalse', 'Sorting', 'Text', 'Analyze', 'Operate']
        const paperQuestionId = data.paper.scores.filter((item) => item.questionId)
        const answerQuestionId = data.answers.filter((item) => item.questionId)
        const sortedQuestions = sortBy(
            data.questions,
            (q) => typeList.indexOf(q.type),
            (q) => data.paper[`${camelCase(q.type)}List`]?.indexOf(q.id)
        )
        sortedQuestions.forEach((item) => {
            paperQuestionId.forEach((i) => {
                if (item.id === i.questionId) {
                    list.paper.push({ typeName: item.type, score: i.score })
                }
            })
            answerQuestionId.forEach((i) => {
                if (item.id === i.questionId) {
                    list.answers.push({ typeName: item.type, score: i.score })
                }
            })
            if (
                item.type === 'MultipleChoice' ||
                item.type === 'MultipleSelection' ||
                item.type === 'TrueOrFalse' ||
                item.type === 'Sorting'
            ) {
                let allQuestions = { id: item.id, answer: item.answer.options, type: item.answer.type }
                data.answers.forEach((i) => {
                    if (item.id === i.questionId) {
                        allQuestions.score = i.score
                        allQuestions.questionAnswer = i.questionAnswer.options
                    }
                })
                data.paper.scores.forEach((m) => {
                    if (item.id === m.questionId) {
                        allQuestions.totalScore = m.score
                    }
                })
                allList.push(allQuestions)
            } else if (item.type === 'Text') {
                let allQuestions = {
                    id: item.id,
                    answer: item.answer.content,
                    explanation: item.explanation,
                    type: item.answer.type,
                }
                data.answers.forEach((i) => {
                    if (item.id === i.questionId) {
                        allQuestions.score = i.score
                        allQuestions.questionAnswer = i.questionAnswer?.content
                    }
                })
                data.paper.scores.forEach((m) => {
                    if (item.id === m.questionId) {
                        allQuestions.totalScore = m.score
                        allQuestions.index = m.index
                    }
                })
                allList.push(allQuestions)
            } else if (item.type === 'Analyze') {
                let allQuestions = {
                    id: item.id,
                    baseQuestions: item.baseQuestions,
                    type: item.type,
                }
                data.answers.forEach((i) => {
                    if (item.id === i.questionId) {
                        allQuestions.score = i.score
                        allQuestions.questionAnswer = i.questionAnswer
                    }
                })
                allList.push(allQuestions)
            } else if (item.type === 'Operate') {
                let allQuestions = {
                    id: item.id,
                    baseQuestions: item.baseQuestions,
                    type: item.type,
                }

                allQuestions.baseQuestions.forEach((item, index) => {
                    data.paper.scores.forEach((m) => {
                        if (allQuestions.id === m.questionId && m.index === index) {
                            item.totalScore = m.score
                            item.index = m.index
                        }
                    })
                    data.answers.forEach((i) => {
                        if (allQuestions.id === i.questionId && i.questionIndex === index) {
                            item.score = i.score
                            item.questionAnswer = i.questionAnswer
                        }
                    })
                })
                allList.push(allQuestions)
            }
        })
        typeList.forEach((type) => {
            const questionIds = data.paper[`${camelCase(type)}List`] || []
            if (questionIds.length > 0) {
                const typeQuestions = filter(data.questions, (r) => questionIds.indexOf(r.id) !== -1).sort(
                    (a, b) => questionIds.indexOf(a.id) - questionIds.indexOf(b.id)
                )
                let paperCount = 0
                let answerCount = 0
                let answerScore = []
                const all = allList.filter((s) => s.type === type)
                const paperTypeNameList = list.paper.filter((a) => a.typeName === type)
                paperTypeNameList.forEach((m) => {
                    paperCount += m.score
                })
                const answerTypeNameList = list.answers.filter((a) => a.typeName === type)
                answerTypeNameList.map((m) => {
                    return answerScore.push(m.score)
                })

                if (type === 'Text') {
                    if (
                        data.answers.length === 0 ||
                        all.some((w) => !find(data.answers, (s) => s.questionId === w.id))
                    ) {
                        answerCount = '待评分'
                    }
                } else if (type === 'Analyze') {
                    const unScored = all.some((q) => {
                        return q.baseQuestions.some(
                            (bq, index) =>
                                bq.type === 'Text' &&
                                !find(data.answers, (s) => s.questionId === q.id && s.questionIndex === index)
                        )
                    })
                    if (data.answers.length === 0 || unScored) {
                        answerCount = '待评分'
                    }
                }

                if (answerScore.some((n) => !isNumber(n))) {
                    answerCount = '待评分'
                } else if (answerCount !== '待评分') {
                    answerTypeNameList.forEach((m) => {
                        answerCount += m.score
                    })
                }

                qs.push({
                    name: `${chNos[qs.length]}、${typeNames[type]}
                    （共${typeQuestions.length}题，满分${
                        String(paperCount).indexOf('.') + 1 > 0 ? paperCount?.toFixed(1) : paperCount
                    }分）`,
                    type,
                    score: `${String(answerCount).indexOf('.') + 1 > 0 ? answerCount?.toFixed(1) : answerCount}`,
                    allQuestion: all,
                })
            }
        })
        setQuestions(qs)
    }, [data])
    const RenderQuestion = (question, index) => {
        let type = question.type
        let judge = ''
        switch (type) {
            case 'MultipleChoice':
                if (question.questionAnswer === undefined) {
                    judge = '❌'
                } else if (question.questionAnswer[0] === question.answer[0]) {
                    judge = '✔️'
                } else {
                    judge = '❌'
                }
                return <Questions key={index} question={question} index={index} judge={judge} inScored={inScored} />
            case 'MultipleSelection':
                if (question.questionAnswer === undefined) {
                    judge = '❌'
                } else if (question.questionAnswer.length === question.answer.length) {
                    const questionAnswer = sortBy(question.questionAnswer, (q) => {
                        return q
                    })
                    const answer = sortBy(question.answer, (q) => {
                        return q
                    })
                    if (questionAnswer.join('') === answer.join('')) {
                        judge = '✔️'
                    } else {
                        judge = '❌'
                    }
                } else {
                    judge = '❌'
                }
                return <Questions key={index} question={question} index={index} judge={judge} inScored={inScored} />
            case 'Sorting':
                if (question.questionAnswer === undefined) {
                    judge = '❌'
                } else if (question.questionAnswer.join('') === question.answer.join('')) {
                    judge = '✔️'
                } else {
                    judge = '❌'
                }
                return <Questions key={index} question={question} index={index} judge={judge} inScored={inScored} />
            case 'TrueOrFalse':
                if (question.questionAnswer === undefined) {
                    judge = '❌'
                } else if (question.questionAnswer[0] === question.answer[0]) {
                    judge = '✔️'
                } else {
                    judge = '❌'
                }
                return <Questions key={index} question={question} index={index} judge={judge} inScored={inScored} />
            case 'Text':
                return (
                    <Text
                        key={index}
                        question={question}
                        index={index}
                        data={data}
                        getData={getData}
                        inScored={inScored}
                        addScoreQuestion={addScoreQuestion}
                    />
                )
            case 'Analyze':
                return (
                    <Analyze
                        key={index}
                        question={question}
                        index={index}
                        data={data}
                        getData={getData}
                        inScored={inScored}
                        addScoreQuestion={addScoreQuestion}
                    />
                )
            case 'Operate':
                return (
                    <Operate
                        key={index}
                        question={question}
                        index={index}
                        data={data}
                        getData={getData}
                        inScored={inScored}
                    />
                )
            default:
                return null
        }
    }
    let tempIndex = 0
    return (
        <div className="answer-statistics">
            {questions.map((item, index) => (
                <div key={index} className="question">
                    <div className="question-type">
                        <div className="question-name">{item.name}</div>
                        <div className="question-score">
                            <span className="get-score">得分</span>
                            <span className={inScored && item.score === '待评分' ? 'active' : 'score'}>
                                {!inScored && (item.score === '待评分' || (item.score === '0' && item.type !== 'Text'))
                                    ? '-'
                                    : item.score}
                            </span>
                        </div>
                    </div>

                    {item.allQuestion?.map((q) => RenderQuestion(q, ++tempIndex))}
                </div>
            ))}
        </div>
    )
}

export default AnswerSituation
