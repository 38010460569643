import React from 'react'
import produce from 'immer'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Pagination } from 'antd'
import { difference, union } from 'lodash'
import Loading from '../../../../components/loading'
import ListItemDetail from './ListItem'

const List = ({ loading, data, getData, selects, setSelects, handleDelete }) => {
    const handleSelect = (e, id, isSelect) => {
        e.stopPropagation()
        const newSelects = produce(selects, (draft) => {
            if (isSelect) {
                const idx = selects.indexOf(id)
                draft.splice(idx, 1)
            } else {
                draft.push(id)
            }
        })
        setSelects(newSelects)
    }
    const handleSelectAll = () => {
        const ss = data.content?.map((item) => item.id) || []
        const unselects = difference(ss, selects)
        if (unselects.length === 0) {
            // 当前页已全选
            setSelects(difference(selects, ss))
        } else {
            setSelects(union(selects, ss))
        }
    }
    return (
        <div className="page-list-container">
            <div className="action-bar">
                <Button onClick={handleSelectAll}>全选</Button>
                <Button disabled={selects.length === 0} onClick={handleDelete}>
                    删除选中项
                </Button>
                <div className="selects-num">已选中 {selects.length || 0} 项</div>
                <div className="results-num">共找到 {data.totalElements || 0} 张试卷</div>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="page-list">
                    {data.content?.map((item, index) => {
                        const selected = selects.indexOf(item.id) !== -1

                        return (
                            <Link
                                className="list-item"
                                key={item.id}
                                to={`/kw/paper/detail/${item.id}`}
                                target="_blank"
                            >
                                <Checkbox checked={selected} onClick={(e) => handleSelect(e, item.id, selected)} />
                                <div className="item-no">{data.number * 10 + index + 1}</div>
                                <ListItemDetail data={item} />
                            </Link>
                        )
                    })}
                    <Pagination
                        defaultCurrent={1}
                        total={data.totalElements}
                        current={data.number + 1}
                        hideOnSinglePage={true}
                        onChange={getData}
                        showSizeChanger={false}
                    />
                </div>
            )}
        </div>
    )
}

export default List
