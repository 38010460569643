import React, { useState, useEffect, useCallback, useMemo } from 'react'
import produce from 'immer'
import saveAs from 'file-saver'
import moment from 'moment'

import Confirm from '../../../../components/modal/Confirm'
import Button from '../../../../components/button'
import { getBaseUrl } from '../../../../common/urlHelp'
import { logout } from '../../../../services/UserRequest'
import { getQuestionResult } from '../configs'
import { getTime } from '../../../../common/utils'

import icon1 from '../../../../assets/report-1.png'
import icon2 from '../../../../assets/report-2.png'
import icon3 from '../../../../assets/report-3.png'
import logo from '../../../../assets/logo-download.png'
import './SubmitSuccessWithReport.styl'

const ReportView = ({ paper, catalog, questions }) => {
    const data = useMemo(() => {
        const newCatalog = produce(catalog, (draft) => {
            draft.forEach((c) => {
                const needScore = c.name !== '简答题' && c.name !== '情景分析题'
                c.list.forEach((item, index) => {
                    const question = questions.find((q) => q.id === item.id) || {}
                    const result = needScore ? getQuestionResult(question) : {}
                    item.correct = result.correct
                    item.userScore = result.score ?? 0
                })
                c.totalScore = needScore ? Math.round(c.list.reduce((p, c) => p + c.score, 0)) : 0
                c.userScore = needScore ? Math.round(c.list.reduce((p, c) => p + c.userScore, 0)) : 0
                c.questionLength = c.list.length
                c.correctLength = c.list.reduce((p, cc) => p + (cc.correct ? 1 : 0), 0)
            })
        })
        const totalScore = Math.round(newCatalog.reduce((p, c) => p + c.totalScore, 0))
        const userScore = Math.round(newCatalog.reduce((p, c) => p + c.userScore, 0))
        return { catalog: newCatalog, totalScore, userScore }
    }, [catalog, questions])
    let renderIndex = 0
    return (
        <div className="report-view">
            <div className="report-logo">
                <img src={logo} className="logo" />
                <span className="separator">|</span>
                <span className="logo-name">1 + X 考试系统</span>
            </div>
            <div className="report-name">{paper.examName}</div>
            <div className="section-title">
                <img src={icon1} />
                <span>试卷说明</span>
            </div>
            <div className="paper-description">{paper.description}</div>
            <div className="section-title">
                <img src={icon2} />
                <span>得分情况</span>
            </div>
            <table className="scores-detail">
                <tbody>
                    <tr>
                        <td />
                        <td>
                            <span>正确题目</span>
                            <span> / 全部题目</span>
                        </td>
                        <td>
                            <span>得分</span>
                            <span> / 满分</span>
                        </td>
                    </tr>
                    <tr>
                        <td>总计</td>
                        <td>-</td>
                        <td>
                            <span className="my-score">{data.userScore}</span>
                            <span> / {data.totalScore}</span>
                        </td>
                    </tr>
                    {data.catalog.map((item) => (
                        <tr key={item.name}>
                            <td>{`${item.no}、${item.name}`}</td>
                            {['实操题', '简答题', '情景分析题'].indexOf(item.name) !== -1 ? (
                                <td>-</td>
                            ) : (
                                <td>
                                    <span>{item.correctLength}</span>
                                    <span> / {item.questionLength}</span>
                                </td>
                            )}
                            {['简答题', '情景分析题'].indexOf(item.name) !== -1 ? (
                                <td>-</td>
                            ) : (
                                <td>
                                    <span>{item.userScore}</span>
                                    <span> / {item.totalScore}</span>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="section-title">
                <img src={icon3} />
                <span>答题卡</span>
            </div>
            <div className="result-answer-sheet">
                <div className="legends">
                    <div>正确</div>
                    <div>错误</div>
                    <div>无反馈</div>
                </div>
                {data.catalog.map((item) => (
                    <div key={item.name} className="answer-section">
                        <div className="section-title">{`${item.no}、${item.name}`}</div>
                        <div className="answer-list">
                            {item.list.map((question) => {
                                const status = question.correct ? 'correct' : question.correct === false ? 'wrong' : ''
                                return (
                                    <div key={question.id} className={`question-result ${status}`}>
                                        {++renderIndex}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const CountdownView = ({ paper, catalog, questions }) => {
    useEffect(() => {
        if (window.examInterval) clearInterval(window.examInterval)
        window.examInterval = null
    }, [])
    const [time, setTime] = useState(120)
    const endCountdown = useCallback(() => {
        // 退出全屏
        if (document.exitFullscreen && document.fullscreenElement) document.exitFullscreen()
        else if (document.mozCancelFullScreen && document.fullscreenElement) document.mozCancelFullScreen()
        else if (document.webkitExitFullscreen && document.fullscreenElement) document.webkitExitFullscreen()
        logout().finally(() => (location.href = `${getBaseUrl()}/login`))
    }, [])
    const countdown = useCallback(() => {
        if (time > 0) {
            setTime(time - 1)
        } else {
            endCountdown()
        }
    }, [time, endCountdown])
    useEffect(() => {
        const interval = setInterval(countdown, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [countdown])
    const onDownload = async () => {
        const html2canvas = await import('html2canvas').then((module) => module.default)
        const node = document.getElementsByClassName('report-view')[0]
        const canvas = await html2canvas(node, { useCORS: true, logging: false })
        const img = canvas.toDataURL('image/png')
        saveAs(img, moment(getTime()).format('YYYY-MM-DD_HH-mm-ss.png'))
    }
    return (
        <div>
            <div className="exam-result-header">
                <i className="iconfont icon-success" />
                <span className="result-text">交卷成功！</span>
                <div className="result-hint">120 秒后自动退出，退出后不保留成绩</div>
            </div>
            <ReportView time={time} paper={paper} catalog={catalog} questions={questions} />
            <div className="footer-buttons">
                <Button warn onClick={endCountdown}>{`退出（ ${time}s ）`}</Button>
                <Button primary onClick={onDownload}>
                    下载成绩
                </Button>
            </div>
        </div>
    )
}

export default (paper, catalog, questions) => {
    return Confirm({
        className: 'submit-success-report-modal',
        footer: null,
        width: 320,
        closable: false,
        keyboard: false,
        centered: true,
        content: <CountdownView paper={paper} catalog={catalog} questions={questions} />,
    })
}
