import AxiosRequest from './AxiosRequest'
const url = 'questions'

export const getQuestion = (id) => {
    return AxiosRequest.get(`${url}/${id}`)
}

export const getQuestions = (params = '') => {
    return AxiosRequest.get(`${url}/search?${params}&sort=_id`)
}

export const getQuestionsByIds = (ids = []) => {
    if (ids.length === 0) return Promise.resolve([])
    return AxiosRequest.get(`${url}?ids=${ids.join('&ids=')}`)
}

// type: MultipleChoice, MultipleSelection, TrueOrFalse, Sorting, Text, Analyze
export const putQuestion = (type, data) => {
    return AxiosRequest.put(`${url}/type/${type}`, data)
}

export const postQuestionByExcel = (fileName) => {
    return AxiosRequest.post(`${url}/excel`, { fileName })
}

export const deleteQuestions = (ids = []) => {
    return AxiosRequest.delete(`${url}?ids=${ids.join('&ids=')}`)
}
