import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { Button, Breadcrumb, Form, Input } from 'antd'

import message from '../../../../components/message'
import Loading from '../../../../components/loading'
import { getQuestion, putQuestion } from '../../../../services/QuestionRequest'
import { dataInput, dataOutput } from './dataHandle'

import MultipleChoice from './children/MultipleChoice'
import MultipleSelection from './children/MultipleSelection'
import Sorting from './children/Sorting'
import TrueOrFalse from './children/TrueOrFalse'
import Text from './children/Text'
import Analyze from './children/Analyze'
import Operate from './children/Operate'

const QuestionEdit = () => {
    const [form] = Form.useForm()
    const history = useHistory()
    const { type, id } = useParams()
    const [loading, setLoading] = useState(true)
    const [initialValues, setInitialValues] = useState({})
    useEffect(() => {
        if (type && !id) {
            setInitialValues(dataInput({ type }))
            setLoading(false)
        } else {
            getQuestion(id).then((data) => {
                setInitialValues(dataInput({ originData: data }))
                setLoading(false)
            })
        }
    }, [id, type])
    if (loading) return <Loading />
    const onFinish = (values) => {
        putQuestion(type, dataOutput(values))
            .then(() => {
                message.success(id ? '操作成功！' : '创建成功')
                history.push(id ? `/kw/question/${type}/detail/${id}` : '/kw/question')
            })
            .catch((err) => {
                message.error(err?.response?.message || '操作失败，请稍后重试！')
            })
    }
    return (
        <div className="question-edit-page">
            <Form
                form={form}
                name="create-form"
                className="ant-create-form"
                onFinish={onFinish}
                requiredMark={false}
                initialValues={initialValues}
            >
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <div className="page-header">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item key="/kw/question">
                            <Link to="/kw/question">题目管理</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{!id ? '题目创建' : '题目编辑'}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Link to={id ? `/kw/question/${type}/detail/${id}` : '/kw/question'}>
                        <Button>取消</Button>
                    </Link>
                    <Button htmlType="submit">保存</Button>
                </div>
                <div className="page-content-container">
                    {renderRoutes(
                        [
                            { path: '/kw/question/MultipleChoice/:action', component: MultipleChoice },
                            { path: '/kw/question/MultipleSelection/:action', component: MultipleSelection },
                            { path: '/kw/question/TrueOrFalse/:action', component: TrueOrFalse },
                            { path: '/kw/question/Sorting/:action', component: Sorting },
                            { path: '/kw/question/Text/:action', component: Text },
                            { path: '/kw/question/Analyze/:action', component: Analyze },
                            { path: '/kw/question/Operate/:action', component: Operate },
                        ],
                        { form }
                    )}
                </div>
            </Form>
        </div>
    )
}

export default QuestionEdit
