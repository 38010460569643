import React, { useState, useRef } from 'react'
import produce from 'immer'
import { find, findIndex } from 'lodash'

import MultipleChoice from './MultipleChoice'
import MultipleSelection from './MultipleSelection'
import Sorting from './Sorting'
import TrueOrFalse from './TrueOrFalse'
import Text from './Text'

import { typeNames } from '../configs'

import 'braft-editor/dist/output.css'
import './common.styl'

const Analyze = ({ stepData, editAnswer, onChange, preview, staticPosition, answerVisible }) => {
    const [customAnswer, setCustomAnswer] = useState(null)
    const caseRef = useRef(null)
    const listRef = useRef(null)

    // 存的是当前的全部小题答案，在PageMain中做批量保存
    const answer = editAnswer || customAnswer || stepData?.baseQuestions?.map((q) => q.userAnswer) || []

    const onCustomChange = (index, questionAnswer) => {
        const newAnswer = produce(answer, (draft) => {
            const prevIndex = findIndex(draft, (as) => as.questionIndex === index)
            if (prevIndex === -1) {
                draft.push(questionAnswer)
            } else {
                draft[prevIndex] = questionAnswer
            }
        })
        if (onChange) {
            onChange(newAnswer)
        } else {
            setCustomAnswer(newAnswer)
        }
    }

    const backToTop = () => {
        caseRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const backToQuestion = (index) => {
        const question = listRef?.current?.getElementsByClassName('exam-question')?.[index]
        question?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    return (
        <div className={'exam-question question-analyze' + (staticPosition ? ' static' : '')}>
            <div className="question-header">
                <div className="question-no">{(stepData.index || 0) + 1}</div>
                {preview && <div className="question-type">【{typeNames[stepData.type]}】</div>}
                <div className="question-title">
                    <span className="question-score">{`${stepData.score}分`}</span>
                    <span className="question-quick-locating">
                        <div className="locating-tag top-tag" onClick={backToTop}>
                            背景信息
                        </div>
                        {stepData.baseQuestions?.map((question, index) => {
                            return (
                                <div
                                    className={'locating-tag' + (question.completed ? ' completed' : '')}
                                    key={`tag-${index}`}
                                    onClick={() => backToQuestion(index)}
                                >
                                    ({index + 1})
                                </div>
                            )
                        })}
                    </span>
                </div>
            </div>
            <div className="analyze-scroll-wrap scrollbar">
                <div
                    ref={caseRef}
                    className="question-case braft-output-content"
                    dangerouslySetInnerHTML={{ __html: stepData.caseContent }}
                />
                <div className="question-list" ref={listRef}>
                    {stepData.baseQuestions?.map((question, index) => {
                        const type = question.type
                        const questionAnswer = find(answer, (as) => as?.questionIndex === index)
                        const options = {
                            key: index,
                            stepData: question,
                            editAnswer: questionAnswer,
                            onChange: (_answer) => onCustomChange(index, _answer),
                            preview,
                            answerVisible,
                        }
                        if (preview) {
                            delete options.editAnswer
                            delete options.onChange
                        }
                        switch (type) {
                            case 'MultipleChoice':
                                return <MultipleChoice {...options} />
                            case 'MultipleSelection':
                                return <MultipleSelection {...options} />
                            case 'Sorting':
                                return <Sorting {...options} />
                            case 'TrueOrFalse':
                                return <TrueOrFalse {...options} />
                            case 'Text':
                                return <Text {...options} />
                            default:
                                return null
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

export default Analyze
