import React from 'react'
import { isNumber } from 'lodash'

import Type from '../formItem/Type'
import InfoGroup from '../formItem/InfoGroup'
import Upload from '../formItem/Upload'
import Tags from '../formItem/Tags'
import Content from '../formItem/Content'
import Answer from '../formItem/Answer'
import Explanation from '../formItem/Explanation'

export const Text = ({ childrenIndex }) => {
    const prevPath = isNumber(childrenIndex) ? [childrenIndex] : []
    return (
        <>
            <Content prevPath={prevPath} />
            <Upload prevPath={prevPath} />
            <Answer prevPath={prevPath} />
            <Explanation prevPath={prevPath} />
        </>
    )
}

export default () => (
    <>
        <Type type="Text" />
        <InfoGroup />
        <Tags />
        <Text />
    </>
)
