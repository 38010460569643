//cSpell:word Zhuan
import React, { useState } from 'react'
import produce from 'immer'
import { Form, Row, Col, Checkbox, Space, DatePicker } from 'antd'
import Confirm from '../components/Confirm'
import Input from '../components/Input'
import SearchPanel from '../components/SearchPanel'
import Button from '../components/Button'
import StringDateWrap from '../../components/StringDatePicker'

export const BaseInfo = ({ hasTip }) => (
    <div className="panel info-panel">
        <div className="panel-title">个人信息</div>
        <div className="panel-content">
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name={['data', '姓名']} label="姓名">
                        <Input readOnly bordered={false} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="identity" label="公民身份证号码">
                        <Input readOnly bordered={false} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name={['data', '性别']} label="性别">
                        <Input readOnly bordered={false} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={['data', '户籍所属省市']} label="户籍所属省市">
                        <Input readOnly bordered={false} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name={['data', '户籍户别']} label="户籍户别">
                <Input readOnly bordered={false} />
            </Form.Item>
            {hasTip && (
                <div className="form-tip">
                    提示：若以上户籍户别信息与您申报人员的实际情况不一致，请至区县就业服务机构、社保中心办理相关调整手续。
                </div>
            )}
        </div>
    </div>
)

const SheBaoZhuanRu = ({ data, onNewRecordSave }) => {
    const [user, setUser] = useState(null)
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()

    const onSearchConfirm = (result) => {
        setUser(null)
        form.resetFields()
        if (!result) return
        const newUser = {
            identity: result.identity,
            data: {
                ...result.data,
                月缴费基数: '-',
                是否选择人才引进居住证参保: false,
                是否勾选下方承诺: false,
            },
        }
        setUser(newUser)
        form.setFieldsValue(newUser)
    }

    const onResetForm = () => {
        setUser(null)
        form.resetFields()
        searchForm.resetFields()
    }

    const onCalculate = () => {
        const prevData = form.getFieldValue('data')
        const newData = produce(prevData, (draft) => {
            const number = Number(draft.月平均工资性收入)
            if (number) draft.月平均工资性收入 = `${number}`
            draft.月缴费基数 = `${number || '-'}`
        })
        form.setFieldsValue({ data: newData })
    }

    const onCheckboxClick = (e) => {
        const checked = e.target.checked
        if (!checked) {
            const prevData = form.getFieldValue('data')
            const newData = produce(prevData, (draft) => {
                draft.居住证号码 = ''
            })
            form.setFieldsValue({ data: newData })
        }
    }

    const onFinish = (values) => {
        Confirm({
            title: '考试系统提示',
            content: '提交后，不可修改或删除信息。是否提交？',
            onOk: () => {
                const obj = produce(values, (draft) => {})
                onNewRecordSave({
                    type: '社保转入',
                    operateObject: [obj],
                }).then(onResetForm)
            },
        })
    }

    const renderChildren = () => {
        if (!user) return null
        return (
            <Form className="data-form sub-page-s-b-z-r" form={form} onFinish={onFinish} initialValues={user}>
                <BaseInfo hasTip />
                <div className="panel extra-info-panel">
                    <div className="panel-title">申报信息</div>
                    <div className="panel-content">
                        <div className="bordered-panel">
                            <div className="sub-module">
                                <div className="module-name">外来从业人员信息录入</div>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['data', '是否选择人才引进居住证参保']}
                                            valuePropName="checked"
                                        >
                                            <Checkbox onClick={onCheckboxClick}>选择人才引进居住证参保</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) =>
                                                prevValues.data['是否选择人才引进居住证参保'] !==
                                                curValues.data['是否选择人才引进居住证参保']
                                            }
                                        >
                                            {() => {
                                                const disabled = !form.getFieldValue([
                                                    'data',
                                                    '是否选择人才引进居住证参保',
                                                ])
                                                return (
                                                    <Form.Item label="居住证号码" name={['data', '居住证号码']}>
                                                        <Input maxLength={10} disabled={disabled} />
                                                    </Form.Item>
                                                )
                                            }}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div className="module-separator" />
                            <div className="sub-module">
                                <div className="module-name">申报信息</div>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="个人序号" name={['data', '个人序号']}>
                                            <Input readOnly bordered={false} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="缴费起始年月" name={['data', '缴费起始年月']}>
                                            <StringDateWrap component={<DatePicker.MonthPicker />} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="月平均工资性收入" name={['data', '月平均工资性收入']}>
                                            <Space>
                                                <Form.Item noStyle>
                                                    <Input maxLength={10} />
                                                </Form.Item>
                                                <div className="form-tip">元</div>
                                                <Button className="theme" onClick={onCalculate}>
                                                    确定
                                                </Button>
                                            </Space>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="月缴费基数" name={['data', '月缴费基数']}>
                                            <Input readOnly bordered={false} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Form.Item name={['data', '是否勾选下方承诺']} valuePropName="checked">
                            <Checkbox>
                                本单位承诺已经完成用工备案登记，并且以上所写内容全部属实，如有不实以及由此产生的后果概由本单位负责
                            </Checkbox>
                        </Form.Item>
                    </div>
                </div>
                <div className="bottom-action">
                    <Button className="commit-btn" type="primary" htmlType="submit">
                        提交
                    </Button>
                    <Button className="cancel-btn" onClick={onResetForm}>
                        取消
                    </Button>
                </div>
            </Form>
        )
    }
    return (
        <div className="sb-detail-sub-page">
            <SearchPanel title="转入人员" form={searchForm} data={data} onConfirm={onSearchConfirm} />
            {renderChildren()}
        </div>
    )
}

export default SheBaoZhuanRu
