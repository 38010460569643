import produce from 'immer'

export const getTime = () => {
    return new Date().valueOf() + window.aheadTime
}

export const formatDate = (dateTime, needTime, separator, autoHideYear) => {
    let date = new Date(dateTime)
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d = date.getDate()
    d = d < 10 ? '0' + d : d
    let autoYear = autoHideYear ? '' : y + (separator || '年')
    let base = autoYear + m + (separator || '月') + d + (separator ? '' : '日')
    if (!needTime) return base
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    minute = minute < 10 ? '0' + minute : minute
    return base + '  ' + h + ':' + minute
}

export const removeEmptyKeys = (obj) => {
    return produce(obj, (draft) => {
        for (const key in draft) {
            // 去除对象内多余的空值key
            if (!draft[key]) {
                delete draft[key]
            }
        }
    })
}
