import React from 'react'
import { useParams, Link } from 'react-router-dom'
import Button from '../../../components/button'
import { levelNames, subjects } from '../../kw/exam/list/configs'
import { getBaseUrl } from '../../../common/urlHelp'
import './BaseInfo.styl'

const BaseInfo = ({ loginData }) => {
    const { id } = useParams()
    const user = loginData?.user || {}
    const exam = loginData?.exam || {}
    return (
        <div className="login-info">
            <div className="confirm-title">考生信息确认</div>
            <div className="confirm-content">
                <div className="blue-border" />
                <span className="second-title">基本信息</span>
            </div>
            <div className="information">
                <div className="line">
                    <span className="line-name">考生姓名:</span>
                    <span className="spacing">{user.nickName}</span>
                </div>
                <div className="line">
                    <span className="line-name">准考证号:</span>
                    <span className="spacing">{user.loginName}</span>
                </div>
            </div>
            <div className="confirm-content">
                <div className="blue-border" />
                <span className="second-title">考试信息</span>
            </div>
            <div className="information">
                <div className="line">
                    <span className="line-name">考试名称:</span>
                    <span className="spacing">{exam.name}</span>
                </div>
                <div className="line">
                    <span className="line-name">证书:</span>
                    <span className="spacing">人力资源共享服务职业技能等级证书</span>
                </div>
                <div className="line">
                    <span className="line-name">等级:</span>
                    <span className="spacing">{levelNames[exam.level]}</span>
                </div>
                <div className="line">
                    <span className="line-name">科目:</span>
                    <span className="spacing">{subjects[exam.subject]}</span>
                </div>
            </div>
            <Link to={`${getBaseUrl()}/${id}/info/instruction`} replace>
                <Button primary>下一步</Button>
            </Link>
        </div>
    )
}

export default BaseInfo
