import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'antd'

import { postExamsPaper } from '../../../../../services/PaperRequest'
import PaperChooser from './PaperChooser'

const Header = ({ paper, setPaper }) => {
    const [visible, setVisible] = useState(false)
    const { id } = useParams()

    const showModal = () => {
        setVisible(true)
    }
    return (
        <div className="exam-student-header" style={{ width: '100%', height: '30px' }}>
            <div style={{ float: 'left' }}>
                <span>关联试卷：</span>
                <span>
                    <a
                        href={`/kw/paper/detail/${paper.originalId || paper.id}`}
                        target="_blank"
                        rel="noreferrer"
                        disabled={!paper.name}
                    >
                        {paper?.name || '无'}
                    </a>
                </span>
            </div>
            <Button type="primary" onClick={showModal} style={{ float: 'right', marginRight: '15px', width: '130px' }}>
                {paper.name ? '重新选择试卷' : '选择试卷'}
            </Button>
            <PaperChooser
                visible={visible}
                setVisible={setVisible}
                paper={paper}
                setPaper={setPaper}
                examId={id}
                handleChoose={postExamsPaper}
            />
        </div>
    )
}

export default Header
