import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { getCurrentTime } from './services/ExamsRequest'
import { initLogger } from './common/arms'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import './resources/iconfont/iconfont.css'
moment.locale('zh-cn')

window.aheadTime = 0
window.getCurrentTime = function () {
    return getCurrentTime()
        .then((time) => {
            const serverTime = time || new Date().valueOf()
            const localTime = new Date().valueOf()
            let aheadTime = serverTime - localTime
            if (Math.abs(aheadTime) < 1000) aheadTime = 0
            window.aheadTime = aheadTime
            return aheadTime
        })
        .catch(() => {
            window.aheadTime = 0
            return 0
        })
}
window.getCurrentTime()
window.onfocus = function () {
    // 用户打开或回到页面
    window.getCurrentTime?.()
}

initLogger(process.env.REACT_APP_LE)

ReactDOM.render(
    <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false} input={{ autoComplete: 'off' }}>
        <App />
    </ConfigProvider>,
    document.getElementById('app')
)
