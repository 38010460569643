import React from 'react'
import { Form } from 'antd'
import { isString } from 'lodash'
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

export const uploadFn = (param) => {
    const serverURL = '/api/files'
    const xhr = new XMLHttpRequest()
    const fd = new FormData()

    const successFn = (response) => {
        // 上传成功后调用param.success并传入上传后的文件地址
        param.success({
            url: `${xhr.responseText}`,
        })
    }

    const progressFn = (event) => {
        // 上传进度发生变化时调用param.progress
        param.progress((event.loaded / event.total) * 100)
    }

    const errorFn = (response) => {
        // 上传发生错误时调用param.error
        param.error({
            msg: 'unable to upload.',
        })
    }

    xhr.upload.addEventListener('progress', progressFn, false)
    xhr.addEventListener('load', successFn, false)
    xhr.addEventListener('error', errorFn, false)
    xhr.addEventListener('abort', errorFn, false)

    fd.append('file', param.file)
    xhr.open('POST', serverURL, true)
    xhr.send(fd)
}
export const validateFn = (file) => {
    // 不允许添加尺寸大于300KB的文件
    return file.size < 1024 * 300
}

const Case = ({ name = 'caseContent', label = '案例', placeholder = '请输入案例内容' }) => {
    const controls = [
        'undo',
        'redo',
        'separator',
        'bold',
        'italic',
        'underline',
        'strike-through',
        'separator',
        'superscript',
        'subscript',
        'remove-styles',
        'emoji',
        'separator',
        'text-indent',
        'text-align',
        'separator',
        'headings',
        'list-ul',
        'list-ol',
        'blockquote',
        'code',
        'separator',
        'link',
        'separator',
        'hr',
        'separator',
        'media',
        'separator',
        'clear',
    ]
    return (
        <Form.Item
            name={name}
            label={label}
            validateTrigger="onBlur"
            getValueProps={(value) => {
                if (isString(value) || !value) return { value: BraftEditor.createEditorState(value) }
                return { value }
            }}
            rules={[
                {
                    required: true,
                    validator: (_, value) => {
                        if (isString(value) ? !value.trim() : value?.isEmpty()) {
                            return Promise.reject(new Error('请输入正文内容'))
                        }
                        return Promise.resolve()
                    },
                    message: '内容不能为空',
                },
            ]}
        >
            <BraftEditor
                placeholder={placeholder}
                style={{ border: '1px solid #d9d9d9' }}
                controls={controls}
                media={{ uploadFn, validateFn, accepts: { image: 'image/png,image/jpeg' } }}
            />
        </Form.Item>
    )
}

export default Case
