import React, { useContext } from 'react'
import { Button, Row, Col } from 'antd'
import { ExamContext, EDIT_ANSWER } from './Context'
import Confirm from '../../../components/modal/Confirm'
import { logout } from '../../../services/UserRequest'
import { getBaseUrl } from '../../../common/urlHelp'

import paperSrc from '../../../assets/paper.png'
import userSrc from '../../../assets/user.png'
import './PageHeader.styl'

const PageHeader = () => {
    const { state, dispatch } = useContext(ExamContext)
    const { user, exam } = state
    const onLogout = () => {
        Confirm({
            title: '退出确认',
            centered: true,
            content: '系统将会记录您的退出操作\n确认退出登录？',
            onOk: () => {
                logout().finally(() => {
                    dispatch({ type: EDIT_ANSWER, payload: { editAnswer: null } })
                    setTimeout(() => {
                        location.href = `${getBaseUrl()}/login`
                    }, 0)
                })
            },
        })
    }
    return (
        <header className="exam-page-header">
            <Row wrap={false} style={{ width: '100%' }} align="middle">
                <Col flex="auto">
                    <div className="exam-name">
                        <img className="icon-paper" src={paperSrc} />
                        <span>{exam.name}</span>
                    </div>
                </Col>
                <Col flex="none">
                    <div className="user">
                        <div className="icon-container">
                            <img className="icon-user" src={userSrc} />
                        </div>
                        <div className="user-info">
                            <div className="username">{user.nickName}</div>
                            <div className="user-ticket">{`准考证号：${user.loginName}`}</div>
                        </div>
                        <Button
                            className="logout-btn"
                            onClick={onLogout}
                            type="link"
                            icon={<i className="iconfont icon-quit" />}
                        >
                            退出
                        </Button>
                    </div>
                </Col>
            </Row>
        </header>
    )
}

export default PageHeader
