import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Pagination } from 'antd'
import { getCandidates, deleteCandidate } from '../../../../../services/ExamCandidateRequest'
import message from '../../../../../components/message'
import Confirm from '../../../../../components/modal/Confirm'
import Header from './Header'
import Filter from './Filter'
import List from './List'

const Students = ({ exam }) => {
    const [filters, setFilters] = useState({})
    const [data, setData] = useState({})
    const [selects, setSelects] = useState([])
    const { id } = useParams()
    const getData = useCallback(
        async (page = 1) => {
            let params = `page=${page}`
            Object.keys(filters).forEach((k) => {
                params = `${params}&${k}=${filters[k]}`
            })
            const res = await getCandidates(`${id}`, `${params}`)
            await new Promise((resolve) => setTimeout(resolve, 200))
            setData(res)
        },
        [id, filters]
    )
    useEffect(() => {
        getData()
    }, [getData])
    const onFilter = (filters) => {
        setFilters(filters)
        setSelects([])
    }
    const handleDelete = () => {
        const deleteFunc = () =>
            deleteCandidate(id, selects)
                .then(() => {
                    message.success('操作成功！')
                    setSelects([])
                    getData()
                })
                .catch(() => message.error('操作失败，请稍后重试！'))
        Confirm({
            title: '提示',
            content: '确认删除所有已选中的项么？',
            okText: '确认',
            cancelText: '取消',
            onOk: deleteFunc,
        })
    }
    return (
        <div className="exam-student-list">
            <Header id={id} getData={getData} />
            <Filter ids={id} onFilter={onFilter} examName={exam.name} />
            {data.content && (
                <List
                    data={data}
                    getData={getData}
                    selects={selects}
                    setSelects={setSelects}
                    handleDelete={handleDelete}
                    detailId={id}
                    exam={exam}
                />
            )}
            <Pagination
                defaultCurrent={1}
                total={data.totalElements}
                current={data.number + 1}
                hideOnSinglePage={true}
                onChange={getData}
                showSizeChanger={false}
            />
        </div>
    )
}

export default Students
