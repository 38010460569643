import React, { useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Tabs } from 'antd'
import Button from '../../../components/button'
import Countdown from './Countdown'
import { getBaseUrl } from '../../../common/urlHelp'
import './Instruction.styl'
const { TabPane } = Tabs

const Instruction = ({ loginData }) => {
    const [disabled, setDisabled] = useState(true)
    const history = useHistory()
    const { id } = useParams()
    const nextStep = () => {
        const el = document.documentElement || {}
        if (el.requestFullscreen) el.requestFullscreen()
        else if (el.mozRequestFullScreen) el.mozRequestFullScreen()
        else if (el.webkitRequestFullscreen) el.webkitRequestFullscreen()
        else if (el.msRequestFullscreen) el.msRequestFullscreen()
        history.replace(`${getBaseUrl()}/${loginData.exam.id}/step/1`)
    }
    const exam = loginData?.exam || {}
    const paper = loginData?.paper || {}
    return (
        <div className="login-instruction">
            <div className="instruction-title">
                <Link to={`${getBaseUrl()}/${id}/info/base`} replace>
                    <i className="iconfont icon-back" />
                </Link>
                <span className="confirm-title">请阅读《考试须知》《试卷说明》</span>
            </div>
            <div className="instruction-content">
                <Tabs type="card">
                    <TabPane tab="考试须知" key="1" className="scrollbar">
                        <div className="card-content">{exam.notes}</div>
                    </TabPane>
                    <TabPane tab="试卷说明" key="2" className="scrollbar">
                        <div className="card-content">{paper.description}</div>
                    </TabPane>
                </Tabs>
            </div>
            <Button primary onClick={nextStep} disabled={disabled}>
                开始答题
                <Countdown start={exam.startDate} onEnd={() => setDisabled(false)} />
            </Button>
            <p className="instruction-bottom">请确保您已认真阅读本次《考试须知》、《试卷说明》！</p>
            <p className="instruction-bottom content-bottom">阅读后，点击开始答题按钮将会进入答题界面！</p>
        </div>
    )
}

export default Instruction
