//cSpell:word Feng Liang
import React, { useState } from 'react'
import produce from 'immer'
import { Button, Modal, Form, Input, Table } from 'antd'
import img from '../../../../../../assets/提示.png'

import messages from '../../components/Message'
import Confirm from '../../components/Confirm'
import './QiFengModal.styl'
import './PiLiangKaiHuModal.styl'

const DownloadModal = ({ data, selectChecked, onNewRecordSave, prevUrl, history, setVisible }) => {
    const [form] = Form.useForm()
    const [newData, setNewData] = useState([])
    const downloadModal = () => {
        const onFinish = () => {
            const values = form.getFieldsValue(true)
            const prevDataList = values.presetData.filter((item) => item.data.姓名 !== '' && item.identity !== '')
            const dataObj = produce(prevDataList, (draft) => {
                prevDataList.forEach((item, index) => {
                    const name = item.data?.姓名 || ''
                    const wages = item.data?.工资 || ''
                    item.data.姓名 = name.trim()
                    item.identity = item.identity.trim()
                    item.data.户籍 = item.data.户籍.trim()
                    item.data.账户类型 = item.data.账户类型.trim()
                    item.data.公积金账号 = item.data.公积金账号.trim()
                    item.data.补充比例 = item.data.补充比例.trim()
                    item.data.工资 = wages.trim()
                })
            })
            setNewData(dataObj)
            modal.destroy()
            form.resetFields()
        }
        const modal = Confirm({
            className: 'confirm-QiFeng-user',
            width: 900,
            title: '导入数据编辑',
            centered: true,
            content: (
                <div className="confirm-status">
                    <p className="confirm-status-tips">
                        <img src={img} className="icon-img" />
                        <span className="tips-content">
                            以下为实操题系统模拟的excel数据列表，请仔细核对其中的数据，适当进行修改和补充，然后确认导入。
                        </span>
                    </p>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        initialValues={data.question}
                        className="confirm-user-form"
                        validateTrigger="onSubmit"
                    >
                        <div className="user-title">
                            <div style={{ width: '100px' }}>姓名</div>
                            <div style={{ width: '240px' }}>身份证号</div>
                            <div style={{ width: '80px' }}>户籍</div>
                            <div style={{ width: '180px' }}>账户类型</div>
                            <div style={{ width: '100px' }}>公积金账号</div>
                            <div style={{ width: '100px' }}>补充比例</div>
                            <div style={{ width: '100px' }}>工资</div>
                        </div>
                        <Form.List name="presetData">
                            {(fields) =>
                                fields.map((field) => (
                                    <div className="user-row" key={field.name}>
                                        <Form.Item
                                            required={false}
                                            name={[field.name, 'data', '姓名']}
                                            style={{ width: '100px' }}
                                            rules={[
                                                ({ getFieldsValue }) => ({
                                                    validator: async (_, value) => {
                                                        const prev = getFieldsValue(true)
                                                        if (
                                                            prev.presetData[field.name].data.姓名 === '' &&
                                                            prev.presetData[field.name].identity === '' &&
                                                            prev.presetData[field.name].data.户籍 === '' &&
                                                            prev.presetData[field.name].data.账户类型 === '' &&
                                                            prev.presetData[field.name].data.公积金账号 === '' &&
                                                            prev.presetData[field.name].data.补充比例 === '' &&
                                                            !prev.presetData[field.name].data.工资
                                                        ) {
                                                            return Promise.resolve()
                                                        } else if (prev.presetData[field.name].data.姓名 === '') {
                                                            return Promise.reject('此项不得为空')
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input maxLength={10} />
                                        </Form.Item>
                                        <Form.Item
                                            required={false}
                                            rules={[
                                                ({ getFieldsValue }) => ({
                                                    validator: async (_, value) => {
                                                        const prev = getFieldsValue(true)
                                                        if (
                                                            prev.presetData[field.name].data.姓名 === '' &&
                                                            prev.presetData[field.name].identity === '' &&
                                                            prev.presetData[field.name].data.户籍 === '' &&
                                                            prev.presetData[field.name].data.账户类型 === '' &&
                                                            prev.presetData[field.name].data.公积金账号 === '' &&
                                                            prev.presetData[field.name].data.补充比例 === '' &&
                                                            !prev.presetData[field.name].data.工资
                                                        ) {
                                                            return Promise.resolve()
                                                        } else if (prev.presetData[field.name].identity === '') {
                                                            return Promise.reject('此项不得为空')
                                                        }
                                                    },
                                                }),
                                            ]}
                                            style={{ width: '240px' }}
                                            name={[field.name, 'identity']}
                                        >
                                            <Input maxLength={30} />
                                        </Form.Item>
                                        <Form.Item style={{ width: '80px' }} name={[field.name, 'data', '户籍']}>
                                            <Input maxLength={10} />
                                        </Form.Item>
                                        <Form.Item style={{ width: '180px' }} name={[field.name, 'data', '账户类型']}>
                                            <Input maxLength={10} />
                                        </Form.Item>
                                        <Form.Item style={{ width: '100px' }} name={[field.name, 'data', '公积金账号']}>
                                            <Input maxLength={30} />
                                        </Form.Item>
                                        <Form.Item style={{ width: '100px' }} name={[field.name, 'data', '补充比例']}>
                                            <Input maxLength={10} />
                                        </Form.Item>
                                        <Form.Item style={{ width: '100px' }} name={[field.name, 'data', '工资']}>
                                            <Input maxLength={10} />
                                        </Form.Item>
                                    </div>
                                ))
                            }
                        </Form.List>
                        <div className="confirm-notice-btn">
                            <Button onClick={() => OnClose(modal)}>关闭</Button>
                            <Button htmlType="submit">确认导入</Button>
                        </div>
                    </Form>
                </div>
            ),
            onCancel: () => {
                modal.destroy()
                form.resetFields()
            },
        })
    }
    const OnClose = (modal) => {
        modal.destroy()
        form.resetFields()
    }
    const onCancel = () => {
        setVisible(false)
    }
    const resultOnOk = () => {
        Modal.destroyAll()
        history.push(prevUrl + '/职工变更业务查询')
        selectChecked('业务查询')
    }
    const onUserOk = () => {
        if (newData.length === 0) {
            messages.error('待开户列表不能为空！')
        } else {
            const modal = Confirm({
                className: 'confirm-submit',
                title: '确认',
                width: 480,
                content:
                    '提交正式受理，请仔细核对所填信息。\n贵单位汇缴至20XX年X月，该员工开户后将参与X月份的汇缴，是否开户?',
                onOk: () => {
                    let prevList = []
                    const list = data.editAnswer?.questionAnswer?.operationRecord || []
                    list.forEach((item) => {
                        if (item.operateObject.length > 1) {
                            item.operateObject.forEach((s) => {
                                prevList.push(s)
                            })
                        } else {
                            prevList.push(item.operateObject[0])
                        }
                    })
                    const operateObject = produce(newData, (draft) => {
                        draft.forEach((item, index) => {
                            item.data['操作类型'] = '个人开户'
                            item.data['月缴存额'] = item.data.工资 * 0.14
                            item.data['身份证号码'] = item.identity
                            if (!prevList.find((s) => s.identity === item.identity && s.data.操作类型 === '个人开户')) {
                                item.data['操作结果'] = ''
                            } else {
                                item.data['操作结果'] = '失败'
                            }

                            if (item.data.操作结果 === '失败') {
                                item.data['个人账号'] = ''
                            } else if (!item.data.公积金账号) {
                                item.data['个人账号'] =
                                    Math.floor(
                                        (Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, 9 - 1)
                                    ) + '205'
                            }
                        })
                    })
                    onNewRecordSave({
                        type: '批量开户',
                        operateObject,
                    })
                    const modal = Confirm({
                        className: 'confirm-submits',
                        title: '确认',
                        width: 480,
                        content: (
                            <div>
                                <p>批量导入已提交，</p>
                                <p style={{ fontWeight: 'bold' }}>
                                    请点击“确定”至职工变更业务查询页面查看批量导入结果。
                                </p>
                                <div className="confirm-notice-btn">
                                    <Button onClick={() => modal.destroy()}>取消</Button>
                                    <Button onClick={resultOnOk}>确认</Button>
                                </div>
                            </div>
                        ),
                    })
                },
                onCancel: () => {
                    modal.destroy()
                },
            })
        }
    }
    const columns = [
        {
            title: '姓名',
            key: '姓名',
            render: (record) => <span>{record.data.姓名}</span>,
        },
        {
            title: '身份证号',
            key: '身份证号',
            render: (record) => <span>{record.identity}</span>,
        },
        {
            title: '户籍',
            key: '户籍',
            render: (record) => <span>{record.data.户籍}</span>,
        },
        {
            title: '账户类型',
            key: '账户类型',
            render: (record) => <span>{record.data.账户类型}</span>,
        },
        {
            title: '公积金账号',
            key: '公积金账号',
            render: (record) => <span>{record.data.公积金账号}</span>,
        },
        {
            title: '补充比例',
            key: '补充比例',
            render: (record) => <span>{record.data.补充比例}</span>,
        },
        {
            title: '工资',
            key: '工资',
            render: (record) => <span>{record.data.工资}</span>,
        },
    ]
    return (
        <div className="confirm-status">
            <div className="download-btn">
                <Button onClick={downloadModal}>导入</Button>
                <Button onClick={downloadModal}>下载导入样例</Button>
            </div>
            <p className="confirm-status-tips">
                <img src={img} className="icon-img" />
                <span className="tips-content">待开户列表： 建议每批记录不要超过150条。</span>
            </p>
            <Table
                columns={columns}
                dataSource={newData || []}
                pagination={false}
                rowKey="identity"
                bordered
                style={{ margin: '20px 0 30px 0' }}
            />
            <div className="confirm-status-form">
                <div className="confirm-notice-btn">
                    <Button onClick={onCancel}>关闭</Button>
                    <Button onClick={onUserOk}>提交</Button>
                </div>
            </div>
        </div>
    )
}

const KHConfirm = ({ data, selectChecked, onNewRecordSave, form, prevUrl, history, visible, setVisible }) => {
    return (
        <Modal
            title="批量导入开户页面"
            visible={visible}
            width={900}
            getContainer=".layout-pane-primary"
            onCancel={() => setVisible(false)}
            className="modal-QiFeng-user"
            centered={true}
            destroyOnClose={true}
        >
            <DownloadModal
                data={data}
                selectChecked={selectChecked}
                onNewRecordSave={onNewRecordSave}
                prevUrl={prevUrl}
                history={history}
                setVisible={setVisible}
            />
        </Modal>
    )
}

export default KHConfirm
