import React, { useState } from 'react'

import { Button } from 'antd'
import { typeNames } from '../../../../ks/exam/configs'
import ScoreConfirm from '../../../paper/edit/ScoreConfirm'
import './Questions.styl'

const Text = ({ question, index, data, getData, displays, inScored, addScoreQuestion }) => {
    const [modifyScore, setModifyScore] = useState(null)
    const onCommonScoreSet = (questions) => {
        ScoreConfirm({
            totalScore: question.totalScore,
            onConfirm: (scores) => {
                setModifyScore(scores)
                const obj = {
                    questionId: questions.id,
                    questionIndex: questions.index,
                    score: scores,
                }
                addScoreQuestion(obj)
            },
        })
    }
    return (
        <div className="answer-sheet">
            {displays === true ? (
                <div className="analyze-type">
                    （{index}）{typeNames[question.type]}
                </div>
            ) : (
                ''
            )}
            <div className="question-content">
                <div className="row-1">题号</div>
                <div className="row-2">学生答案</div>
                <div className="row-3">参考答案</div>
                <div className="row-4">解析</div>
                <div className="row-5">分值</div>
                <div className="row-6">得分</div>
            </div>
            <div className="question-answer">
                <div className="row-1">{index}</div>
                <div className="row-2">{question.questionAnswer ? question.questionAnswer : '-'}</div>
                <div className="row-3">{question.answer}</div>
                <div className="row-5">{question.explanation}</div>
                <div className="row-6">{question.totalScore}</div>

                <div className="row-7">
                    {!inScored && !('score' in question)
                        ? '-'
                        : modifyScore
                        ? modifyScore
                        : question.score
                        ? String(question.score).indexOf('.') + 1 > 0
                            ? question.score?.toFixed(1)
                            : question.score
                        : '0'}
                </div>
            </div>
            <Button type="primary" className="setup-score" onClick={() => onCommonScoreSet(question)}>
                修改评分
            </Button>
        </div>
    )
}

export default Text
