//cSpell:word Zhuan Feng
import React from 'react'
import produce from 'immer'
import { find } from 'lodash'
import { Link } from 'react-router-dom'
import { Alert, Form, Row, Col, Input, Select, Button, Modal } from 'antd'

import img from '../../../../../../assets/提示.png'
import message from '../../components/Message'
import QiConfirm from '../components/QiFengModal'
import Confirm from '../../components/Confirm'
import './DefaultPage.styl'
import '../components/NoticeModal.styl'

const Option = Select.Option

const ZhuanRu = ({ data, selectChecked, onNewRecordSave, prevUrl }) => {
    const [form] = Form.useForm()

    const onFinish = (values) => {
        let object = {
            identity: '',
            data: {
                身份证号码: '',
                个人账号: '',
                姓名: '',
                工资: 7500,
                所属部门: '商务部',
                月缴存额: 1050,
                账户类型: '',
            },
        }
        const dataObj = produce(values, (draft) => {
            draft.identity = draft.identity.trim()
            draft.data.姓名 = draft.data.姓名.trim()
        })
        if (!dataObj.data.个人账号) {
            if (dataObj.identity === '310119199501034312') {
                object.data.个人账号 = '128789016025'
                object.data.所属部门 = '技术部'
                object.data.工资 = 10000
                object.data.月缴存额 = 1400
            } else {
                object.data.个人账号 = parseInt(Math.random() * 1000000000, 10) + '205'
            }
        } else {
            object.data.个人账号 = dataObj.data.个人账号
        }
        object.data.账户类型 = dataObj.data.账户类型
        object.data.账户状态 = '待缴存停缴'
        object.identity = dataObj.identity
        object.data.身份证号码 = dataObj.identity
        object.data.姓名 = dataObj.data.姓名
        const resultOnOk = (obj) => {
            if (!dataObj.data.个人账号) {
                Modal.destroyAll()
            } else {
                QiConfirm(obj, form, onNewRecordSave, data)
            }
        }
        Confirm({
            className: 'confirm-submit',
            title: '确认',
            width: 480,
            content: '提交正式受理，请仔细核对所填信息，您是否确认提交？',
            onOk: () => {
                if (
                    !find(
                        data.editAnswer.questionAnswer.operationRecord,
                        (s) => s.operateObject[0].identity === object.identity && s.type === '转入'
                    )
                ) {
                    const obj = produce(object, (draft) => {
                        draft.data['操作类型'] = '转入'
                    })
                    onNewRecordSave({
                        type: '转入',
                        operateObject: [obj],
                    }).then(form.resetFields)

                    Confirm({
                        className: 'confirm-result',
                        title: '转入业务办理结果',
                        width: 480,
                        content: (
                            <div>
                                <p>该职工个人账户转入成功，请通过单位业务的“启封”功能，办理启封业务。</p>
                                <div className="confirm-notice-btn">
                                    <Button>转移清单</Button>
                                    <Button onClick={() => Modal.destroyAll()}>取消</Button>
                                    <Button onClick={() => resultOnOk(obj)}>确认</Button>
                                </div>
                            </div>
                        ),
                    })
                } else {
                    message.error('该身份证号已有转入记录，无法重复操作')
                }
            },
        })
    }

    const initialValues = {
        data: { 账户类型: '住房公积金' },
    }
    return (
        <div className="gjj-default-page">
            <div className="default-header">
                <Link to={prevUrl} onClick={selectChecked} className="current">
                    首页
                </Link>
                &gt;
                <span className="header-personal">转入</span>
            </div>
            <div className="default-main default">
                <Alert
                    className="alert-warn warning"
                    message="该业务只允许职工账户在“市公积金管理中心住房公积金集中封存专户”的情况下办理"
                    type="warning"
                    showIcon={true}
                    icon={<img src={img} className="icon-img" />}
                />
                <Form
                    className="search-from"
                    form={form}
                    onFinish={onFinish}
                    initialValues={initialValues}
                    labelCol={{ flex: '110px' }}
                >
                    <Row gutter={24} className="form-row">
                        <Col span={8}>
                            <Form.Item label="账户类型" name={['data', '账户类型']}>
                                <Select allowClear={false}>
                                    <Option value="住房公积金">住房公积金</Option>
                                    <Option value="补充住房公积金">补充住房公积金</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} className="form-row">
                        <Col span={8}>
                            <Form.Item className="form-labels">
                                <Form.Item label="职工账号" name={['data', '个人账号']}>
                                    <Input style={{ marginRight: '20px' }} maxLength={30} />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <span className="tips">请填写个人住房公积金账号</span>
                    </Row>
                    <Row gutter={24} className="form-row form-rows">
                        <Col span={8}>
                            <Form.Item className="form-label">
                                <Form.Item
                                    label="姓名"
                                    name={['data', '姓名']}
                                    required={false}
                                    rules={[{ required: true, whitespace: true, message: '此项不得为空' }]}
                                >
                                    <Input style={{ marginRight: '20px' }} maxLength={10} />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <span className="span-tips">姓名需为中文字符（可包含·）</span>
                    </Row>
                    <Row gutter={24} className="form-row">
                        <Col span={8}>
                            <Form.Item
                                style={{ marginTop: '-20px' }}
                                label="身份证号"
                                name="identity"
                                required={false}
                                rules={[{ required: true, whitespace: true, message: '此项不得为空' }]}
                            >
                                <Input maxLength={30} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} className="form-row form-radius">
                        <Col span={24} style={{ textAlign: 'left', marginTop: '20px' }}>
                            <Button type="primary" className="submit-btn" htmlType="submit">
                                提交
                            </Button>
                            <Button>批量导入</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default ZhuanRu
