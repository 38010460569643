import React from 'react'
import produce from 'immer'
import { useParams } from 'react-router-dom'
import { Form, Input, Button } from 'antd'

import message from '../../../../../components/message'
import { postOrPutCandidate } from '../../../../../services/ExamCandidateRequest'
import './Modal.styl'

const ModalForm = ({ getData, setIsModalVisible, isModalVisible }) => {
    const { record = {} } = isModalVisible
    const [form] = Form.useForm()
    const { id } = useParams()
    const handleOk = () => {
        const values = produce(form.getFieldsValue(true), (draft) => {
            draft.name = draft.name.trim()
            draft.ticket = draft.ticket.trim()
            draft.password = draft.password.trim()
            draft.examinationSite = draft.examinationSite && draft.examinationSite.trim()
            draft.examinationRoom = draft.examinationRoom && draft.examinationRoom.trim()
            if (!draft.cardId) {
                delete draft.cardId
            } else {
                draft.cardId = draft.cardId && draft.cardId.trim()
            }
        })
        postOrPutCandidate(id, values, !isModalVisible.record)
            .then(() => {
                setIsModalVisible({ visible: false })
                message.success('操作成功！')
                getData()
            })
            .catch((err) => message.error(err.response.data.message))
    }
    const handleCancel = () => {
        setIsModalVisible({ visible: false })
    }
    return (
        <Form form={form} initialValues={record} onFinish={handleOk} labelAlign="right" labelCol={{ span: 8 }}>
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <h3 className="top-h3">一、身份信息</h3>
            <Form.Item
                name="name"
                label="考生姓名"
                rules={[{ required: true, whitespace: true, message: '请填写考生姓名' }]}
            >
                <Input placeholder="最多 30 字符" maxLength={30} style={{ width: 240 }} />
            </Form.Item>
            <Form.Item
                name="ticket"
                label="准考证号"
                rules={[{ required: true, whitespace: true, message: '请填写准考证号' }]}
            >
                <Input placeholder="最多 50 字符" maxLength={50} style={{ width: 240 }} />
            </Form.Item>
            <Form.Item name="cardId" label="身份证号">
                <Input placeholder="10 ~ 18 字符" minLength={10} maxLength={18} style={{ width: 240 }} />
            </Form.Item>
            <Form.Item
                name="password"
                label="登录密码"
                rules={[{ required: true, whitespace: true, message: '请填写登录密码' }]}
            >
                <Input.Password placeholder="最多 10 字符" maxLength={10} style={{ width: 240 }} />
            </Form.Item>
            <h3>二、排考信息</h3>
            <Form.Item name="examinationSite" label="所属考核站点">
                <Input placeholder="最多 50 字符" maxLength={50} style={{ width: 240 }} />
            </Form.Item>
            <Form.Item name="examinationRoom" label="考场名称">
                <Input placeholder="最多 50 字符" maxLength={50} style={{ width: 240 }} />
            </Form.Item>
            <div className="right-action">
                <Button onClick={handleCancel}>取消</Button>
                <Button type="primary" htmlType="submit">
                    完成
                </Button>
            </div>
        </Form>
    )
}

export default ModalForm
