//cSpell:word Shui shebao gongjijin geshui
import React, { useState, useRef } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import produce from 'immer'
import classnames from 'classnames'
import { findIndex } from 'lodash'

import Button from '../../../../components/button'
import SimulationModal from '../components/SimulationModal'
import Answer from '../components/Answer'
import SimulationSystem from '../simulation'
import { typeNames } from '../configs'

import 'braft-editor/dist/output.css'
import './common.styl'
import style from './OperateText.module.styl'

const OperateQuestion = ({ stepData, preview, onSimulate, answerVisible }) => {
    //const isGeShui = stepData?.entry === '个税软件'
    return (
        <div className="exam-question question-operate-child child-question">
            <div className="question-header">
                <div className="question-no">{(stepData.index || 0) + 1}</div>
                {(preview || !stepData.id) && <div className="question-type">【{typeNames[stepData.type]}】</div>}
                <div className="question-title">
                    <span className="question-score">{`${stepData.score}分`}</span>
                </div>
            </div>
            <div
                className={classnames('question-case', 'braft-output-content', style.geshuiText)}
                dangerouslySetInnerHTML={{ __html: stepData.content }}
            />
            <Button primary onClick={() => onSimulate(stepData)}>
                进入模拟操作系统
            </Button>
            <Answer visible={answerVisible} reference={stepData.reference} />
            <div className="content-separator" />
        </div>
    )
}

const Operate = ({ stepData, editAnswer, onSave, preview, staticPosition, answerVisible }) => {
    const [customAnswer, setCustomAnswer] = useState(null)
    const history = useHistory()
    const match = useRouteMatch()
    const caseRef = useRef(null)
    const listRef = useRef(null)

    //const isGeShui = stepData?.baseQuestions?.[0]?.entry === '个税软件'

    // 存的是当前的全部小题答案，在PageMain中只用更新当前的小题答案，保存接口在模拟系统入口处调用
    const answers = editAnswer || customAnswer || stepData?.baseQuestions?.map((q) => q.userAnswer) || []

    const onCustomChange = (index, questionAnswer) => {
        const newAnswer = produce(answers, (draft) => {
            const prevIndex = findIndex(draft, (as) => as.questionIndex === index)
            if (prevIndex === -1) {
                draft.push(questionAnswer)
            } else {
                draft[prevIndex] = questionAnswer
            }
        })
        if (onSave) {
            // 实操题实时保存，仅需更新当前的小题答案
            onSave(questionAnswer)
        } else {
            setCustomAnswer(newAnswer)
        }
    }

    const backToTop = () => {
        caseRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const backToQuestion = (index) => {
        const question = listRef?.current?.getElementsByClassName('exam-question')?.[index]
        question?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    return (
        <div className={'exam-question question-analyze question-operate' + (staticPosition ? ' static' : '')}>
            <div className="question-header">
                <div className="question-no">{(stepData.index || 0) + 1}</div>
                {(preview || !stepData.id) && <div className="question-type">【{typeNames[stepData.type]}】</div>}
                <div className="question-title">
                    <span className="question-score">{`${stepData.score}分`}</span>
                    <span className="question-quick-locating">
                        <div className="locating-tag top-tag" onClick={backToTop}>
                            背景信息
                        </div>
                        {stepData.baseQuestions?.map((question, index) => {
                            return (
                                <div
                                    className={'locating-tag' + (question.completed ? ' completed' : '')}
                                    key={`tag-${index}`}
                                    onClick={() => backToQuestion(index)}
                                >
                                    ({index + 1})
                                </div>
                            )
                        })}
                    </span>
                </div>
            </div>
            <div className="analyze-scroll-wrap scrollbar">
                <div
                    ref={caseRef}
                    className={classnames('question-case', 'braft-output-content', style.geshuiText)}
                    dangerouslySetInnerHTML={{ __html: stepData.caseContent }}
                />
                <div className="question-list" ref={listRef}>
                    {stepData.baseQuestions?.map((question, index) => {
                        const options = {
                            key: index,
                            stepData: question,
                            preview,
                            answerVisible,
                            onSimulate: () => {
                                let subRoute = ''
                                switch (question.entry) {
                                    case '社保软件':
                                        subRoute = 'shebao'
                                        break
                                    case '个税软件':
                                        subRoute = 'geshui'
                                        break
                                    case '公积金系统':
                                        subRoute = 'gongjijin'
                                        break
                                    default:
                                        break
                                }
                                history.push(`${match.url}/operate/${stepData.index}/${index}/${subRoute}`)
                            },
                        }
                        return <OperateQuestion key={options.index} {...options} />
                    })}
                </div>
            </div>
            {/** stepData.index 用作试卷预览时只匹配显示当前实操题对应的模拟弹窗 */}
            <Route
                path={`${match.path}/operate/${stepData.index}/:index`}
                render={() => (
                    <SimulationModal>
                        <SimulationSystem stepData={stepData} answers={answers} onChange={onCustomChange} />
                    </SimulationModal>
                )}
            />
        </div>
    )
}

export default Operate
