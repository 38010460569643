//cSpell:word gongjijin Feng Zhuan Bian Geng Jiao Xiao
import React, { useState } from 'react'
import { useParams, Switch, Route } from 'react-router-dom'

import DetailPageSidebar from './DetailPageSidebar'
import GeRenKaiHu from './subPage/GeRenKaiHu'
import QiFeng from './subPage/QiFeng'
import ZhuanRu from './subPage/ZhuanRu'
import ZhuanChu from './subPage/ZhuanChu'
import FengCun from './subPage/FengCun'
import DaiZhuanChuTingJiao from './subPage/DaiZhuanChuTingJiao'
import DaiXiaoHuTingJiao from './subPage/DaiXiaoHuTingJiao'
import ZhiGongBianGengYeWuChaXu from './subPage/ZhiGongBianGengYeWuChaXu'
import DefaultPage from './subPage/DefaultPage'
import './HomePage.styl'

const HomePage = ({ prevPath, prevUrl, data, onNewRecordSave }) => {
    const [defaultOption, setDefaultOption] = useState({ open: '', selected: '' })
    const { level1 } = useParams()
    const selectChecked = (name) => {
        if (name === '业务查询') {
            setDefaultOption({ key: 100 * Math.random(), open: '网上业务查询', selected: '职工变更业务查询' })
        } else {
            setDefaultOption({ key: '', open: '', selected: '' })
        }
    }
    return (
        <div className="gjj-page">
            <DetailPageSidebar
                defaultOption={defaultOption}
                allow={data?.question?.name}
                prevUrl={prevUrl}
                level1={level1}
            />
            <div className="gjj-main scrollbar">
                <Switch>
                    <Route
                        path={`${prevPath}/个人开户`}
                        render={() => (
                            <GeRenKaiHu
                                data={data}
                                selectChecked={selectChecked}
                                onNewRecordSave={onNewRecordSave}
                                prevUrl={prevUrl}
                            />
                        )}
                    />
                    <Route
                        path={`${prevPath}/启封`}
                        render={() => (
                            <QiFeng
                                data={data}
                                selectChecked={selectChecked}
                                onNewRecordSave={onNewRecordSave}
                                prevUrl={prevUrl}
                            />
                        )}
                    />
                    <Route
                        path={`${prevPath}/转入`}
                        render={() => (
                            <ZhuanRu
                                data={data}
                                selectChecked={selectChecked}
                                onNewRecordSave={onNewRecordSave}
                                prevUrl={prevUrl}
                            />
                        )}
                    />
                    <Route
                        path={`${prevPath}/转出`}
                        render={() => (
                            <ZhuanChu
                                data={data}
                                selectChecked={selectChecked}
                                onNewRecordSave={onNewRecordSave}
                                prevUrl={prevUrl}
                            />
                        )}
                    />
                    <Route
                        path={`${prevPath}/封存`}
                        render={() => (
                            <FengCun
                                data={data}
                                selectChecked={selectChecked}
                                onNewRecordSave={onNewRecordSave}
                                prevUrl={prevUrl}
                            />
                        )}
                    />
                    <Route
                        path={`${prevPath}/待转出停缴`}
                        render={() => (
                            <DaiZhuanChuTingJiao
                                data={data}
                                selectChecked={selectChecked}
                                onNewRecordSave={onNewRecordSave}
                                prevUrl={prevUrl}
                            />
                        )}
                    />
                    <Route
                        path={`${prevPath}/待销户停缴`}
                        render={() => (
                            <DaiXiaoHuTingJiao
                                data={data}
                                selectChecked={selectChecked}
                                onNewRecordSave={onNewRecordSave}
                                prevUrl={prevUrl}
                            />
                        )}
                    />
                    <Route
                        path={`${prevPath}/职工变更业务查询`}
                        render={() => (
                            <ZhiGongBianGengYeWuChaXu data={data} selectChecked={setDefaultOption} prevUrl={prevUrl} />
                        )}
                    />
                    <Route render={() => <DefaultPage />} />
                </Switch>
            </div>
        </div>
    )
}

export default HomePage
