import React from 'react'
import { Form, Row, Col, Input, Select, Button } from 'antd'
import { removeEmptyKeys } from '../../../../common/utils'

const Option = Select.Option

const ListFilter = ({ onFilter, closer }) => {
    const [form] = Form.useForm()

    const onFinish = (values) => {
        onFilter(removeEmptyKeys(values))
    }

    const onReset = () => {
        form.resetFields()
        onFilter({})
    }

    const Actions = (
        <Col span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
                搜索
            </Button>
            <Button style={{ marginLeft: '10px' }} onClick={onReset}>
                重置
            </Button>
        </Col>
    )

    return (
        <Form form={form} className="search-form" onFinish={onFinish} labelCol={{ flex: '60px' }}>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item name="type" label="题型">
                        <Select placeholder="不限">
                            <Option value="">不限</Option>
                            <Option value="MultipleChoice">单选</Option>
                            <Option value="MultipleSelection">多选</Option>
                            <Option value="TrueOrFalse">判断</Option>
                            <Option value="Sorting">排序</Option>
                            <Option value="Text">简答</Option>
                            <Option value="Analyze">情景分析</Option>
                            <Option value="Operate">实操</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="level" label="等级">
                        <Select placeholder="不限">
                            <Option value="">不限</Option>
                            <Option value="Beginner">初级</Option>
                            <Option value="Intermediate">中级</Option>
                            <Option value="Advanced">高级</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="subject" label="科目">
                        <Select placeholder="不限">
                            <Option value="">不限</Option>
                            <Option value="Theory">理论</Option>
                            <Option value="Practice">实操</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="field" label="领域">
                        <Select placeholder="不限">
                            <Option value="">不限</Option>
                            <Option value="A">人事服务</Option>
                            <Option value="B">薪税服务</Option>
                            <Option value="C">招聘支持</Option>
                            <Option value="D">培训服务</Option>
                            <Option value="E">薪酬福利</Option>
                            <Option value="F">招聘服务</Option>
                            <Option value="G">HRSSC运营管理</Option>
                            <Option value="H">HR产品设计与运营</Option>
                            <Option value="I">人力资源数字化应用</Option>
                            <Option value="J">人力资源共享服务职业基础</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item name="tag" label="标签">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="key" label="关键词">
                        <Input />
                    </Form.Item>
                </Col>
                {closer && Actions}
            </Row>
            {!closer && <Row justify="end">{Actions}</Row>}
        </Form>
    )
}

export default ListFilter
