import produce from 'immer'
import { defaultData, indexNo } from './configs'

export const dataInput = ({ originData, type }) => {
    if (!originData && type) {
        return defaultData[type]
    }

    const handleSingle = (data) => {
        // 针对不同类型的题目的选项和答案进行处理
        const _type = data.type
        if (_type === 'Text') return { ...data }
        if (_type === 'Operate') return { ...data }

        data.options.forEach((option, index) => {
            option.no = indexNo[index]
        })
        data.optionType = data.options[0]?.type || 'Text'
        const answers = data.answer.options || []
        if (_type === 'MultipleChoice' || _type === 'TrueOrFalse') {
            data.radioValue = data.answer?.options[0] || 'A'
        } else if (_type === 'MultipleSelection') {
            data.options.forEach((option) => {
                option.checked = answers.indexOf(option.no) !== -1
            })
        } else if (_type === 'Sorting') {
            data.options.forEach((option, index) => {
                option.order = answers[index] || 'A'
            })
        }

        // 渲染处理
        const optionType = data.options[0]?.type
        if (optionType === 'Image') {
            data.imgOptions = data.options
            data.options = null
        }
    }

    if (originData.type === 'Analyze') {
        originData.baseQuestions.forEach((data) => handleSingle(data))
    } else {
        handleSingle(originData)
    }
    return { ...originData }
}

export const dataOutput = (originData) => {
    const handleSingle = (data) => {
        return produce(data, (draft) => {
            const type = draft.type

            if (draft.content) draft.content = draft.content.trim()
            if (draft.key) draft.key = draft.key?.trim()

            // 简答题没有选项，故跳过后续对选项的处理
            if (type === 'Text') {
                draft.answer = {
                    type,
                    content: draft.answer.content,
                }
                return draft
            }
            const optionType = draft.optionType || 'Text'
            draft.options = optionType === 'Text' ? draft.options : draft.imgOptions
            draft.options.forEach((option) => {
                option.type = optionType
                option.content = option.content.trim()
            })
            if (type === 'MultipleChoice' || type === 'TrueOrFalse') {
                draft.answer = {
                    type,
                    options: [draft.radioValue],
                }
            } else if (type === 'MultipleSelection') {
                const options = []
                draft.options.forEach((o) => o.checked && options.push(o.no))
                draft.answer = {
                    type,
                    options,
                }
            } else if (type === 'Sorting') {
                draft.answer = {
                    type,
                    options: draft.options.map((o) => o.order),
                }
            }
        })
    }
    if (originData.type === 'Analyze') {
        return produce(originData, (draft) => {
            draft.caseContent = draft.caseContent?.toHTML?.() || draft.caseContent
            draft.baseQuestions = draft.baseQuestions?.map((data) => handleSingle(data)) || []
        })
    } else if (originData.type === 'Operate') {
        return produce(originData, (draft) => {
            draft.caseContent = draft.caseContent?.toHTML() || draft.caseContent
            draft.baseQuestions =
                draft.baseQuestions?.map((data) => {
                    data.reference = data.reference?.toHTML?.() || data.reference
                    return data
                }) || []
        })
    }
    return handleSingle(originData)
}
