import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { camelCase } from 'lodash'
import { Modal, Button, Pagination, Radio } from 'antd'

import { typeNames } from '../../../question/edit/configs'
import { getPapers } from '../../../../../services/PaperRequest'
import Filter from '../../../paper/list/Filter'
import message from '../../../../../components/message'
import './PaperChooser.styl'

const PaperChooseView = ({ visible, setVisible, onConfirm, paper, setPaper, examId, handleChoose }) => {
    const [filters, setFilters] = useState({})
    const [switchPaper, setSwitchPaper] = useState({})
    const [data, setData] = useState({})
    const getData = useCallback(
        async (page = 1) => {
            let params = `page=${page}`
            Object.keys(filters).forEach((k) => {
                params = `${params}&${k}=${filters[k]}`
            })
            try {
                const res = await getPapers(`${params}`)
                await new Promise((resolve) => setTimeout(resolve, 200))
                setData(res)
            } catch (error) {
                const status = error?.response?.status
                if (status === 403) {
                    message.error('没有权限查看试卷')
                    return
                }
                if (status === 404) {
                    message.error('没有找到试卷')
                    return
                }

                message.error('试卷获取错误')
            }
        },
        [filters]
    )
    useEffect(() => {
        if (visible) getData()
    }, [getData, visible])
    useEffect(() => {
        if (visible && paper) setSwitchPaper(paper)
    }, [visible, paper])
    const onFilter = (filters) => {
        setFilters(filters)
    }
    const handleOk = () => {
        if (!paper?.id && !switchPaper.id) return message.error('请先选择试卷！')
        handleChoose(examId, switchPaper.originalId || switchPaper.id)
            .then((res) => {
                onConfirm()
                setPaper?.(switchPaper)
                message.success('操作成功！')
            })
            .catch((error) => {
                console.error(error)
                message.error('操作失败，请稍后重试！')
            })
    }
    return (
        <div className="add-form">
            <Filter onFilter={onFilter} />
            <div className="action-bar">
                <div className="results-num">共找到 {data.totalElements || 0} 张试卷</div>
            </div>
            <div className="page-list">
                {data.content?.map((item, index) => {
                    const isChecked = item.id === switchPaper.id || item.id === switchPaper.originalId
                    const questions = []
                    let total = 0
                    Object.keys(typeNames).forEach((key) => {
                        const questionCount = item[`${camelCase(key)}List`].length
                        if (questionCount > 0) {
                            questions.push({
                                name: typeNames[key].substr(0, typeNames[key].length - 1),
                                count: questionCount,
                            })
                            total += questionCount
                        }
                    })
                    return (
                        <div key={item.id} className="list-item" onClick={() => setSwitchPaper(item)}>
                            <Radio checked={isChecked} />
                            <div className="item-no">{data.number * 10 + index + 1}</div>
                            <div className="paper-item-detail">
                                <div className="paper-item-content">
                                    <div className="row-1">
                                        <span>{item.certificateName}</span>
                                        <span>{item.levelName}</span>
                                        <span>{item.subjectName}</span>
                                    </div>
                                    <div className="row-2">{item.name}</div>
                                    <div className="row-3">
                                        <span>{`分值总计：${
                                            String(item.totalScore).indexOf('.') + 1 > 0
                                                ? item.totalScore?.toFixed(1)
                                                : item.totalScore
                                        }`}</span>
                                        <span>{`题目数量：${total}（${questions
                                            .map((q) => q.name + q.count)
                                            .join('，')}）`}</span>
                                    </div>
                                </div>
                                <Link to={`/kw/paper/detail/${item.id}`} className="paper-watch-detail" target="_blank">
                                    <Button type="primary">查看详情</Button>
                                </Link>
                            </div>
                        </div>
                    )
                })}
            </div>
            <Pagination
                defaultCurrent={1}
                total={data.totalElements}
                current={data.number + 1}
                hideOnSinglePage={true}
                showSizeChanger={false}
                onChange={getData}
                style={{ float: 'right' }}
            />
            <div className="right-action">
                <Button onClick={() => setVisible(false)}>取消</Button>
                <Button type="primary" onClick={handleOk}>
                    确认
                </Button>
            </div>
        </div>
    )
}

export default ({ visible, setVisible, onRefresh, paper, setPaper, examId, handleChoose }) => {
    const onConfirm = () => {
        setVisible(false)
        onRefresh && onRefresh()
    }

    return (
        <Modal
            title={'选择试卷'}
            className="choose-paper-modal"
            width={'80%'}
            visible={visible}
            footer={null}
            onCancel={() => setVisible(false)}
            destroyOnClose
        >
            <PaperChooseView
                visible={visible}
                setVisible={setVisible}
                onConfirm={onConfirm}
                paper={paper}
                setPaper={setPaper}
                examId={examId}
                handleChoose={handleChoose}
            />
        </Modal>
    )
}
