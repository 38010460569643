import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import immer from 'immer'
import classNames from 'classnames'
import message from '../components/Message'
import { Alert, Button, Table, Tabs, Modal, DatePicker, Form, Input, Checkbox, Radio, Select, InputNumber } from 'antd'
import orderBy from 'lodash/orderBy'

import StringDateWrap from '../components/StringDatePicker'
import { AddressCascader, ProvinceCascader } from '../components/CasCader'
import Confirm from '../components/Confirm'
import useBoolean from './components/useBoolean'
import FormGroup from './components/FormGroup'
import './PersonList.styl'

const columns = [
    {
        title: '姓名',
        dataIndex: ['data', '姓名'],
        width: 80,
        fixed: 'left',
    },
    {
        title: '证件类型',
        dataIndex: ['data', '证件类型'],
        width: 105,
    },
    {
        title: '证件号码',
        dataIndex: ['identity'],
    },
    {
        title: '性别',
        dataIndex: ['data', '性别'],
    },
    {
        title: '人员状态',
        dataIndex: ['data', '人员状态'],
    },
    {
        title: '报送状态',
        dataIndex: ['data', '报送状态'],
        render: (val) => (val === '报送成功' ? <span style={{ color: '#02C882' }}>{val}</span> : <span>{val}</span>),
    },
    {
        title: '身份验证状态',
        dataIndex: ['data', '身份验证'],
        render: (val) => (val === '验证通过' ? <span style={{ color: '#02C882' }}>{val}</span> : <span>{val}</span>),
    },
    {
        title: '手机号码',
        dataIndex: ['data', '手机号码'],
    },
    {
        title: '是否残疾',
        dataIndex: ['data', '是否残疾'],
        render: (val) => (val ? '是' : '否'),
    },
    {
        title: '是否烈属',
        dataIndex: ['data', '是否烈属'],
        render: (val) => (val ? '是' : '否'),
    },
    {
        title: '是否孤老',
        dataIndex: ['data', '是否孤老'],
        render: (val) => (val ? '是' : '否'),
    },
]

const defaultItem = {
    data: {
        证件类型: '居民身份证',
        国籍: '中国',
        人员状态: '正常',
        个人投资额: '0.00',
        '个人投资比例（%）': '0.0000',
        任职受雇从业类型: '雇员',
        是否扣除减除费用: true,
        是否残疾: false,
        是否烈属: false,
        是否孤老: false,
        报送状态: '待报送',
        身份验证: '待验证',
    },
}
/**
 *
 * 1.人员信息采集
 * 新增人员，并报送
 * 2.人员信息维护
 * 编辑（双击列表行），并报送
 */
function PersonList({ match, name, presetData, onNewRecordSave, questionAnswer }) {
    const isEdit = name === '人员信息维护'

    const [visible, { setTrue, setFalse }] = useBoolean()
    const [list, setList] = useState(presetData)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [form] = Form.useForm()

    //合并或新增
    //将学生答案中的数据，合并到预置数据中
    useEffect(() => {
        const studentList = questionAnswer?.content || []

        setList((_list) => {
            const newList = immer(_list, (draft) => {
                for (const item of studentList) {
                    const index = draft.findIndex((val) => val.identity === item.identity)
                    if (index > -1) {
                        draft[index] = item
                    } else {
                        draft.push(item)
                    }
                }
                //把值为 "" 赋值为 null
                draft.forEach((item) => {
                    Object.keys(item.data).forEach((key) => {
                        if (typeof item.data[key] === 'string' && !item.data[key]) {
                            item.data[key] = null
                        }
                    })
                })
            })

            return orderBy(newList, (val) => val.data['_update'] || 1, ['desc'])
        })
    }, [questionAnswer])

    const onAddBtnClick = () => {
        form.resetFields()
        form.setFieldsValue(defaultItem)
        setTrue()
    }
    const onFinish = (value) => {
        value.data['报送状态'] = '待报送'
        value.data['身份验证'] = '待验证'

        if (!value.identity) {
            message.error('操作失败！证件号码不得为空')
            return
        }
        if (!value.data['姓名']) {
            message.error('操作失败！姓名不得为空')
            return
        }

        //检查学生答案有没有相同的身份证号
        if (!isEdit) {
            const studentList = questionAnswer?.content || []
            if (studentList.some((val) => val.identity === value.identity)) {
                message.error('操作失败！不得重复添加人员')
                return
            }
        }

        const newValue = immer(value, (darft) => {
            const arr = [
                { name: '个人投资比例（%）', fractionDigits: 4 },
                { name: '个人投资额', fractionDigits: 2 },
            ]
            arr.forEach((key) => {
                const val = darft.data[key.name]
                const fl = parseFloat(val)
                if (!Number.isNaN(fl)) {
                    darft.data[key.name] = fl.toFixed(key.fractionDigits)
                }
            })
            darft.data['证件号码'] = value.identity
            darft.data['_update'] = Date.now()
        })
        onNewRecordSave({
            type: isEdit ? '人员信息维护' : '人员信息登记',
            operateObject: [newValue],
        }).then(() => {
            setFalse()
            message.success('保存成功！')
        })
    }

    const onSubmitClick = () => {
        Confirm({
            okText: '确认',
            className: 'simulation-geshui-confirm',
            title: '提示',
            centered: true,
            content: `${isEdit ? '' : '保存后，不可修改或删除信息。'}是否保存？`,
            getContainer: null,
            onOk: form.submit, //会调用 onFinish 方法
        })
    }

    //报送
    const onReport = () => {
        //需要报送的列表
        const reportList = list.filter((val) => selectedRowKeys.includes(val.identity))
        const statusFilter = (val) => val.data['报送状态'] === '待报送'

        //选中的人员中不存在「报送状态」为「待报送」的人员
        const exist = reportList.some(statusFilter)
        if (!exist) {
            message.error('操作失败！')
            return
        }

        const operateObject = reportList.filter(statusFilter).map((val) => {
            return immer(val, (draft) => {
                draft.data['报送状态'] = '报送成功'
                draft.data['身份验证'] = '验证通过'
            })
        })
        onNewRecordSave({
            type: '人员信息报送验证',
            operateObject,
        }).then(() => {
            setSelectedRowKeys([])
            message.success('保存成功！')
        })
    }

    const onRow = (record) => {
        return {
            onDoubleClick: (event) => {
                if (!isEdit) {
                    return
                }
                form.resetFields()
                form.setFieldsValue(record)
                setTrue()
            },
        }
    }

    return (
        <div>
            <nav className="breadcrumb">
                <Link to={match.url.replace('/人员信息采集', '')} className="breadcrumb-item">
                    <div className="home-icon" />
                    首页
                </Link>
                &gt;&gt;
                <span className="breadcrumb-item current">人员信息采集</span>
            </nav>
            <div className="person-list">
                <Alert message="只有“报送成功”的纳税人才能进行扣缴申报操作，请及时进行【报送】" type="info" showIcon />
                <div className="summary">
                    <div className="summary-info">
                        <div className="line" />
                        总人数：
                        <span data-unit="人">360</span>
                        本月入职：
                        <span data-unit="人">55</span>
                        本月离职：
                        <span data-unit="人">25</span>
                    </div>
                    <div className="summary-btn-group">
                        <div>
                            <Button
                                type="primary"
                                className={classNames({ 'no-answer': isEdit })}
                                onClick={onAddBtnClick}
                            >
                                添加
                            </Button>
                            <Button type="primary" className="no-answer">
                                导入
                            </Button>
                            <Button type="primary" onClick={onReport}>
                                报送
                            </Button>
                        </div>
                        <div>
                            <Button className="no-answer">导出</Button>
                            <Button className="no-answer">展开查询条件</Button>
                            <Button className="no-answer">更多操作</Button>
                        </div>
                    </div>
                </div>
                <Tabs activeKey="境内人员">
                    <Tabs.TabPane tab="境内人员" key="境内人员">
                        <Table
                            onRow={onRow}
                            bordered
                            scroll={{ x: 'max-content' }}
                            columns={columns}
                            rowKey="identity"
                            pagination={{
                                defaultPageSize: 10,
                                position: ['bottomCenter'],
                            }}
                            dataSource={list}
                            className={classNames({ 'edit-table': isEdit })}
                            rowSelection={{
                                type: 'checkbox',
                                selectedRowKeys,
                                onChange: (keys) => setSelectedRowKeys(keys),
                            }}
                            footer={() => <Button className="no-answer">下一步，填写报表</Button>}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="境外人员" key="境外人员" />
                </Tabs>
            </div>
            <Modal
                destroyOnClose
                className="simulation-geshui-modal"
                width={900}
                visible={visible}
                onCancel={setFalse}
                maskClosable={false}
                style={{ top: 110, bottom: 110 }}
                bodyStyle={{ height: 'calc(100vh - 331px)', overflowY: 'auto' }}
                getContainer=".layout-pane-primary"
                title="境内人员信息"
                footer={
                    <Button type="primary" onClick={onSubmitClick}>
                        保存
                    </Button>
                }
            >
                <div className="geshui-modal-form">
                    <Form scrollToFirstError form={form} onFinish={onFinish}>
                        <Form.Item required className="person-status-item" label="人员状态" name={['data', '人员状态']}>
                            <Radio.Group>
                                <Radio value="正常">正常</Radio>
                                <Radio value="非正常">非正常</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <FormGroup title="基本信息">
                            <Form.Item label="证件类型" required name={['data', '证件类型']}>
                                <Select
                                    placeholder="请选择"
                                    options={[{ value: '居民身份证' }]}
                                    disabled={isEdit}
                                    dropdownClassName="simulation-geshui-select"
                                />
                            </Form.Item>
                            <Form.Item label="证件号码" name="identity" required>
                                <Input maxLength={30} placeholder="请输入" disabled={isEdit} />
                            </Form.Item>

                            <Form.Item label="姓名" required name={['data', '姓名']}>
                                <Input placeholder="请输入" maxLength={10} disabled={isEdit} />
                            </Form.Item>
                            <Form.Item label="国籍" required name={['data', '国籍']}>
                                <Input placeholder="请输入" disabled />
                            </Form.Item>
                            <Form.Item label="性别" required name={['data', '性别']}>
                                <Select
                                    dropdownClassName="simulation-geshui-select"
                                    placeholder="请选择"
                                    options={[{ value: '男' }, { value: '女' }]}
                                />
                            </Form.Item>
                            <Form.Item label="学历" name={['data', '学历']}>
                                <Select
                                    dropdownClassName="simulation-geshui-select"
                                    allowClear
                                    placeholder="请选择"
                                    options={[
                                        { value: '小学' },
                                        { value: '初中' },
                                        { value: '普通高中' },
                                        { value: '中职' },
                                        { value: '大学专科' },
                                        { value: '大学本科' },
                                        { value: '研究生' },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="出生日期" required name={['data', '出生日期']}>
                                <StringDateWrap component={<DatePicker />} disabled={isEdit} />
                            </Form.Item>
                            <Form.Item label="纳税人识别号" name={['data', '纳税人识别号']}>
                                <Input disabled />
                            </Form.Item>
                            <Form.Item label="是否存在以下情况">
                                <Form.Item noStyle name={['data', '是否残疾']} valuePropName="checked">
                                    <Checkbox
                                        onChange={(e) => {
                                            if (e.target.checked === false) {
                                                form.setFieldsValue({ data: { 残疾证号: '' } })
                                            }
                                        }}
                                    >
                                        残疾
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item noStyle name={['data', '是否烈属']} valuePropName="checked">
                                    <Checkbox
                                        onChange={(e) => {
                                            if (e.target.checked === false) {
                                                form.setFieldsValue({ data: { 烈属证号: '' } })
                                            }
                                        }}
                                    >
                                        烈属
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item noStyle name={['data', '是否孤老']} valuePropName="checked">
                                    <Checkbox>孤老</Checkbox>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item />
                            <Form.Item label="残疾证号" dependencies={[['data', '是否残疾']]}>
                                {({ getFieldValue, setFieldsValue }) => {
                                    const value = getFieldValue(['data', '是否残疾'])
                                    return (
                                        <Form.Item name={['data', '残疾证号']} noStyle>
                                            <Input
                                                maxLength={30}
                                                placeholder={!value ? '' : '请输入'}
                                                disabled={!value}
                                            />
                                        </Form.Item>
                                    )
                                }}
                            </Form.Item>
                            <Form.Item label="烈属证号" dependencies={[['data', '是否烈属']]}>
                                {({ getFieldValue, setFieldsValue }) => {
                                    const value = getFieldValue(['data', '是否烈属'])
                                    return (
                                        <Form.Item name={['data', '烈属证号']} noStyle>
                                            <Input
                                                maxLength={30}
                                                placeholder={!value ? '' : '请输入'}
                                                disabled={!value}
                                            />
                                        </Form.Item>
                                    )
                                }}
                            </Form.Item>
                        </FormGroup>
                        <FormGroup title="任职受雇从业信息">
                            <Form.Item label="任职受雇从业类型" name={['data', '任职受雇从业类型']}>
                                <Select
                                    dropdownClassName="simulation-geshui-select"
                                    placeholder="请选择"
                                    options={[
                                        { value: '雇员' },
                                        { value: '保险营销员' },
                                        { value: '证券经纪人' },
                                        { value: '实习学生（全日制学历教育）' },
                                        { value: '其他' },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item />
                            <Form.Item dependencies={[['data', '任职受雇从业类型']]} noStyle>
                                {({ getFieldValue }) => {
                                    const type = getFieldValue(['data', '任职受雇从业类型'])
                                    return (
                                        <Form.Item
                                            label="任职受雇从业日期"
                                            name={['data', '任职受雇从业日期']}
                                            required={type !== '其他'}
                                        >
                                            <StringDateWrap placeholder="请选择" component={<DatePicker />} />
                                        </Form.Item>
                                    )
                                }}
                            </Form.Item>
                            <Form.Item
                                dependencies={[
                                    ['data', '任职受雇从业类型'],
                                    ['data', '人员状态'],
                                ]}
                                noStyle
                            >
                                {({ getFieldValue }) => {
                                    const type = getFieldValue(['data', '任职受雇从业类型'])
                                    const status = getFieldValue(['data', '人员状态'])
                                    const required = type === '其他' && status === '非正常'
                                    return (
                                        <Form.Item label="离职日期" name={['data', '离职日期']} required={required}>
                                            <StringDateWrap placeholder="请选择" component={<DatePicker />} />
                                        </Form.Item>
                                    )
                                }}
                            </Form.Item>
                            <Form.Item label="是否扣除减除费用" name={['data', '是否扣除减除费用']}>
                                <Select
                                    placeholder="请选择"
                                    dropdownClassName="simulation-geshui-select"
                                    options={[
                                        { value: true, label: '是' },
                                        { value: false, label: '否' },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="入职年度就业情况" name={['data', '入职年度就业情况']}>
                                <Select
                                    dropdownClassName="simulation-geshui-select"
                                    allowClear
                                    placeholder="请选择"
                                    options={[{ value: '当年首次入职学生' }, { value: '当年首次入职其他人员' }]}
                                />
                            </Form.Item>
                            <Form.Item label="工号" name={['data', '工号']}>
                                <Input placeholder="请输入" maxLength={30} />
                            </Form.Item>
                            <Form.Item label="职务" name={['data', '职务']}>
                                <Select
                                    dropdownClassName="simulation-geshui-select"
                                    allowClear
                                    placeholder="请选择"
                                    options={[{ value: '高层' }, { value: '普通' }]}
                                />
                            </Form.Item>
                        </FormGroup>
                        <FormGroup title="联系方式">
                            <Form.Item label="手机号码" required name={['data', '手机号码']}>
                                <Input placeholder="请输入" maxLength={30} />
                            </Form.Item>
                            <Form.Item label="电子邮箱" name={['data', '电子邮箱']}>
                                <Input placeholder="请输入" maxLength={30} />
                            </Form.Item>
                            <Form.Item label="经常居住地" name={['data', '经常居住地']}>
                                <AddressCascader popupClassName="simulation-geshui-select" />
                            </Form.Item>
                            <Form.Item label="联系地址" name={['data', '联系地址']}>
                                <AddressCascader popupClassName="simulation-geshui-select" />
                            </Form.Item>
                            <Form.Item label="开户银行" name={['data', '开户银行']}>
                                <Input placeholder="请输入" maxLength={30} />
                            </Form.Item>
                            <Form.Item label="开户行省份" name={['data', '开户行省份']}>
                                <ProvinceCascader popupClassName="simulation-geshui-select" />
                            </Form.Item>
                            <Form.Item label="银行账号" name={['data', '银行账号']}>
                                <Input placeholder="请输入" maxLength={30} />
                            </Form.Item>
                            <Form.Item label="户籍所在地" name={['data', '户籍所在地']}>
                                <AddressCascader popupClassName="simulation-geshui-select" />
                            </Form.Item>
                        </FormGroup>
                        <FormGroup title="投资信息">
                            <Form.Item label="个人投资额" name={['data', '个人投资额']}>
                                <InputNumber placeholder="请输入" stringMode precision={2} />
                            </Form.Item>
                            <Form.Item label="个人投资比例（%）" name={['data', '个人投资比例（%）']}>
                                <InputNumber placeholder="请输入" stringMode precision={4} />
                            </Form.Item>
                        </FormGroup>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default PersonList
