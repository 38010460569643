import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import { operateKeys } from './config'

const RecordTable = ({ data, name }) => {
    const [tableData, setTableData] = useState(null)
    useEffect(() => {
        const newTableData = {}
        const dataSource = []
        const operateObject = data || []
        const categories = operateKeys[name]
        const existCategories = []
        categories.forEach((category) => {
            category.answerKeys.forEach((child) => {
                const shouldMerge = existCategories.indexOf(category.category) !== -1
                const newRow = {
                    key: child,
                    index: dataSource.length + 1,
                    rowSpan: !shouldMerge ? category.answerKeys.length : 0,
                    category: category.category,
                    answerKey: child,
                }
                operateObject.forEach((oo, index) => {
                    const answerKey = `答案${index + 1}`
                    let answer = '-'
                    if (child.indexOf('身份证') !== -1) {
                        answer = operateObject[index].identity
                    } else if (child === '学历') {
                        if (operateObject[index].data?.['学历'] instanceof Array) {
                            operateObject[index].data?.['学历'].forEach((a) => {
                                if (!a) {
                                    answer = `${operateObject[index].data?.['学历']}-`
                                }
                            })
                        }
                    } else if (child === '住房租金') {
                        if (operateObject[index].data?.['住房租金'] instanceof Array) {
                            answer =
                                operateObject[index].data?.['住房租金'][0] +
                                ',' +
                                operateObject[index].data?.['住房租金'][1]
                        } else {
                            answer = operateObject[index].data?.['住房租金']
                        }
                    } else if (child === '职业工种') {
                        if (operateObject[index].data?.['职业工种'] instanceof Array) {
                            answer =
                                operateObject[index].data?.['职业工种'][0] +
                                ',' +
                                operateObject[index].data?.['职业工种'][1]
                        } else {
                            answer = operateObject[index].data?.['职业工种']
                        }
                    } else if (child === '入职年度就业情况') {
                        if (operateObject[index].data?.['入职年度就业情况'] instanceof Array) {
                            operateObject[index].data?.['入职年度就业情况'].forEach((a) => {
                                if (!a) {
                                    answer = `${operateObject[index].data?.['入职年度就业情况']}-`
                                }
                            })
                        } else {
                            answer = !operateObject[index].data?.['入职年度就业情况']
                                ? '-'
                                : operateObject[index].data?.['入职年度就业情况']
                        }
                    } else if (child === '离职日期') {
                        if (operateObject[index].data?.['离职日期'] instanceof Array) {
                            operateObject[index].data?.['离职日期'].forEach((a) => {
                                if (!a) {
                                    answer = `${operateObject[index].data?.['离职日期']}-`
                                }
                            })
                        } else {
                            answer = !operateObject[index].data?.['离职日期']
                                ? '-'
                                : operateObject[index].data?.['离职日期']
                        }
                    } else if (child === '住房贷款利息') {
                        if (operateObject[index].data?.['住房贷款利息'] instanceof Array) {
                            answer =
                                operateObject[index].data?.['住房贷款利息'][0] +
                                ',' +
                                operateObject[index].data?.['住房贷款利息'][1]
                        } else {
                            answer = operateObject[index].data?.['住房贷款利息']
                        }
                    } else if (child === '任职受雇从业日期') {
                        if (operateObject[index].data?.['任职受雇从业日期'] instanceof Array) {
                            operateObject[index].data?.['任职受雇从业日期'].forEach((a) => {
                                if (!a) {
                                    answer = `${operateObject[index].data?.['任职受雇从业日期']}-`
                                }
                            })
                        } else {
                            answer = operateObject[index].data?.['任职受雇从业日期']
                        }
                    } else if (child === '职务') {
                        if (operateObject[index].data?.['职务'] instanceof Array) {
                            operateObject[index].data?.['职务'].forEach((a) => {
                                if (!a) {
                                    answer = `${operateObject[index].data?.['职务']}-`
                                }
                            })
                        } else {
                            answer = operateObject[index].data?.['职务'] || '-'
                        }
                    } else if (child === '职工账号') {
                        answer = operateObject[index].data?.['个人账号'] || '-'
                    } else if (child === '本次劳动合同起止日期') {
                        answer = operateObject[index].data?.['本次劳动合同终止日期'] || '-'
                        if (operateObject[index].data?.['本次劳动合同终止日期'] instanceof Array) {
                            operateObject[index].data?.['本次劳动合同终止日期'].forEach((a) => {
                                if (!a) {
                                    answer = (
                                        <div>
                                            <p>{operateObject[index].data?.['本次劳动合同起始日期']}</p>
                                            <p>{operateObject[index].data?.['本次劳动合同终止日期']},-</p>
                                        </div>
                                    )
                                }
                            })
                        }
                    } else if (child === '停缴期限') {
                        answer = operateObject[index].data['停缴期限']
                            ? operateObject[index].data['停缴期限'] + '个月'
                            : '-'
                    } else {
                        answer = operateObject[index].data?.[child]
                        if (answer === true) answer = '是'
                        else if (answer === false) answer = '否'
                        else if (!answer) answer = '-'
                    }
                    newRow[answerKey] = answer
                })
                dataSource.push(newRow)
                // 将已添加的类别暂存，以便下次遇到同类别的数据则合并
                existCategories.push(category.category)
            })
        })
        const columns = [
            {
                title: '序号',
                width: 78,
                dataIndex: 'index',
                key: 'index',
                fixed: 'left',
            },
            {
                title: '类别',
                width: 160,
                dataIndex: 'category',
                key: 'category',
                fixed: 'left',
                render: (value, row, index) => {
                    return {
                        children: value,
                        props: { rowSpan: row.rowSpan },
                    }
                },
            },
            {
                title: '项目',
                width: 160,
                dataIndex: 'answerKey',
                key: 'answerKey',
                fixed: 'left',
            },
        ]
        operateObject.forEach((oo, index) => {
            const answerKey = `答案${index + 1}`
            columns.push({
                title: answerKey,
                width: 200,
                dataIndex: answerKey,
                key: answerKey,
            })
        })
        newTableData.columns = columns
        newTableData.dataSource = dataSource
        setTableData(newTableData)
    }, [data, name])
    if (!tableData) return null
    return (
        <Table
            columns={tableData.columns}
            dataSource={tableData.dataSource}
            rowKey="index"
            bordered
            pagination={false}
            scroll={{ x: 'max-content' }}
        />
    )
}

export default RecordTable
