import React, { useState, useEffect, useCallback } from 'react'
import Confirm from '../../../../components/modal/Confirm'
import { getBaseUrl } from '../../../../common/urlHelp'
import { logout } from '../../../../services/UserRequest'
import './SubmitSuccess.styl'

const CountdownView = () => {
    const [time, setTime] = useState(5)
    const countdown = useCallback(() => {
        if (time > 0) {
            setTime(time - 1)
        } else {
            // 退出全屏
            if (document.exitFullscreen && document.fullscreenElement) document.exitFullscreen()
            else if (document.mozCancelFullScreen && document.fullscreenElement) document.mozCancelFullScreen()
            else if (document.webkitExitFullscreen && document.fullscreenElement) document.webkitExitFullscreen()
            logout().finally(() => (location.href = `${getBaseUrl()}/login`))
        }
    }, [time])
    useEffect(() => {
        const interval = setInterval(countdown, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [countdown])
    return (
        <div>
            <i className="iconfont icon-success" />
            <div className="result-text">交卷成功！</div>
            <div className="result-hint">{time} 秒后将会自动退出</div>
        </div>
    )
}

export default () => {
    return Confirm({
        className: 'submit-success-modal',
        footer: null,
        width: 320,
        closable: false,
        keyboard: false,
        centered: true,
        content: <CountdownView />,
    })
}
