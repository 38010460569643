import AxiosRequest from './AxiosRequest'
import { loginEvent, setUid } from '../common/arms'

export const ping = () => {
    return AxiosRequest.get('ping')
}

export const putPassword = (data) => {
    return AxiosRequest.put('/user/password', data)
}

export const test = () => {
    return AxiosRequest.get('test')
}

//考务端不传examId
export const login = (data = {}, examId) => {
    const start = Date.now()
    return AxiosRequest.post(!examId ? 'login' : 'candidate/login', data).then((res) => {
        if (examId) {
            loginEvent(true, Date.now() - start)
            setUid(res.id)
        }
        return res
    })
}

export const logout = () => {
    return AxiosRequest.get('logout')
}

export const getUserNames = (ids) => {
    return AxiosRequest.get(`user/names?ids=${ids}`)
}

export const uploadFile = (data, progress) => {
    return AxiosRequest.post(`files`, data, {
        onUploadProgress: progress,
    })
}
