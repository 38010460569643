import React, { useState, useEffect } from 'react'
import { find, sortBy } from 'lodash'
import Questions from './Questions'
import Text from './Text'
import './Analyze.styl'

const Analyze = ({ question, index, data, getData, inScored, addScoreQuestion }) => {
    const [analyzeQuestions, setAnalyzeQuestions] = useState([])
    useEffect(() => {
        const qs = []
        let allList = []
        const list = { paper: [], answers: [] }
        const typeList = ['MultipleChoice', 'MultipleSelection', 'TrueOrFalse', 'Sorting', 'Text']
        question.baseQuestions.forEach((item, index) => {
            const baseQuestionScore = find(data.paper.scores, (s) => s.questionId === question.id && s.index === index)
            list.paper.push({ typeName: item.type, score: baseQuestionScore?.score || 0 })
            if (
                item.type === 'MultipleChoice' ||
                item.type === 'MultipleSelection' ||
                item.type === 'TrueOrFalse' ||
                item.type === 'Sorting'
            ) {
                let allQuestions = { answer: item.answer.options, type: item.answer.type }
                data.paper.scores.forEach((m) => {
                    if (question.id === m.questionId && m.index === index) {
                        allQuestions.totalScore = m.score
                        allQuestions.index = m.index
                    }
                })
                data.answers.forEach((i) => {
                    if (question.id === i.questionId && i.questionIndex === index) {
                        allQuestions.questionAnswer = i.questionAnswer?.options
                        allQuestions.score = i.score
                    }
                })
                allList.push(allQuestions)
            } else if (item.type === 'Text') {
                let allQuestions = {
                    id: question.id,
                    answer: item.answer.content,
                    explanation: item.explanation,
                    type: item.answer.type,
                }
                data.answers.forEach((i) => {
                    if (question.id === i.questionId && i.questionIndex === index) {
                        allQuestions.score = i.score
                        allQuestions.questionAnswer = i.questionAnswer?.content
                    }
                })
                data.paper.scores.forEach((m) => {
                    if (question.id === m.questionId && m.index === index) {
                        allQuestions.totalScore = m.score
                        allQuestions.index = m.index
                    }
                })
                allList.push(allQuestions)
            }
        })
        let paperCount = 0
        typeList.forEach((type) => {
            const questionIds = question.baseQuestions.filter((t) => t.type === type)
            if (questionIds.length > 0) {
                const paperTypeNameList = list.paper.filter((a) => a.typeName === type)
                paperTypeNameList.forEach((m) => {
                    paperCount += m.score
                })
            }
        })
        qs.push({
            title: `（共${question.baseQuestions.length}题，满分${
                String(paperCount).indexOf('.') + 1 > 0 ? paperCount?.toFixed(1) : paperCount
            }分）`,
            allQuestion: allList,
        })
        setAnalyzeQuestions(qs)
    }, [question, data])
    const RenderQuestion = (question, index) => {
        let type = question.type
        let judge = ''
        switch (type) {
            case 'MultipleChoice':
                if (question.questionAnswer === undefined) {
                    judge = '❌'
                } else if (question.questionAnswer[0] === question.answer[0]) {
                    judge = '✔️'
                } else {
                    judge = '❌'
                }
                return (
                    <Questions
                        key={index}
                        question={question}
                        index={index}
                        displays={true}
                        judge={judge}
                        inScored={inScored}
                    />
                )
            case 'MultipleSelection':
                if (question.questionAnswer === undefined) {
                    judge = '❌'
                } else if (question.questionAnswer.length === question.answer.length) {
                    const questionAnswer = sortBy(question.questionAnswer, (q) => {
                        return q
                    })
                    const answer = sortBy(question.answer, (q) => {
                        return q
                    })
                    if (questionAnswer.join('') === answer.join('')) {
                        judge = '✔️'
                    } else {
                        judge = '❌'
                    }
                } else {
                    judge = '❌'
                }
                return (
                    <Questions
                        key={index}
                        question={question}
                        index={index}
                        displays={true}
                        judge={judge}
                        inScored={inScored}
                    />
                )
            case 'Sorting':
                if (question.questionAnswer === undefined) {
                    judge = '❌'
                } else if (question.questionAnswer.join('') === question.answer.join('')) {
                    judge = '✔️'
                } else {
                    judge = '❌'
                }
                return (
                    <Questions
                        key={index}
                        question={question}
                        index={index}
                        displays={true}
                        judge={judge}
                        inScored={inScored}
                    />
                )
            case 'TrueOrFalse':
                if (question.questionAnswer === undefined) {
                    judge = '❌'
                } else if (question.questionAnswer[0] === question.answer[0]) {
                    judge = '✔️'
                } else {
                    judge = '❌'
                }
                return (
                    <Questions
                        key={index}
                        question={question}
                        index={index}
                        displays={true}
                        judge={judge}
                        inScored={inScored}
                    />
                )
            case 'Text':
                return (
                    <Text
                        key={index}
                        question={question}
                        index={index}
                        data={data}
                        getData={getData}
                        displays={true}
                        inScored={inScored}
                        addScoreQuestion={addScoreQuestion}
                    />
                )
            default:
                return null
        }
    }
    let tempIndex = 0
    return (
        <div className="analyze-box">
            <div className="analyze-name">
                {index}
                {analyzeQuestions[0]?.title}
            </div>
            {analyzeQuestions.map((item, index) => (
                <div key={index} className="analyze-content">
                    {item.allQuestion?.map((q) => RenderQuestion(q, ++tempIndex))}
                </div>
            ))}
        </div>
    )
}

export default Analyze
