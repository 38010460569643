import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import message from '../../../components/message'
import Confirm, { ErrorConfirm } from '../../../components/modal/Confirm'
import PageHeader from './PageHeader'
import PageMain from './PageMain'
import { ExamProvider, ExamContext, CHANGE_STEP } from './Context'
import ExamLoading from './ExamLoading'

import { ping } from '../../../services/UserRequest'
import { getCandidateExam, getCandidateAnswers } from '../../../services/CandidateRequest'
import { recordAction } from '../../../services/CandidateRequest'
import { getBaseUrl } from '../../../common/urlHelp'

import './index.styl'

const PageContainer = () => {
    const { state, dispatch } = useContext(ExamContext)
    const history = useHistory()
    const { step } = useParams()
    const { editAnswer } = state
    const confirmExit = (callback) => {
        Confirm({
            className: 'practice-confirm-modal',
            title: '提示',
            content: '当前有未保存的内容，确认离开此页面吗？',
            onConfirm: callback,
        })
    }
    useEffect(() => {
        const beforeunload = (e) => {
            let confirmText = '当前有未保存的内容，确认离开此页面吗？'
            if (editAnswer === null || editAnswer === undefined) return false
            ;(e || window.event).returnValue = confirmText
            return confirmText
        }
        window.addEventListener('beforeunload', beforeunload)
        return () => {
            window.removeEventListener('beforeunload', beforeunload)
        }
    }, [editAnswer, history])
    useEffect(() => {
        dispatch({ type: CHANGE_STEP, payload: { step: Number(step) } })
    }, [dispatch, step])
    return (
        <React.Fragment>
            <div className="page-container">
                <PageHeader />
                <PageMain confirmExit={confirmExit} />
            </div>
        </React.Fragment>
    )
}

const Exam = () => {
    const [state, setState] = useState(null)
    const { id } = useParams()
    useEffect(() => {
        Promise.all([ping(), getCandidateExam(id), getCandidateAnswers(id).catch(() => [])])
            .then((res) => {
                const examData = res[1]
                const { candidate } = examData
                if (candidate && candidate.status !== 'Init') return (location.href = `${getBaseUrl()}/login`)
                if (examData.paper) examData.paper.examName = examData.exam?.name
                setState({
                    user: res[0],
                    examData,
                    answers: res[2],
                })
            })
            .catch((err) => {
                const msg = err.response?.message || err.response?.data?.message || '操作失败！请刷新页面后重试'
                if (msg === '操作失败！请刷新页面后重试') return message.error(msg)
                ErrorConfirm({ message: msg })
            })
        // 防作弊
        document.oncopy = () => false
        document.onpaste = () => false
        document.oncontextmenu = () => false
        document.onselectstart = () => false
        document.onkeydown = (e) => e.keyCode !== 122 && e.keyCode !== 123
        window.onblur = (e) => recordAction('lostFocus')
        return () => {
            document.oncopy = () => null
            document.onpaste = () => null
            document.oncontextmenu = null
            document.onselectstart = null
            document.onkeydown = null
            window.onblur = null
        }
    }, [id])
    useEffect(() => {
        return () => {
            if (document.fullScreen && document.exitFullscreen && document.fullscreenElement) document.exitFullscreen()
            else if (document.mozFullScreen && document.mozCancelFullScreen && document.fullscreenElement)
                document.mozCancelFullScreen()
            else if (document.webkitIsFullScreen && document.webkitExitFullscreen && document.fullscreenElement)
                document.webkitExitFullscreen()
        }
    }, [])
    //return <ExamLoading />
    if (!state) return <ExamLoading />
    return (
        <div className="exam-page">
            <ExamProvider originData={state}>
                <PageContainer />
            </ExamProvider>
        </div>
    )
}

export default Exam
