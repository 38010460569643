import React, { useContext, useEffect, useState } from 'react'
import produce from 'immer'
import { find } from 'lodash'
import { ExamContext } from './Context'
import './Sidebar.styl'

const status = ['未完成', '已完成', '当前']

const PageSidebar = ({ onStepChange }) => {
    const [openState, setOpenStatus] = useState({})
    const { state } = useContext(ExamContext)
    const { catalog, questions, currentStep } = state
    useEffect(() => {
        // 当切换题目时如果当前题目区被折叠的话就主动展开（可再手动折叠）
        setOpenStatus((prev) =>
            produce(prev, (draft) => {
                draft[currentStep?.type] = true
            })
        )
    }, [catalog, currentStep])
    const onFold = (type, isFold) => {
        setOpenStatus((prev) => ({ ...prev, [type]: !isFold }))
    }
    return (
        <div className="exam-page-sidebar">
            <div className="sidebar-title">答题卡</div>
            <div className="sidebar-catalog scrollbar">
                {catalog.map((item, index) => {
                    let score = 0
                    item.list.forEach((li) => (score += li.score))
                    const isFold = !openState[item.type]
                    return (
                        <div className={`catalog-item ${item.type} ${isFold ? 'fold' : 'unfold'}`} key={item.name}>
                            <div className="item-title" onClick={() => onFold(item.type, !isFold)}>
                                <div className="item-no">{item.no}</div>
                                <div className="item-type">{item.name}</div>
                                <div className="item-score">{`（共${item.list.length}题，满分${
                                    String(score).indexOf('.') + 1 > 0 ? score?.toFixed(1) : score
                                }分）`}</div>
                                <i className={`iconfont icon-${isFold ? 'unfold' : 'fold'}`} />
                            </div>
                            <div className="item-list">
                                {item.list.map((listItem) => {
                                    const completed = find(questions, (q) => q.id === listItem.id)?.completed || false
                                    const append =
                                        (listItem.id === currentStep?.id ? ' current' : '') +
                                        (completed ? ' completed' : '')
                                    return (
                                        <div
                                            key={listItem.id}
                                            className={`list-item${append}`}
                                            onClick={() => onStepChange(listItem.index + 1)}
                                        >
                                            {listItem.index + 1}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="sidebar-footer">
                {status.map((s) => (
                    <div className="status-item" key={s}>
                        <div className="item-tag" />
                        <div className="item-content">{s}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PageSidebar
