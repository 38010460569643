import React from 'react'
import { Form, Button, Dropdown, Menu, Select, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import Type from '../formItem/Type'
import InfoGroup from '../formItem/InfoGroup'
import Tags from '../formItem/Tags'
import Key from '../formItem/Key'
import Case from '../formItem/Case'

import { MultipleChoice } from './MultipleChoice'
import { MultipleSelection } from './MultipleSelection'
import { TrueOrFalse } from './TrueOrFalse'
import { Sorting } from './Sorting'
import { Text } from './Text'
import { defaultData } from '../configs'

const baseQuestions = [
    { type: 'MultipleChoice', name: '单选题' },
    { type: 'MultipleSelection', name: '多选题' },
    { type: 'TrueOrFalse', name: '判断题' },
    { type: 'Sorting', name: '排序题' },
    { type: 'Text', name: '简答题' },
]

const RenderQuestion = (props) => {
    const type = props.value?.type
    if (type === 'MultipleChoice') {
        return <MultipleChoice {...props} />
    } else if (type === 'MultipleSelection') {
        return <MultipleSelection {...props} />
    } else if (type === 'TrueOrFalse') {
        return <TrueOrFalse {...props} />
    } else if (type === 'Sorting') {
        return <Sorting {...props} />
    } else if (type === 'Text') {
        return <Text {...props} />
    }
    return null
}

// 情景分析题，由其他类型的题目组成
export default ({ form }) => {
    return (
        <>
            <Type type="Analyze" />
            <InfoGroup />
            <Tags />
            <Key />
            <Case />

            <Form.List
                name="baseQuestions"
                rules={[
                    {
                        validator: (_, questions) => {
                            if (!questions || questions.length < 1) {
                                return Promise.reject(new Error('至少需有一个小题'))
                            }
                            return Promise.resolve()
                        },
                    },
                ]}
            >
                {(fields, { add, move, remove }, { errors }) => (
                    <Form.Item label="小题">
                        {fields.map((field, index) => (
                            <Form.Item key={field.key} required={false} isListField={true} noStyle>
                                <Form.Item style={{ border: '1px solid #d9d9d9', padding: '18px 20px' }}>
                                    <div style={{ display: 'flex', marginBottom: '24px' }}>
                                        <div style={{ whiteSpace: 'nowrap', lineHeight: '32px' }}>
                                            第({index + 1})题
                                        </div>
                                        <Form.Item name={[field.name, 'type']} style={{ width: '100%' }} noStyle>
                                            <Select
                                                bordered={false}
                                                open={false}
                                                style={{ pointerEvents: 'none', color: '#6076FF' }}
                                                suffixIcon={null}
                                            >
                                                {baseQuestions.map((bq) => (
                                                    <Select.Option key={bq.type} value={bq.type}>
                                                        【{bq.name}】
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Space>
                                            <Button
                                                onClick={() => move(field.name, field.name - 1)}
                                                disabled={field.name === 0}
                                            >
                                                向上移动
                                            </Button>
                                            <Button
                                                onClick={() => move(field.name, field.name + 1)}
                                                disabled={field.name === fields.length - 1}
                                            >
                                                向下移动
                                            </Button>
                                            <Button onClick={() => remove(field.name)}>删除题目</Button>
                                        </Space>
                                    </div>
                                    <Form.Item name={[field.name]}>
                                        <RenderQuestion form={form} childrenIndex={index} />
                                    </Form.Item>
                                </Form.Item>
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {baseQuestions.map((question) => (
                                            <Menu.Item key={question.type}>
                                                <div onClick={() => add(defaultData[question.type])}>
                                                    {question.name}
                                                </div>
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                placement="topCenter"
                            >
                                <Button style={{ width: '160px' }} icon={<PlusOutlined />}>
                                    添加题目
                                </Button>
                            </Dropdown>
                        </Form.Item>
                        <Form.ErrorList errors={errors} />
                    </Form.Item>
                )}
            </Form.List>
        </>
    )
}
