//cSpell:word Feng
import produce from 'immer'
import { Button, Modal, Form, Row, Col, Input, DatePicker } from 'antd'
import img from '../../../../../../assets/提示.png'

import StringDateWrap from '../../components/StringDatePicker'
import 'moment/locale/zh-cn'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import Confirm from '../../components/Confirm'
import './QiFengModal.styl'

export default (resData, form, onNewRecordSave, data, selectChecked, history, prevUrl, setSelects) => {
    const onFinish = (values) => {
        const dataObj = produce(values, (draft) => {
            draft.data.单位名称 = draft.data.单位名称.trim()
            draft.data.单位账号 = draft.data.单位账号.trim()
        })
        const resultOnOk = () => {
            Modal.destroyAll()
            history.push(prevUrl + '/职工变更业务查询')
            selectChecked('业务查询')
        }
        const modal = Confirm({
            className: 'confirm-submit',
            title: '确认',
            width: 480,
            closable: true,
            centered: true,
            content: '提交正式受理，请仔细核对所填信息，您是否确认提交？',
            okText: '确认',
            onOk: () => {
                const operateObject = produce(resData, (draft) => {
                    draft.forEach((item, index) => {
                        item.data['单位名称'] = dataObj.data.单位名称
                        item.data['单位账号'] = dataObj.data.单位账号
                        item.data['操作类型'] = '转出'
                        item.data['汇缴到的年月'] = dataObj.data.职工转出前需汇缴到的年月
                    })
                })
                onNewRecordSave({
                    type: '转出',
                    operateObject,
                }).then(form.resetFields)
                Modal.destroyAll()
                setSelects([])
                Confirm({
                    className: 'confirm-result',
                    title: '提示',
                    width: 480,
                    content: (
                        <div>
                            <p>住房公积金转出提交成功！</p>
                            <p>操作流水号：XXX</p>
                            <p style={{ fontWeight: 'bold' }}>是否去变更管理页面查看结果？</p>
                            <div className="confirm-notice-btn">
                                <Button onClick={() => Modal.destroyAll()}>取消</Button>
                                <Button>申请表</Button>
                                <Button onClick={resultOnOk}>确认</Button>
                            </div>
                        </div>
                    ),
                })
            },
            onCancel: () => {
                modal.destroy()
            },
        })
    }
    const onCancel = () => {
        Modal.destroyAll()
        form.resetFields()
    }
    return Confirm({
        className: 'confirm-QiFeng-status',
        width: 720,
        title: '转出信息填写',
        content: (
            <div className="confirm-status">
                <p className="confirm-status-tips">
                    <img src={img} className="icon-img" />
                    <span className="tips-content">
                        请与职工核实转入单位的单位名称，需按单位公积金账户对应的单位名称填写。
                    </span>
                </p>
                <Form form={form} className="confirm-status-form" onFinish={onFinish} labelCol={{ flex: '185px' }}>
                    <Row gutter={24}>
                        <Col span={20}>
                            <Form.Item
                                label="单位名称"
                                name={['data', '单位名称']}
                                required={false}
                                rules={[{ required: true, whitespace: true, message: '此项不得为空' }]}
                            >
                                <Input maxLength={30} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={20}>
                            <Form.Item
                                label="单位账号"
                                name={['data', '单位账号']}
                                required={false}
                                rules={[{ required: true, whitespace: true, message: '此项不得为空' }]}
                            >
                                <Input maxLength={30} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={20}>
                            <Form.Item
                                label="职工转出前需汇缴到的年月"
                                name={['data', '职工转出前需汇缴到的年月']}
                                required={false}
                                rules={[{ required: true, message: '此项不得为空' }]}
                            >
                                <StringDateWrap
                                    component={
                                        <DatePicker.MonthPicker
                                            style={{ width: '343px', borderRadius: '5px' }}
                                            locale={locale}
                                        />
                                    }
                                    value={['data', '职工转出前需汇缴到的年月']}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} className="confirm-notice-btn">
                        <Col>
                            <Button onClick={onCancel}>关闭</Button>
                            <Button htmlType="submit">提交</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        ),
        onCancel: () => {
            Modal.destroyAll()
            form.resetFields()
        },
    })
}
