export const typeKeys = ['MultipleChoice', 'MultipleSelection', 'TrueOrFalse', 'Sorting', 'Text', 'Analyze', 'Operate']

export const typeNames = {
    MultipleChoice: '单选题',
    MultipleSelection: '多选题',
    TrueOrFalse: '判断题',
    Sorting: '排序题',
    Text: '简答题',
    Analyze: '情景分析题',
    Operate: '实操题',
}
