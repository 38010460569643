import { message as _message } from 'antd'
import './Message.styl'

const showMessage = (message, isUnique, style) => {
    const key = Date.now()
    const options = {
        className: `simulation-message ${style || 'success'}-style`,
        content: message,
        description: '',
        key,
        duration: 3,
    }
    const fn = style === 'success' ? _message.success : _message.error
    if (isUnique) {
        _message.destroy()
        setTimeout(() => {
            fn(options)
        }, 200)
    } else {
        fn(options)
    }
}

const success = (message, isUnique) => showMessage(message, isUnique, 'success')
const error = (message, isUnique) => showMessage(message, isUnique, 'error')

const message = { success, error, warn: error }

export default message
