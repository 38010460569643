import React, { useState } from 'react'
import { camelCase, find } from 'lodash'
import { Button, message } from 'antd'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'

import { typeNames } from '../../../question/edit/configs'
import { putCandidateScore } from '../../../../../services/ExamCandidateRequest'
import ScoreStatistics from './ScoreStatistics'
import AnswerSituation from './AnswerSituation'
import './ListItem.styl'

const ListItem = ({ data, getData }) => {
    const { id } = useParams()
    const [scoreList, setScoreList] = useState([])
    const questions = []

    Object.keys(typeNames).forEach((key) => {
        const questionCount = data.paper[`${camelCase(key)}List`].length
        if (questionCount > 0) {
            questions.push({
                name: typeNames[key],
                count: questionCount,
                value: key,
            })
        }
    })

    const addScoreQuestion = (obj) => {
        setScoreList((prev) => [...prev, obj])
    }
    const preservationText = () => {
        const allList = []
        data.questions.forEach((item) => {
            scoreList.forEach((s) => {
                if (s.questionId === item.id && item.type === 'Text') {
                    let allQuestions = {
                        candidateId: data.candidate.id,
                        examId: data.candidate.examId,
                        questionId: s.questionId,
                        questionIndex: s.questionIndex,
                        score: s.score,
                    }
                    allList.push(allQuestions)
                } else if (s.questionId === item.id && item.type === 'Analyze') {
                    item.baseQuestions.forEach((q, index) => {
                        if (q.type === 'Text' && s.questionIndex === index) {
                            let allQuestions = {
                                candidateId: data.candidate.id,
                                examId: data.candidate.examId,
                                questionId: s.questionId,
                                questionIndex: s.questionIndex,
                                score: s.score,
                            }
                            allList.push(allQuestions)
                        }
                    })
                }
            })
        })
        let text = []
        let analyze = []
        if (data.questions.some((q) => q.type === 'Text' && !find(data.answers, (s) => s.questionId === q.id))) {
            text = data.questions.some((q) => q.type === 'Text' && !find(scoreList, (s) => s.questionId === q.id))
            analyze = data.questions.some((q) => {
                return (
                    q.type === 'Analyze' &&
                    q.baseQuestions.some(
                        (bq, index) =>
                            bq.type === 'Text' &&
                            !find(scoreList, (s) => s.questionId === q.id && s.questionIndex === index)
                    )
                )
            })
        } else {
            text = data.questions.some((q) => q.type === 'Text' && !find(data.answers, (s) => s.questionId === q.id))
            analyze = data.questions.some((q) => {
                return (
                    q.type === 'Analyze' &&
                    q.baseQuestions.some(
                        (bq, index) =>
                            bq.type === 'Text' &&
                            !find(data.answers, (s) => s.questionId === q.id && s.questionIndex === index)
                    )
                )
            })
        }

        if (text || analyze) {
            message.error('操作失败！存在主观题为空！')
        } else {
            putCandidateScore(allList)
                .then(() => {
                    message.success('操作成功!')
                    getData()
                })
                .catch((err) => {
                    message.error('操作失败！刷新后重试！')
                })
        }
    }
    const inScored = !!data.candidate.submitDate
    return (
        <div className="list-item-paper">
            <div className="detail-information">
                <h3 className="detail-information-title">基本信息</h3>
                <div>
                    学生姓名：<b>{data.candidate.name}</b>
                </div>
                <div>准考证号：{data.candidate.ticket}</div>
                <div>
                    交卷时间：
                    {!data.candidate.submitDate ? '-' : moment(data.candidate.submitDate).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <Button className="paper-snapshot">
                    <Link to={{ pathname: `/kw/exam/detail/${id}`, activeKey: '2' }}>查看试卷快照</Link>
                </Button>
                <Button type="primary" className="text-button" onClick={preservationText}>
                    保存主观题评分
                </Button>
            </div>
            <div className="detail-score">
                <h3 className="detail-score-title">分数统计</h3>
                <ScoreStatistics inScored={inScored} data={data} questions={questions} />
            </div>
            <div className="detail-score">
                <h3 className="detail-score-title">答题情况</h3>
                <AnswerSituation
                    inScored={inScored}
                    data={data}
                    getData={getData}
                    addScoreQuestion={addScoreQuestion}
                />
            </div>
        </div>
    )
}

export default ListItem
