import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Breadcrumb, Modal, Form, message } from 'antd'
import { find } from 'lodash'
import { useHistory } from 'react-router'
import { intersection } from 'lodash'

import Loading from '../../../../components/loading'
import { getPaper, copyPaper } from '../../../../services/PaperRequest'
import { getExamNames } from '../../../../services/ExamsRequest'
import { getUserNames } from '../../../../services/UserRequest'
import ListItem from '../list/ListItem'
import DetailView from './DetailView'
import BaseForm from '../edit/BaseForm'
import Preview from './Preview'
import './index.styl'

const PaperDetail = ({ roles }) => {
    const { id } = useParams()
    const [data, setData] = useState({})
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            const data = await getPaper(id)
            const userNames = await getUserNames([data.createdUser, data.modifiedUser])
            const paperNames = await getExamNames(data.examIds)
            data.createdUserName = find(userNames, (u) => u.id === data.createdUser)?.username || '-'
            data.modifiedUserName = find(userNames, (u) => u.id === data.modifiedUser)?.username || '-'
            data.paperNames = paperNames.map((p) => p.name).join('、')
            setData(data)
        }
        fetchData()
    }, [id])

    const openCopyPaperModal = () => {
        setVisible(true)
        form.setFieldsValue({
            name: data.name,
            description: data.description,
            level: data.level,
            subject: data.subject,
        })
    }

    const onCopyPaper = () => {
        setLoading(true)
        form.validateFields().then((val) => {
            copyPaper(data.id, val)
                .then((res) => {
                    setVisible(false)
                    Modal.confirm({
                        title: '提示',
                        content: '复制成功，是否查看复制的试卷？',
                        onOk: () => {
                            history.push(`/kw/paper/detail/${res.id}`)
                        },
                    })
                })
                .catch(() => {
                    message.warn('复制失败')
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    const disabledEdit = intersection(roles, ['Admin', 'Paper']).length === 0

    if (!data.id) return <Loading />

    return (
        <div className="paper-detail-page">
            <div className="page-header">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item key="/kw/paper">
                        <Link to="/kw/paper">试卷管理</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>试卷详情</Breadcrumb.Item>
                </Breadcrumb>
                <Button disabled={disabledEdit}>
                    <Link to={`/kw/paper/edit/${id}`}>编辑</Link>
                </Button>
                <Button disabled={disabledEdit} onClick={openCopyPaperModal}>
                    复制
                </Button>
                <Button>
                    <Link target="__blank" to={`/kw/print/paper/${id}`}>
                        打印
                    </Link>
                </Button>
            </div>
            <div className="list-item-wrap">
                <ListItem data={data} />
            </div>
            <DetailView data={data} />
            <div className="paper-preview-container">
                <Preview paper={data} />
            </div>
            <Modal
                visible={visible}
                onOk={onCopyPaper}
                width={800}
                title={`复制试卷【${data.name}】`}
                onCancel={() => setVisible(false)}
                okButtonProps={{ loading }}
            >
                <Form form={form}>
                    <BaseForm />
                </Form>
            </Modal>
        </div>
    )
}

export default PaperDetail
