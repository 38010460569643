import AxiosRequest from './AxiosRequest'
const url = 'exams'

export const getExam = (id) => {
    return AxiosRequest.get(`${url}/${id}`)
}

/**
 * ！暂不使用
 * 通过考试查询关联的题目
 */
export const getExamQuestions = (id) => {
    return AxiosRequest.get(`${url}/${id}/questions`)
}
/**
 * ！暂不使用
 * 通过考试查询关联的试卷
 */
export const getExamPaper = (id) => {
    return AxiosRequest.get(`${url}/${id}/paper`)
}

export const getExams = (params = '') => {
    return AxiosRequest.get(`${url}?${params}`)
}

export const createExam = (data) => {
    return AxiosRequest.post(`${url}`, data)
}

export const putExam = (id, data) => {
    return AxiosRequest.put(`${url}/${id}`, data)
}

export const duplicateExam = (id, data) => {
    return AxiosRequest.post(`${url}/${id}/duplicate`, data)
}

export const deleteExam = (id) => {
    return AxiosRequest.delete(`${url}/${id}`)
}

export const putStatus = (id, data) => {
    return AxiosRequest.put(`${url}/${id}/status`, data)
}

export const putPin = (id, pinStatus) => {
    return AxiosRequest.put(`${url}/${id}/pin`, pinStatus)
}

export const getExamNames = (ids = []) => {
    if (ids.length === 0) return []
    return AxiosRequest.get(`${url}/names?ids=${ids.join('&ids=')}`)
}

export const getCurrentTime = () => {
    return AxiosRequest.get(`/currentTimeMillis`)
}
