import React, { useState, useContext, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { isString, findIndex, filter, isEmpty } from 'lodash'
import produce from 'immer'

import { ExamContext, EDIT_ANSWER, UPDATE_DATA } from './Context'
import PageSidebar from './Sidebar'
import MultipleChoice from './questions/MultipleChoice'
import MultipleSelection from './questions/MultipleSelection'
import Sorting from './questions/Sorting'
import TrueOrFalse from './questions/TrueOrFalse'
import Text from './questions/Text'
import Analyze from './questions/Analyze'
import Operate from './questions/Operate'
import Button from '../../../components/button'
import message from '../../../components/message'
import { ErrorConfirm } from '../../../components/modal/Confirm'
import ExamInfoModal from './components/ExamInfoModal'
import Countdown from './components/Countdown'
import SubmitSuccess from './components/SubmitSuccess'
import SubmitSuccessWithReport from './components/SubmitSuccessWithReport'
import FullscreenButton from './components/FullscreenButton'
import SubmitConfirm from './components/SubmitConfirm'

import { checkExamStatus, postAnswers, submitPaper } from '../../../services/CandidateRequest'
import { getBaseUrl } from '../../../common/urlHelp'
import { getCompletedStatus } from './configs'
import './PageMain.styl'

const PageMain = () => {
    const { state, dispatch } = useContext(ExamContext)
    const { isMock, currentStep = {}, exam, catalog, paper, questions, editAnswer } = state
    const [answerVisible, setAnswerVisible] = useState(false)
    const { id } = useParams()
    const history = useHistory()
    const resetEditStatus = useCallback(() => {
        dispatch({ type: EDIT_ANSWER, payload: { editAnswer: null } })
    }, [dispatch])
    // 保存答案。 callback用来交卷前先保存答案
    const onSaveData = async (customAnswer, callback) => {
        const saveAnswer = customAnswer || editAnswer
        if (!saveAnswer) return callback ? callback(0) : questions
        let saveData = Array.isArray(saveAnswer) ? saveAnswer : [saveAnswer]
        saveData = filter(saveData, (d) => !isEmpty(d.questionAnswer))
        return postAnswers(exam.id, exam.paperSnapshotId, saveData)
            .then(() => {
                const questionIndex = findIndex(questions, (q) => q.id === currentStep.id)
                if (questionIndex === -1) return '未找到题目'

                // 保存成功后将答案数据、完成状态塞回到题目中
                const newQuestions = produce(questions, (draft) => {
                    if (Array.isArray(saveAnswer)) {
                        // 情景分析题
                        saveAnswer.forEach((answer) => {
                            try {
                                draft[questionIndex].baseQuestions[answer.questionIndex].userAnswer = answer
                                const calculateQuestion = draft[questionIndex].baseQuestions[answer.questionIndex]
                                const shouldCompleted = getCompletedStatus(calculateQuestion)
                                draft[questionIndex].baseQuestions[answer.questionIndex].completed = shouldCompleted
                            } catch (error) {
                                return '答案赋值失败'
                            }
                        })
                        const shouldCompleted = draft[questionIndex].baseQuestions.every((bq) => bq.completed)
                        if (callback) {
                            const increase =
                                draft[questionIndex].completed === shouldCompleted ? 0 : shouldCompleted ? 1 : -1
                            callback(increase)
                        }
                        draft[questionIndex].completed = shouldCompleted
                    } else {
                        draft[questionIndex].userAnswer = saveAnswer
                        const shouldCompleted = getCompletedStatus(draft[questionIndex])
                        if (callback) {
                            const increase =
                                draft[questionIndex].completed === shouldCompleted ? 0 : shouldCompleted ? 1 : -1
                            callback(increase)
                        }
                        draft[questionIndex].completed = shouldCompleted
                    }
                })
                const changedStep = newQuestions[questionIndex]
                dispatch({ type: UPDATE_DATA, payload: { questions: newQuestions, currentStep: changedStep } })
                resetEditStatus()
                return newQuestions
            })
            .catch((error) => {
                const msg = error?.response?.data?.message || '操作失败！请刷新页面后重试'
                if (msg === '操作失败！请刷新页面后重试' && callback) return message.error(msg)
                resetEditStatus()
                ErrorConfirm({ message: msg })
                return msg
            })
    }
    // 实时保存的实操题答案，保存接口在组件内完成，此处只是根据返回的结果去修改状态
    const onOperateSave = (answer) => {
        const questionIndex = findIndex(questions, (q) => q.id === currentStep.id)
        if (questionIndex === -1) return '未找到题目'
        const newQuestions = produce(questions, (draft) => {
            try {
                const question = draft[questionIndex].baseQuestions[answer.questionIndex]
                question.userAnswer = answer
                question.completed = getCompletedStatus(question)
                draft[questionIndex].completed = draft[questionIndex].baseQuestions.every((bq) => bq.completed)
            } catch (error) {
                return '答案赋值失败'
            }
        })
        const changedStep = newQuestions[questionIndex]
        dispatch({ type: UPDATE_DATA, payload: { questions: newQuestions, currentStep: changedStep } })
    }
    // 交卷
    const onSubmit = async (type) => {
        const onPaperSubmit = async (notSubmit) => {
            try {
                // 模拟考试因为需要前端计算分数得拿到实时的答案
                const newQuestions = await onSaveData()
                // 交卷
                if (!notSubmit) await submitPaper(id)
                if (isMock) {
                    SubmitSuccessWithReport(paper, catalog, newQuestions || questions)
                } else {
                    SubmitSuccess()
                }
                return true
            } catch (error) {
                const msg = error?.response?.data?.message || '操作失败！请刷新页面后重试'
                if (msg === '操作失败！请刷新页面后重试') return message.error(msg)
                resetEditStatus()
                ErrorConfirm({ message: msg })
                throw new Error(msg)
            }
        }

        // 考试时间到自动保存并交卷
        if (type === 'timeout') return onPaperSubmit(true)

        // 保存当前答案后提示是否交卷
        // 因为state不会实时更新而此处需要提前拿到题目完成状态，故需拿到保存后完成题目数的增量
        const showConfirm = (completedIncrease = 0) => {
            let completedNum = 0
            questions.forEach((q) => q.completed && ++completedNum)
            completedNum += completedIncrease
            SubmitConfirm(completedNum, questions.length - completedNum, onPaperSubmit)
        }
        await onSaveData(null, showConfirm)
    }
    const onStepChange = async (step, needLocating) => {
        const error = await onSaveData()
        if (error && isString(error)) {
            if (error !== '未找到题目' && error !== '答案赋值失败') return
            return message.error('操作失败！请刷新页面后重试')
        }
        if (step <= 0 || (currentStep.isLast && step > currentStep.index)) return
        setAnswerVisible(false)
        checkExamStatus(id)
            .then(() => {
                // 切换到新的题目类型时，需将该模块定位到顶部
                if (needLocating) {
                    const currentType = currentStep?.type
                    const nextType = questions[step - 1]?.type
                    if (nextType !== currentType) {
                        const nextModule = document.getElementsByClassName(nextType)[0]
                        setTimeout(() => {
                            nextModule?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                        }, 300)
                    }
                }
                history.push(`${getBaseUrl()}/${id}/step/${step}`)
            })
            .catch((error) => {
                const msg = error?.response?.data?.message || '操作失败！请刷新页面后重试'
                if (msg === '操作失败！请刷新页面后重试') return message.error(msg)
                resetEditStatus()
                ErrorConfirm({ message: msg })
            })
    }
    const onAnswerChange = (editAnswer) => {
        dispatch({ type: EDIT_ANSWER, payload: { editAnswer } })
    }
    const RenderQuestion = () => {
        const type = currentStep.type
        const options = {
            stepData: currentStep,
            editAnswer,
            answerVisible,
            onChange: onAnswerChange,
        }
        switch (type) {
            case 'MultipleChoice':
                return <MultipleChoice {...options} />
            case 'MultipleSelection':
                return <MultipleSelection {...options} />
            case 'Sorting':
                return <Sorting {...options} />
            case 'TrueOrFalse':
                return <TrueOrFalse {...options} />
            case 'Text':
                return <Text {...options} />
            case 'Analyze':
                return <Analyze {...options} />
            case 'Operate':
                return <Operate {...options} onSave={onOperateSave} />
            default:
                break
        }
    }
    return (
        <>
            <PageSidebar onStepChange={onStepChange} />
            <div className="exam-page-main">
                <div className="view-header">
                    <ExamInfoModal />
                    <div className="header-right">
                        <div className="remain-time">
                            <i className="iconfont icon-clock" />
                            <span className="time-tip">剩余时间</span>
                            <Countdown start={exam.startDate} end={exam.endDate} onEnd={onSubmit} />
                        </div>
                        <Button warn className="submit-btn" onClick={onSubmit}>
                            交 卷
                        </Button>
                    </div>
                </div>
                <div className="view-question scrollbar" key={currentStep.id}>
                    {RenderQuestion()}
                </div>
                <div className="view-footer">
                    {isMock && (
                        <Button className="answer-btn" onClick={() => setAnswerVisible(!answerVisible)}>
                            {answerVisible ? '隐藏题目答案' : '显示题目答案'}
                        </Button>
                    )}
                    {(currentStep.type === 'Analyze' || currentStep.type === 'Text') && (
                        <Button disabled={!editAnswer} onClick={() => onSaveData()}>
                            保存
                        </Button>
                    )}
                    <Button disabled={currentStep.index === 0} onClick={() => onStepChange(currentStep.index, true)}>
                        上一题
                    </Button>
                    <Button
                        primary
                        disabled={currentStep.isLast}
                        onClick={() => onStepChange(currentStep.index + 2, true)}
                    >
                        下一题
                    </Button>
                </div>
            </div>
            <FullscreenButton />
        </>
    )
}

export default PageMain
