function Header() {
    return (
        <header>
            <div className="system-name">
                <div className="icon logo" />
                <span className="system-name-text">自然人电子税务局（模拟扣缴端）</span>
                <div className="daijiao">
                    <div className="icon nav-bar-icon nav-bar-icon-代扣代缴" />
                    <div>代扣代缴</div>
                </div>
            </div>

            <ul>
                <li>
                    <div className="icon nav-bar-icon nav-bar-icon-操作手册" />
                    操作手册
                </li>
                <li>
                    <div className="icon nav-bar-icon nav-bar-icon-消息中心" />
                    消息中心
                </li>
                <li>
                    <div className="icon nav-bar-icon nav-bar-icon-单位管理" />
                    单位管理
                </li>
                <li>
                    <div className="icon nav-bar-icon nav-bar-icon-在线" />
                    在线
                </li>
            </ul>
        </header>
    )
}

export default Header
