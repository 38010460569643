import { submitPaperEvent } from '../common/arms'
import AxiosRequest from './AxiosRequest'
const url = 'candidate'

export const getReleaseExams = () => {
    return AxiosRequest.get(`${url}/release_exams`)
}

// const time = () => {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve()
//         }, 5000)
//     })
// }

export const getCandidateExam = (id) => {
    // return time().then(() => {
    //     return AxiosRequest.get(`${url}/exam/${id}`)
    // })
    return AxiosRequest.get(`${url}/exam/${id}`)
}

export const checkExamStatus = (id) => {
    return AxiosRequest.get(`${url}/exam/${id}/status`)
}

export const getCandidateAnswers = (id) => {
    return AxiosRequest.get(`${url}/exam/${id}/answers`)
}

export const postAnswer = (examId, paperId, answer) => {
    return AxiosRequest.post(`${url}/exam/${examId}/paper/${paperId}/answer`, answer)
}

export const postAnswers = (examId, paperId, answers) => {
    return AxiosRequest.post(`${url}/exam/${examId}/paper/${paperId}/answers`, answers)
}

export const postOperationRecord = (examId, paperId, record) => {
    return AxiosRequest.post(`${url}/exam/${examId}/paper/${paperId}/operationRecord`, record)
}

export const submitPaper = (examId) => {
    const start = Date.now()
    return AxiosRequest.post(`${url}/exam/${examId}/submit_date`)
        .then((res) => {
            submitPaperEvent(true, Date.now() - start)
            return res
        })
        .catch((error) => {
            submitPaperEvent(false, Date.now() - start, new Error().stack)
            return Promise.reject(error)
        })
}

// actionName: lostFocus | switchFullscreen
export const recordAction = (actionName) => {
    return AxiosRequest.get(`${url}/action/${actionName}`)
}
