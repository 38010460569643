//cSpell:word Zhao Zhuan Jiao Tiao Zheng
import React from 'react'
import { useParams, Switch, Route } from 'react-router-dom'

import DetailPageSidebar from './DetailPageSidebar'
import DefaultPage from './subPage/DefaultPage'
import ZhaoGongDengJi from './subPage/ZhaoGongDengJi'
import TuiGongDengJi from './subPage/TuiGongDengJi'
import SheBaoZhuanRu from './subPage/SheBaoZhuanRu'
import SheBaoZhuanChu from './subPage/SheBaoZhuanChu'
import JiaoFeiJiShuTiaoZheng from './subPage/JiaoFeiJiShuTiaoZheng'
import './DetailPage.styl'

const DetailPage = ({ prevPath, prevUrl, data, onNewRecordSave }) => {
    const { level1, level2 } = useParams()
    return (
        <div className="sb-detail-page">
            <DetailPageSidebar allow={data?.question?.name} prevUrl={prevUrl} level1={level1} level2={level2} />
            <div className="sb-detail-main scrollbar">
                <Switch>
                    <Route
                        path={`${prevPath}/:level1/就业参保登记`}
                        render={() => <ZhaoGongDengJi data={data} onNewRecordSave={onNewRecordSave} />}
                    />
                    <Route
                        path={`${prevPath}/:level1/退工登记办理`}
                        render={() => <TuiGongDengJi data={data} onNewRecordSave={onNewRecordSave} />}
                    />
                    <Route
                        path={`${prevPath}/:level1/转入人员`}
                        render={() => <SheBaoZhuanRu data={data} onNewRecordSave={onNewRecordSave} />}
                    />
                    <Route
                        path={`${prevPath}/:level1/转出人员`}
                        render={() => <SheBaoZhuanChu data={data} onNewRecordSave={onNewRecordSave} />}
                    />
                    <Route
                        path={`${prevPath}/:level1/缴费基数调整人员`}
                        render={() => <JiaoFeiJiShuTiaoZheng data={data} onNewRecordSave={onNewRecordSave} />}
                    />
                    <Route render={() => <DefaultPage level1={level1} />} />
                </Switch>
            </div>
        </div>
    )
}

export default DetailPage
