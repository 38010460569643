import React, { useState, useEffect, useRef } from 'react'

import RecordList from './RecordList'
import RecordItem from './RecordItem'

import finger from '../../../../../assets/finger.png'
import './index.styl'

const TaskAndRecords = ({ data }) => {
    const [panel, setPanel] = useState('task')
    const contentRef = useRef(null)
    const [recordDetail, setRecordDetail] = useState(null)
    useEffect(() => {
        if (contentRef && contentRef.current) contentRef.current.scrollTop = 0
    }, [panel])
    return (
        <div className="task-and-records">
            <div className="view-tabs">
                <div
                    className={`tab${panel === 'task' ? ' active' : ''}`}
                    onClick={() => {
                        setPanel('task')
                        setRecordDetail(null)
                    }}
                >
                    任务说明
                </div>
                <div className={`tab${panel === 'record' ? ' active' : ''}`} onClick={() => setPanel('record')}>
                    操作记录
                </div>
                <div className="operation-tip">
                    <img src={finger} className="finger-img" />
                    <span>提示：上下拖拽边缘，可调整可视区域</span>
                </div>
            </div>
            <div className="view-content scrollbar" ref={contentRef}>
                {panel === 'task' ? (
                    <div
                        className="task-content braft-output-content"
                        dangerouslySetInnerHTML={{ __html: data.question?.content }}
                    />
                ) : !recordDetail ? (
                    <RecordList data={data} setRecordDetail={setRecordDetail} />
                ) : (
                    <RecordItem data={recordDetail} setRecordDetail={setRecordDetail} />
                )}
            </div>
        </div>
    )
}

export default TaskAndRecords
