import React, { useState } from 'react'
import { Checkbox, Button, Modal } from 'antd'

import Confirm from '../../components/Confirm'
import './NoticeModal.styl'

const ConfirmNotice = ({ detail, url, history }) => {
    const [checked, setChecked] = useState(false)
    const [display, setDisplay] = useState(true)
    const onChange = (e) => {
        setChecked(e.target.checked)
        setDisplay(e.target.checked)
    }
    const onOk = () => {
        if (!checked) {
            setDisplay(false)
        } else {
            Modal.destroyAll()
        }
    }
    const onCancel = () => {
        Modal.destroyAll()
        history.push(url)
    }
    return (
        <div>
            <div className="success scrollbar" style={{ maxHeight: '290px' }}>
                {detail}
            </div>
            <p>
                <Checkbox
                    onChange={onChange}
                    style={{ marginLeft: '230px', fontSize: '12px', color: '#555555', lineHeight: '16px' }}
                >
                    以上条款本单位
                    <span style={{ fontWeight: 'bold' }}>已知晓并接受</span>
                </Checkbox>
            </p>
            {display === false ? <p style={{ textAlign: 'center', color: 'red' }}>请先阅读须知再勾选接受声明</p> : ''}
            <div className="confirm-notice-btn">
                <Button onClick={onCancel}>关闭</Button>
                <Button onClick={onOk}>确认</Button>
            </div>
        </div>
    )
}

export default ({ title, detail, url, history }) => {
    return Confirm({
        className: 'confirm-notice',
        width: 720,
        title,
        content: <ConfirmNotice detail={detail} url={url} history={history} />,
        onCancel: () => {
            Modal.destroyAll()
            history.push(url)
        },
        getContainer: '.layout-pane-primary',
    })
}
