//cSpell:word Feng
import React, { useState, useEffect } from 'react'
import produce from 'immer'
import { find } from 'lodash'
import { Button, Table, Checkbox, message, Alert } from 'antd'
import { useHistory, Link } from 'react-router-dom'

import img from '../../../../../../assets/提示.png'
import Confirm from '../components/FengCunModal'
import Confirms from '../components/FengCunSubmitModal'
import PublicForm from './PublicForm'
import './QiFeng.styl'

const FengCun = ({ data, selectChecked, onNewRecordSave, prevUrl }) => {
    const history = useHistory()
    const [checked, setChecked] = useState(false)

    const [list, setList] = useState(data.question.presetData)
    useEffect(() => {
        const content = data.editAnswer.questionAnswer?.content || []
        setList(
            produce((draft) => {
                for (const item of content) {
                    const index = draft.findIndex((val) => val.data.职工账号 === item.data.职工账号)
                    if (index > -1) {
                        draft[index] = Object.assign({}, draft[index], item)
                    } else {
                        draft.push(item)
                    }
                }
            })
        )
    }, [data])
    const prevData = list.filter((s) => !s.data.操作类型)
    const prevList = produce(prevData, (draft) => {
        draft.forEach((d, index) => {
            d.key = index
        })
    })
    let selectsList = []
    prevList.forEach((item) => {
        if (item.data.封存原因) {
            selectsList.push(item.data.职工账号)
        }
    })
    const [selects, setSelects] = useState(selectsList)
    const transferOut = () => {
        if (selects.length === 0) {
            message.warning({
                className: 'gjj-warning',
                content: <p style={{ float: 'right' }}>请先勾选已确认过封存信息的职工再点击【封存】按钮</p>,
            })
        } else {
            let resData = []
            selects.forEach((select) => {
                resData.push(prevList.find((s) => s.data.职工账号 === select))
            })
            Confirms(resData, onNewRecordSave, data, selectChecked, history, prevUrl)
        }
    }

    const handleSelect = (e, identity, isSelect) => {
        e.stopPropagation()
        const newSelects = produce(selects, (draft) => {
            if (isSelect) {
                const idx = selects.indexOf(identity)
                draft.splice(idx, 1)
            } else {
                draft.push(identity)
                const resData = find(prevList, (s) => s.data.职工账号 === identity)
                Confirm(selects, setSelects, resData, list, setList, checked, setChecked)
            }
        })
        setSelects(newSelects)
    }

    const columns = [
        {
            key: 'checkbox',
            fixed: 'left',
            render: (record) => {
                const selected = selects.indexOf(record.data.职工账号) !== -1
                return <Checkbox checked={selected} onClick={(e) => handleSelect(e, record.data.职工账号, selected)} />
            },
        },
        {
            title: '账户类型',
            key: '账户类型',
            render: (record) => (
                <span>
                    {record.data.账户类型 === '住房公积金' || record.data.账户类型 === '基本账户类型'
                        ? '基本账户类型'
                        : '补充账户类型'}
                </span>
            ),
        },
        {
            title: '职工账号',
            key: '职工账号',
            render: (record) => <span>{record.data.职工账号}</span>,
        },
        {
            title: '所属部门',
            key: '所属部门',
            render: (record) => <span>{record.data.所属部门}</span>,
        },
        {
            title: '工号',
            key: '工号',
            render: (record) => '-',
        },
        {
            title: '姓名',
            key: '姓名',
            render: (record) => <span>{record.data?.姓名 || '—'}</span>,
        },
        {
            title: '修正名',
            key: '修正名',
            render: (record) => '-',
        },
        {
            title: '身份证号',
            key: '身份证号',
            render: (record) => <span>{record?.identity || '—'}</span>,
        },
        {
            title: '账户状态',
            key: '账户状态',
            render: (record) => <span>{record.data?.账户状态 || '正常'}</span>,
        },
        {
            title: '工资',
            key: '工资',
            render: (record) => <span>{record.data?.工资 || '—'}</span>,
        },
        {
            title: '月缴存额',
            key: '月缴存额',
            render: (record) => <span>{record.data?.月缴存额 || '—'}</span>,
        },
        {
            title: '末次缴存年月',
            key: '末次缴存年月',
            render: (record) => <span>{record.data?.末次缴存年月 || '202106'}</span>,
        },
        {
            title: '封存原因',
            key: '封存原因',
            render: (record) => <span>{record.data?.封存原因 || ''}</span>,
        },
        {
            title: '退工日期',
            key: '退工日期',
            render: (record) => <span>{record.data?.退工日期 || ''}</span>,
        },
    ]
    return (
        <div className="gjj-QiFeng-page">
            <div className="QiFeng-header">
                <Link to={prevUrl} onClick={selectChecked} className="current">
                    首页
                </Link>
                &gt;
                <span className="header-personal">封存</span>
            </div>
            <div className="QiFeng-main">
                <div className="main-header">
                    <PublicForm />
                </div>
                <div className="main-button">
                    <Button onClick={transferOut}>封存</Button>
                    <Button>批量导入</Button>
                </div>
                <Alert
                    className="alert-warn"
                    message="身份证信息为空或全为0，或不满足身份证规则的职工需先变更身份证信息后，方可使用在线封存业务！"
                    type="warning"
                    showIcon={true}
                    icon={<img src={img} className="icon-img" />}
                />
                <Table
                    columns={columns}
                    dataSource={prevList}
                    className="main-table"
                    pagination={false}
                    rowKey="key"
                    bordered
                    scroll={{ x: 'max-content' }}
                    style={{ margin: '20px 0 30px 0' }}
                />
            </div>
        </div>
    )
}

export default FengCun
