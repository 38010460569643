import React, { useMemo } from 'react'
import moment from 'moment'

/**
 * 使用方法
 * <Form.Item>
 *      <StringDateWrap component={DatePicker} /> or <StringDateWrap component={DatePicker.MonthPicker} />
 * </Form.Item>
 * value 接受 "20200805"
 * onChange(val) val="20200805"
 */
export function StringDateWrap({ component, onChange, value, ...rest }) {
    const _onChange = (momentValue, dateString) => {
        onChange?.(dateString)
    }

    const _value = useMemo(() => {
        if (!value) return null
        const momentValue = moment(value)
        if (momentValue.isValid()) {
            return momentValue
        }
        return null
    }, [value])

    const props = { ...rest, value: _value, onChange: _onChange }

    return React.cloneElement(component, props)
}

export default StringDateWrap
