import { useLayoutEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import AxiosRequest from '../../../services/AxiosRequest'

import Login from '../login'
import ExamInfo from '../info'
import Exam from '../exam'
import adapter from './adapter'

// match.path => "/mock"
const MockExam = ({ match }) => {
    useLayoutEffect(() => {
        AxiosRequest.defaults.adapter = adapter
        // console.log('%c 使用 adapter', 'background: #ffff00; color: #ff0000')
        return () => {
            AxiosRequest.defaults.adapter = null
        }
    }, [])

    return (
        <>
            <Switch>
                <Route path={`${match.path}/:id/info`} component={ExamInfo} />
                <Route path={`${match.path}/:id/step/:step`} component={Exam} />
                <Route path={[`${match.path}`, `${match.path}/login`]} component={Login} />
            </Switch>
        </>
    )
}

export default MockExam
