export const combineURLs = (baseURL, relativeURL) => {
    return relativeURL ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '') : baseURL
}

export const isAbsoluteURL = (url) => {
    // eslint-disable-next-line
    return /^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(url)
}

export const buildFullPath = (baseURL, requestedURL) => {
    if (baseURL && !isAbsoluteURL(requestedURL)) {
        return combineURLs(baseURL, requestedURL)
    }
    return requestedURL
}

/**
 * 从 localStorage 读取对象
 * @param {string} key key
 * @param {object} defaultValue 默认值（对象）
 * @returns {object}
 */
export const getData = (key, defaultValue) => {
    const string = window.localStorage.getItem(key)
    try {
        return JSON.parse(string) || defaultValue
    } catch (error) {
        if (defaultValue) {
            return defaultValue
        }
        console.error('读取对象失败', error, string)
    }
}

/**
 * 写入到 localStorage
 * @param {string} key
 * @param {any} value
 */
export const setData = (key, value) => {
    try {
        const valueString = JSON.stringify(value)
        window.localStorage.setItem(key, valueString)
    } catch (error) {
        console.error('写入对象失败', error, key, value)
    }
}

export const removeKeys = (keys = []) => {
    keys?.forEach((key) => {
        window.localStorage.removeItem(key)
    })
}
