//cSpell:word shebao
import React from 'react'
import { useRouteMatch, Route, Switch } from 'react-router-dom'

import message from '../components/Message'
import HomePage from './HomePage'
import DetailPage from './DetailPage'

import noticeSrc from '../../../../../resources/shebao/notice.png'
import userSrc from '../../../../../resources/shebao/user.png'
import './index.styl'

const SheBao = ({ data, onNewRecordSave }) => {
    const match = useRouteMatch()
    const onCommonSave = (newRecord) => {
        return onNewRecordSave(newRecord).then(() => message.success('提交成功！'))
    }
    return (
        <div className="simulation-shebao">
            <div className="page-header">
                <div className="system-name">人力资源和社会保障自助经办系统（模拟上海市）</div>
                <div className="header-right">
                    <img src={noticeSrc} className="img-notice" />
                    <div className="user-wrap">
                        <img src={userSrc} className="img-user" />
                    </div>
                </div>
            </div>
            <div className="page-content scrollbar">
                <Switch>
                    <Route path={`${match.path}`} exact render={() => <HomePage />} />
                    <Route
                        path={[`${match.path}/:level1`, `${match.path}/:level1/:level2`]}
                        render={() => (
                            <DetailPage
                                prevPath={match.path}
                                prevUrl={match.url}
                                data={data}
                                onNewRecordSave={onCommonSave}
                            />
                        )}
                    />
                </Switch>
            </div>
        </div>
    )
}

export default SheBao
