import Confirm from '../../components/Confirm'
import './Confirm.styl'

const CustomConfirm = ({ className, ...props }) =>
    Confirm({
        className: `sb-confirm ${className || ''}`,
        getContainer: null,
        ...props,
    })

export default CustomConfirm
