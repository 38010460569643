import React from 'react'
import { Form, Input } from 'antd'

export default ({ maxLength = 300 }) => (
    <Form.Item
        name="key"
        label="标识"
        validateTrigger="onBlur"
        rules={[{ required: true, whitespace: true, message: '内容不能为空' }]}
        extra="方便在题库中搜索该题"
    >
        <Input.TextArea showCount maxLength={maxLength} onPressEnter={(e) => e.preventDefault()} />
    </Form.Item>
)
