import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Breadcrumb, message, Tabs } from 'antd'

import Loading from '../../../../components/loading'
import { getExam } from '../../../../services/ExamsRequest'
import Paper from './paperSetting'
import Students from './students'

const ExamDetail = (props) => {
    const activeKey = props.location.activeKey
    const { id } = useParams()
    const [exam, setExam] = useState(null)
    useEffect(() => {
        getExam(id)
            .then((res) => {
                setExam(res)
            })
            .catch((error) => {
                if (error?.response?.status === 404) {
                    message.warn(
                        <>
                            考试不存在！,<a href="/kw/exam">返回考试列表</a>
                        </>,
                        0
                    )
                }
            })
    }, [id])
    if (!exam) return <Loading />
    return (
        <div className="exam-detail-page">
            <div className="page-header">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item key="/kw/exam">
                        <Link to="/kw/exam">考试管理</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>考试详情</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-title" style={{ marginTop: '10px', marginBottom: 10, fontSize: '22px' }}>
                {exam.name}
            </div>
            <Tabs type="card" defaultActiveKey={activeKey}>
                <Tabs.TabPane tab="考生列表" key="1">
                    <Students exam={exam} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="试卷配置" key="2">
                    <Paper exam={exam} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default ExamDetail
