//cSpell:word gongjijin
import React from 'react'
import { useRouteMatch, Route, Switch } from 'react-router-dom'

import HomePage from './HomePage'

import logoSrc from '../../../../../resources/gongjijin/logo.png'
import signOutSrc from '../../../../../resources/gongjijin/退出.png'
import userSrc from '../../../../../resources/gongjijin/编组.png'
import './index.styl'

const GongJiJin = ({ data, onNewRecordSave }) => {
    const match = useRouteMatch()
    return (
        <div className="simulation-gongjijin">
            <div className="page-header">
                <div className="page-left">
                    <img src={logoSrc} className="img-logo" />
                    <div className="system-name">住房公积金单位网上业务办理系统（模拟上海市）</div>
                </div>
                <div className="page-right">
                    <div className="page-middle">
                        <p>汇补缴业务办理时间：工作日9：00～16：00</p>
                        <p>除汇补缴以外的单位业务办理时间：全天24小时</p>
                    </div>
                    <div className="page-user">
                        <img src={userSrc} />
                        单位用户
                    </div>
                    <div className="page-sign">
                        <img src={signOutSrc} className="img-sign-out" />
                        <p className="sign-out">安全退出</p>
                    </div>
                </div>
            </div>
            <div className="page-content scrollbar">
                <Switch>
                    <Route
                        path={[`${match.path}`, `${match.path}/:level1`]}
                        render={() => (
                            <HomePage
                                prevPath={match.path}
                                prevUrl={match.url}
                                data={data}
                                onNewRecordSave={onNewRecordSave}
                            />
                        )}
                    />
                </Switch>
            </div>
        </div>
    )
}

export default GongJiJin
