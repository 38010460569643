import React, { useState } from 'react'
import { Checkbox } from 'antd'
import './SelectPanel.styl'

const SelectPanel = ({ title, children, disabled }) => {
    const [visible, setVisible] = useState(false)
    const onVisibleChange = (e) => {
        setVisible(e.target.checked)
    }
    if (disabled) return null
    return (
        <div className="panel sb-select-panel">
            <div className="panel-title">
                <Checkbox onChange={onVisibleChange} disabled={disabled}>
                    {title}
                </Checkbox>
            </div>
            {visible && <div className="panel-content">{children}</div>}
        </div>
    )
}

export default SelectPanel
