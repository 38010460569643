import React, { useState, useEffect, Suspense } from 'react'
import { renderRoutes } from 'react-router-config'
import { useHistory } from 'react-router-dom'
import { ping } from '../../../services/UserRequest'
import Loading from '../../../components/loading'
import Sidebar from './Sidebar'
import './index.styl'

const Layout = (props) => {
    const [user, setUser] = useState(null)
    const history = useHistory()
    useEffect(() => {
        if (!user) {
            ping()
                .then((res) => setUser(res))
                .catch(() => history.replace(`/kw/login?back=${location.pathname}`))
        }
    }, [user, history])
    if (!user) return null
    return (
        <div className="page-layout">
            <Sidebar user={user} />
            <main className="scrollbar">
                <Suspense fallback={<Loading />}>{renderRoutes(props.route.routes, user)}</Suspense>
            </main>
        </div>
    )
}

export default Layout
