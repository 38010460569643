import React, { useEffect, useState } from 'react'
import { find, filter, camelCase } from 'lodash'
import { getPaperQuestions } from '../../../../services/PaperRequest'
import { typeNames, chNos } from '../../../ks/exam/configs'
import message from '../../../../components/message/index'
import MultipleChoice from '../../../ks/exam/questions/MultipleChoice'
import MultipleSelection from '../../../ks/exam/questions/MultipleSelection'
import TrueOrFalse from '../../../ks/exam/questions/TrueOrFalse'
import Sorting from '../../../ks/exam/questions/Sorting'
import Text from '../../../ks/exam/questions/Text'
import Analyze from '../../../ks/exam/questions/Analyze'
import Operate from '../../../ks/exam/questions/Operate'

import { ExamProvider } from '../../../ks/exam/Context'

import './Preview.styl'

export default ({ paper, examId, answerVisible }) => {
    const [questions, setQuestions] = useState([])
    useEffect(() => {
        getPaperQuestions(paper.id)
            .then((res) => {
                const qs = []
                const typeList = [
                    'MultipleChoice',
                    'MultipleSelection',
                    'TrueOrFalse',
                    'Sorting',
                    'Text',
                    'Analyze',
                    'Operate',
                ]
                let currentIndex = 0
                typeList.forEach((type) => {
                    const questionIds = paper[`${camelCase(type)}List`] || []
                    if (questionIds.length > 0) {
                        const typeQuestions = filter(res, (r) => questionIds.indexOf(r.id) !== -1).sort(
                            (a, b) => questionIds.indexOf(a.id) - questionIds.indexOf(b.id)
                        )
                        let totalScore = 0
                        typeQuestions.forEach((t, tIndex) => {
                            t.index = currentIndex++
                            if (type === 'Analyze' || type === 'Operate') {
                                const scores = filter(paper.scores, (s) => s.questionId === t.id)
                                let analyzeScore = 0
                                scores.forEach((s) => (analyzeScore += s.score))
                                t.score = analyzeScore
                                t.baseQuestions?.forEach((q, baseIndex) => {
                                    q.index = baseIndex
                                    q.userAnswer = q.answer || {
                                        questionAnswer: {},
                                        questionId: q.id,
                                        questionIndex: baseIndex,
                                    }
                                    q.score = find(scores, (s) => s.index === baseIndex)?.score || 0
                                    totalScore += q.score
                                })
                            } else {
                                t.score = find(paper.scores, (s) => s.questionId === t.id)?.score || 0
                                totalScore += t.score
                            }
                        })
                        qs.push({
                            name: `${chNos[qs.length]}、${typeNames[type]}
                        （共${typeQuestions.length}题，满分${
                                String(totalScore).indexOf('.') + 1 > 0 ? totalScore?.toFixed(1) : totalScore
                            }分）`,
                            children: typeQuestions,
                        })
                    }
                })
                setQuestions(qs)
            })
            .catch((error) => {
                const status = error?.response?.status
                if (status === 403) {
                    message.error('没有权限查看题目')
                    return
                }

                message.error('题目获取错误')
            })
    }, [paper, examId])

    const RenderQuestion = (question) => {
        const type = question.type
        const options = {
            key: question.id,
            stepData: question,
            preview: true,
            answerVisible,
        }
        switch (type) {
            case 'MultipleChoice':
                return <MultipleChoice {...options} />
            case 'MultipleSelection':
                return <MultipleSelection {...options} />
            case 'Sorting':
                return <Sorting {...options} />
            case 'TrueOrFalse':
                return <TrueOrFalse {...options} />
            case 'Text':
                return <Text {...options} />
            case 'Analyze':
                return <Analyze staticPosition {...options} />
            case 'Operate':
                return <Operate staticPosition {...options} />
            default:
                return null
        }
    }

    const state = {
        user: {},
        examData: { questions: {}, exam: {}, paper: {} },
        answers: {},
    }

    return (
        <ExamProvider originData={state}>
            <div className="paper-preview">
                {questions.map((question) => (
                    <div className="question-module" key={question.name}>
                        <div className="question-name">{question.name}</div>
                        {question.children?.map((q) => RenderQuestion(q))}
                    </div>
                ))}
            </div>
        </ExamProvider>
    )
}
