import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import produce from 'immer'
import moment from 'moment'
import { Table, Space, Dropdown, Modal, Checkbox, Button, Pagination } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'

import { duplicateExam, putStatus } from '../../../../services/ExamsRequest'
import Loading from '../../../../components/loading'
import message from '../../../../components/message'
import SetupList from './SetupList'
import ListMenu from './ListMenu'
import './List.styl'

import { levelNames, types, subjects, scoreStatus, status } from './configs'

const examProgress = (exam) => {
    const now = Date.now()
    if (now > exam.startDate && now < exam.endDate) {
        return '考试中'
    }
    if (now < exam.startDate) {
        return '待考试'
    }
    if (now > exam.endDate) {
        return '已结束'
    }
}

const List = ({ loading, data, onRefresh }) => {
    const [settingData, setSettingData] = useState({ visible: false, record: {} })
    const [copyData, setCopyData] = useState({ visible: false })

    const onStatusChange = (record) => {
        const data = { status: record.status === 'Release' ? 'Unpublished' : 'Release' }
        putStatus(record.id, data)
            .then((res) => {
                message.success('操作成功！')
                onRefresh()
            })
            .catch((err) => message.error(err.response.data.message + '！'))
    }

    const columns = [
        {
            title: '序号',
            key: 'num',
            width: 50,
            render: (text, record, index) => (
                <span className={record.pin ? 'pin' : ''}>{data.number * 10 + index + 1}</span>
            ),
        },
        {
            title: 'ID',
            dataIndex: 'sequence',
            key: 'sequence',
        },
        {
            title: '基本信息',
            key: 'information',
            width: 250,
            render: (record) => (
                <div>
                    <p style={{ fontWeight: 'bold', fontSize: '17px' }}>{record.name}</p>
                    <p>类型：{types[record.type]}</p>
                    <p>科目：{subjects[record.subject]}</p>
                    <p>等级：{levelNames[record.level]}</p>
                </div>
            ),
        },
        {
            title: '时间进度',
            key: 'time',
            width: 250,
            render: (record) => (
                <div>
                    <p>评分状态：{scoreStatus[record.scoreStatus]}</p>
                    <p>考试进度：{examProgress(record)}</p>
                    <p>开始时间：{moment(record.startDate).format('YYYY-MM-DD HH:mm:ss')}</p>
                    <p>结束时间：{moment(record.endDate).format('YYYY-MM-DD HH:mm:ss')}</p>
                    <p>考试时长：{record.durationMinute}分钟</p>
                    <p>允许提前登陆时间：15分钟</p>
                    <p>倒计时提醒：{record.countdownMinute}分钟</p>
                </div>
            ),
        },
        {
            title: '试卷',
            key: 'paper',
            width: 100,
            render: (record) => <span>{record.paperOriginalId === null ? '未配置' : '已配置'}</span>,
        },
        {
            title: '考生数',
            key: 'number',
            render: (record) => <span>{record.candidateCount}</span>,
        },
        {
            title: '发布状态',
            key: 'status',
            render: (record) => <span>{status[record.status]}</span>,
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <div style={{ width: '90px' }}>
                        {record.status === 'Unpublished' && (
                            <Button style={{ marginBottom: '10px' }} onClick={() => onStatusChange(record)} block>
                                发布
                            </Button>
                        )}
                        <Button type="primary" block>
                            <Link to={`/kw/exam/detail/${record.id}`}>进入详情</Link>
                        </Button>
                    </div>
                    <Dropdown
                        overlay={() => ListMenu(record, setSettingData, setCopyData, onStatusChange, onRefresh)}
                        record={record}
                    >
                        <EllipsisOutlined rotate={90} style={{ fontSize: '24px', cursor: 'pointer' }} />
                    </Dropdown>
                </Space>
            ),
        },
    ]

    const onCopyOk = () => {
        duplicateExam(copyData.id, { paper: copyData.paper, candidate: copyData.candidate })
            .then((res) => {
                setCopyData({ visible: false })
                message.success('复制成功')
                onRefresh()
            })
            .catch(() => message.error('操作失败，请稍后重试！'))
    }

    const onCheckboxChange = (e) => {
        setCopyData((prev) => {
            return produce(prev, (draft) => {
                draft[e.target.name] = e.target.checked
            })
        })
    }

    return (
        <div className="page-slit-container">
            <div style={{ float: 'right', marginBottom: 8 }}>共找到 {data.totalElements || 0} 个考试</div>
            {loading ? (
                <Loading />
            ) : (
                <Table
                    columns={columns}
                    dataSource={data.content}
                    rowKey="id"
                    pagination={false}
                    className="exam-table"
                />
            )}
            <Pagination
                defaultCurrent={1}
                total={data.totalElements}
                current={data.number + 1}
                hideOnSinglePage={true}
                onChange={onRefresh}
                showSizeChanger={false}
            />
            <SetupList settingData={settingData} setSettingData={setSettingData} onRefresh={onRefresh} />
            <Modal
                title="选择需要复制的内容"
                visible={copyData.visible}
                onOk={onCopyOk}
                onCancel={() => setCopyData({ visible: false })}
            >
                <p>
                    <Checkbox defaultChecked disabled>
                        考试设置
                    </Checkbox>
                </p>
                <p>
                    <Checkbox name="paper" checked={copyData.paper} onChange={onCheckboxChange} block>
                        试卷
                    </Checkbox>
                </p>
                <p>
                    <Checkbox name="candidate" checked={copyData.candidate} onChange={onCheckboxChange}>
                        考生信息
                    </Checkbox>
                </p>
            </Modal>
        </div>
    )
}

export default List
