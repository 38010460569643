import React from 'react'
import Confirm from '../../../../components/modal/Confirm'

export default (completedNum, unCompletedNum, onConfirm) => {
    return Confirm({
        className: 'confirm-submit-modal',
        title: '交卷确认',
        centered: true,
        content: (
            <div className="confirm-submit-view">
                <div>
                    交卷后答案<b>不可再做修改！</b>系统将会自动退出！
                </div>
                <div className="paper-status">
                    <span>
                        已完成：<b>{completedNum}</b>题
                    </span>
                    <span>
                        未完成：<b>{unCompletedNum}</b>题
                    </span>
                </div>
                <div>是否确认交卷？</div>
            </div>
        ),
        onOk: () => onConfirm(),
    })
}
