import provinces from 'china-division/dist/provinces.json'
import cities from 'china-division/dist/cities.json'
import areas from 'china-division/dist/areas.json'

areas.forEach((area) => {
    const matchCity = cities.filter((city) => city.code === area.cityCode)[0]
    if (matchCity) {
        matchCity.children = matchCity.children || []
        matchCity.children.push({
            label: area.name,
            value: area.name,
        })
    }
})

cities.forEach((city) => {
    const matchProvince = provinces.filter((province) => province.code === city.provinceCode)[0]
    if (matchProvince) {
        matchProvince.children = matchProvince.children || []
        const realName = city.name === '市辖区' ? matchProvince.name : city.name
        matchProvince.children.push({
            label: realName,
            value: realName,
            children: city.children,
        })
    }
})

const options = provinces.map((province) => ({
    label: province.name,
    value: province.name,
    children: province.children,
}))

export const provinceOptions = provinces.map((province) => ({
    label: province.name,
    value: province.name,
}))

export default options
