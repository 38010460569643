import React from 'react'
import { notification } from 'antd'
import './index.styl'

const showMessage = (message, isUnique, style) => {
    const key = Date.now()
    const options = {
        className: `exam-message ${style || 'success'}-style`,
        icon: <i className={`iconfont icon-${style}`} />,
        message: (
            <div className={'error-message-view'}>
                <div className="message-content">{message}</div>
            </div>
        ),
        description: '',
        key,
        placement: 'bottomLeft',
        duration: 5,
    }
    if (isUnique) {
        notification.destroy()
        setTimeout(() => {
            notification.open(options)
        }, 200)
    } else {
        notification.open(options)
    }
}

const success = (message, isUnique) => showMessage(message, isUnique, 'success')
const error = (message, isUnique) => showMessage(message, isUnique, 'error')

const message = { success, error, warn: error }

export default message
