import React, { useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { logout, putPassword } from '../../../services/UserRequest'
import { Modal, Form, Input, message } from 'antd'
import './Sidebar.styl'

const Sidebar = ({ user }) => {
    const [modules, setModules] = useState([])
    const history = useHistory()
    useEffect(() => {
        const roles = user?.roles || []
        const allModules = [
            { name: '考试管理', link: '/kw/exam', auth: 'Exam' },
            { name: '试卷管理', link: '/kw/paper', auth: 'Paper' },
            { name: '题目管理', link: '/kw/question', auth: 'Question' },
            { name: '模拟演练', link: '/kw/maneuvers', auth: 'Exam' },
        ]
        setModules(allModules.filter((m) => roles.indexOf(m.auth) !== -1))
    }, [user])
    const onLogout = () => {
        logout().finally(() => {
            setTimeout(() => history.replace('/kw/login'), 0)
        })
    }

    return (
        <div className="page-sidebar">
            {modules.map((module) => (
                <NavLink key={module.link} to={module.link}>
                    {module.name}
                </NavLink>
            ))}
            <div className="bottom-bar">
                <PasswordConfirm nickName={user.nickName} />
                <span className="logout" onClick={onLogout}>
                    退出
                </span>
            </div>
        </div>
    )
}

const PasswordConfirm = ({ nickName }) => {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const openModal = () => setVisible(true)
    const closeModal = () => {
        form.resetFields()
        setVisible(false)
    }

    const updatePassword = () => {
        setLoading(true)
        form.validateFields().then((values) => {
            putPassword(values)
                .then(() => {
                    message.success('更新成功！')
                    setVisible(false)
                })
                .catch((error) => {
                    message.error(error.response?.data?.message || '操作失败！请刷新重试')
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    return (
        <>
            <span onDoubleClick={openModal}>{nickName}，</span>
            <Modal
                title="修改密码"
                visible={visible}
                okButtonProps={{ loading }}
                onOk={updatePassword}
                okTex="更新"
                cancelText="关闭"
                onCancel={closeModal}
            >
                <Form form={form} layout="vertical">
                    <Form.Item label="旧密码" name="oldPassword" rules={[{ required: true, message: '不能为空' }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item label="新密码" name="password" rules={[{ required: true, message: '不能为空' }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="确认新密码"
                        name="passwordConfirm"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: '不能为空' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('两次输入的密码不一致'))
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default Sidebar
