import React, { useState, useEffect } from 'react'
import { isNumber, find } from 'lodash'

import './ScoreStatistics.styl'

const ScoreStatistics = ({ inScored, data, questions }) => {
    const [scoresList, setScoresList] = useState({ paper: [], answers: [], totalScore: 0 })
    useEffect(() => {
        const listObj = { paper: [], answers: [], totalScores: 0 }
        const scoreList = { paper: [], answers: [] }
        const paperQuestionId = data.paper.scores.filter((item) => item.questionId)
        const answerQuestionId = data.answers.filter((item) => item.questionId)
        data.questions.forEach((item) => {
            paperQuestionId.forEach((i) => {
                if (item.id === i.questionId) {
                    listObj.paper.push({ typeName: item.typeName, score: i.score })
                }
            })
            answerQuestionId.forEach((i) => {
                if (item.id === i.questionId) {
                    listObj.answers.push({ typeName: item.typeName, score: i.score })
                }
            })
        })
        questions.forEach((item) => {
            let paperCount = 0
            let answerCount = 0
            let answerScore = []
            const paperTypeNameList = listObj.paper.filter((a) => a.typeName === item.name)
            paperTypeNameList.forEach((m) => {
                paperCount += m.score
            })
            scoreList.paper.push(paperCount)
            const answerTypeNameList = listObj.answers.filter((a) => a.typeName === item.name)
            answerTypeNameList.map((m) => {
                return answerScore.push(m.score)
            })
            if (item.value === 'Text') {
                if (
                    data.answers.length === 0 ||
                    data.questions.some((q) => q.type === 'Text' && !find(data.answers, (s) => s.questionId === q.id))
                ) {
                    answerCount = '待评分'
                }
            } else if (item.value === 'Analyze') {
                const unScored = data.questions.some((q) => {
                    return (
                        q.type === 'Analyze' &&
                        q.baseQuestions.some(
                            (bq, index) =>
                                bq.type === 'Text' &&
                                !find(data.answers, (s) => s.questionId === q.id && s.questionIndex === index)
                        )
                    )
                })
                if (data.answers.length === 0 || unScored) {
                    answerCount = '待评分'
                }
            }
            if (answerCount === '待评分' || answerScore.some((n) => !isNumber(n))) {
                scoreList.answers.push('待评分')
            } else {
                answerTypeNameList.forEach((m) => {
                    answerCount += m.score
                })
                scoreList.answers.push(answerCount)
            }
        })
        if (scoreList.answers.some((item) => item === '待评分')) {
            listObj.totalScores = '待计算'
        } else {
            scoreList.answers.forEach((item) => {
                listObj.totalScores += item
            })
        }

        setScoresList({ paper: scoreList.paper, answers: scoreList.answers, totalScore: listObj.totalScores })
    }, [data, questions])
    return (
        <div className="score-statistics">
            <div className="column">
                <div className="row-1" />
                <div className="row-2">考生</div>
                <div className="row-3">试卷</div>
            </div>
            <div className="column">
                <div className="row-1">总分</div>
                <div className={inScored && scoresList.totalScore === '待计算' ? 'active' : 'row-2'}>
                    {!inScored
                        ? '-'
                        : String(scoresList.totalScore).indexOf('.') + 1 > 0
                        ? scoresList.totalScore?.toFixed(1)
                        : scoresList.totalScore}
                </div>
                <div className="row-3">
                    {String(data.paper.totalScore).indexOf('.') + 1 > 0
                        ? data.paper.totalScore?.toFixed(1)
                        : data.paper.totalScore}
                </div>
            </div>
            <div className="columns">
                <div className="rows-1">
                    {questions.map((item, index) => (
                        <span key={index}>{item.name}</span>
                    ))}
                </div>

                <div className="rows-2">
                    {scoresList.answers.map((item, index) => (
                        <span className={inScored && item === '待评分' ? 'active' : ''} key={index}>
                            {!inScored && (item === '待评分' || (item === 0 && questions[index]?.name !== '简答题'))
                                ? '-'
                                : String(item).indexOf('.') + 1 > 0
                                ? item?.toFixed(1)
                                : item}
                        </span>
                    ))}
                </div>

                <div className="rows-3">
                    {scoresList.paper.map((item, index) => (
                        <span key={index}>{String(item).indexOf('.') + 1 > 0 ? item?.toFixed(1) : item}</span>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ScoreStatistics
