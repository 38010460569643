import { filter } from 'lodash'

export const dataInput = (originData) => {
    if (!originData) {
        return {
            name: '',
            level: 'Beginner',
            subject: 'Theory',
            description: '',
            analyzeList: [],
            multipleChoiceList: [],
            multipleSelectionList: [],
            sortingList: [],
            textList: [],
            trueOrFalseList: [],
            operateList: [],
            scores: [],
        }
    }
    return originData
}
export const dataOutput = (data) => {
    const {
        analyzeList,
        multipleChoiceList,
        multipleSelectionList,
        sortingList,
        textList,
        trueOrFalseList,
        operateList,
    } = data
    const questionCount =
        analyzeList.length +
        multipleChoiceList.length +
        multipleSelectionList.length +
        sortingList.length +
        textList.length +
        trueOrFalseList.length +
        operateList.length
    data.description = data.description.trim()
    const scoreCount = filter(data.scores, 'questionId').length
    if (questionCount === 0) data.error = '请至少添加1道题目！'
    if (questionCount > scoreCount) data.error = '请给所有题目设置分值！'
    return data
}
